import NoticeDetailPage from './NoticeDetailPage';

export const NoticeDetailPageConfig = {
  routes: [
    {
      path: '/pages/notice/detail',
      exact: true,
      component: NoticeDetailPage,
    },
  ],
};
