import React, { useEffect, useRef, useState } from 'react';
import queryString from 'query-string';
import OftadehLayout from '../../components/OftadehLayout/OftadehLayout';
import {
  Button,
  CircularProgress,
  FormControlLabel,
  FormLabel,
  Grid,
  makeStyles,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import swal from '@sweetalert/with-react';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import SaveIcon from '@material-ui/icons/Save';
import ListIcon from '@material-ui/icons/List';
import DeleteIcon from '@material-ui/icons/Delete';
import { getNewsCategoryList, getNewsInfo } from '../../constants/PromiseHttp';
import { API_URL, DEF_LANGUAGE, DEF_NEWS_CATE, getDefName } from '../../constants';
import axiosInstance from '../../constants/AxiosInstance';
import FormControl from '@material-ui/core/FormControl';
import LanguageAutocomplete from '../customComp/LanguageAutocomplete';
import HospitalAutocomplete from '../customComp/HospitalAutocomplete';
import DiseaseTransferList from '../customComp/DiseaseTransferList';
import FileUpload from '../../components/FileUpload/FileUpload';
import Jodit from '../../components/Jodit/Jodit';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  my3: {
    margin: '1.3rem 0',
  },
}));

const NewsEditPage = (props) => {
  const query = queryString.parse(props.location.search);
  const type = query.type ? query.type : 'new';
  const IS_SYS_ADMIN = sessionStorage.getItem('bt_role') === 'SYS_ADM';

  const editor = useRef(null);
  const [news, setNews] = useState({});
  const [categoryList, setCategoryList] = useState([]);

  const [uploadFile, setUploadFile] = useState([]);
  const childRef = useRef(null);
  const imgKey = 'images';

  const { history } = props;
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getData();
    getNewsCategoryList().then((result) => {
      // console.log(result);
      setCategoryList(result);
    });
  }, []);

  const getData = () => {
    if (type === 'edit' && query.id) {
      getNewsInfo(query.id).then((result) => {
        setNews(result);
        if (editor.current) {
          editor.current.setValue(result['contents']);
        }
        if (childRef.current) {
          childRef.current.getSignedUrl(result[imgKey]);
        }
      });
    }
  };

  const addNews = async (e) => {
    e.preventDefault();
    const contents = await editor.current.getValue();
    handleChangeData(contents, 'contents');

    if (!news.title) {
      swal('Missing required title.');
      return;
    }
    if (!news.contents) {
      swal('Missing required contents of news.');
      return;
    }
    if ((!uploadFile || uploadFile.length === 0) && (!news[imgKey] || news[imgKey].length === 0)) {
      swal('Missing required image of news.');
      return;
    }

    let param = {
      title: news.title,
      contents: news.contents,
      categoryId: news.categoryId || DEF_NEWS_CATE,
      languageId: news.languageId || DEF_LANGUAGE,
      hospitalId: news.hospitalId,
      tags: news.tags,
      hidden: IS_SYS_ADMIN ? news.hidden || false : true,
      subDiseaseIds: news.subDiseaseIds,
    };

    setLoading(true);
    if (type === 'new') {
      param['writerId'] = sessionStorage.getItem('bt_user');
      param['isSysAdmin'] = IS_SYS_ADMIN;
      axiosInstance.post(`${API_URL}/news`, param, { headers: { Pragma: 'no-cache' } }).then((response) => {
        if (response.status === 200) {
          if (childRef.current) {
            childRef.current.fileUpload(uploadFile, response.data.value.id, 'news', `${API_URL}/news/`).then(() => {
              setLoading(false);
              swal('Registered.', {
                icon: 'success',
              });
              goList();
            });
          } else {
            setLoading(false);
            swal('Registered.', {
              icon: 'success',
            });
            goList();
          }
        } else {
          setLoading(false);
        }
      });
    } else {
      param['id'] = query.id;
      //console.log(param);
      axiosInstance.put(`${API_URL}/news`, param, { headers: { Pragma: 'no-cache' } }).then(async (response) => {
        if (response.status === 200) {
          if (childRef.current) {
            await Promise.all([childRef.current.fileUpload(uploadFile, query.id, 'news', `${API_URL}/news/`)]);

            swal('It is changed.', {
              icon: 'success',
            });
            goDetail();
          } else {
            swal('It is changed.', {
              icon: 'success',
            });
            goDetail();
          }
        }
      });
    }
  };

  const deleteNews = (e) => {
    e.preventDefault();
    swal({
      title: 'Are you sure you want to delete?',
      text: '',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        axiosInstance.delete(`${API_URL}/news/${query.id}`, { headers: { Pragma: 'no-cache' } }).then((response) => {
          if (response.status === 200) {
            swal('It is deleted.', {
              icon: 'success',
            });
            goList();
          }
        });
      } else {
      }
    });
  };

  const goList = () => {
    history.push('/pages/news/');
  };

  const goDetail = () => {
    history.push(`/pages/news/detail?type=edit&id=${query.id}`);
  };

  const setSelectedDisease = (value) => {
    handleChangeData(value, 'subDiseaseIds');
  };

  const handleChangeData = (value, field) => {
    news[field] = value;
    setNews({ ...news });
  };

  const handleFile = (file, key) => {
    console.log(file, key);
    if (key === imgKey) {
      setUploadFile([...file]);
    }
  };
  const handleUpload = () => {
    console.log('Upload Success');
  };
  const handleRemove = () => {
    console.log('Remove Success');
    setUploadFile([]);
  };

  return (
    <OftadehLayout>
      <Grid container className={classes.my3} alignItems="center">
        <Grid item className={classes.mRight} style={{ fontWeight: 'bold' }}>
          <Typography variant="h5" component="h1" style={{ fontWeight: 'bold' }}>
            News & Column {type === 'new' ? 'Registration' : 'Edit'}
          </Typography>
        </Grid>
      </Grid>

      <Card className={classes.root}>
        <CardContent>
          <Grid container xs={12} md={12}>
            <Grid item xs={6} mb={6}>
              <Typography component="legend">
                Language <span style={{ color: 'red' }}>*</span>
              </Typography>
              <LanguageAutocomplete target={news} handleChangeData={handleChangeData} />
            </Grid>
            <Grid item xs={6} mb={6}>
              <Typography component="legend">Hospital</Typography>
              <HospitalAutocomplete target={news} handleChangeData={handleChangeData} />
            </Grid>
            <Grid item xs={10}>
              <FormControl component="fieldset">
                <Typography component="legend">
                  Category <span style={{ color: 'red' }}>*</span>
                </Typography>
                {categoryList && (
                  <RadioGroup
                    aria-label="categoryId"
                    name="categoryId"
                    onChange={(event) => {
                      handleChangeData(event.target.value, 'categoryId');
                    }}
                    row
                  >
                    <FormControlLabel
                      value={DEF_NEWS_CATE}
                      checked={news.categoryId === undefined || news.categoryId === (categoryList[0] && categoryList[0].id)}
                      control={<Radio />}
                      label={(categoryList[0] && categoryList[0][getDefName()]) || ''}
                    />
                    <FormControlLabel
                      value={categoryList[1] && categoryList[1].id}
                      checked={news.categoryId === (categoryList[1] && categoryList[1].id)}
                      control={<Radio />}
                      label={(categoryList[1] && categoryList[1][getDefName()]) || ''}
                    />
                    <FormControlLabel
                      value={categoryList[2] && categoryList[2].id}
                      checked={news.categoryId === (categoryList[2] && categoryList[2].id)}
                      control={<Radio />}
                      label={(categoryList[0] && categoryList[2][getDefName()]) || ''}
                    />
                  </RadioGroup>
                )}
              </FormControl>
            </Grid>
            {IS_SYS_ADMIN && (
              <Grid item xs={2} mb={6}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={!news.hidden}
                      onChange={(e, newValue) => {
                        handleChangeData(!newValue, 'hidden');
                      }}
                    />
                  }
                  label="Show"
                />
              </Grid>
            )}
            <Grid item xs={12} style={{ marginTop: 20 }}>
              <Typography component="legend">
                Title <span style={{ color: 'red' }}>*</span>
              </Typography>
              <TextField
                className={classes.my3}
                placeholder="Please enter a title."
                margin="normal"
                style={{ width: '100%' }}
                InputLabelProps={{
                  shrink: true,
                }}
                value={news.title || ''}
                variant={'outlined'}
                onChange={(event) => {
                  handleChangeData(event.target.value, 'title');
                }}
              />
            </Grid>

            <Grid item xs={12} style={{ marginTop: 10 }}>
              <Typography component="legend">
                Contents <span style={{ color: 'red' }}>*</span>
              </Typography>
              <Jodit ref={editor} id={'editor'} uploadApi={`${API_URL}/upload`} val={news.contents} />
            </Grid>
            <Grid item xs={12} style={{ marginTop: '10px' }}>
              <Typography style={{}}>Tags</Typography>
              <TextField
                size={'small'}
                key={`TextField-tags`}
                className={classes.mInput}
                placeholder=",Please enter them separated by commas."
                margin="normal"
                style={{ width: '100%', marginRight: '10px' }}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                value={news.tags || ''}
                onChange={(event) => {
                  handleChangeData(event.target.value, 'tags');
                }}
              />
            </Grid>

            <Grid item xs={12} mb={12} style={{ marginTop: '10px' }}>
              <Typography component="legend">
                Thumbnail <span style={{ color: 'red' }}>*</span>
              </Typography>
              <FileUpload
                type={'file'}
                ref={childRef}
                buttonText={'FILE'}
                onFileHandler={handleFile}
                onRemoveAfter={handleRemove}
                onUploadAfter={handleUpload}
                fileId={query.id}
                folder={'news'}
                apiUrl={`${API_URL}/news/`}
                uploadKey={imgKey}
                maxCount={1}
              />
            </Grid>
          </Grid>
          <Grid item style={{ marginTop: '30px' }}>
            <Typography style={{}}>Disease selection</Typography>
            {(type === 'new' || (news && news.id)) && (
              <DiseaseTransferList selectedDisease={news.subDiseaseIds} setSelectedDisease={setSelectedDisease} />
            )}
          </Grid>
        </CardContent>
        <CardActions style={{ marginLeft: '16px', marginRight: '16px' }}>
          <Grid container justifyContent={'space-between'} xs={12} md={12} spacing={3}>
            <Grid item>
              <Button
                variant="contained"
                style={{ backgroundColor: green[700], color: '#ffffff', marginRight: '10px' }}
                size="medium"
                onClick={goList}
                startIcon={<ListIcon />}
              >
                List
              </Button>
              {type !== 'new' ? (
                <Button variant="contained" color="secondary" size="medium" onClick={deleteNews} startIcon={<DeleteIcon />}>
                  Delete
                </Button>
              ) : null}
            </Grid>
            <Grid item>
              <Button variant="contained" color="primary" size="medium" onClick={addNews} startIcon={<SaveIcon />}>
                {type === 'new' ? 'Registration' : 'Edit'}
              </Button>
            </Grid>
          </Grid>
        </CardActions>
      </Card>
      {loading && (
        <div style={{ position: 'fixed', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', zIndex: 999 }}>
          <CircularProgress color={'secondary'} />
        </div>
      )}
    </OftadehLayout>
  );
};

export default NewsEditPage;
