import { makeStyles, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { getRegionList } from '../../constants/PromiseHttp';
import { Autocomplete } from '@material-ui/lab';
import { getDefName } from '../../constants';

const useStyles = makeStyles((theme) => ({
  mInput: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
}));

const RegionAutocomplete = (props) => {
  const classes = useStyles();

  const [regions, setRegions] = useState([]);

  useEffect(() => {
    getRegionList().then((result) => {
      setRegions(result);
    });
  }, []);

  const getSelectedRegion = () => {
    if (props.target && props.target.regionId) {
      const item = regions.find((reg) => {
        if (reg.id === props.target.regionId) return reg;
      });
      //console.log('getSelectedRegion', item);
      return item || null;
    }

    return null;
  };

  return (
    <Autocomplete
      key="cbox-region"
      value={getSelectedRegion()}
      onChange={(event, newValue) => {
        props.handleChangeData(newValue.id, 'regionId');
      }}
      size={'small'}
      disableClearable
      options={regions}
      getOptionLabel={(option) => option[getDefName()] || ''}
      getOptionSelected={(option, value) => option.id === value.id}
      style={{ width: '100%' }}
      className={classes.mInput}
      renderInput={(params) => <TextField {...params} variant="outlined" inputProps={{ ...params.inputProps, readOnly: true }} />}
    />
  );
};

export default RegionAutocomplete;
