import React, { useEffect, useState } from 'react';
import OftadehLayout from '../../../components/OftadehLayout/OftadehLayout';
import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Paper,
  TableCell,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  getFaqCategoryList,
  getHospitalTypeList,
  getLanguageList,
  getProductTypeList,
  getRegionList,
} from '../../../constants/PromiseHttp';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import { AddCircle, RemoveCircle } from '@material-ui/icons';
import axiosInstance from '../../../constants/AxiosInstance';
import { API_URL, DEF_LANGUAGE, getDefName, SETTING_CODES } from '../../../constants';
import swal from '@sweetalert/with-react';
import clsx from 'clsx';
import { Autocomplete } from '@material-ui/lab';
import SaveIcon from '@material-ui/icons/Save';
const CountryLanguage = require('country-language');

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  my3: {
    margin: '1.3rem 0',
  },
  mb0: {
    marginBottom: 0,
  },
  mRight: {
    marginRight: '.85rem',
  },
  p1: {
    padding: '.85rem',
  },
  fixedHeight: {
    height: 350,
  },
  minHeight: {
    minHeight: 240,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  table: {
    width: '100%',
  },
}));

function RightTable(props) {
  //console.log('RightTable props :', props);
  if (props.languages) {
    return (
      <Table className={props.classes.table} stickyHeader aria-label="codes-table">
        <TableHead>
          <TableRow>
            {props.languages.map((lang) => {
              let key = `name_${lang.id}`;
              return (
                <TableCell key={'col_' + key} align={'center'}>
                  {lang[getDefName()]}
                </TableCell>
              );
            })}
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.currentList &&
            props.currentList.map((obj, idx) => {
              return (
                <TableRow key={'row_' + obj.id}>
                  {props.languages.map((lang) => {
                    let key = `name_${lang.id}`;
                    return (
                      <TableCell key={'cell_' + obj.id + '_' + key} align={'center'}>
                        {lang.id === 'ko' ? (
                          obj[key]
                        ) : (
                          <TextField
                            type="text"
                            id={'tf_' + obj.id + '_' + key}
                            name={'tf_' + obj.id + '_' + key}
                            key={'tf_' + obj.id + '_' + key}
                            onChange={props.handleChangeData(idx, key)}
                            placeholder={lang[key]}
                            variant="outlined"
                            size="small"
                            defaultValue={obj[key] || ''}
                          />
                        )}
                      </TableCell>
                    );
                  })}
                  <TableCell key={'cell_del_' + idx} align={'center'}>
                    {obj.id !== 'ko' && obj.id !== 'en' && (
                      <IconButton variant="outlined" color="secondary" aria-label="remove" onClick={() => props.deleteFields(obj.id)}>
                        <RemoveCircle />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    );
  } else {
    return '';
  }
}

const SettingCodesPage = (props) => {
  const [codes, setCodes] = useState([]);
  const [select, setSelect] = useState({});
  const [addFieldOpen, setAddFieldOpen] = useState(false);
  const [languages, setLanguages] = useState([]);

  const [currentList, setCurrentList] = useState([]);

  const classes = useStyles();
  const minHeightPaper = clsx(classes.paper, classes.minHeight);

  useEffect(() => {
    setCodes(SETTING_CODES);
  }, []);

  useEffect(() => {
    setSelect(codes[0]);
    handleListItemClick(0);
    getLanguageList().then((result) => {
      setLanguages(result);
    });
  }, [codes]);

  const addFields = (name, codeIdx, code) => {
    var path = '';
    var param = { name_ko: name, sortOrder: currentList.length };
    switch (codeIdx) {
      case 0: {
        path = 'hospitalType';
        break;
      }
      case 1: {
        path = 'region';
        break;
      }
      case 2: {
        path = 'product/type';
        break;
      }
      case 3: {
        path = 'language';
        param['id'] = code.toLowerCase();
        break;
      }
      case 4: {
        path = 'faq/category';
        break;
      }
    }
    console.log('add>>', path, param);
    if (path && param) {
      axiosInstance.post(`${API_URL}/${path}/`, param, { headers: { Pragma: 'no-cache' } }).then((response) => {
        if (response.status === 200) {
          swal('Registered.', {
            icon: 'success',
          });
          switch (codeIdx) {
            case 0: {
              window.localStorage.setItem('hospitalTypeList', '');
              break;
            }
            case 1: {
              window.localStorage.setItem('regionList', '');
              break;
            }
            case 3: {
              window.localStorage.setItem('languageList', '');
              break;
            }
            case (2, 4): {
              break;
            }
          }
          handleListItemClick(codeIdx);
        }
      });
    }
  };

  const editFields = () => {
    //console.log('editFields : ', currentList);
    var codeIdx = select.idx;
    var path = '';
    switch (codeIdx) {
      case 0: {
        path = 'hospitalType/batch';
        break;
      }
      case 1: {
        path = 'region/batch';
        break;
      }
      case 2: {
        path = 'product/type/batch';
        break;
      }
      case 3: {
        path = 'language/batch';
        break;
      }
      case 4: {
        path = 'faq/category/batch';
        break;
      }
    }

    const param = { list: currentList };
    if (path && param) {
      axiosInstance.put(`${API_URL}/${path}/`, param, { headers: { Pragma: 'no-cache' } }).then((response) => {
        if (response.status === 200) {
          swal('It is changed.', {
            icon: 'success',
          });
          switch (codeIdx) {
            case 0: {
              window.localStorage.setItem('hospitalTypeList', '');
              break;
            }
            case 1: {
              window.localStorage.setItem('regionList', '');
              break;
            }
            case 3: {
              window.localStorage.setItem('languageList', '');
              getLanguageList().then((result) => {
                setLanguages(result);
              });
              break;
            }
            case (2, 4): {
              break;
            }
          }
          handleListItemClick(codeIdx);
        }
      });
    }
  };

  const deleteFields = (objId) => {
    var codeIdx = select.idx;
    //console.log('deleteFields', objId, codeIdx);
    var path = '';
    switch (codeIdx) {
      case 0: {
        path = 'hospitalType/';
        break;
      }
      case 1: {
        path = 'region';
        break;
      }
      case 2: {
        path = 'product/type';
        break;
      }
      case 3: {
        path = 'language';
        break;
      }
      case 4: {
        path = 'faq/category';
        break;
      }
    }
    swal({
      title: 'Are you sure you want to delete?',
      text: '',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        axiosInstance.delete(`${API_URL}/${path}/${objId}`, { headers: { Pragma: 'no-cache' } }).then((response) => {
          if (response.status === 200) {
            swal('It is deleted.', {
              icon: 'success',
            });
            switch (codeIdx) {
              case 0: {
                window.localStorage.setItem('hospitalTypeList', '');
                break;
              }
              case 1: {
                window.localStorage.setItem('regionList', '');
                break;
              }
              case 3: {
                window.localStorage.setItem('languageList', '');
                break;
              }
              case (2, 4): {
                break;
              }
            }
            handleListItemClick(codeIdx);
          }
        });
      } else {
      }
    });
  };

  const handleListItemClick = (idx) => {
    switch (idx) {
      case 0: {
        getHospitalTypeList().then((result) => {
          makeCurrentList(result);
        });
        break;
      }
      case 1: {
        getRegionList().then((result) => {
          makeCurrentList(result);
        });
        break;
      }
      case 2: {
        getProductTypeList().then((result) => {
          makeCurrentList(result);
        });
        break;
      }
      case 3: {
        getLanguageList().then((result) => {
          makeCurrentList(result);
        });
        break;
      }
      case 4: {
        getFaqCategoryList().then((result) => {
          makeCurrentList(result);
        });
        break;
      }
    }
  };

  const handleAddFieldOpen = () => {
    setAddFieldOpen(true);
  };
  const handleAddFieldClose = (event, name, idx, code) => {
    //console.log('handleAddFieldClose > ', event, name, idx, code);
    if (name && name.length > 0) {
      addFields(name, idx, code || DEF_LANGUAGE);
    }

    setAddFieldOpen(false);
  };

  const makeCurrentList = (result) => {
    const objList = [...result];
    const list = objList.map((obj) => {
      var _obj = { ...obj };
      languages.map((_lang) => {
        var item = obj[`name_${_lang.id}`];
        if (!item) {
          _obj[`name_${_lang.id}`] = '';
        }
      });
      return _obj;
    });
    setCurrentList(list);
  };

  const handleChangeData = (idx, key) => (event) => {
    currentList[idx][key] = event.target.value;
    setCurrentList([...currentList]);
    //console.log(currentList);
  };

  const AddFieldDialog = (prop) => {
    const [name, setName] = useState('');
    const [lang, setLang] = useState(DEF_LANGUAGE);

    return (
      <Dialog open={addFieldOpen} onClose={handleAddFieldClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">ADD</DialogTitle>
        <DialogContent>
          <TextField margin="dense" id="name" onChange={(e) => setName(e.target.value)} placeholder="Korean Name " fullWidth />
          {prop.prop === 3 && (
            <Autocomplete
              key="cbox-lang"
              value={lang}
              onChange={(event, newValue) => {
                setLang(newValue);
                //console.log('onChange : ', newValue);
              }}
              disableClearable
              size={'small'}
              options={CountryLanguage.getLanguageCodes(1)}
              getOptionLabel={(option) => CountryLanguage.getLanguage(option).name[0] || ''}
              style={{ width: '100%' }}
              className={classes.mInput}
              renderInput={(params) => <TextField {...params} variant="outlined" inputProps={{ ...params.inputProps, readOnly: false }} />}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddFieldClose} color="primary">
            Cancel
          </Button>
          <Button onClick={(event) => handleAddFieldClose(event, name, prop.prop, lang)} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  const LeftList = () => {
    return (
      <Paper>
        <List component="nav" aria-label="Codes List">
          <ListItem>
            <ListItemText primary="Codes " />
          </ListItem>
          <Divider />
          {codes
            ? codes.map((code) => {
                return (
                  <ListItem
                    key={code.tableName}
                    button
                    selected={select ? code.idx === select.idx : code.idx === 0 ? true : false}
                    onClick={(event) => {
                      setSelect(code);
                      handleListItemClick(code.idx);
                    }}
                  >
                    <ListItemText id={`${code.tableName}-title`} primary={code[getDefName()]} />
                  </ListItem>
                );
              })
            : ''}
        </List>
      </Paper>
    );
  };

  return (
    <OftadehLayout>
      <Grid container className={classes.my3} alignItems="center">
        <Grid item className={classes.mRight} style={{ fontWeight: 'bold' }}>
          <Typography variant="h5" component="h1" style={{ fontWeight: 'bold' }}>
            Code
          </Typography>
        </Grid>
      </Grid>
      <main>
        <div />
        <Grid container spacing={3}>
          <Grid item xs={2}>
            <LeftList />
          </Grid>
          <Grid item xs={10}>
            <Paper className={minHeightPaper}>
              <List component="nav" aria-label="Codes List">
                <ListItem className={classes.p3}>
                  <ListItemText primary="Detail" />
                  <IconButton variant="outlined" color="primary" aria-label="add" onClick={handleAddFieldOpen}>
                    <AddCircle />
                  </IconButton>
                  <IconButton variant="outlined" color="primary" aria-label="save" onClick={editFields}>
                    <SaveIcon />
                  </IconButton>
                  <AddFieldDialog prop={select ? select.idx : 0} />
                </ListItem>
                <Divider />
                <ListItem>
                  <RightTable
                    classes={classes}
                    languages={languages}
                    currentList={currentList}
                    handleChangeData={handleChangeData}
                    deleteFields={deleteFields}
                  />
                </ListItem>
              </List>
            </Paper>
          </Grid>
        </Grid>
      </main>
    </OftadehLayout>
  );
};

export default SettingCodesPage;
