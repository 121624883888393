const navigationAtozConfig = [
  {
    id: 'Main',
    title: 'Menu',
    type: 'group',
    children: [
      {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'item',
        icon: 'bar_chart',
        url: '/',
        exact: true,
      },
      {
        id: 'notice',
        title: 'Notice',
        type: 'item',
        icon: 'notification',
        url: '/pages/notice',
        exact: true,
      },
      {
        id: 'consult',
        title: 'Consulting',
        type: 'item',
        icon: 'user',
        url: '/pages/consult',
        // badge: localStorage.getItem('new_consult') && { title: 'new', bg: 'red' },
        exact: true,
      },
      {
        id: 'videoConsult',
        title: 'Video Consulting',
        type: 'item',
        icon: 'video_camera',
        url: '/pages/consult/video',
        // badge: localStorage.getItem('new_videoConsult') && { title: 'new', bg: 'red' },
        exact: true,
      },
      {
        id: 'consultCase',
        title: 'Consultation Case',
        type: 'item',
        icon: 'solution',
        url: '/pages/consult/case',
        exact: true,
      },
      {
        id: 'bookingRoot',
        title: 'Reservation',
        type: 'collapse',
        icon: 'snippets',
        children: [
          {
            id: 'bookingHospital',
            title: 'Hospital',
            type: 'item',
            url: '/pages/booking/hospital',
            exact: false,
          },
          {
            id: 'bookingProduct',
            title: 'Product',
            type: 'item',
            url: '/pages/booking/product',
            exact: false,
          },
          {
            id: 'bookingPickup',
            title: 'Pickup',
            type: 'item',
            url: '/pages/booking/pickup',
            exact: false,
          },
        ],
      },
      {
        id: 'review',
        title: 'Review',
        type: 'item',
        icon: 'file_image',
        url: '/pages/review',
        exact: true,
      },
      {
        id: 'hospital',
        title: 'Hospital',
        type: 'item',
        icon: 'database',
        url: '/pages/hospital',
        exact: true,
      },
      {
        id: 'recDoctor',
        title: 'Doctor',
        type: 'item',
        icon: 'idcard',
        url: '/pages/recDoctor',
        exact: true,
      },
      {
        id: 'product',
        title: 'Product',
        type: 'item',
        icon: 'shopping',
        url: '/pages/product',
        exact: true,
      },
      {
        id: 'news',
        title: 'News & Column',
        type: 'item',
        icon: 'file_text',
        url: '/pages/news',
        exact: true,
      },
      {
        id: 'memberRoot',
        title: 'Member',
        type: 'collapse',
        icon: 'team',
        children: [
          {
            id: 'member',
            title: 'Member',
            type: 'item',
            url: '/pages/member',
            exact: true,
          },
          {
            id: 'manager',
            title: 'Manager',
            type: 'item',
            url: '/pages/manager',
            exact: true,
          },
        ],
      },
      {
        id: 'settingRoot',
        title: 'Settings',
        type: 'collapse',
        icon: 'setting',
        children: [
          // {
          //   id: 'settingDefaultInfo',
          //   title: '기본정보',
          //   type: 'item',
          //   url: '/pages/setting/defaultInfo',
          //   exact: true,
          // },
          {
            id: 'settingBanner',
            title: 'Banner',
            type: 'item',
            url: '/pages/setting/banner',
            exact: true,
          },
          {
            id: 'settingCodes',
            title: 'Code',
            type: 'item',
            url: '/pages/setting/codes',
            exact: true,
          },
          {
            id: 'settingDisease',
            title: 'Disease',
            type: 'item',
            url: '/pages/setting/disease',
            exact: true,
          },
          {
            id: 'settingFaq',
            title: 'FAQ',
            type: 'item',
            url: '/pages/setting/faq',
            exact: true,
          },
        ],
      },
      {
        id: 'inquire',
        title: 'Inquire',
        type: 'item',
        icon: 'question',
        url: '/pages/inquire',
        exact: true,
      },
      {
        id: 'question',
        title: 'Message',
        type: 'item',
        icon: 'message',
        url: '/pages/question',
        exact: true,
      },
    ],
  },
];

export default navigationAtozConfig;
