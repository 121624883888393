import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Box, Button, Grid, ListItem, TextField, Typography } from '@material-ui/core';
import { AttachFile, RemoveCircle } from '@material-ui/icons';
import List from '@material-ui/core/List';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { Storage } from 'aws-amplify';
import swal from '@sweetalert/with-react';
import axiosInstance from '../../constants/AxiosInstance';
import awsconfig from '../../aws-exports';
import LinearProgress from '@material-ui/core/LinearProgress';
import PropTypes from 'prop-types';
import { BASE_IMG_URL } from '../../constants';
import SaveIcon from '@material-ui/icons/Save';
import UploadToS3 from './UploadToS3';
import ModalImage from 'react-modal-image';

const LinearProgressWithLabel = (props) => {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
};
LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

const FileUpload = forwardRef((props, ref) => {
  // 신규 업로드 파일
  const [uploadList, setUploadList] = useState([]);
  const [progress, setProgress] = useState({});
  const [complete, setComplete] = useState([]);
  // 기존 업도르된 파일
  const [uploaded, setUploaded] = useState([]);
  const [orgUploaded, setOrgUploaded] = useState([]);

  useImperativeHandle(ref, () => ({
    async getSignedUrl(files) {
      if (files && files.length > 0) {
        setOrgUploaded([...files]);
        const newArr = [];
        for (let i = 0; i < files.length; i++) {
          const url = await Storage.get(files[i].key, { customPrefix: { public: '' } });
          newArr.push({ url: url, ...files[i] });
          if (i === files.length - 1) {
            setUploaded([...newArr]);
          }
        }
      } else {
        setUploaded([]);
      }
    },
    async fileUpload(uploadFile, fid) {
      // if (!props.fileId) {
      //   swal('Missing required key.');
      //   return;
      // }
      const fileId = fid ? fid : props.fileId;
      const param = {
        id: fileId,
      };
      if (!param.id) {
        swal('Missing required key.');
        return;
      }

      const upKey = props.uploadKey ? props.uploadKey : 'files';
      param[upKey] = [];
      param['uploadKey'] = upKey;

      console.log(param, uploadFile);
      const compArr = [];
      for (let i = 0; i < uploadFile.length; i++) {
        //const timestamp = new Date().getTime();
        // const result = await Storage.put(props.folder + '/' + fileId + '_' + timestamp + (i + 1 + uploadFile[i].size), uploadFile[i], {
        //   customPrefix: { public: '' },
        //   progressCallback(per) {
        //     setProgress({ [upKey + '_' + i]: (per.loaded / per.total) * 100 });
        //   },
        // });
        const result = await UploadToS3(uploadFile[i], props.folder, fileId, (val) => {
          setProgress({ [upKey + '_' + i]: val });
        });
        compArr.push(upKey + '_' + i);
        const url = `${BASE_IMG_URL}` + result.key;
        param[upKey].push({ key: result.key, url: url, name: uploadFile[i].name, type: uploadFile[i].type, size: uploadFile[i].size });
        setComplete([...complete, ...compArr]);
        if (i === uploadFile.length - 1) {
          setComplete([...complete, ...compArr]);
          axiosInstance.put(props.apiUrl, param).then((response) => {
            if (response.status === 200) {
              // swal('It is changed.', {
              //   icon: 'success',
              // });
              setUploaded([]);
              setUploadList([]);
              if (props.onUploadAfter) props.onUploadAfter();
            }
          });
        }
      }
    },
  }));

  const readFile = (file, limit) => {
    if (!limit) limit = 5;
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = (_read) => {
        if (limit > 0 && _read.total > 1000000 * limit) {
          reject(`File too large : limit ${limit}MB`);
        } else {
          resolve(_read);
        }
      };
    });
  };

  function isFileImage(file) {
    const acceptedImageTypes = ['image/gif', 'image/jpeg', 'image/png'];

    return file && acceptedImageTypes.includes(file['type']);
  }

  const onChangeFile = async (e) => {
    const files = e.target.files;
    if (props.maxCount && files.length > props.maxCount) {
      alert(`max file count : ${props.maxCount}`);
      return;
    }
    if (props.maxCount && uploadList.length + files.length + uploaded.length > props.maxCount) {
      alert(`max file count : ${props.maxCount}`);
      return;
    }
    const arr = [];
    for (let i = 0; i < files.length; i++) {
      // arr.push(files[i]);
      const temp = files[i];
      //console.log('onChangeFile temp :', temp);
      if (isFileImage(temp)) {
        await readFile(temp, -1).then((file) => {
          temp['path'] = file.target.result;
          arr.push(temp);
        });
      } else {
        arr.push(temp);
      }

      if (i === files.length - 1) {
        setUploadList([...uploadList, ...arr]);
        props.onFileHandler([...uploadList, ...arr], props.uploadKey);
      }
    }
  };

  const handleFileUpload = () => {
    document.getElementById('file_' + props.uploadKey).click();
  };

  const removeFile = (isUploaded, idx) => {
    if (isUploaded) {
      uploaded.splice(idx, 1);
      setUploaded([...uploaded]);
      if (props.onRemoveAfter) props.onRemoveAfter([...uploaded], props.uploadKey);
    } else {
      uploadList.splice(idx, 1);
      setUploadList([...uploadList]);
      props.onFileHandler([...uploadList], props.uploadKey);
    }
  };

  const removeUploadListFile = (idx) => {
    removeFile(false, idx);
  };

  const removeStorageFile = async (item, idx) => {
    swal({
      title: 'Are you sure you want to delete?',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        if (props.apiUrl) {
          const param = {
            bucket: awsconfig.aws_user_files_s3_bucket,
            key: uploaded[idx].key,
          };
          const url = encodeURI(
            props.apiUrl + 'files/' + param.bucket + '?key=' + param.key + '&id=' + props.fileId + '&uploadKey=' + props.uploadKey,
          );
          axiosInstance.delete(url).then((res) => {
            if (res.status === 200) {
              removeFile(true, idx);
            } else {
              alert('Remove Failed, try again.');
            }
          });
        }
      } else {
        return;
      }
    });
  };

  const ThumbButton = () => (
    <IconButton>
      <AttachFile onClick={handleFileUpload} />
    </IconButton>
  );

  return (
    <>
      <input
        type="file"
        style={{ display: 'none' }}
        multiple
        id={'file_' + props.uploadKey}
        accept={props.type === 'gallery' ? 'application/pdf, image/*' : props.accept ? props.accept : '*.*'}
        onChange={(e) => onChangeFile(e)}
      />
      {props.type === 'thumbnail' ? (
        <Grid container spacing={2} alignItems={'center'} style={{ marginTop: 5 }}>
          {props.onInputTextChange ? (
            <Grid item xs={12}>
              <TextField
                placeholder="http://"
                size={'small'}
                variant={'outlined'}
                defaultValue={props.textInput}
                onChange={(e) => props.onInputTextChange(e.target.value, props.uploadKey)}
                InputProps={{ endAdornment: <ThumbButton /> }}
              />
            </Grid>
          ) : (
            <ThumbButton />
          )}
          <Grid item>
            {uploaded && uploaded[0] ? (
              <Grid container alignItems={'center'} spacing={2}>
                <Grid item>THUMBNAILE: </Grid>
                <Grid item>
                  <img
                    src={uploaded[0].url}
                    width={60}
                    alt={'file-' + 0}
                    onError={(e) => {
                      e.target.src = 'https://via.placeholder.com/60.png?text=noimage';
                    }}
                  />
                </Grid>
                {!props.hideFileName && <Grid item>{uploaded[0].name}</Grid>}
                <Grid item>
                  <IconButton aria-label="delete">
                    <DeleteIcon fontSize="small" onClick={(e) => removeStorageFile(uploaded[0], 0)} />
                  </IconButton>
                </Grid>
              </Grid>
            ) : (
              uploadList &&
              uploadList[0] && (
                <Grid container alignItems={'center'} spacing={2}>
                  <Grid item>THUMBNAILE: </Grid>
                  <Grid item>
                    <img
                      src={uploadList[0].path}
                      width={60}
                      alt={'file-' + 0}
                      onError={(e) => {
                        e.target.src = 'https://via.placeholder.com/60.png?text=noimage';
                      }}
                    />
                  </Grid>
                  {!props.hideFileName && <Grid item> {uploadList[0].name} </Grid>}
                  <Grid item>
                    <IconButton aria-label="delete">
                      <DeleteIcon fontSize="small" onClick={(e) => removeUploadListFile(0)} />
                    </IconButton>
                  </Grid>
                </Grid>
              )
            )}
          </Grid>
        </Grid>
      ) : (
        <></>
      )}
      {props.type === 'gallery' ? (
        <>
          <Grid container spacing={2} style={{ marginTop: 6 }}>
            {uploaded &&
              uploaded.map((item, idx) => {
                return (
                  <Grid item md={2} key={'file-gallery-' + idx}>
                    <IconButton
                      aria-label="delete"
                      color={'secondary'}
                      style={{ right: '-80%', top: 20, zIndex: 999 }}
                      onClick={(e) => removeStorageFile(item, idx)}
                    >
                      <RemoveCircle />
                    </IconButton>
                    <img
                      src={item.url}
                      width={'100%'}
                      alt={'file-' + idx}
                      onError={(e) => {
                        e.target.src = 'https://via.placeholder.com/100.png?text=noimage';
                      }}
                    />
                  </Grid>
                );
              })}
          </Grid>
          <Button variant="outlined" color="primary" startIcon={<AttachFile />} onClick={handleFileUpload}>
            {props.buttonText ? props.buttonText : 'Add File'}
          </Button>
          <Grid container spacing={2}>
            {uploadList.map((item, idx) => {
              return (
                <Grid item md={2} key={'file-gallery-' + idx}>
                  <IconButton
                    aria-label="delete"
                    color={'secondary'}
                    style={{ right: '-80%', top: 20, zIndex: 999 }}
                    onClick={(e) => removeUploadListFile(idx)}
                  >
                    <RemoveCircle />
                  </IconButton>
                  <img
                    src={item.path}
                    width={'100%'}
                    alt={'file-' + idx}
                    onError={(e) => {
                      e.target.src = 'https://via.placeholder.com/100.png?text=noimage';
                    }}
                  />
                </Grid>
              );
            })}
          </Grid>
        </>
      ) : (
        <></>
      )}
      {props.type === 'file' ? (
        <>
          <List dense>
            {uploaded &&
              uploaded.map((item, idx) => {
                return (
                  <ListItem key={'file-' + idx}>
                    <Grid container spacing={2} alignItems={'center'} style={{ marginTop: 5 }}>
                      {item.type.includes('image') ? (
                        <>
                          <Grid item xs={3} md={2}>
                            <ModalImage
                              small={item.url}
                              large={item.url}
                              onError={(e) => {
                                e.target.src = 'https://via.placeholder.com/60.png?text=noimage';
                              }}
                            />
                          </Grid>
                          <Grid item>{item.name}</Grid>
                        </>
                      ) : (
                        <>
                          <Grid item md={2}>
                            <a download={item.name} href={item.url}>
                              <img
                                src={item.url}
                                width={60}
                                alt={'file-' + idx}
                                onError={(e) => {
                                  e.target.src = 'https://via.placeholder.com/60.png?text=noimage';
                                }}
                              />
                            </a>
                          </Grid>
                          <Grid item>{item.name}</Grid>
                        </>
                      )}

                      <Grid item>
                        <IconButton aria-label="delete">
                          <DeleteIcon fontSize="small" onClick={(e) => removeStorageFile(item, idx)} />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </ListItem>
                );
              })}
          </List>
          <Button variant="outlined" color="primary" startIcon={<AttachFile />} onClick={handleFileUpload}>
            {props.buttonText ? props.buttonText : 'Add File'}
          </Button>
          <Grid container spacing={2}>
            <List dense>
              {uploadList.map((item, idx) => {
                return (
                  <ListItem key={'file-' + idx}>
                    <Grid container spacing={2} alignItems={'center'} style={{ marginTop: 5 }}>
                      <Grid item>
                        <img
                          src={item.type.includes('image') ? item.path : 'https://via.placeholder.com/60.png?text=noimage'}
                          width={60}
                          alt={'file-' + idx}
                          onError={(e) => {
                            e.target.src = 'https://via.placeholder.com/60.png?text=noimage';
                          }}
                        />
                      </Grid>
                      <Grid item>
                        {item.name}{' '}
                        <LinearProgressWithLabel
                          value={
                            progress[props.uploadKey + '_' + idx]
                              ? progress[props.uploadKey + '_' + idx]
                              : complete.indexOf(props.uploadKey + '_' + idx) !== -1
                              ? 100
                              : 0
                          }
                        />
                      </Grid>
                      <Grid item>
                        <IconButton aria-label="delete">
                          <DeleteIcon fontSize="small" onClick={(e) => removeUploadListFile(idx)} />
                        </IconButton>
                        {props.doUpload && <IconButton aria-label="save">{<SaveIcon onClick={(e) => props.doUpload()} />}</IconButton>}
                      </Grid>
                    </Grid>
                  </ListItem>
                );
              })}
            </List>
          </Grid>
        </>
      ) : (
        <></>
      )}
    </>
  );
});

export default FileUpload;

export const removeStorageFileOnly = async (fid, fileKey, apiUrl, uploadKey) => {
  if (apiUrl) {
    const param = {
      bucket: awsconfig.aws_user_files_s3_bucket,
      key: fileKey,
    };
    const url = encodeURI(apiUrl + 'files/' + param.bucket + '?key=' + param.key + '&id=' + fid + '&uploadKey=' + uploadKey);
    axiosInstance.delete(url).then((res) => {
      if (res.status === 200) {
        console.log('delete success');
      } else {
        console.log(res.statusText);
      }
    });
  }
};

export const fileUploadOnly = (uploadFile, fid, folderName, apiUrl, upoloadKey) => {
  return new Promise(async function (resolve, reject) {
    const param = {
      id: fid,
    };
    if (!uploadFile) {
      reject('fileUploadOnly::uploadFile is empty');
    }
    if (!param.id) {
      swal('Missing required key.');
      reject('fileUploadOnly::Missing required key');
    }

    const upKey = upoloadKey ? upoloadKey : 'files';
    param[upKey] = [];
    param['uploadKey'] = upKey;

    const timestamp = new Date().getTime();
    let fileId = fid + '_' + timestamp;
    uploadFile &&
      UploadToS3(uploadFile, folderName, fileId).then((result) => {
        //const url = `${BASE_IMG_URL}` + result.key;
        param[upKey].push({
          key: result.key,
          url: result.path,
          name: uploadFile.name,
          type: uploadFile.type,
          size: uploadFile.size,
        });
        axiosInstance.put(apiUrl, param).then((response) => {
          if (response.status === 200) {
            resolve(upKey);
          } else {
            reject(`fileUploadOnly::updateInfo url failed : ${response.status}`);
          }
        });
      });
  }).catch((err) => {
    console.log(err);
    return;
  });
};
