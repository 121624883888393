import axiosInstance from './AxiosInstance';
import { API_URL, DEF_LANGUAGE } from './index';
import swal from '@sweetalert/with-react';

export const sortedResponse = (response, field) => {
  return (
    response.data.value &&
    response.data.value.sort(function (a, b) {
      return a[field] > b[field] ? 1 : -1;
    })
  );
};
export const getUserList = (typeId) => {
  return new Promise(function (resolve) {
    axiosInstance.get(`${API_URL}/user/filter/${typeId || 'member'}`, { headers: { Pragma: 'no-cache' } }).then((response) => {
      //console.log(response.data.value);
      if (response.status === 200) {
        const userList = sortedResponse(response, 'createdAt').reverse();
        resolve(userList);
      }
    });
  }).catch((err) => {
    console.log(err);
    swal(`세션이 만료되었습니다. 다시 로그인해 주세요. (${err.code})`, {
      icon: 'warning',
    });
  });
};

export const getUserInfo = (id) => {
  return new Promise(function (resolve) {
    axiosInstance.get(`${API_URL}/user/${id}`, { headers: { Pragma: 'no-cache' } }).then((response) => {
      //console.log(response.data.value);
      if (response.status === 200) {
        resolve(response.data.value);
      }
    });
  }).catch((err) => {
    console.log(err);
  });
};

export const searchByUserName = (name, isManager) => {
  return new Promise(function (resolve) {
    let path = isManager ? 'searchManager' : 'search';
    axiosInstance.get(`${API_URL}/user/${path}/${name}`, { headers: { Pragma: 'no-cache' } }).then((response) => {
      if (response.status === 200) {
        //console.log(response.data.value);
        resolve(response.data.value);
      }
    });
  }).catch((err) => {
    console.log(err);
  });
};

export const getHospitalManagerList = (mid) => {
  return new Promise(function (resolve) {
    axiosInstance.get(`${API_URL}/hospital/list/for/${mid}`, { headers: { Pragma: 'no-cache' } }).then((response) => {
      if (response.status === 200) {
        const hospitalList = sortedResponse(response, 'createdAt').reverse();
        //console.log(hospitalList);
        resolve(hospitalList);
      }
    });
  }).catch((err) => {
    console.log(err);
  });
};

export const getHospitalListIn = (langId) => {
  return new Promise(function (resolve) {
    axiosInstance.get(`${API_URL}/hospital/in/${langId}`, { headers: { Pragma: 'no-cache' } }).then((response) => {
      if (response.status === 200) {
        const hospitalList = sortedResponse(response, 'createdAt').reverse();
        //console.log(hospitalList);
        resolve(hospitalList);
      }
    });
  }).catch((err) => {
    console.log(err);
  });
};

export const getHospitalList = (force = false) => {
  return new Promise(function (resolve) {
    if (!force) {
      const hospitalList = localStorage.getItem('hospitalList');
      if (hospitalList) {
        console.log('use localStorage hospitalList');
        resolve(JSON.parse(hospitalList));
        return;
      }
    }
    axiosInstance.get(`${API_URL}/hospital/all`, { headers: { Pragma: 'no-cache' } }).then((response) => {
      if (response.status === 200) {
        const hospitalList = sortedResponse(response, 'createdAt').reverse();
        //console.log(hospitalList);
        window.localStorage.setItem('hospitalList', JSON.stringify(hospitalList));
        resolve(hospitalList);
      }
    });
  }).catch((err) => {
    console.log(err);
  });
};

export const getHospitalInfo = (id) => {
  return new Promise(function (resolve) {
    axiosInstance.get(`${API_URL}/hospital/${id}`, { headers: { Pragma: 'no-cache' } }).then((response) => {
      //console.log(response.data.value);
      if (response.status === 200) {
        resolve(response.data.value);
      }
    });
  }).catch((err) => {
    console.log(err);
  });
};

export const getDoctorManagerList = (mid) => {
  return new Promise(function (resolve) {
    axiosInstance.get(`${API_URL}/doctor/listForManager/${mid}`, { headers: { Pragma: 'no-cache' } }).then((response) => {
      if (response.status === 200) {
        const doctorList = sortedResponse(response, 'createdAt').reverse();
        //console.log(doctorList);
        window.localStorage.setItem('doctorList', JSON.stringify(doctorList));
        resolve(doctorList);
      }
    });
  }).catch((err) => {
    console.log(err);
  });
};

export const getDoctorList = () => {
  return new Promise(function (resolve) {
    axiosInstance.get(`${API_URL}/doctor/all`, { headers: { Pragma: 'no-cache' } }).then((response) => {
      if (response.status === 200) {
        const doctorList = sortedResponse(response, 'createdAt').reverse();
        //console.log(doctorList);
        window.localStorage.setItem('doctorList', JSON.stringify(doctorList));
        resolve(doctorList);
      }
    });
  }).catch((err) => {
    console.log(err);
  });
};

export const getDoctorInfo = (id) => {
  return new Promise(function (resolve) {
    axiosInstance.get(`${API_URL}/doctor/${id}`, { headers: { Pragma: 'no-cache' } }).then((response) => {
      //console.log(response.data.value);
      if (response.status === 200) {
        resolve(response.data.value);
      }
    });
  }).catch((err) => {
    console.log(err);
  });
};

export const getReviewList = () => {
  return new Promise(function (resolve) {
    axiosInstance.get(`${API_URL}/review/list/all`, { headers: { Pragma: 'no-cache' } }).then((response) => {
      //console.log(response.data.value);
      if (response.status === 200) {
        const reviewList = sortedResponse(response, 'createdAt').reverse();
        resolve(reviewList);
      }
    });
  }).catch((err) => {
    console.log(err);
  });
};
export const getReviewListForUser = (userId) => {
  return new Promise(function (resolve) {
    axiosInstance.get(`${API_URL}/review/filter/${userId}`, { headers: { Pragma: 'no-cache' } }).then((response) => {
      //console.log(response.data.value);
      if (response.status === 200) {
        const reviewList = sortedResponse(response, 'createdAt').reverse();
        resolve(reviewList);
      }
    });
  }).catch((err) => {
    console.log(err);
  });
};
export const getReviewInfo = (id) => {
  return new Promise(function (resolve) {
    axiosInstance.get(`${API_URL}/review/${id}`, { headers: { Pragma: 'no-cache' } }).then((response) => {
      //console.log(response.data.value);
      if (response.status === 200) {
        resolve(response.data.value);
      }
    });
  }).catch((err) => {
    console.log(err);
  });
};

export const getDiseaseList = () => {
  return new Promise(function (resolve) {
    const diseaseList = localStorage.getItem('diseaseList');
    if (diseaseList) {
      console.log('use localStorage diseaseList');
      resolve(JSON.parse(diseaseList));
    }

    axiosInstance.get(`${API_URL}/disease/`, { headers: { Pragma: 'no-cache' } }).then((response) => {
      //console.log(response.data.value);
      if (response.status === 200) {
        const diseaseList = sortedResponse(response, 'sortOrder');
        if (diseaseList) window.localStorage.setItem('diseaseList', JSON.stringify(diseaseList));
        resolve(diseaseList);
      }
    });
  }).catch((err) => {
    console.log(err);
  });
};

export const getDiseaseInfo = (id) => {
  return new Promise(function (resolve) {
    axiosInstance.get(`${API_URL}/disease/${id}`, { headers: { Pragma: 'no-cache' } }).then((response) => {
      //console.log(response.data.value);
      if (response.status === 200) {
        resolve(response.data.value);
      }
    });
  }).catch((err) => {
    console.log(err);
  });
};

export const getSubDiseaseList = (pid) => {
  return new Promise(function (resolve) {
    axiosInstance.get(`${API_URL}/subDisease/${pid}`, { headers: { Pragma: 'no-cache' } }).then((response) => {
      //console.log(response.data.value);
      if (response.status === 200) {
        const diseaseList = sortedResponse(response, 'createdAt').reverse();
        resolve(diseaseList);
      }
    });
  }).catch((err) => {
    console.log(err);
  });
};

export const getSubDiseaseAll = () => {
  return new Promise(function (resolve) {
    axiosInstance.get(`${API_URL}/subDisease/for/${DEF_LANGUAGE}/and/ko`, { headers: { Pragma: 'no-cache' } }).then((response) => {
      //console.log(response.data.value);
      if (response.status === 200) {
        const diseaseList = sortedResponse(response, 'sortOrder');
        resolve(diseaseList);
      }
    });
  }).catch((err) => {
    console.log(err);
  });
};

export const getSubDiseaseInfo = (pid, id) => {
  return new Promise(function (resolve) {
    axiosInstance.get(`${API_URL}/subDisease/${pid}/${id}`, { headers: { Pragma: 'no-cache' } }).then((response) => {
      //console.log(response.data.value);
      if (response.status === 200) {
        resolve(response.data.value);
      }
    });
  }).catch((err) => {
    console.log(err);
  });
};

export const getLanguageList = () => {
  return new Promise(function (resolve) {
    const languageList = localStorage.getItem('languageList');
    if (languageList) {
      //console.log(languageList);
      resolve(JSON.parse(languageList));
    }

    axiosInstance.get(`${API_URL}/language/`, { headers: { Pragma: 'no-cache' } }).then((response) => {
      //console.log(response.data.value);
      if (response.status === 200) {
        const languageList = sortedResponse(response, 'sortOrder');
        //console.log(languageList);
        if (languageList) window.localStorage.setItem('languageList', JSON.stringify(languageList));
        resolve(languageList);
      }
    });
  }).catch((err) => {
    console.log(err);
  });
};

export const getRegionList = () => {
  return new Promise(function (resolve) {
    const regionList = localStorage.getItem('regionList');
    if (regionList) {
      //console.log(regionList);
      resolve(JSON.parse(regionList));
    }

    axiosInstance.get(`${API_URL}/region/`, { headers: { Pragma: 'no-cache' } }).then((response) => {
      //console.log(response.data.value);
      if (response.status === 200) {
        const regionList = sortedResponse(response, 'sortOrder');
        //console.log(regionList);
        if (regionList) window.localStorage.setItem('regionList', JSON.stringify(regionList));
        resolve(regionList);
      }
    });
  }).catch((err) => {
    console.log(err);
  });
};

export const getHospitalTypeList = () => {
  return new Promise(function (resolve) {
    const hospitalTypeList = localStorage.getItem('hospitalTypeList');
    if (hospitalTypeList) {
      //console.log(hospitalTypeList);
      resolve(JSON.parse(hospitalTypeList));
    }

    axiosInstance.get(`${API_URL}/hospitalType/`, { headers: { Pragma: 'no-cache' } }).then((response) => {
      //console.log(response.data.value);
      if (response.status === 200) {
        const hospitalTypeList = sortedResponse(response, 'sortOrder');
        //console.log(hospitalTypeList);
        if (hospitalTypeList) window.localStorage.setItem('hospitalTypeList', JSON.stringify(hospitalTypeList));
        resolve(hospitalTypeList);
      }
    });
  }).catch((err) => {
    console.log(err);
  });
};

export const getNoticeList = () => {
  return new Promise(function (resolve) {
    axiosInstance.get(`${API_URL}/notice/`, { headers: { Pragma: 'no-cache' } }).then((response) => {
      //console.log(response.data.value);
      if (response.status === 200) {
        const noticeList = sortedResponse(response, 'createdAt').reverse();
        resolve(noticeList);
      }
    });
  }).catch((err) => {
    console.log(err);
  });
};

export const getNoticeManagerList = (mid) => {
  return new Promise(function (resolve) {
    axiosInstance.get(`${API_URL}/notice/filter/${mid}`, { headers: { Pragma: 'no-cache' } }).then((response) => {
      //console.log(response.data.value);
      if (response.status === 200) {
        const productList = sortedResponse(response, 'createdAt').reverse();
        resolve(productList);
      }
    });
  }).catch((err) => {
    console.log(err);
  });
};

export const getNoticeInfo = (id) => {
  return new Promise(function (resolve) {
    axiosInstance.get(`${API_URL}/notice/${id}`, { headers: { Pragma: 'no-cache' } }).then((response) => {
      //console.log(response.data.value);
      if (response.status === 200) {
        resolve(response.data.value);
      }
    });
  }).catch((err) => {
    console.log(err);
  });
};

export const getConsultList = () => {
  return new Promise(function (resolve) {
    axiosInstance.get(`${API_URL}/consult/`, { headers: { Pragma: 'no-cache' } }).then((response) => {
      //console.log(response.data.value);
      if (response.status === 200) {
        const consultList = sortedResponse(response, 'createdAt').reverse();
        resolve(consultList);
      }
    });
  }).catch((err) => {
    console.log(err);
  });
};

export const getConsultListForUser = (userId) => {
  return new Promise(function (resolve) {
    axiosInstance.get(`${API_URL}/consult/for/${userId}`, { headers: { Pragma: 'no-cache' } }).then((response) => {
      //console.log(response.data.value);
      if (response.status === 200) {
        const consultList = sortedResponse(response, 'createdAt').reverse();
        resolve(consultList);
      }
    });
  }).catch((err) => {
    console.log(err);
  });
};

export const getAnswer = (aid) => {
  return new Promise(function (resolve) {
    axiosInstance.get(`${API_URL}/answer/info/${aid}`, { headers: { Pragma: 'no-cache' } }).then((response) => {
      //console.log(response.data.value);
      if (response.status === 200) {
        resolve(response.data.value);
      }
    });
  }).catch((err) => {
    console.log(err);
  });
};

export const getAnswerNestedList = (qid) => {
  return new Promise(function (resolve) {
    axiosInstance.get(`${API_URL}/answer/${qid}`, { headers: { Pragma: 'no-cache' } }).then((response) => {
      //console.log(response.data.value);
      if (response.status === 200) {
        const list = sortedResponse(response, 'createdAt').reverse();
        resolve(list);
      }
    });
  }).catch((err) => {
    console.log(err);
  });
};

export const getConsultNestedList = () => {
  return new Promise(function (resolve) {
    axiosInstance.get(`${API_URL}/consult/nested/`, { headers: { Pragma: 'no-cache' } }).then((response) => {
      //console.log(response.data.value);
      if (response.status === 200) {
        const consultList = sortedResponse(response, 'createdAt').reverse();
        resolve(consultList);
      }
    });
  }).catch((err) => {
    console.log(err);
  });
};

export const getConsultInfo = (id) => {
  return new Promise(function (resolve) {
    axiosInstance.get(`${API_URL}/consult/admin/${id}`, { headers: { Pragma: 'no-cache' } }).then((response) => {
      //console.log(response.data.value);
      if (response.status === 200) {
        resolve(response.data.value);
      }
    });
  }).catch((err) => {
    console.log(err);
  });
};

export const getConsultCaseList = () => {
  return new Promise(function (resolve) {
    axiosInstance.get(`${API_URL}/consultCase/`, { headers: { Pragma: 'no-cache' } }).then((response) => {
      //console.log(response.data.value);
      if (response.status === 200) {
        const consultList = sortedResponse(response, 'createdAt').reverse();
        resolve(consultList);
      }
    });
  }).catch((err) => {
    console.log(err);
  });
};

export const getConsultCaseInfo = (id) => {
  return new Promise(function (resolve) {
    axiosInstance.get(`${API_URL}/consultCase/${id}`, { headers: { Pragma: 'no-cache' } }).then((response) => {
      //console.log(response.data.value);
      if (response.status === 200) {
        resolve(response.data.value);
      }
    });
  }).catch((err) => {
    console.log(err);
  });
};

export const getVideoConsultList = () => {
  return new Promise(function (resolve) {
    axiosInstance.get(`${API_URL}/consult/video/`, { headers: { Pragma: 'no-cache' } }).then((response) => {
      //console.log(response.data.value);
      if (response.status === 200) {
        const consultList = sortedResponse(response, 'createdAt').reverse();
        resolve(consultList);
      }
    });
  }).catch((err) => {
    console.log(err);
  });
};

export const getVideoConsultInfo = (id) => {
  return new Promise(function (resolve) {
    axiosInstance.get(`${API_URL}/consult/admin/${id}`, { headers: { Pragma: 'no-cache' } }).then((response) => {
      //console.log(response.data.value);
      if (response.status === 200) {
        resolve(response.data.value);
      }
    });
  }).catch((err) => {
    console.log(err);
  });
};

export const getFaqCategoryList = () => {
  return new Promise(function (resolve) {
    axiosInstance.get(`${API_URL}/faq/category`, { headers: { Pragma: 'no-cache' } }).then((response) => {
      //console.log(response.data.value);
      if (response.status === 200) {
        const faqList = sortedResponse(response, 'sortOrder');
        resolve(faqList);
      }
    });
  }).catch((err) => {
    console.log(err);
  });
};

export const getFaqList = (categoryId, langId) => {
  return new Promise(function (resolve) {
    axiosInstance.get(`${API_URL}/faq/filter/${categoryId}/${langId}`, { headers: { Pragma: 'no-cache' } }).then((response) => {
      //console.log(response.data.value);
      if (response.status === 200) {
        const faqList = sortedResponse(response, 'createdAt').reverse();
        resolve(faqList);
      }
    });
  }).catch((err) => {
    console.log(err);
  });
};

export const getFaqInfo = (id) => {
  return new Promise(function (resolve) {
    axiosInstance.get(`${API_URL}/faq/${id}`, { headers: { Pragma: 'no-cache' } }).then((response) => {
      //console.log(response.data.value);
      if (response.status === 200) {
        resolve(response.data.value);
      }
    });
  }).catch((err) => {
    console.log(err);
  });
};

export const getProductManagerList = (mid) => {
  return new Promise(function (resolve) {
    axiosInstance.get(`${API_URL}/product/listForManager/${mid}`, { headers: { Pragma: 'no-cache' } }).then((response) => {
      //console.log(response.data.value);
      if (response.status === 200) {
        const productList = sortedResponse(response, 'createdAt').reverse();
        resolve(productList);
      }
    });
  }).catch((err) => {
    console.log(err);
  });
};

export const getProductList = () => {
  return new Promise(function (resolve) {
    axiosInstance.get(`${API_URL}/product/all`, { headers: { Pragma: 'no-cache' } }).then((response) => {
      //console.log(response.data.value);
      if (response.status === 200) {
        const productList = sortedResponse(response, 'createdAt').reverse();
        resolve(productList);
      }
    });
  }).catch((err) => {
    console.log(err);
  });
};

export const getProductTypeList = () => {
  return new Promise(function (resolve) {
    axiosInstance.get(`${API_URL}/product/type`, { headers: { Pragma: 'no-cache' } }).then((response) => {
      //console.log(response.data.value);
      if (response.status === 200) {
        const productList = sortedResponse(response, 'sortOrder');
        resolve(productList);
      }
    });
  }).catch((err) => {
    console.log(err);
  });
};

export const getProductInfo = (id) => {
  return new Promise(function (resolve) {
    axiosInstance.get(`${API_URL}/product/${id}`, { headers: { Pragma: 'no-cache' } }).then((response) => {
      //console.log(response.data.value);
      if (response.status === 200) {
        resolve(response.data.value);
      }
    });
  }).catch((err) => {
    console.log(err);
  });
};

export const getNewsManagerList = (mid) => {
  return new Promise(function (resolve) {
    axiosInstance.get(`${API_URL}/news/listForManager/${mid}`, { headers: { Pragma: 'no-cache' } }).then((response) => {
      //console.log(response.data.value);
      if (response.status === 200) {
        const newsList = sortedResponse(response, 'createdAt').reverse();
        resolve(newsList);
      }
    });
  }).catch((err) => {
    console.log(err);
  });
};

export const getNewsList = () => {
  return new Promise(function (resolve) {
    console.log('getNewsList');
    let final = [];

    const fetchData = (cursor) => {
      let url = `${API_URL}/news/all?limit=10`;
      if (cursor) url += `&cursor=${cursor}`;
      console.log('getNewsList :', url);
      axiosInstance.get(`${url}`, { headers: { Pragma: 'no-cache' } }).then((response) => {
        //console.log(response.data.value);
        if (response.status === 200) {
          const newsList = sortedResponse(response, 'createdAt').reverse();
          newsList.map((n) => {
            final.push(n);
          });
          if (response.data.cursor) fetchData(response.data.cursor);
          else {
            resolve(final);
          }
        }
      });
    };
    fetchData();
  }).catch((err) => {
    console.log(err);
  });
};

export const getNewsCategoryList = () => {
  return new Promise(function (resolve) {
    axiosInstance.get(`${API_URL}/news/category`, { headers: { Pragma: 'no-cache' } }).then((response) => {
      //console.log(response.data.value);
      if (response.status === 200) {
        const newsList = sortedResponse(response, 'sortOrder');
        resolve(newsList);
      }
    });
  }).catch((err) => {
    console.log(err);
  });
};

export const getNewsInfo = (id) => {
  return new Promise(function (resolve) {
    axiosInstance.get(`${API_URL}/news/${id}`, { headers: { Pragma: 'no-cache' } }).then((response) => {
      //console.log(response.data.value);
      if (response.status === 200) {
        resolve(response.data.value);
      }
    });
  }).catch((err) => {
    console.log(err);
  });
};

export const getBookingList = (categoryId) => {
  return new Promise(function (resolve) {
    axiosInstance.get(`${API_URL}/booking/filter/${categoryId}`, { headers: { Pragma: 'no-cache' } }).then((response) => {
      //console.log(response.data.value);
      if (response.status === 200) {
        const bookingList = sortedResponse(response, 'createdAt').reverse();
        resolve(bookingList);
      }
    });
  }).catch((err) => {
    console.log(err);
  });
};

export const getBookingListForUser = (userId) => {
  return new Promise(function (resolve) {
    axiosInstance.get(`${API_URL}/booking/for/${userId}`, { headers: { Pragma: 'no-cache' } }).then((response) => {
      //console.log(response.data.value);
      if (response.status === 200) {
        const bookingList = sortedResponse(response, 'createdAt').reverse();
        resolve(bookingList);
      }
    });
  }).catch((err) => {
    console.log(err);
  });
};

export const getBookingInfo = (id) => {
  return new Promise(function (resolve) {
    axiosInstance.get(`${API_URL}/booking/${id}`, { headers: { Pragma: 'no-cache' } }).then((response) => {
      //console.log(response.data.value);
      if (response.status === 200) {
        resolve(response.data.value);
      }
    });
  }).catch((err) => {
    console.log(err);
  });
};

export const getExchangeData = (code) => {
  return new Promise(function (resolve) {
    axiosInstance.get(`${API_URL}/exchange/${code}`, { headers: { Pragma: 'no-cache' } }).then((response) => {
      //console.log(response.data.value);
      if (response.status === 200) {
        resolve(response.data.value);
      }
    });
  }).catch((err) => {
    console.log(err);
  });
};

export const getBannerList = (status) => {
  return new Promise(function (resolve) {
    axiosInstance.get(`${API_URL}/banner/filter/${status}`, { headers: { Pragma: 'no-cache' } }).then((response) => {
      //console.log(response.data.value);
      if (response.status === 200) {
        const bannerList = sortedResponse(response, 'createdAt').reverse();
        resolve(bannerList);
      }
    });
  }).catch((err) => {
    console.log(err);
  });
};

export const getBannerInfo = (id) => {
  return new Promise(function (resolve) {
    axiosInstance.get(`${API_URL}/banner/${id}`, { headers: { Pragma: 'no-cache' } }).then((response) => {
      //console.log(response.data.value);
      if (response.status === 200) {
        resolve(response.data.value);
      }
    });
  }).catch((err) => {
    console.log(err);
  });
};

export const getInquireList = () => {
  return new Promise(function (resolve) {
    axiosInstance.get(`${API_URL}/inquire/`, { headers: { Pragma: 'no-cache' } }).then((response) => {
      //console.log(response.data.value);
      if (response.status === 200) {
        const list = sortedResponse(response, 'createdAt').reverse();
        resolve(list);
      }
    });
  }).catch((err) => {
    console.log(err);
  });
};

export const getInquireInfo = (id) => {
  return new Promise(function (resolve) {
    axiosInstance.get(`${API_URL}/inquire/${id}`, { headers: { Pragma: 'no-cache' } }).then((response) => {
      //console.log(response.data.value);
      if (response.status === 200) {
        resolve(response.data.value);
      }
    });
  }).catch((err) => {
    console.log(err);
  });
};

export const getQuestionList = () => {
  return new Promise(function (resolve) {
    axiosInstance.get(`${API_URL}/question/`, { headers: { Pragma: 'no-cache' } }).then((response) => {
      //console.log(response.data.value);
      if (response.status === 200) {
        const list = sortedResponse(response, 'createdAt').reverse();
        resolve(list);
      }
    });
  }).catch((err) => {
    console.log(err);
  });
};

export const getQuestionInfo = (id) => {
  return new Promise(function (resolve) {
    axiosInstance.get(`${API_URL}/question/${id}`, { headers: { Pragma: 'no-cache' } }).then((response) => {
      //console.log(response.data.value);
      if (response.status === 200) {
        resolve(response.data.value);
      }
    });
  }).catch((err) => {
    console.log(err);
  });
};
