import NewsListPage from './NewsListPage';

export const NewsListPageConfig = {
  routes: [
    {
      path: '/pages/news',
      exact: true,
      component: NewsListPage,
    },
  ],
};
