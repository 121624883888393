import axios from 'axios';
import { Auth } from 'aws-amplify';
import awsconfig from '../aws-exports';
const API_URL = '';
const axiosInstance = axios.create({
  baseURL: API_URL,
  timeout: 1000 * 30,
  headers: { Pragma: 'no-cache', CacheControl: 'no-cache', Expires: '0', Authorization: `${sessionStorage.getItem('bt_jwt')}` },
});

axiosInstance.interceptors.request.use(
  function (config) {
    const token = `${sessionStorage.getItem('bt_jwt')}`;
    config.headers.Authorization = token;
    config.headers.Bucket = awsconfig.aws_user_files_s3_bucket;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  function (response) {
    tokenRefresh().then((r) => {});
    return response;
  },

  function (error) {
    if (error.response) {
      console.log(error.response.data);
      console.log(error.response.status);
      console.log(error.response.headers);
      return Promise.resolve(error.response.data);
    } else if (error.request) {
      console.log(error.request);
    } else {
      console.log('Error', error.message);
    }
    console.log(error.config);
    const orgRequest = error.config;
    if (error.response && error.response.status === 401) {
      console.log('토큰 만료');
      tokenRefresh()
        .then((refreshRes) => {
          console.log('Refresh Success');
          // window.location.reload();
        })
        .catch((err) => {
          console.log(err.message);
          // window.location.href = '/pages/auth/login';
        });
    }
    return Promise.resolve(error); //reject(error)
  },
);

export const tokenRefresh = async () => {
  try {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const currentSession = await Auth.currentSession();
    cognitoUser.refreshSession(currentSession.refreshToken, (err, session) => {
      if (session) {
        //console.log(session);
        const { accessToken } = session;
        sessionStorage.setItem('bt_jwt', accessToken.jwtToken);
        return Promise.resolve(accessToken.jwtToken);
      } else {
        console.log(err.message);
      }
    });
  } catch (e) {
    console.log('Unable to refresh Token', e);
  }
};

export default axiosInstance;
