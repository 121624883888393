import * as React from 'react';
import { forwardRef, useImperativeHandle } from 'react';
import { useDropzone } from 'react-dropzone';
import 'core-js/modules/es.array.from';
import IconButton from '@material-ui/core/IconButton';
import { RemoveCircle } from '@material-ui/icons';
import axiosInstance from '../../constants/AxiosInstance';
import { Storage } from 'aws-amplify';
import { BASE_IMG_URL } from '../../constants';
import UploadToS3 from './UploadToS3';

const DropZone = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    async getSignedUrl(type) {},
  }));

  const onDrop = (acceptedFiles) => {
    removeAllFile();
    preview(acceptedFiles).then((r) => {
      //console.log(props);
      props.handleAddImage(r, props.type);
    });
  };

  const preview = async (acceptedFiles) => {
    let file = {};
    for (let i = 0; i < acceptedFiles.length; i++) {
      await readFile(acceptedFiles[acceptedFiles.length - 1], -1).then((_file) => {
        file = _file;
      });
    }
    return file;
  };

  const readFile = (file, limit) => {
    if (!limit) limit = 5;
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = (_read) => {
        if (limit > 0 && _read.total > 1000000 * limit) {
          reject(`File too large : limit ${limit}MB`);
        } else {
          resolve({ path: _read.target.result, name: file.name, obj: file, id: `file_${new Date().getTime().toString()}` });
        }
      };
    });
  };

  const { getRootProps, getInputProps, inputRef } = useDropzone({
    noKeyboard: true,
    maxSize: 5242880, //20971520,
    multiple: true,
    accept: 'application/pdf, image/*',
    onDrop,
  });

  const removeAllFile = () => {
    inputRef.current.files = new DataTransfer().files;
    if (props.file) {
      props.removeStorageFile(props.file);
      props.handleAddImage(null, props.type);
    }
  };

  const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    minHeight: '250px',
  };

  return (
    <section className="container">
      <div {...getRootProps({ className: 'dropzone', style: props.customStyle ? props.customStyle : baseStyle })}>
        <input {...getInputProps({ className: `${props.type}` })} />
        {props.file ? (
          <p key={'file-gallery-'} style={{ padding: 0, margin: 0 }}>
            <IconButton
              aria-label="delete"
              color={'secondary'}
              style={{ right: '-90%', top: 20, zIndex: 999 }}
              onClick={(e) => removeAllFile()}
            >
              <RemoveCircle />
            </IconButton>
            <img
              src={props.file.url ? props.file.url : props.file.path}
              width={'100%'}
              alt={'file-img'}
              onError={(e) => {
                e.target.src = 'https://via.placeholder.com/100.png?text=noimage';
              }}
            />
          </p>
        ) : props.customText ? (
          <p> {props.customText}</p>
        ) : (
          <p> + image here to prompt users to click or drag</p>
        )}
      </div>
    </section>
  );
});

export default DropZone;

export const fileUpload = async (attatch, targetId, folder, apiUrl) => {
  const param = {
    id: targetId,
  };

  for (let i = 0; i < attatch.length; i++) {
    const att = attatch[i];
    const uploadFile = att.file;

    const timestamp = new Date().getTime();
    const ext = uploadFile.name && uploadFile.name.split('.').pop();

    if (ext) {
      // const result = await Storage.put(folder + '/' + targetId + '_' + timestamp + (i + 1) + '.' + ext, uploadFile, {
      //   customPrefix: { public: '' },
      // });
      const result = await UploadToS3(uploadFile, folder, targetId, (val) => {
        //setProgress({ [upKey + '_' + i]: val });
      });
      const url = `${BASE_IMG_URL}` + result.key;
      const upKey = att.key;
      param[upKey] = [];
      param['uploadKey'] = param['uploadKey'] ? `${param['uploadKey']}, ${upKey}` : upKey;
      param[upKey].push({
        key: result.key,
        url: url,
        name: uploadFile.name,
        type: uploadFile.type,
        size: uploadFile.size,
      });
      if (i === attatch.length - 1) {
        axiosInstance.put(apiUrl, param).then((response) => {
          if (i === attatch.length) {
            return response;
          }
        });
      }
    }
  }
};
