import React, { useEffect, useRef, useState } from 'react';
import queryString from 'query-string';
import OftadehLayout from '../../components/OftadehLayout/OftadehLayout';
import { Button, Divider, Grid, makeStyles, Typography } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import swal from '@sweetalert/with-react';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import ListIcon from '@material-ui/icons/List';
import DeleteIcon from '@material-ui/icons/Delete';
import axiosInstance from '../../constants/AxiosInstance';
import { API_URL, DEF_NOTICE_CATE } from '../../constants';
import { getInquireInfo } from '../../constants/PromiseHttp';
import ImgListView from '../customComp/ImgListView';
import SaveIcon from '@material-ui/icons/Save';
import FileUpload from '../../components/FileUpload/FileUpload';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    color: '#3f51b5',
  },
  my3: {
    margin: '1.3rem 0',
  },
  mb0: {
    marginBottom: 0,
  },
  mRight: {
    marginRight: '.85rem',
  },
  p1: {
    padding: '.85rem',
  },
}));

const InquireDetailPage = (props) => {
  const query = queryString.parse(props.location.search);
  const type = query.type ? query.type : 'new';

  const editor = useRef(null);
  const [inquire, setInquire] = useState({});

  const { history } = props;
  const classes = useStyles();

  const [uploadFile, setUploadFile] = useState([]);
  const childRef = useRef(null);
  const imgKey = 'files';

  const handleChangeData = (value, field) => {
    inquire[field] = value;
    setInquire({ ...inquire });
  };

  useEffect(() => {
    if (type === 'edit' && query.id) {
      getData();
    } else {
      setInquire([]);
    }
  }, []);

  const getData = () => {
    getInquireInfo(query.id).then((result) => {
      setInquire(result);
      if (childRef.current) {
        childRef.current.getSignedUrl(result[imgKey]);
      }
    });
  };

  const addInquire = () => {
    let param = {};

    if (type === 'new') {
      axiosInstance.post(`${API_URL}/inquire/`, param).then(async (response) => {
        if (response.status === 200) {
          if (childRef.current && response.data.value.id) {
            await Promise.all([childRef.current.fileUpload(uploadFile, response.data.value.id, 'inquire', `${API_URL}/inquire/`)]);
            swal('Registered.', {
              icon: 'success',
            });
            goList();
          } else {
            swal('Registered.', {
              icon: 'success',
            });
            goList();
          }
        }
      });
    } else {
      param['id'] = query.id;
      //TEST
      param['languageId'] = 'ko';
      param['name'] = 'kjkang';
      param['email'] = 'kjkang@atozsoft.co.kr';
      param['contents'] = '테스트용 글입니다. ' + new Date().getTime().toString();

      axiosInstance.put(`${API_URL}/inquire/`, param).then(async (response) => {
        if (response.status === 200) {
          if (childRef.current) {
            await Promise.all([childRef.current.fileUpload(uploadFile, query.id, 'inquire', `${API_URL}/inquire/`)]);
            swal('It is changed.', {
              icon: 'success',
            });
            goList();
          } else {
            swal('It is changed.', {
              icon: 'success',
            });
            goList();
          }
        }
      });
    }
  };

  const deleteInquire = () => {
    swal({
      title: 'Are you sure you want to delete?',
      text: '',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        axiosInstance.delete(`${API_URL}/inquire/${query.id}`, { headers: { Pragma: 'no-cache' } }).then((response) => {
          if (response.status === 200) {
            swal('It is deleted.', {
              icon: 'success',
            });
            goList();
          }
        });
      } else {
      }
    });
  };

  const goList = () => {
    history.push('/pages/inquire/');
  };

  const handleFile = (file, key) => {
    console.log(file, key);
    setUploadFile([...file]);
  };
  const handleUpload = () => {
    console.log('Upload Success');
  };
  const handleRemove = () => {
    console.log('Remove Success');
    getData();
  };

  return (
    <OftadehLayout>
      <Grid container className={classes.my3} alignItems="center">
        <Grid item className={classes.mRight} style={{ fontWeight: 'bold' }}>
          <Typography variant="h5" component="h1" style={{ fontWeight: 'bold' }}>
            Inquire Detail
          </Typography>
        </Grid>
      </Grid>

      <Card className={classes.root}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12} mb={12}>
              <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                Basic Info
              </Typography>
            </Grid>
            <Grid item xs={4} mb={6}>
              <div className={classes.title}>Language</div>
              <Typography variant="body1">{(inquire && inquire.languageId) || ''}</Typography>
            </Grid>
            <Grid item xs={4} mb={6}>
              <div className={classes.title}>Name</div>
              <Typography variant="body1">{(inquire && inquire.name) || ''}</Typography>
            </Grid>
            <Grid item xs={4} mb={6}>
              <div className={classes.title}>Email</div>
              <Typography variant="body1">{(inquire && inquire.email) || ''}</Typography>
            </Grid>
          </Grid>
          <Divider style={{ marginTop: '10px' }} />
          <Grid container spacing={3} style={{ marginTop: '10px' }}>
            <Grid item xs={12} mb={12}>
              <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                Question
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.title}>Contents</div>
              <Typography variant="body1">{(inquire && inquire.contents) || ''}</Typography>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.title}>Attachments </div>
              {inquire && <ImgListView images={inquire && inquire.files} />}
            </Grid>
          </Grid>
        </CardContent>
        <CardActions style={{ marginLeft: '16px', marginRight: '16px' }}>
          <Grid container xs={12} md={12} spacing={3}>
            <Grid item>
              <Button
                variant="contained"
                style={{ backgroundColor: green[700], color: '#ffffff', marginRight: '10px' }}
                size="medium"
                onClick={goList}
                startIcon={<ListIcon />}
              >
                List
              </Button>
              {type !== 'new' ? (
                <Button variant="contained" color="secondary" size="medium" onClick={deleteInquire} startIcon={<DeleteIcon />}>
                  Delete
                </Button>
              ) : null}
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    </OftadehLayout>
  );
};

export default InquireDetailPage;
