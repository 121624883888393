import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { getBookingListForUser } from '../../constants/PromiseHttp';
import MUIDataTable from 'mui-datatables';
import { BOOKING_CATEGORY, BOOKING_STATUS, getTypeName } from '../../constants';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  my3: {
    margin: '1.3rem 0',
  },
  mb0: {
    marginBottom: 0,
  },
  mRight: {
    marginRight: '.85rem',
  },
  p1: {
    padding: '.85rem',
  },
}));

const TabBookingList = (props) => {
  const { history } = props;
  const [data, setData] = useState();
  // const [types, setTypes] = useState([]);
  //const classes = useStyles();

  const cols = [
    {
      name: 'id',
      label: 'id',
      options: {
        filter: false,
        sort: true,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: 'id',
      label: 'No.',
      options: {
        filter: false,
        sort: true,
        display: true,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return tableMeta.rowIndex + 1;
        },
      },
    },
    {
      name: 'categoryId',
      label: 'Category',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return getTypeName(BOOKING_CATEGORY, value);
        },
      },
    },
    {
      name: 'email',
      label: 'Email',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'phoneNumber',
      label: 'Phone Number',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'createdAt',
      label: 'Reservation Date',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const dt = new Date(Number(value));
          return dt.getFullYear() + '-' + (dt.getMonth() + 1) + '-' + dt.getDate() + ' ' + dt.getHours() + ':' + dt.getMinutes();
        },
      },
    },
    {
      name: 'status',
      label: 'Reservation Status',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return getTypeName(BOOKING_STATUS, value);
        },
      },
    },
  ];

  const rowClick = (_data) => {
    console.log(_data);
    let cate = data[_data[1]].categoryId;
    history.push(`/pages/booking/detail?cate=${cate}&type=edit&id=${_data[0]}`);
  };

  const options = {
    print: false,
    filter: false,
    search: false,
    filterType: 'checkbox',
    selectableRows: 'none',
    viewColumns: false,
    download: false,
    onRowClick: rowClick,
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    getBookingListForUser(props.userId).then((result) => {
      setData(result);
    });
  };

  return (
    <div>
      {/*<OftadehBreadcrumbs path={history} />*/}
      <MUIDataTable style={{ width: '100%' }} title={''} data={data} columns={cols} options={options} />
    </div>
  );
};
export default TabBookingList;
