import { format } from 'date-fns';
import { gapi } from 'gapi-script';

export const queryReport = async (props) => {
  const { viewID, startDate, endDate, metrics, dimensions, orderBy, filter } = props;

  const requestMetrics = (metrics) => {
    let result = [];
    metrics.forEach((item) => {
      result.push({
        expression: item,
      });
    });
    return result;
  };
  const requestDimensions = (dimensions) => {
    let result = [];
    dimensions.forEach((item) => {
      result.push({
        name: item,
      });
    });
    return result;
  };

  return (
    gapi.client &&
    gapi.client.request({
      path: '/v4/reports:batchGet',
      root: 'https://analyticsreporting.googleapis.com/',
      method: 'POST',
      body: {
        reportRequests: [
          {
            viewId: viewID,
            filtersExpression: filter,
            dateRanges: [
              {
                startDate: format(new Date(startDate), 'yyyy-MM-dd'),
                endDate: format(new Date(endDate), 'yyyy-MM-dd'),
              },
            ],
            metrics: requestMetrics(metrics),
            dimensions: requestDimensions(dimensions),
            orderBys: orderBy
              ? [
                  {
                    fieldName: orderBy.fieldName,
                    sortOrder: orderBy.order,
                  },
                ]
              : [],
          },
        ],
      },
    })
  );
};
