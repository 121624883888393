import React, { useEffect, useRef, useState } from 'react';
import queryString from 'query-string';
import OftadehLayout from '../../../components/OftadehLayout/OftadehLayout';
import { Button, FormControlLabel, Grid, makeStyles, Radio, RadioGroup, Switch, TextField, Typography } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import swal from '@sweetalert/with-react';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import SaveIcon from '@material-ui/icons/Save';
import ListIcon from '@material-ui/icons/List';
import DeleteIcon from '@material-ui/icons/Delete';
import { getConsultCaseInfo } from '../../../constants/PromiseHttp';
import axiosInstance from '../../../constants/AxiosInstance';
import {
  API_URL,
  DEF_COUNTRY,
  DEF_DATE,
  DEF_GENDER,
  DEF_LANGUAGE,
  EMAIL_REG,
  GENDER_TYPE,
  getDefName,
  PHONE_REG,
  phoneNumberValidation,
} from '../../../constants';
import LanguageAutocomplete from '../../customComp/LanguageAutocomplete';
import FormControl from '@material-ui/core/FormControl';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import CountryAutocomplete from '../../customComp/CountryAutocomplete';
import DiseaseTransferList from '../../customComp/DiseaseTransferList';
import FileUpload from '../../../components/FileUpload/FileUpload';
import HospitalAutocomplete from '../../customComp/HospitalAutocomplete';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  my3: {
    margin: '1.3rem 0',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  mRight: {
    marginRight: '.85rem',
  },
  mInput: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
  textArea: {
    width: '98%',
    minHeight: 300,
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    borderColor: 'lightgray',
  },
}));

const ConsultCaseEditPage = (props) => {
  const query = queryString.parse(props.location.search);
  const type = query.type ? query.type : 'new';

  const [csCase, setConsultCase] = useState({});

  const { history } = props;
  const classes = useStyles();

  const [uploadFile, setUploadFile] = useState([]);
  const childRef = useRef(null);
  const imgKey = 'statement';

  const [uploadPhoto, setUploadPhoto] = useState([]);
  const photoRef = useRef(null);
  const photoKey = 'photo';

  const [uploadDicom, setUploadDicom] = useState([]);
  const dicomRef = useRef(null);
  const dicomKey = 'dicom';

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    if ((type === 'edit' || type === 'clone') && query.id) {
      getConsultCaseInfo(query.id).then((result) => {
        //console.log('Edit >>', result);
        setConsultCase(result);
        if (type === 'clone') {
          let data = result;
          data[imgKey] = '';
          data[photoKey] = '';
          data[dicomKey] = '';
          setConsultCase(data);
        } else {
          if (childRef.current) {
            childRef.current.getSignedUrl(result[imgKey]);
          }
          if (photoRef.current) {
            photoRef.current.getSignedUrl(result[photoKey]);
          }
          if (dicomRef.current) {
            dicomRef.current.getSignedUrl(result[dicomKey]);
          }
        }
      });
    }
  };

  const addConsultCase = () => {
    if (!csCase.userId && !csCase.userName) {
      swal('Missing required name.');
      return;
    }
    if (!csCase.email) {
      swal('Missing required email.');
      return;
    }
    if (!csCase.birth) {
      swal('Missing required birth.');
      return;
    }
    if (!csCase.title) {
      swal('Missing required title of consult.');
      return;
    }
    if (!csCase.contents) {
      swal('Missing required contents of consult.');
      return;
    }

    let param = {
      languageId: csCase.languageId || DEF_LANGUAGE,
      userId: csCase.userId, //회원의 문의인 경우, 차후 사용을 위해 받아둠.
      userName: csCase.userName,
      email: csCase.email,
      gender: csCase.gender || DEF_GENDER,
      phoneNumber: csCase.phoneNumber,
      birth: csCase.birth,
      countryId: csCase.countryId || DEF_COUNTRY,
      hospitalId: csCase.hospitalId,
      title: csCase.title,
      contents: csCase.contents,
      answers: csCase.answers,
      subDiseaseIds: csCase.subDiseaseIds,
      tags: csCase.tags,
      hidden: csCase.hidden || false,
    };

    if (type === 'new' || type === 'clone') {
      axiosInstance.post(`${API_URL}/consultCase/`, param, { headers: { Pragma: 'no-cache' } }).then(async (response) => {
        if (response.status === 200) {
          await Promise.all([
            childRef.current &&
              childRef.current.fileUpload(uploadFile, response.data.value.id, 'consultCase', `${API_URL}/consultCase/`).then(() => {
                console.log(`${imgKey} upload success`);
              }),
            photoRef.current &&
              photoRef.current.fileUpload(uploadPhoto, response.data.value.id, 'consultCase', `${API_URL}/consultCase/`).then(() => {
                console.log(`${photoKey} upload success`);
              }),
            dicomRef.current &&
              dicomRef.current.fileUpload(uploadDicom, response.data.value.id, 'consultCase', `${API_URL}/consultCase/`).then(() => {
                console.log(`${dicomKey} upload success`);
              }),
          ]);
          swal('Registered.', {
            icon: 'success',
          });
          goList();
        }
      });
    } else {
      param['id'] = query.id;

      axiosInstance.put(`${API_URL}/consultCase/`, param, { headers: { Pragma: 'no-cache' } }).then(async (response) => {
        if (response.status === 200) {
          await Promise.all([
            childRef.current &&
              childRef.current.fileUpload(uploadFile, query.id, 'consultCase', `${API_URL}/consultCase/`).then(() => {
                console.log(`${imgKey} upload success`);
              }),
            photoRef.current &&
              photoRef.current.fileUpload(uploadPhoto, query.id, 'consultCase', `${API_URL}/consultCase/`).then(() => {
                console.log(`${photoKey} upload success`);
              }),
            dicomRef.current &&
              dicomRef.current.fileUpload(uploadDicom, query.id, 'consultCase', `${API_URL}/consultCase/`).then(() => {
                console.log(`${dicomKey} upload success`);
              }),
          ]);
          swal('It is changed.', {
            icon: 'success',
          });
          goList();
        }
      });
    }
  };

  const deleteConsultCase = () => {
    swal({
      title: 'Are you sure you want to delete?',
      text: '',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        axiosInstance.delete(`${API_URL}/consultCase/${query.id}`, { headers: { Pragma: 'no-cache' } }).then((response) => {
          if (response.status === 200) {
            goList();
            swal('It is deleted.', {
              icon: 'success',
            });
          }
        });
      } else {
      }
    });
  };

  const goList = () => {
    history.push('/pages/consult/case/');
  };

  const setSelectedDisease = (value) => {
    handleChangeData(value, 'subDiseaseIds');
  };

  const handleChangeData = (value, field) => {
    csCase[field] = value;
    setConsultCase({ ...csCase });
  };

  const handleFile = (file, key) => {
    console.log(file, key);

    if (key === imgKey) {
      setUploadFile([...file]);
    }
    if (key === photoKey) {
      setUploadPhoto([...file]);
    }
    if (key === dicomKey) {
      setUploadDicom([...file]);
    }
  };
  const handleUpload = () => {
    console.log('Upload Success');
  };
  const handleRemove = () => {
    console.log('Remove Success');
    getData();
  };

  return (
    <OftadehLayout>
      <Grid container className={classes.my3} alignItems="center">
        <Grid item className={classes.mRight} style={{ fontWeight: 'bold' }}>
          <Typography variant="h5" component="h1" style={{ fontWeight: 'bold' }}>
            Consultation Case {type === 'new' ? 'Registration' : type === 'clone' ? 'Clone' : 'Edit'}
          </Typography>
        </Grid>
      </Grid>

      <div className={classes.root}>
        <Card className={classes.root}>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={6} mb={6}>
                <Typography component="legend">
                  Language <span style={{ color: 'red' }}>*</span>
                </Typography>
                <LanguageAutocomplete target={csCase} handleChangeData={handleChangeData} />
              </Grid>
              <Grid item xs={6} mb={6}>
                <Typography style={{}}>
                  Country <span style={{ color: 'red' }}>*</span>
                </Typography>
                <CountryAutocomplete target={csCase} handleChangeData={handleChangeData} />
              </Grid>

              <Grid item xs={6}>
                <Typography style={{}}>
                  Name <span style={{ color: 'red' }}>*</span>
                </Typography>
                <TextField
                  size={'small'}
                  key={`TextField-name`}
                  className={classes.mInput}
                  placeholder=""
                  margin="normal"
                  style={{ width: '100%', marginRight: '10px' }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  value={(csCase && csCase.userName) || ''}
                  onChange={(event) => {
                    handleChangeData(event.target.value, 'userName');
                  }}
                />
              </Grid>
              <Grid item xs={6} mb={6}>
                <Typography style={{}}>
                  Preferred institution <span style={{ color: 'red' }}>*</span>
                </Typography>
                <HospitalAutocomplete target={csCase} handleChangeData={handleChangeData} />
              </Grid>
              <Grid item xs={6}>
                <Typography style={{}}>
                  Email <span style={{ color: 'red' }}>*</span>
                </Typography>
                <TextField
                  size={'small'}
                  type={'email'}
                  key={`TextField-email`}
                  className={classes.mInput}
                  placeholder=""
                  margin="normal"
                  style={{ width: '100%', marginRight: '10px' }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  value={(csCase && csCase.email) || ''}
                  onChange={(event) => {
                    handleChangeData(event.target.value, 'email');
                  }}
                  error={!EMAIL_REG.test(csCase.email)}
                  helperText={EMAIL_REG.test(csCase.email) ? '' : 'Please enter your email correctly.'}
                />
              </Grid>

              <Grid item xs={6}>
                <Typography style={{}}>Phone number</Typography>
                <TextField
                  size={'small'}
                  type="text"
                  key={`TextField-phone`}
                  className={classes.mInput}
                  placeholder=""
                  margin="normal"
                  style={{ width: '100%', marginRight: '10px' }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  value={(csCase && csCase.phoneNumber) || ''}
                  onChange={(event) => {
                    handleChangeData(event.target.value, 'phoneNumber');
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <form noValidate>
                  <Typography style={{}}>
                    Birth date <span style={{ color: 'red' }}>*</span>
                  </Typography>
                  <TextField
                    id="date"
                    type="date"
                    value={(csCase && csCase.birth) || DEF_DATE}
                    variant="outlined"
                    size="small"
                    className={classes.mInput}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(event) => {
                      handleChangeData(event.target.value, 'birth');
                    }}
                  />
                </form>
              </Grid>
              <Grid item xs={6}>
                <Typography style={{}}>
                  Gender <span style={{ color: 'red' }}>*</span>
                </Typography>
                <FormControl component="gender-form" className={classes.mInput}>
                  <RadioGroup
                    aria-label="gender-radio-group"
                    name="gender-radio-group"
                    onChange={(event) => {
                      handleChangeData(event.target.value, 'gender');
                    }}
                    row
                  >
                    <FormControlLabel
                      value={GENDER_TYPE[0].id}
                      checked={csCase.gender === undefined || csCase.gender === GENDER_TYPE[0].id}
                      control={<Radio />}
                      label={GENDER_TYPE[0][getDefName()] || ''}
                    />
                    <FormControlLabel
                      value={GENDER_TYPE[1].id}
                      checked={csCase.gender === GENDER_TYPE[1].id}
                      control={<Radio />}
                      label={GENDER_TYPE[1][getDefName()] || ''}
                    />
                    <FormControlLabel
                      value={GENDER_TYPE[2].id}
                      checked={csCase.gender === GENDER_TYPE[2].id}
                      control={<Radio />}
                      label={GENDER_TYPE[2][getDefName()] || ''}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={10}>
                <Typography style={{}}>Tags</Typography>
                <TextField
                  size={'small'}
                  key={`TextField-tags`}
                  className={classes.mInput}
                  placeholder=",Please enter them separated by commas."
                  margin="normal"
                  style={{ width: '100%', marginRight: '10px' }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  value={(csCase && csCase.tags) || ''}
                  onChange={(event) => {
                    handleChangeData(event.target.value, 'tags');
                  }}
                />
              </Grid>
              <Grid item xs={2} mb={6}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={!csCase.hidden}
                      onChange={(e, newValue) => {
                        handleChangeData(!newValue, 'hidden');
                      }}
                    />
                  }
                  label="Show"
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Card className={classes.my3}>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography style={{}}>
                  Case title <span style={{ color: 'red' }}>*</span>
                </Typography>
                <TextField
                  size={'small'}
                  type="text"
                  key={`TextField-title`}
                  className={classes.mInput}
                  placeholder=""
                  margin="normal"
                  style={{ width: '100%', marginRight: '10px' }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  value={(csCase && csCase.title) || ''}
                  onChange={(event) => {
                    handleChangeData(event.target.value, 'title');
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography style={{}}>
                  Contents <span style={{ color: 'red' }}>*</span>
                </Typography>
                <TextareaAutosize
                  key={'textarea-contents'}
                  id={'textarea-contents'}
                  className={classes.textArea}
                  aria-label="default"
                  placeholder=""
                  value={csCase.contents || ''}
                  onChange={(e) => handleChangeData(e.target.value, 'contents')}
                />
              </Grid>
              <Grid item xs={12} mb={12}>
                <Typography style={{ marginTop: 10 }} variant="subtitle1">
                  Attachments{' '}
                </Typography>
              </Grid>
              <Grid item xs={12} mb={12}>
                <Typography style={{ marginTop: 10 }} variant="subtitle2">
                  Diagnosis, Opinion, Test result sheet, etc.{' '}
                </Typography>
                <FileUpload
                  type={'file'}
                  ref={childRef}
                  buttonText={'File'}
                  onFileHandler={handleFile}
                  onRemoveAfter={handleRemove}
                  onUploadAfter={handleUpload}
                  fileId={query.id}
                  folder={'consultCase'}
                  apiUrl={`${API_URL}/consultCase/`}
                  uploadKey={imgKey}
                  maxCount={1}
                />
              </Grid>
              <Grid item xs={12} mb={12}>
                <Typography style={{ marginTop: 20 }} variant="subtitle2">
                  Photo Material (JPG, PNG, PDF, GIF){' '}
                </Typography>
                <FileUpload
                  type={'file'}
                  accept={'application/pdf, image/*'}
                  ref={photoRef}
                  buttonText={'File'}
                  onFileHandler={handleFile}
                  onRemoveAfter={handleRemove}
                  onUploadAfter={handleUpload}
                  fileId={query.id}
                  folder={'consultCase'}
                  apiUrl={`${API_URL}/consultCase/`}
                  uploadKey={photoKey}
                  maxCount={1}
                />
              </Grid>
              <Grid item xs={12} mb={12}>
                <Typography style={{ marginTop: 20 }} variant="subtitle2">
                  DICOM File
                </Typography>
                <FileUpload
                  type={'file'}
                  ref={dicomRef}
                  buttonText={'File'}
                  onFileHandler={handleFile}
                  onRemoveAfter={handleRemove}
                  onUploadAfter={handleUpload}
                  fileId={query.id}
                  folder={'consultCase'}
                  apiUrl={`${API_URL}/consultCase/`}
                  uploadKey={dicomKey}
                  maxCount={1}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography style={{ marginTop: 20 }} variant="subtitle1">
                  Disease selection
                </Typography>
                {(type === 'new' || (csCase && csCase.id)) && (
                  <DiseaseTransferList selectedDisease={csCase.subDiseaseIds} setSelectedDisease={setSelectedDisease} />
                )}
              </Grid>
            </Grid>
          </CardContent>
          <CardActions style={{ marginLeft: '16px', marginRight: '16px' }}>
            <Grid container justifyContent={'space-between'} xs={12} md={12} spacing={3}>
              <Grid item>
                <Button
                  variant="contained"
                  style={{ backgroundColor: green[700], color: '#ffffff', marginRight: '10px' }}
                  size="medium"
                  onClick={goList}
                  startIcon={<ListIcon />}
                >
                  List
                </Button>
                {type !== 'new' ? (
                  <Button variant="contained" color="secondary" size="medium" onClick={deleteConsultCase} startIcon={<DeleteIcon />}>
                    Delete
                  </Button>
                ) : null}
              </Grid>
              <Grid item>
                <Button variant="contained" color="primary" size="medium" onClick={addConsultCase} startIcon={<SaveIcon />}>
                  {type === 'new' ? 'Registration' : 'Save'}
                </Button>
              </Grid>
            </Grid>
          </CardActions>
        </Card>
      </div>
    </OftadehLayout>
  );
};

export default ConsultCaseEditPage;
