import React, { useEffect, useRef, useState } from 'react';

import queryString from 'query-string';
import OftadehLayout from '../../components/OftadehLayout/OftadehLayout';
import { AppBar, Button, CircularProgress, Grid, makeStyles, Typography } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import swal from '@sweetalert/with-react';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import SaveIcon from '@material-ui/icons/Save';
import ListIcon from '@material-ui/icons/List';
import DeleteIcon from '@material-ui/icons/Delete';
import axiosInstance from '../../constants/AxiosInstance';
import { API_URL, DEF_LANGUAGE } from '../../constants';
import PropTypes from 'prop-types';
import { getHospitalInfo } from '../../constants/PromiseHttp';
import TabSpecialtyView from './TabSpecialtyView';
import TabDescView from './TabDescView';
import { TabInfoView } from './TabInfoView';
import TabAttatchView from './TabAttatchView';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  my3: {
    margin: '1.3rem 0',
  },
  mb0: {
    marginBottom: 0,
  },
  mRight: {
    marginRight: '.85rem',
  },
  p1: {
    padding: '.85rem',
  },
}));

function TabPanel(props) {
  const { children, value, index, hospital, handle, attRef, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} key={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && getTabView(index, hospital, handle, attRef)}
    </div>
  );
}

const getTabView = (idx, hospital, handle, attRef) => {
  if (!hospital || !handle) {
    return;
  }
  switch (idx) {
    case 0:
      return <TabInfoView id={`tabview-0`} hospital={hospital} handleChangeData={handle} />;
    case 1:
      return <TabDescView id={`tabview-1`} hospital={hospital} handleChangeData={handle} />;
    case 2:
      return <TabSpecialtyView id={`tabview-3`} hospital={hospital} handleChangeData={handle} />;
    case 3:
      return <TabAttatchView id={`tabview-2`} hospital={hospital} handleChangeData={handle} ref={attRef} />;
    default:
      return <TabInfoView id={`tabview-${idx}`} />;
  }
};
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const HospitalEditPage = (props) => {
  const query = queryString.parse(props.location.search);
  const type = query.type ? query.type : 'new';
  const [loading, setLoading] = useState(false);
  const IS_SYS_ADMIN = sessionStorage.getItem('bt_role') === 'SYS_ADM';

  const [hospital, setHospital] = useState({});
  const attachViewRef = useRef(null);

  const { history } = props;
  const classes = useStyles();

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    if ((type === 'edit' || type === 'clone') && query.id) {
      getHospitalInfo(query.id).then((result) => {
        setHospital(result);
        let data = result;
        if (type === 'clone') {
          data['images'] = [];
          data['files'] = [];
          data['thumbs1'] = '';
          data['thumbs2'] = '';
          data['thumbs3'] = '';
          setHospital(data);
        }
      });
    } else {
      if (!hospital.adminId && !hospital.adminName) {
        handleChangeData(sessionStorage.getItem('bt_user'), 'adminId');
        handleChangeData(sessionStorage.getItem('bt_name'), 'adminName');
      }
    }
  };

  const addHospital = () => {
    if (!hospital.name_ko || !hospital.name_en) {
      swal('Missing required hospital name.');
      return;
    }
    if (!hospital.adminName && !hospital.adminId) {
      swal('Missing required hospital admin.');
      return;
    }
    if (!hospital.typeId) {
      swal('Missing required hospital type.');
      return;
    }
    if (!hospital.info.regNumber) {
      swal('Missing required medical institution registration number.');
      return;
    }

    let param = {
      name_en: hospital.name_en,
      name_ko: hospital.name_ko,
      languageId: hospital.languageId || DEF_LANGUAGE,
      typeId: hospital.typeId,
      adminId: hospital.adminId,
      adminName: hospital.adminName,
      hidden: IS_SYS_ADMIN ? hospital.hidden || false : true,
      info: hospital.info,
      desc: hospital.desc,
      thumbUrl1: hospital.thumbUrl1,
      thumbUrl2: hospital.thumbUrl2,
      thumbUrl3: hospital.thumbUrl3,
      subDiseaseIds: hospital.subDiseaseIds,
    };

    setLoading(true);
    if (type === 'new' || type === 'clone') {
      param['writerId'] = sessionStorage.getItem('bt_user');
      param['isSysAdmin'] = IS_SYS_ADMIN;
      axiosInstance.post(`${API_URL}/hospital/`, param, { headers: { Pragma: 'no-cache' } }).then(async (response) => {
        if (response.status === 200) {
          if (attachViewRef.current) {
            attachViewRef.current.doUpload(response.data.value.id).then(() => {
              swal('Registered.', {
                icon: 'success',
              });
              window.localStorage.setItem('hospitalList', '');
              goList();
              setLoading(false);
            });
          } else {
            swal('Registered.', {
              icon: 'success',
            });
            window.localStorage.setItem('hospitalList', '');
            goList();
            setLoading(false);
          }
        } else {
          setLoading(false);
        }
      });
    } else {
      param['id'] = query.id;

      axiosInstance.put(`${API_URL}/hospital/`, param, { headers: { Pragma: 'no-cache' } }).then(async (response) => {
        if (response.status === 200) {
          console.log(attachViewRef);
          if (attachViewRef.current) {
            attachViewRef.current.doUpload(query.id).then(() => {
              swal('It is changed.', {
                icon: 'success',
              });
              window.localStorage.setItem('hospitalList', '');
              goDetail();
              setLoading(false);
            });
          } else {
            swal('It is changed.', {
              icon: 'success',
            });
            window.localStorage.setItem('hospitalList', '');
            goDetail();
            setLoading(false);
          }
        } else {
          setLoading(false);
        }
      });
    }
  };

  const deleteHospital = () => {
    swal({
      title: 'Are you sure you want to delete?',
      text: '',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        axiosInstance.delete(`${API_URL}/hospital/${query.id}`, { headers: { Pragma: 'no-cache' } }).then((response) => {
          if (response.status === 200) {
            window.localStorage.setItem('hospitalList', '');
            goList();
            swal('It is deleted.', {
              icon: 'success',
            });
          }
        });
      } else {
      }
    });
  };

  const handleChangeData = (value, field) => {
    hospital[field] = value;
    setHospital({ ...hospital });
  };

  const goList = () => {
    history.push('/pages/hospital/');
  };

  const goDetail = () => {
    history.push(`/pages/hospital/detail?type=edit&id=${query.id}`);
  };

  const [tabValue, setTabValue] = React.useState(0);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <OftadehLayout>
      <Grid container className={classes.my3} alignItems="center">
        <Grid item className={classes.mRight} style={{ fontWeight: 'bold' }}>
          <Typography variant="h5" component="h1" style={{ fontWeight: 'bold' }}>
            Hospital Detail {type === 'new' ? 'Registration' : 'Edit'}
          </Typography>
        </Grid>
      </Grid>

      <div className={classes.root}>
        <Card className={classes.root}>
          {hospital && (
            <CardContent>
              <AppBar position="static">
                <Tabs value={tabValue} onChange={handleTabChange} aria-label="tabs">
                  <Tab label="Information" {...a11yProps(0)} />
                  <Tab label="Detail" {...a11yProps(1)} />
                  <Tab label="Disease" {...a11yProps(2)} />
                  <Tab label="Attachments" {...a11yProps(3)} />
                </Tabs>
              </AppBar>
              <TabPanel value={tabValue} hospital={hospital} handle={handleChangeData} index={0} />
              <TabPanel value={tabValue} hospital={hospital} handle={handleChangeData} index={1} />
              <TabPanel value={tabValue} hospital={hospital} handle={handleChangeData} index={2} />
              <TabPanel value={tabValue} hospital={hospital} handle={handleChangeData} attRef={attachViewRef} index={3} />
            </CardContent>
          )}
          <CardActions key={'card-action'} style={{ marginLeft: '16px', marginRight: '16px' }}>
            <Grid container justifyContent={'space-between'} xs={12} md={12} spacing={3}>
              <Grid item>
                <Button
                  variant="contained"
                  style={{ backgroundColor: green[700], color: '#ffffff', marginRight: '10px' }}
                  size="medium"
                  onClick={goList}
                  startIcon={<ListIcon />}
                >
                  List
                </Button>
                {type === 'edit' ? (
                  <Button variant="contained" color="secondary" size="medium" onClick={deleteHospital} startIcon={<DeleteIcon />}>
                    Delete
                  </Button>
                ) : null}
              </Grid>
              <Grid item>
                <Button variant="contained" color="primary" size="medium" onClick={addHospital} startIcon={<SaveIcon />}>
                  {type !== 'edit' ? 'Registration' : 'Save'}
                </Button>
              </Grid>
            </Grid>
          </CardActions>
        </Card>
      </div>
      {loading && (
        <div style={{ position: 'fixed', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', zIndex: 999 }}>
          <CircularProgress color={'secondary'} />
        </div>
      )}
    </OftadehLayout>
  );
};

export default HospitalEditPage;
