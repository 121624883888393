import React, { useEffect, useState } from 'react';
import OftadehLayout from '../../components/OftadehLayout/OftadehLayout';
import { Button, CircularProgress, Grid, makeStyles, Typography } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import { getBookingList, getConsultInfo, getHospitalList, getProductList } from '../../constants/PromiseHttp';
import { useLocation } from 'react-router-dom';
import swal from '@sweetalert/with-react';
import axiosInstance from '../../constants/AxiosInstance';
import { API_URL, BOOKING_CATEGORY, BOOKING_STATUS, getDefName, getTypeName } from '../../constants';

const useStyles = makeStyles((theme) => ({
  my3: {
    margin: '1.3rem 0',
  },
  mb0: {
    marginBottom: 0,
  },
  mRight: {
    marginRight: '.85rem',
  },
  p1: {
    padding: '.85rem',
  },
}));

const BookingListPage = (props) => {
  const { history } = props;
  const classes = useStyles();

  const location = useLocation();
  const cate = location.pathname.replace('/pages/booking/', '');

  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  // const [types, setTypes] = useState([]);
  const [hospitals, setHospitals] = useState([]);
  const [products, setProducts] = useState([]);

  const cols = [
    {
      name: 'id',
      label: 'id',
      options: {
        filter: false,
        sort: true,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: 'id',
      label: 'No.',
      options: {
        filter: false,
        sort: true,
        display: true,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return tableMeta.rowIndex + 1;
        },
      },
    },
    {
      name: 'userName',
      label: 'User',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value || '(undefined)';
        },
      },
    },
    {
      name: 'consultType',
      label: 'Consult Type',
      options: {
        filter: false,
        sort: true,
        display: cate === BOOKING_CATEGORY[0].id,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (value && (value === 'free' ? 'FREE (1:1)' : value.toUpperCase())) || '';
        },
      },
    },
    {
      name: 'hospitalId',
      label: 'Hospital',
      options: {
        filter: false,
        sort: true,
        display: cate !== BOOKING_CATEGORY[2].id,
        customBodyRender: (value, tableMeta, updateValue) => {
          const _hos = hospitals.find((hos) => hos.id === value);
          return _hos ? _hos['name_ko'] : '';
        },
      },
    },
    {
      name: 'productId',
      label: 'Product',
      options: {
        filter: false,
        sort: true,
        display: cate === BOOKING_CATEGORY[1].id,
        customBodyRender: (value, tableMeta, updateValue) => {
          const _prod = products.find((prod) => prod.id === value);
          return _prod ? _prod['name_ko'] : '';
        },
      },
    },
    {
      name: 'consultId',
      label: 'Consult',
      options: {
        filter: false,
        sort: true,
        display: false, //cate === BOOKING_CATEGORY[0].id,
      },
    },
    {
      name: 'useTranslator',
      label: 'Translator',
      options: {
        filter: false,
        sort: true,
        display: cate === BOOKING_CATEGORY[2].id,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? 'USE' : 'NOT USED';
        },
      },
    },
    {
      name: 'reserveDate',
      label: 'Reservation Date',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          if (!value) return '';
          const dt = new Date(Number(value));
          return dt.getFullYear() + '-' + (dt.getMonth() + 1) + '-' + dt.getDate() + ' ' + dt.getHours() + ':' + dt.getMinutes();
        },
      },
    },
    {
      name: 'confirmedAt',
      label: 'Confirmed Date',
      options: {
        filter: false,
        sort: true,
        display: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          if (!value) {
            return '';
          }
          const dt = new Date(Number(value));
          return dt.getFullYear() + '-' + (dt.getMonth() + 1) + '-' + dt.getDate() + ' ' + dt.getHours() + ':' + dt.getMinutes();
        },
      },
    },
    {
      name: 'status',
      label: 'Reservation Status',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return getTypeName(BOOKING_STATUS, value);
        },
      },
    },
  ];

  const rowClick = (data) => {
    console.log(data);
    history.push(`/pages/booking/detail?cate=${cate}&type=edit&id=${data[0]}`);
  };

  const rowDelete = (row) => {
    let ids = [];
    row.data.map((r) => {
      ids.push(data[r.dataIndex].id);
      return r;
    });
    let delParam = {
      ids: ids.toString(),
    };
    swal({
      title: 'Are you sure you want to delete?',
      text: '',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        axiosInstance.post(`${API_URL}/booking/deleteIds`, delParam, { headers: { Pragma: 'no-cache' } }).then((response) => {
          swal('It is deleted.', {
            icon: 'success',
          });
          getData();
        });
      } else {
        getData();
      }
    });
  };

  const options = {
    print: false,
    filter: false,
    filterType: 'checkbox',
    selectableRows: 'multiple',
    viewColumns: false,
    download: false,
    onRowClick: rowClick,
    onRowsDelete: rowDelete,
  };

  useEffect(() => {
    getData();
    getHospitalList().then((result) => {
      setHospitals(result);
    });
    getProductList().then((result) => {
      setProducts(result);
    });
  }, []);

  const getData = () => {
    setLoading(true);
    getBookingList(cate).then((result) => {
      setLoading(false);
      setData(result);
    });
  };

  const getTitle = () => {
    return getTypeName(BOOKING_CATEGORY, cate) + ' List';
  };

  return (
    <OftadehLayout>
      <Grid container className={classes.my3} alignItems="center">
        <Grid item className={classes.mRight} style={{ fontWeight: 'bold' }}>
          <Typography variant="h5" component="h1" style={{ fontWeight: 'bold' }}>
            {getTitle()}
          </Typography>
        </Grid>
        <Grid item>
          <Button onClick={() => history.push(`/pages/booking/edit?cate=${cate}&type=new`)} variant="outlined" color="primary" size="small">
            Regestered.
          </Button>
        </Grid>
      </Grid>
      {/*<OftadehBreadcrumbs path={history} />*/}
      <MUIDataTable title={''} data={data} columns={cols} options={options} />
      {loading && (
        <div style={{ position: 'fixed', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', zIndex: 999 }}>
          <CircularProgress color={'secondary'} />
        </div>
      )}
    </OftadehLayout>
  );
};

export default BookingListPage;
