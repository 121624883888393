import React, { useEffect, useRef, useState } from 'react';
import queryString from 'query-string';
import OftadehLayout from '../../components/OftadehLayout/OftadehLayout';
import { Button, Grid, makeStyles, Typography } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import swal from '@sweetalert/with-react';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import SaveIcon from '@material-ui/icons/Save';
import ListIcon from '@material-ui/icons/List';
import DeleteIcon from '@material-ui/icons/Delete';
import axiosInstance from '../../constants/AxiosInstance';
import { API_URL, getTypeName, NOTICE_CATEGORY } from '../../constants';
import { getNoticeInfo } from '../../constants/PromiseHttp';
import ImgListView from '../customComp/ImgListView';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    color: '#3f51b5',
  },
  my3: {
    margin: '1.3rem 0',
  },
}));

const NoticeDetailPage = (props) => {
  const query = queryString.parse(props.location.search);
  const type = query.type ? query.type : 'new';
  const IS_SYS_ADMIN = sessionStorage.getItem('bt_role') === 'SYS_ADM';

  const [notice, setNotice] = useState({});

  const { history } = props;
  const classes = useStyles();

  useEffect(() => {
    if (type === 'edit' && query.id) {
      getData();
    } else {
      setNotice([]);
    }
  }, []);

  const getData = () => {
    getNoticeInfo(query.id).then((result) => {
      setNotice(result);
    });
  };

  const deleteNotice = () => {
    swal({
      title: 'Are you sure you want to delete?',
      text: '',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        axiosInstance.delete(`${API_URL}/notice/${query.id}`, { headers: { Pragma: 'no-cache' } }).then((response) => {
          if (response.status === 200) {
            swal('It is deleted.', {
              icon: 'success',
            });
            goList();
          }
        });
      } else {
      }
    });
  };

  const goList = () => {
    history.push('/pages/notice/');
  };

  const goEdit = () => {
    history.push(`/pages/notice/edit?type=edit&id=${query.id}`);
  };

  return (
    <OftadehLayout>
      <Grid container className={classes.my3} alignItems="center">
        <Grid item className={classes.mRight} style={{ fontWeight: 'bold' }}>
          <Typography variant="h5" component="h1" style={{ fontWeight: 'bold' }}>
            Notice Detail
          </Typography>
        </Grid>
      </Grid>

      <Card className={classes.root}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12} mb={12}>
              <div className={classes.title}>Category</div>
              <Typography variant="body1">{getTypeName(NOTICE_CATEGORY, notice.category)}</Typography>
            </Grid>
            <Grid item xs={6} mb={6}>
              <Grid item xs={6} mb={6}>
                <div className={classes.title}>Language</div>
                <Typography variant="body1">{notice.languageId}</Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} mb={6}>
              <div className={classes.title}>Title</div>
              <Typography variant="body1">{notice.title}</Typography>
            </Grid>
            <Grid item xs={12} mb={6}>
              <div className={classes.title}>Contents</div>
              <div dangerouslySetInnerHTML={{ __html: notice.contents }} />
            </Grid>

            <Grid item xs={12}>
              <div className={classes.title}>Thumbnail</div>
              <ImgListView images={notice && notice.thumb} />
            </Grid>
            <Grid item xs={12}>
              <div className={classes.title}>Attchments</div>
              <ImgListView images={notice && notice.files} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Grid container justifyContent={'space-between'} xs={12} md={12} spacing={3} style={{ marginTop: '10px' }}>
        <Grid item>
          <Button
            variant="contained"
            style={{ backgroundColor: green[700], color: '#ffffff', marginRight: '10px' }}
            size="medium"
            onClick={goList}
            startIcon={<ListIcon />}
          >
            List
          </Button>
          {IS_SYS_ADMIN && type !== 'new' ? (
            <Button variant="contained" color="secondary" size="medium" onClick={deleteNotice} startIcon={<DeleteIcon />}>
              Delete
            </Button>
          ) : null}
        </Grid>

        {IS_SYS_ADMIN && (
          <Grid item>
            <Button variant="contained" color="primary" size="medium" onClick={goEdit} startIcon={<SaveIcon />}>
              Edit
            </Button>
          </Grid>
        )}
      </Grid>
    </OftadehLayout>
  );
};

export default NoticeDetailPage;
