import ProductEditPage from './ProductEditPage';

export const ProductEditPageConfig = {
  routes: [
    {
      path: '/pages/product/edit',
      exact: true,
      component: ProductEditPage,
    },
  ],
};
