import VideoConsultEditPage from './VideoConsultEditPage';

export const VideoConsultEditPageConfig = {
  routes: [
    {
      path: '/pages/consult/video/edit',
      exact: true,
      component: VideoConsultEditPage,
    },
  ],
};
