import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import OftadehLayout from '../../../components/OftadehLayout/OftadehLayout';
import { Button, Grid, makeStyles, TextField, Typography } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import swal from '@sweetalert/with-react';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import SaveIcon from '@material-ui/icons/Save';
import ListIcon from '@material-ui/icons/List';
import DeleteIcon from '@material-ui/icons/Delete';
import axiosInstance from '../../../constants/AxiosInstance';
import { API_URL, DEF_LANGUAGE, getDefName } from '../../../constants';
import { getFaqCategoryList, getFaqInfo } from '../../../constants/PromiseHttp';
import { Autocomplete } from '@material-ui/lab';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import LanguageAutocomplete from '../../customComp/LanguageAutocomplete';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  my3: {
    margin: '1.3rem 0',
  },
  mRight: {
    marginRight: '.85rem',
  },
  textArea: {
    width: '98%',
    margin: theme.spacing(2),
    padding: theme.spacing(2),
    minHeight: 300,
    borderColor: 'lightgray',
  },
  mInput: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
}));

const SettingFaqDetailPage = (props) => {
  const query = queryString.parse(props.location.search);
  const type = query.type ? query.type : 'new';

  const [data, setData] = useState({});
  const [categoryList, setCategoryList] = useState([]);

  const { history } = props;
  const classes = useStyles();

  const getData = () => {
    getFaqInfo(query.id).then((result) => {
      setData(result);
    });
  };

  useEffect(() => {
    getFaqCategoryList().then((result) => {
      //console.log(result);
      setCategoryList(result);
    });

    if (type === 'edit' && query.id) {
      getData();
    }
  }, []);

  const addFaq = () => {
    if (!data.title) {
      swal('Missing required question.');
      return;
    }
    if (!data.contents) {
      swal('Missing required answer.');
      return;
    }
    if (!data.categoryId) {
      swal('Missing required category.');
      return;
    }

    let param = {
      title: data.title,
      contents: data.contents,
      categoryId: data.categoryId,
      languageId: data.languageId || DEF_LANGUAGE,
    };

    if (type === 'new') {
      param['writerId'] = sessionStorage.getItem('bt_user');
      axiosInstance.post(`${API_URL}/faq`, param, { headers: { Pragma: 'no-cache' } }).then((response) => {
        if (response.status === 200) {
          swal('Registered.', {
            icon: 'success',
          });
          goList();
        }
      });
    } else {
      param['id'] = query.id;

      axiosInstance.put(`${API_URL}/faq`, param, { headers: { Pragma: 'no-cache' } }).then((response) => {
        if (response.status === 200) {
          swal('It is changed.', {
            icon: 'success',
          });
          goList();
        }
      });
    }
  };

  const deleteFaq = () => {
    swal({
      title: 'Are you sure you want to delete?',
      text: '',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        axiosInstance.delete(`${API_URL}/faq/${query.id}`, { headers: { Pragma: 'no-cache' } }).then((response) => {
          if (response.status === 200) {
            swal('It is deleted.', {
              icon: 'success',
            });
            goList();
          }
        });
      } else {
      }
    });
  };

  const goList = () => {
    history.push(`/pages/setting/faq?cate=${data.categoryId}`);
  };

  const handleChangeData = (value, field) => {
    //console.log('handleChangeData :', value, field);
    data[field] = value;
    setData({ ...data });
  };

  const getSelectedCategory = () => {
    const item = categoryList.find((cate) => {
      if (data.categoryId ? cate.id === data.categoryId : false) return cate;
    });
    return item || null;
  };

  return (
    <OftadehLayout>
      <Grid container className={classes.my3} alignItems="center">
        <Grid item className={classes.mRight} style={{ fontWeight: 'bold' }}>
          <Typography variant="h5" component="h1" style={{ fontWeight: 'bold' }}>
            FAQ {type === 'new' ? 'Registration' : 'Edit'}
          </Typography>
        </Grid>
      </Grid>

      <div className={classes.root}>
        <Card className={classes.root}>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={4} mb={6}>
                <Typography component="legend">
                  Language <span style={{ color: 'red' }}>*</span>
                </Typography>
                <LanguageAutocomplete target={data} handleChangeData={handleChangeData} />
              </Grid>
              <Grid item xs={4} mb={6}>
                <Typography style={{}}>
                  Category <span style={{ color: 'red' }}>*</span>
                </Typography>
                <Autocomplete
                  key="autocomplete-faq-category"
                  value={getSelectedCategory()}
                  onChange={(e, newValue) => handleChangeData(newValue.id, 'categoryId')}
                  size={'small'}
                  disableClearable
                  options={categoryList}
                  getOptionLabel={(option) => option[getDefName()] || ''}
                  style={{ width: '100%' }}
                  className={classes.mInput}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" size="small" inputProps={{ ...params.inputProps, readOnly: true }} />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography style={{}}>
                  Question <span style={{ color: 'red' }}>*</span>
                </Typography>
                <TextField
                  label=""
                  className={classes.mInput}
                  placeholder="Please enter a title."
                  margin="normal"
                  style={{ width: '100%' }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={data.title || ''}
                  variant={'outlined'}
                  size="small"
                  onChange={(e) => handleChangeData(e.target.value, 'title')}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography style={{}}>
                  Answer contents <span style={{ color: 'red' }}>*</span>
                </Typography>
                <TextareaAutosize
                  key={'textarea-contents'}
                  id={'textarea-contents'}
                  className={classes.textArea}
                  aria-label="default"
                  placeholder=""
                  value={data.contents || ''}
                  onChange={(e) => handleChangeData(e.target.value, 'contents')}
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions style={{ marginLeft: '16px', marginRight: '16px' }}>
            <Grid container xs={12} md={12} spacing={3}>
              <Grid item>
                <Button
                  variant="contained"
                  style={{ backgroundColor: green[700], color: '#ffffff', marginRight: '10px' }}
                  size="medium"
                  onClick={goList}
                  startIcon={<ListIcon />}
                >
                  List
                </Button>
                {type !== 'new' ? (
                  <Button variant="contained" color="secondary" size="medium" onClick={deleteFaq} startIcon={<DeleteIcon />}>
                    Delete
                  </Button>
                ) : null}
              </Grid>
              <Grid item>
                <Button variant="contained" color="primary" size="medium" onClick={addFaq} startIcon={<SaveIcon />}>
                  {type === 'new' ? 'Registration' : 'Edit'}
                </Button>
              </Grid>
            </Grid>
          </CardActions>
        </Card>
      </div>
    </OftadehLayout>
  );
};

export default SettingFaqDetailPage;
