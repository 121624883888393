import SettingDiseaseListPage from './SettingDiseaseListPage';

export const SettingDiseaseListPageConfig = {
  routes: [
    {
      path: '/pages/setting/disease',
      exact: true,
      component: SettingDiseaseListPage,
    },
  ],
};
