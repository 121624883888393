import { Checkbox, makeStyles, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { getDiseaseList, getSubDiseaseAll } from '../../constants/PromiseHttp';
import { Autocomplete } from '@material-ui/lab';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { getDefName, getSubName } from '../../constants';

const useStyles = makeStyles((theme) => ({
  mInput: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
}));
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const SubDiseaseAutocomplete = (props) => {
  const [subDiseaseList, setSubDiseaseList] = useState([]);
  const [diseaseList, setDiseaseList] = useState([]);
  const classes = useStyles();
  const sortedByParentId = (result) => {
    return (
      result &&
      result.sort(function (a, b) {
        return a['parentId'] > b['parentId'] ? 1 : -1;
      })
    );
  };

  useEffect(() => {
    getSubDiseaseAll().then((result) => {
      const res = sortedByParentId(result);
      setSubDiseaseList(res);
    });
    getDiseaseList().then((result) => {
      setDiseaseList(result);
    });
  }, []);

  useEffect(() => {
    if (props.target) {
      props.target.subDiseaseIds &&
        subDiseaseList.map((di) => {
          if (props.target.subDiseaseIds.indexOf(di.id) > -1) {
            props.selected.push(di);
          }
        });
      props.setSelected([...props.selected]);
    }
  }, [subDiseaseList]);

  const getGroupName = (option) => {
    const di = diseaseList.find((di) => {
      return di.id === option.parentId;
    });
    const groupName = di ? `${di[getDefName()]}(${di[getSubName()]})` : option.parentId; //di[getDefName()] : option.parentId;
    //console.log(groupName);
    return groupName;
  };

  return (
    <Autocomplete
      multiple
      size={'small'}
      id="disease-tags"
      options={subDiseaseList}
      disableCloseOnSelect
      groupBy={(option) => getGroupName(option)}
      getOptionLabel={(option) => `${option[getDefName()]}(${option[getSubName()]})` || ''}
      value={props.selected}
      onChange={(event, newValue) => {
        props.setSelected(newValue);
      }}
      renderOption={(option, { selected }) => (
        <React.Fragment>
          <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
          {`${option[getDefName()]}(${option[getSubName()]})`}
          {/*{renderTree(option)}*/}
        </React.Fragment>
      )}
      style={{ width: '100%' }}
      className={classes.mInput}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label=""
          placeholder="Disease selection"
          inputProps={{ ...params.inputProps, readOnly: false }}
        />
      )}
    />
  );
};

export default SubDiseaseAutocomplete;
