import { Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import ModalImage from 'react-modal-image';

const ImgListView = (props) => {
  //console.log(props.images);

  return (
    <Grid container spacing={3} style={{ margin: '1.3rem 0' }}>
      {props.images &&
        props.images.map((image, idx) => (
          <Grid container alignItems={'center'} xs={12} md={12} spacing={3} key={`img_${idx}_${image?.name}`}>
            {image.type.includes('image') ? (
              <>
                <Grid item md={4} key={'file-image-' + idx}>
                  <ModalImage small={image.url} large={image.url} alt={image.name} />
                </Grid>
                <Grid item>{image.name}</Grid>
              </>
            ) : (
              <>
                <Grid item md={4} key={'file-image-' + idx}>
                  <a download={image.name} href={image.url}>
                    <img
                      src={image.url}
                      width={'100%'}
                      alt={'file-' + idx}
                      onError={(e) => {
                        e.target.src = 'https://via.placeholder.com/100.png?text=noimage';
                      }}
                    />
                  </a>
                </Grid>
                <Grid item>{image.name}</Grid>
              </>
            )}
          </Grid>
        ))}
    </Grid>
  );
};

export default ImgListView;
