import NewsEditPage from './NewsEditPage';

export const NewsEditPageConfig = {
  routes: [
    {
      path: '/pages/news/edit',
      exact: true,
      component: NewsEditPage,
    },
  ],
};
