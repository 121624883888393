import React, { useEffect, useState } from 'react';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { Grid, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  textArea: {
    width: '98%',
    minHeight: 300,
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    borderColor: 'lightgray',
  },
}));

const TabDescView = (props) => {
  const classes = useStyles();
  const [desc, setDesc] = useState({});

  useEffect(() => {
    if (props.hospital) {
      setDesc(props.hospital.desc || {});
    }
  }, [props.hospital]);

  const handleChangeDescData = (value, field) => {
    //console.log('handleChangeDescData :', value);
    desc[field] = value;
    setDesc({ ...desc });
    // props.setHospital({ ...props.hospital });
    props.handleChangeData(desc, 'desc');
  };

  return (
    <Grid container spacing={3} style={{ marginTop: '30px' }}>
      <Grid item xs={12} mb={12}>
        <Typography>Introduce</Typography>
        <TextareaAutosize
          key={'textarea-desc-default'}
          id={'textarea-desc-default'}
          className={classes.textArea}
          aria-label="default"
          placeholder=""
          value={(desc && desc.default) || ''}
          onChange={(e) => handleChangeDescData(e.target.value, 'default')}
          // onBlur={(value) => handleChangeDescData(value, 'default')}
        />
      </Grid>
      <Grid item xs={12} mb={12}>
        <Typography>Specialized treatment</Typography>
        <TextareaAutosize
          key={'textarea-desc-treatment'}
          id={'textarea-desc-treatment'}
          className={classes.textArea}
          aria-label="treatment"
          placeholder=""
          value={(props.hospital && props.hospital.desc && props.hospital.desc.treatment) || ''}
          onChange={(e) => handleChangeDescData(e.target.value, 'treatment')}
          // onBlur={(value) => handleChangeDescData(value, 'treatment')}
        />
      </Grid>
      <Grid item xs={12} mb={12}>
        <Typography>Other services</Typography>
        <TextareaAutosize
          key={'textarea-desc-service'}
          id={'textarea-desc-service'}
          className={classes.textArea}
          aria-label="service"
          placeholder=""
          value={(desc && desc.service) || ''}
          onChange={(e) => handleChangeDescData(e.target.value, 'service')}
          // onBlur={(value) => handleChangeDescData(value, 'service')}
        />
      </Grid>
      <Grid item xs={12} mb={12}>
        <Typography>Certificate of possession</Typography>
        <TextareaAutosize
          key={'textarea-desc-certificate'}
          id={'textarea-desc-certificate'}
          className={classes.textArea}
          aria-label="certificate"
          placeholder=""
          value={(desc && desc.certificate) || ''}
          onChange={(e) => handleChangeDescData(e.target.value, 'certificate')}
          // onBlur={(value) => handleChangeDescData(value, 'certificate')}
        />
      </Grid>
    </Grid>
  );
};

export default TabDescView;
