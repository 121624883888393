import NoticeEditPage from './NoticeEditPage';

export const NoticeEditPageConfig = {
  routes: [
    {
      path: '/pages/notice/edit',
      exact: true,
      component: NoticeEditPage,
    },
  ],
};
