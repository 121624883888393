import React, { useEffect, useState } from 'react';
import OftadehLayout from '../../../components/OftadehLayout/OftadehLayout';
import { Button, CircularProgress, Grid, makeStyles, Typography } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import { getHospitalList, getVideoConsultList } from '../../../constants/PromiseHttp';
import withStyles from '@material-ui/styles/withStyles';
import axiosInstance from '../../../constants/AxiosInstance';
import swal from '@sweetalert/with-react';
import { API_URL, CONSULT_STATUS, getDefName, getTypeName } from '../../../constants';
import { Delete, FiberNew } from '@material-ui/icons';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  my3: {
    margin: '1.3rem 0',
  },
  mRight: {
    marginRight: '.85rem',
  },
}));

const VideoConsultListPage = (props) => {
  const { history } = props;
  const classes = useStyles();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [hospitals, setHospitals] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    setLoading(true);
    getVideoConsultList().then((result) => {
      setLoading(false);
      setData(result);
    });

    getHospitalList().then((result) => {
      setHospitals(result);
    });
  };
  const cols = [
    {
      name: 'id',
      label: 'id',
      options: {
        filter: false,
        sort: true,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: 'id',
      label: 'No.',
      options: {
        filter: false,
        sort: true,
        display: true,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return tableMeta.rowIndex + 1;
        },
      },
    },
    {
      name: 'userName',
      label: 'Inquirer',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Grid container spacing={3}>
              <Grid item> {value} </Grid>
              <Grid item>
                {(!data[tableMeta.rowIndex].adminViewCnt || data[tableMeta.rowIndex].adminViewCnt < 1) && <FiberNew color={'secondary'} />}
              </Grid>
            </Grid>
          );
        },
      },
    },
    {
      name: 'languageId',
      label: 'Language',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'countryId',
      label: 'Country',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'hospitalId',
      label: 'Medical institution',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const _hos = hospitals.find((hos) => hos.id === value);
          return _hos ? _hos['name_ko'] : '';
        },
      },
    },
    {
      name: 'createdAt',
      label: 'Registration Date',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return moment(Number(value)).format('YYYY-MM-DD HH:mm');
        },
      },
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value && getTypeName(CONSULT_STATUS, value);
        },
      },
    },
  ];

  const rowClick = (data) => {
    // console.log(data);
    if (data[0].adminViewCnt || data[0].adminViewCnt < 1) {
      localStorage.setItem('new_videoConsult', parseInt(localStorage.getItem('new_videoConsult')) - 1);
    }
    history.push(`/pages/consult/video/detail?id=${data[0]}`);
  };

  const rowDelete = (row) => {
    let ids = [];
    row.data.map((r) => {
      ids.push(data[r.dataIndex].id);
      return r;
    });
    let delParam = {
      ids: ids.toString(),
    };
    swal({
      title: 'Are you sure you want to delete?',
      text: '',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        axiosInstance.post(`${API_URL}/consult/deleteIds`, delParam, { headers: { Pragma: 'no-cache' } }).then((response) => {
          swal('It is deleted.', {
            icon: 'success',
          });
          getData();
        });
      } else {
        getData();
      }
    });
  };

  const handleAddToCase = (rows) => {
    console.log('handleAddToCase :', rows);
    let ids = [];
    rows.data.map((r) => {
      ids.push(data[r.dataIndex].id);
      return r;
    });
    console.log(ids);
    let param = {
      ids: ids.toString(),
    };
    axiosInstance.post(`${API_URL}/consult/addToCase`, param, { headers: { Pragma: 'no-cache' } }).then((response) => {
      swal('Registered.', {
        icon: 'success',
      });
      getData();
    });
  };

  const options = {
    print: false,
    filter: false,
    filterType: 'checkbox',
    selectableRows: 'multiple',
    viewColumns: false,
    download: false,
    onRowClick: rowClick,
    onRowsDelete: rowDelete,
    // customToolbarSelect: (selectedRows) => {
    //   console.log(selectedRows);
    //   return (
    //     <Grid>
    //       <Button color={'primary'} variant={'contained'} style={{ marginRight: '10px' }} onClick={() => handleAddToCase(selectedRows)}>
    //         Move to medical case
    //       </Button>
    //       <Button
    //         startIcon={<Delete />}
    //         variant="outlined"
    //         color="primary"
    //         aria-label="delete"
    //         style={{ marginRight: '10px' }}
    //         onClick={() => rowDelete(selectedRows)}
    //       >
    //         Delete
    //       </Button>
    //     </Grid>
    //   );
    // },
  };

  const GreenButton = withStyles((theme) => ({
    root: {
      color: theme.palette.success.main,
      border: '1px solid ',
      borderColor: theme.palette.success.main,
    },
  }))(Button);

  return (
    <OftadehLayout>
      <Grid container className={classes.my3} alignItems="center" spacing={1}>
        <Grid item className={classes.mRight} style={{ fontWeight: 'bold' }}>
          <Typography variant="h5" component="h1" style={{ fontWeight: 'bold' }}>
            Video Consulting List
          </Typography>
        </Grid>

        <Grid item>
          <Button onClick={() => history.push('/pages/consult/video/edit?type=new')} variant="outlined" color="primary" size="small">
            Registration
          </Button>
        </Grid>
      </Grid>
      {/*<OftadehBreadcrumbs path={history} />*/}
      <MUIDataTable title={''} data={data} columns={cols} options={options} />
      {loading && (
        <div style={{ position: 'fixed', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', zIndex: 999 }}>
          <CircularProgress color={'secondary'} />
        </div>
      )}
    </OftadehLayout>
  );
};

export default VideoConsultListPage;
