import React, { useEffect, useRef, useState } from 'react';
import { Button, CircularProgress, Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import HospitalAutocomplete from './HospitalAutocomplete';
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';
import swal from '@sweetalert/with-react';
import { API_URL } from '../../constants';
import { getAnswer, getHospitalList } from '../../constants/PromiseHttp';
import FileUpload from '../../components/FileUpload/FileUpload';
import axiosInstance from '../../constants/AxiosInstance';
import ModalImage from 'react-modal-image';
import { green } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '.85rem',
    borderRadius: 5,
    background: '#fff',
  },
  mBottom: {
    marginBottom: '.5rem',
  },
  button: {
    marginTop: '.85rem',
  },
  textArea: {
    width: '96%',
    minHeight: 300,
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    borderColor: 'lightgray',
  },
  mInput: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
  sub: {
    margin: theme.spacing(1),
    marginLeft: theme.spacing(5),
    padding: theme.spacing(1),
  },
}));

const AnswerComponent = (props) => {
  const classes = useStyles();

  const { targetId, targetInfo, handleClose } = props;

  const [answer, setAnswer] = useState({});
  const [parentId, setParentId] = useState('');
  const [userId, setUserId] = useState('');
  const [userName, setUserName] = useState('');
  const depth = 0;

  const [loading, setLoading] = useState(false);

  const [uploadFile, setUploadFile] = useState([]);
  const childRef = useRef(null);
  const imgKey = 'files';

  useEffect(() => {
    setParentId(props.parentId);

    if (props.answerId && props.answerId.length > 0) {
      getAnswer(props.answerId).then((result) => {
        //console.log(result);
        setAnswer(result);
        if (childRef.current) {
          childRef.current.getSignedUrl(result[imgKey]);
        }
      });
    }
  }, []);

  const handleHospital = (value, field) => {
    console.log('select Hospital > ', value);
    answer[field] = value;
    setAnswer({ ...answer });
  };

  const addAnswer = (message, parentId, hospitalId, hospitalNameKo, hospitalNameEn, userId, userName) => {
    if (message) {
      const param = {
        questionId: targetId,
        questionInfo: targetInfo || {},
        contents: message,
        hospitalId: hospitalId || '',
        hospitalName_ko: hospitalNameKo || '',
        hospitalName_en: hospitalNameEn || '',
        userId: userId || '',
        userName: userName || '',
        parentId: parentId || '',
      };

      setLoading(true);
      return axiosInstance.post(`${API_URL}/answer/`, param).then(async (response) => {
        if (response.status === 200) {
          if (uploadFile.length > 0) {
            await Promise.all([childRef.current.fileUpload(uploadFile, response.data.value.id, 'answer', `${API_URL}/answer/`)]).then(
              () => {
                setUploadFile([]);
                setLoading(false);
                swal('Answer has been registered.', {
                  icon: 'success',
                });
                handleClose(true);
              },
            );
          } else {
            setLoading(false);
            setUploadFile([]);
            swal('Answer has been registered.', {
              icon: 'success',
            });
            handleClose(true);
          }
        } else {
          setLoading(false);
          swal('Error was occurred.', {
            icon: 'warning',
          });
        }
      });
    } else {
      alert('Please, Insert text');
    }
  };

  const editAnswer = (aid, message, parentId, hospitalId, hospitalNameKo, hospitalNameEn, userId, userName) => {
    if (message) {
      const param = {
        id: aid,
        questionId: targetId,
        contents: message,
        hospitalId: hospitalId || '',
        hospitalName_ko: hospitalNameKo || '',
        hospitalName_en: hospitalNameEn || '',
        userId: userId || '',
        userName: userName || '',
        parentId: parentId || '',
      };

      return axiosInstance.put(`${API_URL}/answer/`, param).then(async (response) => {
        if (response.status === 200) {
          if (uploadFile.length > 0) {
            await Promise.all([childRef.current.fileUpload(uploadFile, param.id, 'answer', `${API_URL}/answer/`)]).then(() => {
              setUploadFile([]);
              swal('It is changed.', {
                icon: 'success',
              });
              handleClose(true);
            });
          } else {
            setUploadFile([]);
            swal('It is changed.', {
              icon: 'success',
            });
            handleClose(true);
          }
        } else {
          swal('Error was occurred.', {
            icon: 'warning',
          });
        }
      });
    } else {
      alert('Please, Insert text');
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!answer.contents) {
      swal('Missing required contents of answer.');
      return;
    }
    if (!answer.hospitalId) {
      swal('Missing required hospital.');
      return;
    }
    if (answer && answer.id) {
      //console.log('editAnswer >', answer.id, answer.parentId);
      editAnswer(
        answer.id,
        answer.contents,
        answer.parentId,
        answer.hospitalId,
        answer.hospitalName_ko,
        answer.hospitalName_en,
        userId,
        userName,
      );
    } else {
      //console.log('addAnswer >', answer.id, answer.parentId);
      addAnswer(answer.contents, parentId, answer.hospitalId, answer.hospitalName_ko, answer.hospitalName_en, userId, userName);
    }
  };

  const handleFile = (file, key) => {
    console.log(file, key);
    setUploadFile([...file]);
  };
  const handleUpload = (file, key) => {
    console.log('Upload Success:', file, key);
  };
  const handleRemove = (file, key) => {
    console.log('Remove Success');
    setUploadFile([]);
  };

  return (
    <div className={classes.root}>
      <Typography variant="h6" component="h1" style={{ fontWeight: 'bold' }} className={classes.mInput}>
        {answer && answer.id ? 'Edit answer' : 'Register answer'}
      </Typography>
      <HospitalAutocomplete target={answer} withName={true} handleChangeData={handleHospital} />
      <TextareaAutosize
        key={'textarea-contents'}
        id={'textarea-contents'}
        className={classes.textArea}
        aria-label="default"
        placeholder="Please enter your answer."
        value={answer.contents}
        onChange={(e) => setAnswer({ ...answer, contents: e.target.value })}
      />
      <Grid item xs={12} mb={12}>
        <Typography component="legend">Attachments</Typography>
        <FileUpload
          type={'file'}
          ref={childRef}
          accept={'application/pdf, image/*'}
          buttonText={'File'}
          onFileHandler={handleFile}
          onRemoveAfter={handleRemove}
          onUploadAfter={handleUpload}
          fileId={props.answerId ? props.answerId : targetId}
          folder={'answer'}
          apiUrl={`${API_URL}/answer/`}
          uploadKey={imgKey}
          maxCount={3}
        />
      </Grid>

      <div className={classes.mBottom}>
        <Grid container xs={12} md={12} spacing={2}>
          <Grid item>
            <Button variant="contained" color="secondary" className={classes.button} onClick={() => handleClose(false)}>
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" className={classes.button} onClick={handleSubmit}>
              Registration
            </Button>
          </Grid>
        </Grid>
      </div>
      {loading && (
        <div style={{ position: 'fixed', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', zIndex: 999 }}>
          <CircularProgress color={'secondary'} />
        </div>
      )}
    </div>
  );
};
export default AnswerComponent;

export const AnswerCard = (prop) => {
  const handleChild = (pid) => {
    console.log('AnswerCard::handleChild :', pid);
    prop.handleChild(pid);
  };

  const handleEdit = (aid) => {
    prop.handleEdit(aid);
  };
  const handleDelete = (aid) => {
    swal({
      title: 'Are you sure you want to delete?',
      text: '',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      willDelete && prop.handleDelete(aid);
    });
  };

  const iterator = (answer, idx, depth) => {
    return (
      <Grid item xs={12} mb={12}>
        <AnswerBox
          key={`answerbox-${answer.id}`}
          idx={idx}
          depth={depth}
          answer={answer}
          handleChild={handleChild}
          handleDelete={handleDelete}
          handleEdit={handleEdit}
        />
        {answer.child && answer.child.map((_child, i) => iterator(_child, i, depth + 1))}
      </Grid>
    );
  };
  return (
    <Grid container spacing={3}>
      {iterator(prop.answer, prop.idx, prop.depth)}
    </Grid>
  );
};

export const AnswerBox = (prop) => {
  const classes = useStyles();
  const [hospitals, setHospitals] = useState();

  useEffect(() => {
    getHospitalList().then((result) => {
      setHospitals(result);
    });
  }, []);

  const getCreatedAt = (value) => {
    const dt = new Date(Number(value));
    return dt.getFullYear() + '-' + (dt.getMonth() + 1) + '-' + dt.getDate() + ' ' + dt.getHours() + ':' + dt.getMinutes();
  };

  const getUserName = (uid, uName) => {
    if (uName && uName.length > 0) {
      return `${prop.answer.userName} (${prop.answer.userId})`;
    } else {
      return `${prop.answer.userId}`;
    }
  };

  const getHospitalName = (hid) => {
    if (!hid || !hospitals) {
      return '';
    }
    let hos = hospitals.find((_hos) => _hos.id === hid);
    return hos ? hos['name_ko'] : '';
  };

  const newLineText = (text) => {
    //console.log(text);
    if (!text || !text.includes('\n')) return text;
    return text.split('\n').map((line) => {
      return (
        <span>
          {line}
          <br />
        </span>
      );
    });
  };

  return (
    //<Paper className={prop.answer.parentId ? classes.sub : classes.mInput} key={`answer-paper-${prop.answer.id}-${prop.idx}`}>
    <Paper className={classes.mInput} style={{ paddingLeft: prop.depth + '10px' }} key={`answer-paper-${prop.answer.id}-${prop.idx}`}>
      <Grid container wrap="nowrap" spacing={2}>
        {prop.answer.parentId && (
          <Grid item>
            <SubdirectoryArrowRightIcon color="primary" />
          </Grid>
        )}
        <Grid item xs zeroMinWidth>
          <h4 style={{ margin: 0, textAlign: 'left' }}>
            {getHospitalName(prop.answer.hospitalId) || getUserName(prop.answer.userId, prop.answer.userName)}
          </h4>
          <p style={{ textAlign: 'left' }}> {newLineText(prop.answer.contents)} </p>
          {/* {prop.answer.files &&
            prop.answer.files[0] &&
            (prop.answer.files[0].type.includes('image') ? (
              <Grid container alignItems={'center'} xs={12} md={12} spacing={3}>
                <Grid item md={2}>
                  <ModalImage small={prop.answer.files[0].url} large={prop.answer.files[0].url} alt={prop.answer.files[0].name} />
                </Grid>
              </Grid>
            ) : (
              <Grid container alignItems={'center'} xs={12} md={12} spacing={3}>
                <Grid item md={2}>
                  <a download={prop.answer.files[0].name} href={prop.answer.files[0].url}>
                    <img
                      src={prop.answer.files[0].url}
                      width={'80px'}
                      alt={prop.answer.files[0].name}
                      onError={(e) => {
                        e.target.src = 'https://via.placeholder.com/100.png?text=noimage';
                      }}
                    />
                  </a>
                </Grid>
                <Grid item>{prop.answer.files[0].name}</Grid>
              </Grid>
            ))} */}

          {prop?.answer?.files &&
            prop?.answer?.files.length > 0 &&
            prop?.answer?.files.map((img) => {
              if (img?.type?.includes('image')) {
                return (
                  <Grid container alignItems={'center'} xs={12} md={12} spacing={3}>
                    <Grid item md={2}>
                      <ModalImage small={img.url} large={img.url} alt={img.name} />
                    </Grid>
                  </Grid>
                );
              } else {
                return (
                  <Grid container alignItems={'center'} xs={12} md={12} spacing={3}>
                    <Grid item md={2}>
                      <a download={img.name} href={img.url}>
                        <img
                          src={img.url}
                          width={'80px'}
                          alt={img.name}
                          onError={(e) => {
                            e.target.src = 'https://via.placeholder.com/100.png?text=noimage';
                          }}
                        />
                      </a>
                    </Grid>
                    <Grid item>{img.name}</Grid>
                  </Grid>
                );
              }
            })}

          {/*<img src={prop.answer.files[0].url} width={30} alt={prop.answer.files[0].name || ''} />*/}
          {/*)}*/}
          <p style={{ textAlign: 'left', color: 'gray' }}>{getCreatedAt(prop.answer.createdAt)}</p>
          <Button
            variant="contained"
            color="primary"
            size="medium"
            className={classes.button}
            style={{ marginRight: 10 }}
            onClick={() => prop.handleChild(prop.answer.id)}
          >
            Answer
          </Button>
          {prop.answer.hospitalId && (
            <Button
              variant="contained"
              color="primary"
              size="medium"
              className={classes.button}
              style={{ backgroundColor: green[700], color: '#ffffff', marginRight: '10px' }}
              onClick={() => prop.handleEdit(prop.answer.id)}
            >
              Edit
            </Button>
          )}
          <Button
            variant="contained"
            color="secondary"
            size="medium"
            className={classes.button}
            onClick={() => prop.handleDelete(prop.answer.id)}
          >
            Delete
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};
