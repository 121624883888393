import { makeStyles, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { getHospitalTypeList } from '../../constants/PromiseHttp';
import { Autocomplete } from '@material-ui/lab';
import { getDefName } from '../../constants';

const useStyles = makeStyles((theme) => ({
  mInput: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
}));
const HospitalTypeAutocomplete = (props) => {
  const classes = useStyles();

  const [hosTypes, setHosTypes] = useState([]);

  useEffect(() => {
    getHospitalTypeList().then((result) => {
      setHosTypes(result);
    });
  }, []);

  const getSelectedHospitalType = () => {
    const item = hosTypes.find((type) => {
      if (props.target && type.id === props.target.typeId) return type;
    });

    //console.log('getSelectedHospitalType', item);
    return item || null;
  };

  return (
    <Autocomplete
      key="cbox-hos-type"
      value={getSelectedHospitalType()}
      onChange={(event, newValue) => {
        props.handleChangeData(newValue.id, 'typeId');
      }}
      size={'small'}
      disableClearable
      options={hosTypes}
      getOptionLabel={(option) => option[getDefName()] || ''}
      getOptionSelected={(option, value) => option.id === value.id}
      style={{ width: '100%' }}
      className={classes.mInput}
      renderInput={(params) => <TextField {...params} variant="outlined" inputProps={{ ...params.inputProps, readOnly: true }} />}
    />
  );
};

export default HospitalTypeAutocomplete;
