import ConsultCaseEditPage from './ConsultCaseEditPage';

export const ConsultCaseEditPageConfig = {
  routes: [
    {
      path: '/pages/consult/case/edit',
      exact: true,
      component: ConsultCaseEditPage,
    },
  ],
};
