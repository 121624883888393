import QuestionListPage from './QuestionListPage';

export const QuestionListPageConfig = {
  routes: [
    {
      path: '/pages/question',
      exact: true,
      component: QuestionListPage,
    },
  ],
};
