import ConsultDetailPage from './ConsultDetailPage';

export const ConsultDetailPageConfig = {
  routes: [
    {
      path: '/pages/consult/detail',
      exact: true,
      component: ConsultDetailPage,
    },
  ],
};
