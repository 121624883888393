import React, { useEffect, useState } from 'react';
import OftadehLayout from '../../components/OftadehLayout/OftadehLayout';
import { Button, CircularProgress, Grid, IconButton, makeStyles, MuiThemeProvider, TableCell, Typography } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';
import MUIDataTable from 'mui-datatables';
import { getConsultNestedList } from '../../constants/PromiseHttp';
import axiosInstance from '../../constants/AxiosInstance';
import swal from '@sweetalert/with-react';
import { API_URL, CONSULT_STATUS, getTypeName, MEMBER_STATUS } from '../../constants';
import { Delete, FiberNew, MoveToInbox } from '@material-ui/icons';
import TableRow from '@material-ui/core/TableRow';
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  my3: {
    margin: '1.3rem 0',
  },
  mRight: {
    marginRight: '.85rem',
  },
}));

const ConsultListPage = (props) => {
  const { history } = props;
  const classes = useStyles();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    setLoading(true);
    getConsultNestedList().then((result) => {
      setLoading(false);
      setData(result);
    });
  };

  const main_theme = createTheme({
    overrides: {
      MUIDataTableSelectCell: {
        expandDisabled: {
          // Soft hide the button.
          visibility: 'hidden',
        },
      },
    },
  });

  const nested_tables_theme = createTheme({
    overrides: {
      MUIDataTableSelectCell: {
        expandDisabled: {
          visibility: 'hidden',
        },
      },
      MUIDataTableToolbar: {
        root: {
          display: 'none',
        },
      },
      MUIDataTableSearch: {
        clearIcon: {
          display: 'none',
        },
      },
      MUIDataTableHeadRow: {
        root: {
          display: 'none',
        },
      },
    },
  });

  const cols = [
    {
      name: 'id',
      label: 'id',
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: 'parentId',
      label: 'parentId',
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: 'id',
      label: 'No.',
      options: {
        filter: false,
        sort: true,
        display: true,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return tableMeta.rowIndex + 1;
        },
      },
    },
    {
      name: 'title',
      label: 'Title',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Grid container spacing={3}>
              <Grid item> {value} </Grid>
              <Grid item>
                {(!data[tableMeta.rowIndex].adminViewCnt || data[tableMeta.rowIndex].adminViewCnt < 1) && <FiberNew color={'secondary'} />}
              </Grid>
            </Grid>
          );
        },
      },
    },
    {
      name: 'userName',
      label: 'Inquirer',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'languageId',
      label: 'Language',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'countryId',
      label: 'Country',
      options: {
        filter: false,
        sort: true,
      },
    },

    {
      name: 'createdAt',
      label: 'Registration Date',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return moment(Number(value)).format('YYYY-MM-DD HH:mm');
        },
      },
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value && getTypeName(CONSULT_STATUS, value);
        },
      },
    },
    {
      name: 'child',
      options: {
        display: false,
        viewColumns: false,
        filter: false,
      },
    },
  ];

  const childCols = [
    {
      name: 'id',
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: 'parentId',
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: 'id',
      options: {
        filter: false,
        sort: true,
        display: true,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <SubdirectoryArrowRightIcon color="primary" />;
        },
      },
    },
    {
      name: 'title',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'userName',
      options: {
        filter: false,
        display: true,
        sort: true,
      },
    },
    {
      name: 'languageId',
      label: 'Language',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'countryId',
      options: {
        filter: false,
        display: true,
        sort: true,
      },
    },
    {
      name: 'createdAt',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const dt = new Date(Number(value));
          return dt.getFullYear() + '-' + (dt.getMonth() + 1) + '-' + dt.getDate() + ' ' + dt.getHours() + ':' + dt.getMinutes();
        },
      },
    },
    {
      name: 'status',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value && getTypeName(CONSULT_STATUS, value);
        },
      },
    },
    {
      name: 'id',
      options: {
        display: true,
        viewColumns: false,
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <IconButton
                color="primary"
                variant="contained"
                onClick={(event) => {
                  handleAddToCase([value]);
                }}
              >
                <MoveToInbox />
              </IconButton>
              <IconButton
                variant="outlined"
                color="primary"
                onClick={(event) => {
                  rowDelete([value]);
                }}
              >
                <Delete />
              </IconButton>
            </>
          );
        },
      },
    },
  ];

  const expRow = (rowData, rowMeta) => {
    const colSpan = rowData.length + 1;
    rowData = rowData.slice(-1)[0]; //try to always keep the nested data item at the end of the array
    return (
      <TableRow>
        <TableCell style={{ paddingLeft: '26px' }} colSpan={colSpan}>
          <MuiThemeProvider theme={nested_tables_theme}>
            <MUIDataTable class="noPadding" data={rowData} columns={childCols} options={nestedTableOptions(rowData)} />
          </MuiThemeProvider>
        </TableCell>
      </TableRow>
    );
  };

  const rowClick = (data) => {
    // console.log(data);
    if (data[0].adminViewCnt || data[0].adminViewCnt < 1) {
      localStorage.setItem('new_consult', parseInt(localStorage.getItem('new_consult')) - 1);
    }
    history.push(`/pages/consult/detail?id=${data[0]}`);
  };

  const rowDelete = (ids) => {
    console.log('rowDelete', ids);
    let delParam = {
      ids: ids.toString(),
    };
    swal({
      title: 'Are you sure you want to delete?',
      text: '',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        axiosInstance.post(`${API_URL}/consult/deleteIds`, delParam, { headers: { Pragma: 'no-cache' } }).then((response) => {
          swal('It is deleted.', {
            icon: 'success',
          });

          getData();
        });
      } else {
        getData();
      }
    });
  };

  const handleAddToCase = (ids) => {
    console.log(ids);
    let param = {
      ids: ids.toString(),
    };
    setLoading(true);
    axiosInstance.post(`${API_URL}/consult/addToCase`, param, { headers: { Pragma: 'no-cache' } }).then((response) => {
      swal('Registered.', {
        icon: 'success',
      });
      setLoading(false);
      getData();
    });
  };

  const options = {
    enableNestedDataAccess: '.',
    print: false,
    filter: false,
    expandableRows: true,
    responsive: 'standard',
    expandableRowsHeader: false,
    expandableRowsOnClick: true,
    isRowDefaultExpanded: true,
    renderExpandableRow: expRow,
    isRowExpandable: (dataIndex, expandedRows) => {
      if (!data[dataIndex].child || data[dataIndex].child.length === 0) return false;
      return true;
    },
    filterType: 'checkbox',
    selectableRows: 'multiple',
    viewColumns: false,
    download: false,
    onRowClick: rowClick,
    onRowsDelete: (selectedRows) => {
      let ids = [];
      selectedRows.data.map((r) => {
        data[r.dataIndex] && ids.push(data[r.dataIndex].id);
        return r;
      });
      rowDelete(ids);
    },
    customToolbarSelect: (selectedRows) => {
      console.log(selectedRows);
      return (
        <Grid>
          <Button
            startIcon={<MoveToInbox />}
            color={'primary'}
            variant={'contained'}
            style={{ marginRight: '10px' }}
            onClick={() => {
              let ids = [];
              selectedRows.data.map((r) => {
                ids.push(data[r.dataIndex].id);
                return r;
              });
              handleAddToCase(ids);
            }}
          >
            Move to medical case
          </Button>
          <Button
            startIcon={<Delete />}
            variant="outlined"
            color="primary"
            aria-label="delete"
            style={{ marginRight: '10px' }}
            onClick={() => {
              let ids = [];
              selectedRows.data.map((r) => {
                data[r.dataIndex] && ids.push(data[r.dataIndex].id);
                return r;
              });
              rowDelete(ids);
            }}
          >
            Delete
          </Button>
        </Grid>
      );
    },
  };

  const nestedTableOptions = (rowData, expandMethod) => {
    const has_expandable_rows = expandMethod !== undefined;
    return {
      pagination: false,
      responsive: 'standard',
      toolbar: false,
      //filterType: 'checkbox',
      selectableRows: 'none', //'multiple'
      elevation: 0,
      expandableRows: has_expandable_rows,
      expandableRowsOnClick: has_expandable_rows,
      renderExpandableRow: expandMethod,
      searchOpen: true,
      search: false,
      download: false,
      print: false,
      onRowClick: rowClick,
      onRowsDelete: (selectedRows) => {
        let ids = [];
        selectedRows.data.map((r) => {
          ids.push(rowData[r.dataIndex].id);
          return r;
        });
        rowDelete(ids);
      },
      customToolbarSelect: (selectedRows) => {
        console.log(selectedRows);
        return (
          <Grid>
            <Button
              color={'primary'}
              variant={'contained'}
              style={{ marginRight: '10px' }}
              onClick={() => {
                let ids = [];
                selectedRows.data.map((r) => {
                  ids.push(rowData[r.dataIndex].id);
                  return r;
                });
                handleAddToCase(ids);
              }}
            >
              Move to medical case
            </Button>
            <Button
              startIcon={<Delete />}
              variant="outlined"
              color="primary"
              aria-label="delete"
              style={{ marginRight: '10px' }}
              onClick={() => {
                let ids = [];
                selectedRows.data.map((r) => {
                  ids.push(data[r.dataIndex].id);
                  return r;
                });
                rowDelete(ids);
              }}
            >
              Delete
            </Button>
          </Grid>
        );
      },
    };
  };

  return (
    <OftadehLayout>
      <Grid container className={classes.my3} alignItems="center" spacing={1}>
        <Grid item className={classes.mRight} style={{ fontWeight: 'bold' }}>
          <Typography variant="h5" component="h1" style={{ fontWeight: 'bold' }}>
            Consulting List
          </Typography>
        </Grid>

        <Grid item>
          <Button onClick={() => history.push('/pages/consult/edit?type=new')} variant="outlined" color="primary" size="small">
            Registration
          </Button>
        </Grid>
      </Grid>
      {/*<OftadehBreadcrumbs path={history} />*/}
      {/*<MUIDataTable title={''} data={data} columns={cols} options={options} />*/}
      <MuiThemeProvider theme={main_theme}>
        <MUIDataTable title={''} data={data} columns={cols} options={options} />
      </MuiThemeProvider>
      {loading && (
        <div style={{ position: 'fixed', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', zIndex: 999 }}>
          <CircularProgress color={'secondary'} />
        </div>
      )}
    </OftadehLayout>
  );
};

export default ConsultListPage;
