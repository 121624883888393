import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import OftadehLayout from '../../components/OftadehLayout/OftadehLayout';
import {
  Button,
  Dialog,
  Divider,
  FormControlLabel,
  Grid,
  makeStyles,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import swal from '@sweetalert/with-react';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import SaveIcon from '@material-ui/icons/Save';
import ListIcon from '@material-ui/icons/List';
import DeleteIcon from '@material-ui/icons/Delete';
import { getBookingInfo, getConsultListForUser } from '../../constants/PromiseHttp';
import axiosInstance from '../../constants/AxiosInstance';
import {
  API_URL,
  BOOKING_CATEGORY,
  BOOKING_STATUS,
  DEF_BOOKING_STATUS,
  DEF_LANGUAGE,
  EMAIL_REG,
  getDefName,
  phoneNumberValidation,
} from '../../constants';
import LanguageAutocomplete from '../customComp/LanguageAutocomplete';
import FormControl from '@material-ui/core/FormControl';
import CountryAutocomplete from '../customComp/CountryAutocomplete';
import SearchUser from '../customComp/SearchUserPage';
import HospitalAutocomplete from '../customComp/HospitalAutocomplete';
import { Autocomplete } from '@material-ui/lab';
import moment from 'moment';
import ProductAutocomplete from '../customComp/ProductAutocomplete';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  my3: {
    margin: '1.3rem 0',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  mRight: {
    marginRight: '.85rem',
  },
  chipContainer: {
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
    display: 'flex',
  },
  searchBtn: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
  textArea: {
    width: '98%',
    margin: theme.spacing(2),
    padding: theme.spacing(2),
    minHeight: 300,
    borderColor: 'lightgray',
  },
  mInput: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
}));

const BookingEditPage = (props) => {
  const query = queryString.parse(props.location.search);
  const type = query.type ? query.type : 'new';
  const cate = query.cate ? query.cate : 'hospital';

  const [booking, setBooking] = useState({});
  const [consultList, setConsultList] = useState([]);
  const [userSearchOpen, setUserSearchOpen] = useState(false);

  const { history } = props;
  const classes = useStyles();

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (type === 'new' && !booking.categoryId) {
      booking.categoryId = query.cate;
    }
  }, [booking]);

  const getData = () => {
    if (query.id) {
      getBookingInfo(query.id).then((result) => {
        setBooking(result);
        if (result.userId) {
          getConsultListForUser(result.userId).then((result) => {
            //console.log(result);
            setConsultList(result);
          });
        }
      });
    }
  };

  const addBooking = () => {
    if (cate !== 'pickup' && !booking.userId) {
      swal('Missing required user id.');
      return;
    }
    if (cate === 'pickup' && !booking.userId && !booking.userName) {
      swal('Missing required user id or user name.');
      return;
    }
    // if (!booking.email) {
    //   swal('Missing required email.');
    //   return;
    // }
    // if (!booking.phoneNumber) {
    //   swal('Missing required phone number.');
    //   return;
    // }
    if (booking.categoryId === BOOKING_CATEGORY[0] && !booking.hospitalId) {
      swal('Missing required select hospital');
      return;
    }
    if (booking.categoryId === BOOKING_CATEGORY[1] && !booking.productId) {
      swal('Missing required select product');
      return;
    }
    if (booking.status === 'confirm' && !booking.confirmedAt) {
      swal('Missing required select confirmed date');
      return;
    }
    if (booking.confirmedAt && booking.status === 'request') {
      swal('Please change the reservation status.');
      return;
    }

    let rdate = new Date(booking.reserveDate).getTime();
    let cdate = new Date(booking.confirmedAt).getTime();
    const param = {
      categoryId: booking.categoryId,
      userId: booking.userId,
      userName: booking.userName,
      languageId: booking.languageId || DEF_LANGUAGE,
      phoneNumber: booking.phoneNumber,
      email: booking.email || booking.userId,
      reserveDate: rdate ? rdate.toString() : booking.reserveDate,
      confirmedAt: cdate ? cdate.toString() : booking.confirmedAt,
      contents: booking.contents,
      hospitalId: booking.hospitalId,
      productId: booking.productId,
      consultId: booking.consultId,
      price: booking.price,
      airflight: booking.airflight,
      consultType: booking.consultType || (booking.categoryId === BOOKING_CATEGORY[0].id && booking.hospitalId ? 'free' : ''),
      useTranslator: booking.useTranslator,
      status: booking.status || DEF_BOOKING_STATUS,
    };

    if (type === 'new') {
      axiosInstance.post(`${API_URL}/booking/`, param, { headers: { Pragma: 'no-cache' } }).then(async (response) => {
        if (response.status === 200) {
          swal('Registered.', {
            icon: 'success',
          });
          goList();
        }
      });
    } else {
      param['id'] = query.id;

      axiosInstance.put(`${API_URL}/booking/`, param, { headers: { Pragma: 'no-cache' } }).then(async (response) => {
        if (response.status === 200) {
          swal('It is changed.', {
            icon: 'success',
          });
          goList();
        }
      });
    }
  };

  const deleteBooking = () => {
    swal({
      title: 'Are you sure you want to delete?',
      text: '',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        axiosInstance.delete(`${API_URL}/booking/${query.id}`, { headers: { Pragma: 'no-cache' } }).then((response) => {
          if (response.status === 200) {
            goList();
            swal('It is deleted.', {
              icon: 'success',
            });
          }
        });
      } else {
      }
    });
  };

  const goList = () => {
    history.push(`/pages/booking/${cate}`);
  };

  const handleChangeData = (value, field) => {
    if (field === 'categoryId') {
      //reset data
      booking['price'] = {};
      booking['consultId'] = '';
      booking['hospitalId'] = '';
      booking['pickupMemo'] = '';
      booking['useTranslator'] = false;
    }

    booking[field] = value;
    setBooking({ ...booking });
  };

  const handleChangePriceData = (value, field) => {
    let price = booking.price ? booking.price : {};
    price[field] = value;
    booking['price'] = price;
    setBooking({ ...booking });
  };

  const callbackSearchedUser = (u) => {
    if (booking) {
      booking.userId = u.id;
      booking.userName = u.name;
      booking.languageId = u.languageId || DEF_LANGUAGE;
      if (!booking.email) {
        booking.email = u.email || u.id;
      }
      if (!booking.phoneNumber) {
        booking.phoneNumber = u.phoneNumber || '';
      }
      setBooking({ ...booking });
      getConsultListForUser(u.id).then((result) => {
        //console.log(result);
        setConsultList(result);
      });
    }
    setUserSearchOpen(false);
  };

  const getSelectedConsult = () => {
    const item =
      consultList &&
      consultList.find((con) => {
        if (booking && booking.consultId ? con.id === booking.consultId : false) {
          console.log('find consult', con);
          return con;
        }
      });
    return item || null;
  };

  const handleUserSearchOpen = () => {
    setUserSearchOpen(true);
  };
  const handleUserSearchClose = () => {
    setUserSearchOpen(false);
  };

  const SearchUserBox = () => {
    return (
      <Grid item xs={4} mb={6}>
        <Typography component="legend">Member {cate !== 'pickup' && <span style={{ color: 'red' }}>*</span>}</Typography>
        <div style={{ marginTop: '8px', marginLeft: 20 }}>
          <TextField
            size="small"
            clssName={classes.mInput}
            value={booking.userName}
            variant="outlined"
            margin="dense"
            InputProps={{ readOnly: true }}
          />
          <Button className={classes.searchBtn} variant="contained" color="primary" onClick={handleUserSearchOpen}>
            Member Search
          </Button>
        </div>
        <Dialog open={userSearchOpen} onClose={handleUserSearchClose} aria-labelledby="customized-dialog-title">
          <SearchUser callbackFunc={callbackSearchedUser} handleClose={handleUserSearchClose} />
        </Dialog>
      </Grid>
    );
  };

  const SearchConsultBox = () => {
    return booking.userId ? (
      <Autocomplete
        key="cbox-consult"
        value={getSelectedConsult()}
        onChange={(event, newValue) => {
          handleChangeData(newValue.id, 'consultId');
        }}
        size={'small'}
        disableClearable
        options={consultList || []}
        getOptionLabel={(option) => option['title'] || (option['type'] === 'video' ? '(video consult)' : '')}
        getOptionSelected={(option, value) => option.id === value.id}
        style={{ width: '100%' }}
        className={classes.mInput}
        renderInput={(params) => <TextField {...params} variant="outlined" inputProps={{ ...params.inputProps, readOnly: true }} />}
      />
    ) : (
      <p style={{ color: 'lightgray' }}>Please select patient first.</p>
    );
  };

  const ConsultTypeBox = () => {
    return booking.userId ? (
      <FormControl component="category-form" className={classes.mInput}>
        <RadioGroup
          aria-label="type-radio-group"
          name="type-radio-group"
          onChange={(event) => {
            handleChangeData(event.target.value, 'consultType');
          }}
          row
        >
          <FormControlLabel
            value={'free'}
            checked={booking.consultType === undefined || booking.consultType === 'free'}
            control={<Radio />}
            label={'FREE'}
          />
          <FormControlLabel value={'video'} checked={booking.consultType === 'video'} control={<Radio />} label={'VIDEO'} />
        </RadioGroup>
      </FormControl>
    ) : (
      <p style={{ color: 'lightgray' }}>Please select patient first.</p>
    );
  };

  // const getFormattedDate = (value) => {
  //   if (!value) return '';
  //   const dt = new Date(Number(value));
  //   return dt.getFullYear() + '-' + (dt.getMonth() + 1) + '-' + dt.getDate() + ' ' + dt.getHours() + ':' + dt.getMinutes();
  // };
  const getLocalDate = (date) => {
    if (date && date.includes('T')) {
      return date;
    }
    const dt = date ? new Date(Number(date)) : '';
    const utcT = moment(dt).format('YYYY-MM-DDThh:mm');
    return utcT;
  };
  const getStatusDesc = (status) => {
    if (status === BOOKING_STATUS[2].id) {
      return cate === 'hospital' ? '' : '(Deposit confirmation)';
    }
    if (status === BOOKING_STATUS[4].id) {
      return '(Service completed)';
    }
    return '';
  };

  return (
    <OftadehLayout>
      <Grid container className={classes.my3} alignItems="center">
        <Grid item className={classes.mRight} style={{ fontWeight: 'bold' }}>
          <Typography variant="h5" component="h1" style={{ fontWeight: 'bold' }}>
            Bookinging {type === 'new' ? 'Registration' : 'Edit'}
          </Typography>
          {type === 'new' && query.id && query.id.length > 0 ? <Typography variant="subtitle1">[Original] {query.id}</Typography> : ''}
        </Grid>
      </Grid>
      <div>
        <Card className={classes.root}>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} mb={6}>
                <Typography style={{ marginTop: 10 }} variant={'h6'}>
                  Basic Info
                </Typography>
              </Grid>
              <Grid item xs={6} mb={6}>
                <Typography component="legend">
                  Language <span style={{ color: 'red' }}>*</span>
                </Typography>
                <LanguageAutocomplete target={booking} handleChangeData={handleChangeData} />
              </Grid>
              <Grid item xs={6} mb={6}>
                <Typography style={{}}>
                  Country <span style={{ color: 'red' }}>*</span>
                </Typography>
                <CountryAutocomplete target={booking} handleChangeData={handleChangeData} />
              </Grid>
            </Grid>
            <Divider style={{ marginTop: 10 }} />
            <Grid container spacing={3}>
              <Grid item xs={12} mb={6}>
                <Typography style={{ marginTop: 10 }} variant={'h6'}>
                  User Info
                </Typography>
              </Grid>
              <SearchUserBox />
              <Grid item xs={4}>
                <Typography style={{}}>Name</Typography>
                <TextField
                  size={'small'}
                  key={`TextField-name`}
                  className={classes.mInput}
                  placeholder="Please enter if you are not a member."
                  margin="normal"
                  style={{ width: '100%', marginRight: '10px' }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  value={(booking && booking.userName) || ''}
                  onChange={(event) => {
                    handleChangeData(event.target.value, 'userName');
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography style={{}}>
                  Email <span style={{ color: 'red' }}>*</span>
                </Typography>
                <TextField
                  size={'small'}
                  type={'email'}
                  key={`TextField-email`}
                  className={classes.mInput}
                  placeholder=""
                  margin="normal"
                  style={{ width: '100%', marginRight: '10px' }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  value={(booking && booking.email) || booking.userId || ''}
                  onChange={(event) => {
                    handleChangeData(event.target.value, 'email');
                  }}
                  error={!EMAIL_REG.test(booking.email) && !EMAIL_REG.test(booking.userId)}
                  helperText={EMAIL_REG.test(booking.email) && EMAIL_REG.test(booking.userId) ? '' : 'Please enter your email correctly.'}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography style={{}}>Phone number</Typography>
                <TextField
                  size={'small'}
                  type="text"
                  key={`TextField-phone`}
                  className={classes.mInput}
                  placeholder=""
                  margin="normal"
                  style={{ width: '100%', marginRight: '10px' }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  value={(booking && booking.phoneNumber) || ''}
                  onChange={(event) => {
                    handleChangeData(event.target.value, 'phoneNumber');
                  }}
                  // error={!phoneNumberValidation(booking.phoneNumber)}
                  // helperText={phoneNumberValidation(booking.phoneNumber) ? '' : 'Please enter your phone number correctly.'}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Card className={classes.my3}>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} mb={12}>
                <Typography style={{ marginTop: 10 }} variant="h6">
                  Reservation
                </Typography>
              </Grid>
              <Grid item xs={6} mb={6}>
                <Typography style={{}}>
                  Select Reservation Category <span style={{ color: 'red' }}>*</span>
                </Typography>
                <FormControl component="category-form" className={classes.mInput}>
                  <RadioGroup
                    aria-label="category-radio-group"
                    name="category-radio-group"
                    onChange={(event) => {
                      handleChangeData(event.target.value, 'categoryId');
                    }}
                    row
                  >
                    <FormControlLabel
                      value={BOOKING_CATEGORY[0].id}
                      checked={booking.categoryId === undefined || booking.categoryId === BOOKING_CATEGORY[0].id}
                      control={<Radio />}
                      label={BOOKING_CATEGORY[0][getDefName()] || ''}
                    />
                    <FormControlLabel
                      value={BOOKING_CATEGORY[1].id}
                      checked={booking.categoryId === BOOKING_CATEGORY[1].id}
                      control={<Radio />}
                      label={BOOKING_CATEGORY[1][getDefName()] || ''}
                    />
                    <FormControlLabel
                      value={BOOKING_CATEGORY[2].id}
                      checked={booking.categoryId === BOOKING_CATEGORY[2].id}
                      control={<Radio />}
                      label={BOOKING_CATEGORY[2][getDefName()] || ''}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {booking.categoryId === undefined || booking.categoryId === BOOKING_CATEGORY[0].id ? (
                <Grid item xs={6}>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <Typography>Consult</Typography>
                      <SearchConsultBox />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>Consult Type</Typography>
                      <ConsultTypeBox />
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                ''
              )}
              {booking.categoryId === undefined || booking.categoryId === BOOKING_CATEGORY[0].id ? (
                <Grid container spacing={3} style={{ marginLeft: 5 }}>
                  <Grid item xs={6}>
                    <Typography>Select Hospital</Typography>
                    <HospitalAutocomplete target={booking} handleChangeData={handleChangeData} />
                  </Grid>
                </Grid>
              ) : (
                ''
              )}
              {booking.categoryId === BOOKING_CATEGORY[1].id ? (
                <Grid container spacing={3} style={{ marginLeft: 5 }}>
                  <Grid item xs={6}>
                    <Typography>Select Product</Typography>
                    <ProductAutocomplete target={booking} handleChangeData={handleChangeData} />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>Deposit (KRW)</Typography>
                    <TextField
                      id="deposit"
                      label=""
                      type={'number'}
                      placeholder={'0'}
                      className={classes.mInput}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={booking.price ? booking.price.deposit : 0}
                      size="small"
                      variant={'outlined'}
                      onChange={(e) => handleChangePriceData(e.target.value, 'deposit')}
                    />
                  </Grid>
                </Grid>
              ) : (
                ''
              )}
              {booking.categoryId === BOOKING_CATEGORY[2].id ? (
                <Grid container spacing={3} style={{ marginLeft: 5 }}>
                  <Grid item xs={3}>
                    <Typography style={{}}>Airflight No.(e.g.. KE777) </Typography>
                    <TextField
                      id="rate"
                      label=""
                      type="text"
                      fullWidth
                      className={classes.mInput}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={booking.airflight || ''}
                      size="small"
                      variant={'outlined'}
                      onChange={(e) => handleChangeData(e.target.value, 'airflight')}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={!booking.useTranslator}
                          onChange={(e, newValue) => {
                            handleChangeData(!newValue, 'useTranslator');
                          }}
                        />
                      }
                      label="Translator"
                    />
                  </Grid>
                  <Grid item xs={10}>
                    <Typography style={{}}>User Message</Typography>
                    <TextField
                      id="memo"
                      label=""
                      type="text"
                      fullWidth
                      className={classes.mInput}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={booking.pickupMemo || ''}
                      size="small"
                      variant={'outlined'}
                      onChange={(e) => handleChangeData(e.target.value, 'pickupMemo')}
                    />
                  </Grid>
                </Grid>
              ) : (
                ''
              )}
              <Grid item xs={6}>
                <Typography style={{ marginTop: 10 }}>Reservation date</Typography>
                <form className={classes.container} noValidate>
                  <TextField
                    id="date"
                    label=""
                    type="datetime-local"
                    className={classes.mInput}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={getLocalDate(booking.reserveDate) || 'None'}
                    size="small"
                    variant={'outlined'}
                    onChange={(e) => handleChangeData(e.target.value, 'reserveDate')}
                  />
                </form>
              </Grid>
              <Grid item xs={6}>
                <Typography style={{ marginTop: 10 }}>Confirmed date</Typography>
                <form className={classes.container} noValidate>
                  <TextField
                    id="date"
                    label=""
                    type="datetime-local"
                    className={classes.mInput}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={getLocalDate(booking.confirmedAt) || 'None'}
                    size="small"
                    variant={'outlined'}
                    disabled={booking.status !== BOOKING_STATUS[1].id}
                    onChange={(e) => handleChangeData(e.target.value, 'confirmedAt')}
                  />
                </form>
              </Grid>
              <Grid item xs={12}>
                <Typography>Status</Typography>
                <FormControl component="status-form" className={classes.mInput}>
                  <RadioGroup
                    aria-label="status-radio-group"
                    name="status-radio-group"
                    onChange={(event) => {
                      handleChangeData(event.target.value, 'status');
                    }}
                    row
                  >
                    <FormControlLabel
                      value={BOOKING_STATUS[0].id}
                      checked={booking.status === undefined || booking.status === BOOKING_STATUS[0].id}
                      control={<Radio />}
                      label={BOOKING_STATUS[0][getDefName()] || ''}
                    />
                    <FormControlLabel
                      value={BOOKING_STATUS[1].id}
                      checked={booking.status === BOOKING_STATUS[1].id}
                      control={<Radio />}
                      label={BOOKING_STATUS[1][getDefName()] || ''}
                    />
                    {cate === 'product' && (
                      <FormControlLabel
                        value={BOOKING_STATUS[2].id}
                        checked={booking.status === BOOKING_STATUS[2].id}
                        control={<Radio />}
                        label={BOOKING_STATUS[2][getDefName()] || ''}
                      />
                    )}
                    <FormControlLabel
                      value={BOOKING_STATUS[3].id}
                      checked={booking.status === BOOKING_STATUS[3].id}
                      control={<Radio />}
                      label={BOOKING_STATUS[3][getDefName()] || ''}
                    />
                    <FormControlLabel
                      value={BOOKING_STATUS[4].id}
                      checked={booking.status === BOOKING_STATUS[4].id}
                      control={<Radio />}
                      label={BOOKING_STATUS[4][getDefName()] || ''}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Card className={classes.my3}>
          <CardActions style={{ marginLeft: '16px', marginRight: '16px' }}>
            <Grid container justifyContent={'space-between'} xs={12} md={12} spacing={3}>
              <Grid item>
                <Button
                  variant="contained"
                  style={{ backgroundColor: green[700], color: '#ffffff', marginRight: '10px' }}
                  size="medium"
                  onClick={goList}
                  startIcon={<ListIcon />}
                >
                  List
                </Button>
                {type !== 'new' ? (
                  <Button variant="contained" color="secondary" size="medium" onClick={deleteBooking} startIcon={<DeleteIcon />}>
                    Delete
                  </Button>
                ) : null}
              </Grid>
              <Grid item>
                <Button variant="contained" color="primary" size="medium" onClick={addBooking} startIcon={<SaveIcon />}>
                  {type === 'new' ? 'Registration' : 'Save'}
                </Button>
              </Grid>
            </Grid>
          </CardActions>
        </Card>
      </div>
    </OftadehLayout>
  );
};

export default BookingEditPage;
