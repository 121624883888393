import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import OftadehLayout from '../../components/OftadehLayout/OftadehLayout';
import { Button, Divider, Grid, IconButton, makeStyles, Typography } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import swal from '@sweetalert/with-react';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import SaveIcon from '@material-ui/icons/Save';
import ListIcon from '@material-ui/icons/List';
import DeleteIcon from '@material-ui/icons/Delete';
import axiosInstance from '../../constants/AxiosInstance';
import { API_URL, getDefName } from '../../constants';
import ImgListView from '../customComp/ImgListView';
import {
  getHospitalInfo,
  getHospitalList,
  getHospitalTypeList,
  getLanguageList,
  getRegionList,
  getSubDiseaseAll,
} from '../../constants/PromiseHttp';
import { Add, AddCircle } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    color: '#3f51b5',
  },
  my3: {
    margin: '1.3rem 0',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  mRight: {
    marginRight: '.85rem',
  },
  mInput: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
  textArea: {
    width: '98%',
    minHeight: 300,
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    borderColor: 'lightgray',
  },
}));

const HospitalDetailPage = (props) => {
  const query = queryString.parse(props.location.search);
  const type = query.type ? query.type : 'new';
  const IS_SYS_ADMIN = sessionStorage.getItem('bt_role') === 'SYS_ADM';

  const [hospital, setHospital] = useState();
  const [languages, setLanguages] = useState([]);
  const [hospitalList, setHospitalList] = useState([]);
  const [subDiseases, setSubDiseases] = useState();
  const [hospitalType, setHospitalType] = useState([]);
  const [region, setRegion] = useState([]);

  const { history } = props;
  const classes = useStyles();

  const [isWriter, setIsWriter] = useState(false);

  useEffect(() => {
    getData();
    getLanguageList().then((result) => {
      setLanguages(result);
    });
    getSubDiseaseAll().then((result) => {
      setSubDiseases(result);
    });
    getHospitalList().then((result) => {
      setHospitalList(result);
    });
    getHospitalTypeList().then((result) => {
      setHospitalType(result);
    });
    getRegionList().then((result) => {
      setRegion(result);
    });
  }, []);

  const getData = () => {
    if (query.id) {
      getHospitalInfo(query.id).then((result) => {
        setHospital(result);
        if (result.adminId) {
          setIsWriter(result.adminId === sessionStorage.getItem('bt_user'));
        }
      });
    }
  };

  const deleteHospital = () => {
    swal({
      title: 'Are you sure you want to delete?',
      text: '',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        axiosInstance.delete(`${API_URL}/hospital/${query.id}`, { headers: { Pragma: 'no-cache' } }).then((response) => {
          if (response.status === 200) {
            window.localStorage.setItem('hospitalList', '');
            goList();
            swal('It is deleted.', {
              icon: 'success',
            });
          }
        });
      } else {
      }
    });
  };

  const goList = () => {
    history.push('/pages/hospital/');
  };

  const goEdit = () => {
    history.push(`/pages/hospital/edit?type=edit&id=${query.id}`);
  };

  const clone = () => {
    history.push(`/pages/hospital/edit?type=clone&id=${query.id}`);
  };

  const getLanguageNames = (ids) => {
    let names = '';
    if (!ids || !languages) {
      return names;
    }

    if (ids && ids?.length) {
      ids.forEach((i) => (names += `${i}, `));
    }

    return names;
  };

  const getDiseaseNames = (ids) => {
    let names = '';
    if (!ids || !subDiseases) {
      return names;
    }

    for (let i = 0; i < ids.length; i++) {
      let sdi = subDiseases.find((_sdi) => _sdi.id === ids[i]);
      if (i > 0) names += ', ';
      names += sdi[getDefName()];
    }
    return names;
  };

  const getHospitalNames = (ids) => {
    let names = '';
    if (!ids || !subDiseases) {
      return names;
    }
    for (let i = 0; i < ids.length; i++) {
      if (i > 0) names += '\n';
      names += getHospitalName(ids[i]);
    }
    return newLineText(names);
  };

  const getHospitalName = (hid) => {
    const hospital = hospitalList && hospitalList.find((hos) => hos.id === hid);
    return hospital ? `${hospital['name_ko']} (${hospital['languageId']})` : '-';
  };
  const getHospitalTypeName = (hid) => {
    const type = hospitalType && hospitalType.find((hos) => hos.id === hid);
    return type ? type[getDefName()] : '-';
  };
  const getRegionName = (rid) => {
    const reg = region && region.find((reg) => reg.id === rid);
    return reg ? reg[getDefName()] : '-';
  };
  const newLineText = (text) => {
    //console.log(text);
    if (!text || !text.includes('\n')) return text;
    return text.split('\n').map((line, i) => {
      return (
        <span key={`line_${i}`}>
          {line}
          <br />
        </span>
      );
    });
  };

  const ThumbImage = (props) => {
    return (
      <Grid item md={4} key={'file-image-' + props.target.name}>
        <a download={props.target.name} href={props.target.url}>
          <img
            src={props.target.url}
            width={'100%'}
            alt={'file-' + props.target.name}
            onError={(e) => {
              e.target.src = 'https://via.placeholder.com/100.png?text=noimage';
            }}
          />
        </a>
      </Grid>
    );
  };

  return (
    <OftadehLayout>
      <Grid container className={classes.my3} alignItems="center">
        <Grid item className={classes.mRight} style={{ fontWeight: 'bold' }}>
          <Typography variant="h5" component="h1" style={{ fontWeight: 'bold' }}>
            Hospital Detail
          </Typography>
        </Grid>
        <Grid item>
          <Button variant="outlined" color="primary" size="medium" onClick={clone} startIcon={<Add />}>
            clone
          </Button>
        </Grid>
      </Grid>
      <div>
        <Card className={classes.root}>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} mb={12}>
                <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                  Basic Info
                </Typography>
              </Grid>
              <Grid item xs={6} mb={6}>
                <div className={classes.title}>Language</div>
                <Typography variant="body1">{(hospital && hospital?.languageId) || ''}</Typography>
              </Grid>
              <Grid item xs={6} mb={6}>
                <div className={classes.title}>Manager</div>
                <Typography variant="body1">{(hospital && hospital?.adminName) || ''}</Typography>
              </Grid>
              <Grid item xs={6} mb={6}>
                <div className={classes.title}>Hospital Name</div>
                <Typography variant="body1">{(hospital && hospital?.name_ko) || ''}</Typography>
              </Grid>
              <Grid item xs={6} mb={6}>
                <div className={classes.title}>Hospital Name (Another Language)</div>
                <Typography variant="body1">{(hospital && hospital?.name_en) || ''}</Typography>
              </Grid>
            </Grid>
            <Divider style={{ marginTop: '10px' }} />
            <Grid container spacing={3} style={{ marginTop: '10px' }}>
              <Grid item xs={4} mb={6}>
                <div className={classes.title}>Medical malpractice liability insurance</div>
                <Typography variant="body1">
                  {hospital && hospital?.info && hospital?.info?.insurance ? 'Support' : 'Not Support'}
                </Typography>
              </Grid>
              <Grid item xs={2} mb={6}>
                <div className={classes.title}>Use of remote consultation</div>
                <Typography variant="body1">
                  {hospital && hospital?.info && hospital?.info?.supportRemote ? 'Support' : 'Not Support'}
                </Typography>
              </Grid>
              {hospital && hospital?.info && hospital?.info?.supportRemote && (
                <Grid item xs={2} mb={6}>
                  <div className={classes.title}>Fee(KRW)</div>
                  <Typography variant="body1">{hospital.info.remotePrice}</Typography>
                </Grid>
              )}
              <Grid item xs={4} mb={6}>
                <div className={classes.title}>Invitational medical institution registration number</div>
                <Typography variant="body1">{(hospital && hospital?.info && hospital?.info?.regNumber) || ''}</Typography>
              </Grid>
              <Grid item xs={4} mb={6}>
                <div className={classes.title}>Category</div>
                <Typography variant="body1">{(hospital && getHospitalTypeName(hospital?.typeId)) || ''}</Typography>
              </Grid>
              <Grid item xs={4} mb={6}>
                <div className={classes.title}>Region</div>
                <Typography variant="body1">{(hospital && hospital?.info && getRegionName(hospital?.info?.regionId)) || ''}</Typography>
              </Grid>
              <Grid item xs={4} mb={6}>
                <div className={classes.title}>Tel.</div>
                <Typography variant="body1">{(hospital && hospital?.info && hospital?.info.tel) || ''}</Typography>
              </Grid>
              <Grid item xs={4} mb={6}>
                <div className={classes.title}>Fax.</div>
                <Typography variant="body1">{(hospital && hospital?.info && hospital?.info.fax) || ''}</Typography>
              </Grid>
              <Grid item xs={4} mb={6}>
                <div className={classes.title}>Web Site</div>
                <Typography variant="body1">{(hospital && hospital?.info && hospital?.info.url) || ''}</Typography>
              </Grid>
              <Grid item xs={4} mb={6}>
                <div className={classes.title}>Email</div>
                <Typography variant="body1">{(hospital && hospital?.info && hospital?.info.email) || ''}</Typography>
              </Grid>
              <Grid item xs={4} mb={6}>
                <div className={classes.title}>Number of medical staff</div>
                <Typography variant="body1">{(hospital && hospital?.info && hospital?.info?.numOfDoctor) || ''}</Typography>
              </Grid>
              <Grid item xs={4} mb={6}>
                <div className={classes.title}>Number of bed</div>
                <Typography variant="body1">{(hospital && hospital?.info && hospital?.info?.numOfBed) || ''}</Typography>
              </Grid>
              <Grid item xs={4} mb={6}>
                <div className={classes.title}>Years of establishment</div>
                <Typography variant="body1">{(hospital && hospital?.info && hospital?.info?.establishedYear) || ''}</Typography>
              </Grid>
              <Grid item xs={4} mb={6}>
                <div className={classes.title}>Address(Korean)</div>
                <Typography variant="body1">{(hospital && hospital?.info && hospital?.info?.address_ko) || ''}</Typography>
              </Grid>
              <Grid item xs={4} mb={6}>
                <div className={classes.title}>Address(English)</div>
                <Typography variant="body1">{(hospital && hospital?.info && hospital?.info?.address_en) || ''}</Typography>
              </Grid>
              <Grid item xs={4} mb={6}>
                <div className={classes.title}>Latitude / Longitude</div>
                <Typography variant="body1">{`${
                  (hospital && hospital?.info && hospital?.info?.coord && hospital?.info?.coord.lat) || '-'
                } / ${(hospital && hospital?.info && hospital?.info?.coord && hospital?.info?.coord?.lon) || '-'}`}</Typography>
              </Grid>
              <Grid item xs={12} mb={6}>
                <div className={classes.title}>Available Language</div>
                <Typography variant="body1">
                  {(hospital && hospital?.info && getLanguageNames(hospital?.info?.availableLanguageIds || '')) || ''}
                </Typography>
              </Grid>
              <Grid item xs={12} mb={6}>
                <div className={classes.title}>More Hospitals</div>
                <Typography variant="body1">
                  {(hospital && hospital?.info && hospitalList && getHospitalNames(hospital?.info?.relatedHospitalIds)) || ''}
                </Typography>
              </Grid>
              <Grid item xs={8} mb={6}>
                <div className={classes.title}>Tags</div>
                <Typography variant="body1">{(hospital && hospital?.info && hospital?.info?.tags) || ''}</Typography>
              </Grid>
              <Grid item xs={4} mb={6}>
                <div className={classes.title}>Show/Hide</div>
                <Typography variant="body1">{hospital && hospital?.hidden ? 'Hide' : 'Show'}</Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Card className={classes.my3}>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} mb={12}>
                <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                  Description
                </Typography>
              </Grid>
              <Divider variant={'fullWidth'} />
              <Grid item xs={12}>
                <div className={classes.title}>Default</div>
                <Typography variant="body1">{newLineText(hospital && hospital?.desc && hospital?.desc?.default) || ''}</Typography>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.title}>Specialized treatment</div>
                <Typography variant="body1">{newLineText(hospital && hospital?.desc && hospital?.desc?.treatment) || ''}</Typography>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.title}>Other services</div>
                <Typography variant="body1">{newLineText(hospital && hospital?.desc && hospital?.desc?.service) || ''}</Typography>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.title}>Certificate of possession</div>
                <Typography variant="body1">{newLineText(hospital && hospital?.desc && hospital?.desc?.certificate) || ''}</Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Card className={classes.my3}>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} mb={12}>
                <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                  Disease
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.title}>Disease</div>
                <Typography variant="body1">{getDiseaseNames(hospital && hospital?.subDiseaseIds)}</Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Card className={classes.my3}>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} mb={12}>
                <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                  Attachments
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.title}>Hospital Main images </div>
                {hospital && <ImgListView images={hospital && hospital?.images} />}
              </Grid>
              <Grid item xs={12}>
                <div className={classes.title}>Hospital Brochure/Promotion </div>
                {hospital && <ImgListView images={hospital && hospital?.files} />}
              </Grid>
              <Grid item xs={12} md={12}>
                <div className={classes.title}>Promotion Video URL</div>
                {hospital && (
                  <Grid container spacing={3} style={{ marginTop: '10px' }}>
                    <Grid item xs={4}>
                      {hospital?.thumbs1 && hospital?.thumbs1[0] && <ThumbImage target={hospital?.thumbs1[0]} />}
                      <a href={hospital?.thumbUrl1}>{hospital?.thumbUrl1}</a>
                    </Grid>
                    <Grid item xs={4}>
                      {hospital?.thumbs2 && hospital?.thumbs2[0] && <ThumbImage target={hospital?.thumbs2[0]} />}
                      <a href={hospital?.thumbUrl2}>{hospital?.thumbUrl2}</a>
                    </Grid>
                    <Grid item xs={4}>
                      {hospital?.thumbs3 && hospital?.thumbs3[0] && <ThumbImage target={hospital?.thumbs3[0]} />}
                      <a href={hospital?.thumbUrl3}>{hospital?.thumbUrl3}</a>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>
      <div className={classes.my3}>
        <Grid container justifyContent={'space-between'} xs={12} md={12} spacing={3}>
          <Grid item>
            <Button
              variant="contained"
              style={{ backgroundColor: green[700], color: '#ffffff', marginRight: '10px' }}
              size="medium"
              onClick={goList}
              startIcon={<ListIcon />}
            >
              List
            </Button>
            {(IS_SYS_ADMIN || isWriter) && (
              <Button variant="contained" color="secondary" size="medium" onClick={deleteHospital} startIcon={<DeleteIcon />}>
                Delete
              </Button>
            )}
          </Grid>
          {(IS_SYS_ADMIN || isWriter) && (
            <Grid item>
              <Button variant="contained" color="primary" size="medium" onClick={goEdit} startIcon={<SaveIcon />}>
                Edit
              </Button>
            </Grid>
          )}
        </Grid>
      </div>
    </OftadehLayout>
  );
};

export default HospitalDetailPage;
