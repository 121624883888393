import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { getReviewListForUser } from '../../constants/PromiseHttp';
import MUIDataTable from 'mui-datatables';
import { Rating } from '@material-ui/lab';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  my3: {
    margin: '1.3rem 0',
  },
  mb0: {
    marginBottom: 0,
  },
  mRight: {
    marginRight: '.85rem',
  },
  p1: {
    padding: '.85rem',
  },
}));

const TabReviewList = (props) => {
  const { history } = props;
  const [data, setData] = useState();
  // const [types, setTypes] = useState([]);
  const classes = useStyles();

  const cols = [
    {
      name: 'id',
      label: 'id',
      options: {
        filter: false,
        sort: true,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: 'id',
      label: 'No.',
      options: {
        filter: false,
        sort: true,
        display: true,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return tableMeta.rowIndex + 1;
        },
      },
    },
    // {
    //   name: 'title',
    //   label: 'Title',
    //   options: {
    //     filter: false,
    //     sort: true,
    //   },
    // },
    {
      name: 'userName',
      label: 'Reviewer',
      options: {
        filter: false,
        sort: true,
        display: true,
        viewColumns: false,
      },
    },
    {
      name: 'rating',
      label: 'Rating',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <Rating name="simple-controlled" value={value} readOnly />;
        },
      },
    },
    {
      name: 'createdAt',
      label: 'Registration Date',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return moment(Number(value)).format('YYYY-MM-DD HH:mm');
        },
      },
    },
  ];

  const rowClick = (_data) => {
    //console.log(_data);
    history.push(`/pages/review/detail?id=${_data[0]}`);
  };

  const options = {
    print: false,
    filter: false,
    search: false,
    filterType: 'checkbox',
    selectableRows: 'none',
    viewColumns: false,
    download: false,
    onRowClick: rowClick,
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    getReviewListForUser(props.userId).then((result) => {
      setData(result);
    });
  };

  return (
    <div>
      {/*<OftadehBreadcrumbs path={history} />*/}
      <MUIDataTable style={{ width: '100%' }} title={''} data={data} columns={cols} options={options} />
    </div>
  );
};
export default TabReviewList;
