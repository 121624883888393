import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  ClickAwayListener,
  Grow,
  Hidden,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from '@material-ui/core';
import { ExitToApp, VpnKey } from '@material-ui/icons';
import Amplify, { Auth } from 'aws-amplify';
import awsconfig from '../../aws-exports';
import { DEF_LANGUAGE, getSystemLanguage } from '../../constants';
// import i18next from '../../config/language/i18n';

Amplify.configure(awsconfig);

const useStyles = makeStyles((theme) => ({
  inline: {
    display: 'inline',
  },
  menuIcon: {
    minWidth: '33px',
  },
  paddingRightZero: {
    paddingRight: 0,
  },
}));

const OftadehAvatarMenu = (props) => {
  const classes = useStyles(props);

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const goLogin = () => {
    window.location.href = '/pages/auth/login';
  };

  const username = sessionStorage.getItem('bt_name') || goLogin();
  const [langCode, setLangCode] = React.useState(getSystemLanguage);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    setOpen(false);
  };

  const handleLogout = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
    sessionStorage.clear();
    localStorage.clear();
    Auth.signOut().then((r) => {
      goLogin();
    });
  };

  const handleChangePwd = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
    window.location.href = '/pages/auth/change-password';
  };

  // const handleChangeLanguage = (e) => {
  //   window.localStorage.setItem('systemLanguage', langCode === 'ko' ? 'en' : 'ko');
  //   setLangCode(getSystemLanguage());
  //   i18next.changeLanguage(getSystemLanguage()).then(() => {});
  // };

  return (
    <>
      {/*<ListItem button onClick={handleChangeLanguage} className={classes.menuIcon}>*/}
      {/*  <Typography component="span" variant="subtitle2" style={{ fontWeight: 'bold' }}>*/}
      {/*    {langCode || DEF_LANGUAGE}*/}
      {/*  </Typography>*/}
      {/*</ListItem>*/}
      <ListItem
        button
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        alignItems="flex-start"
        className={classes.paddingRightZero}
      >
        {/*<ListItemAvatar>*/}
        {/*  <OftadehAvatarBadge*/}
        {/*    overlap="circle"*/}
        {/*    anchorOrigin={{*/}
        {/*      vertical: "bottom",*/}
        {/*      horizontal: "right"*/}
        {/*    }}*/}
        {/*    variant="dot"*/}
        {/*  >*/}
        {/*    <Avatar*/}
        {/*      alt="Mohammad Oftadeh"*/}
        {/*      src="https://lh5.googleusercontent.com/-WqhFe4eMggE/AAAAAAAAAAI/AAAAAAAAAAA/ACHi3rdFUa5CK9Wi6g5qd8ZUt6apKFYSwA/photo.jpg?sz=328"*/}
        {/*    />*/}
        {/*  </OftadehAvatarBadge>*/}
        {/*</ListItemAvatar>*/}

        <Hidden implementation="css" smDown>
          <ListItemText
            primary={
              <React.Fragment>
                <Typography component="span" variant="subtitle2" style={{ fontWeight: 'bold' }}>
                  {username}
                </Typography>
              </React.Fragment>
            }
            // secondary={
            //   <React.Fragment>
            //     <Typography component="span" variant="caption" className={classes.inline} color="textPrimary">
            //       {/*{position}*/}
            //     </Typography>
            //   </React.Fragment>
            // }
          />
        </Hidden>
      </ListItem>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="menu-list-grow">
                  {/*<MenuItem onClick={handleClose}>*/}
                  {/*  <ListItemIcon className={classes.menuIcon}>*/}
                  {/*    <AccountCircle fontSize="small" />*/}
                  {/*  </ListItemIcon>*/}
                  {/*  Profile*/}
                  {/*</MenuItem>*/}
                  {/*<MenuItem onClick={handleClose}>*/}
                  {/*  <ListItemIcon className={classes.menuIcon}>*/}
                  {/*    <Settings fontSize="small" />*/}
                  {/*  </ListItemIcon>*/}
                  {/*  settings*/}
                  {/*</MenuItem>*/}
                  <MenuItem onClick={handleLogout}>
                    <ListItemIcon className={classes.menuIcon}>
                      <ExitToApp fontSize="small" />
                    </ListItemIcon>
                    Logout
                  </MenuItem>
                  <MenuItem onClick={handleChangePwd}>
                    <ListItemIcon className={classes.menuIcon}>
                      <VpnKey fontSize="small" />
                    </ListItemIcon>
                    Change Password
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

OftadehAvatarMenu.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  container: PropTypes.any,
};

export default OftadehAvatarMenu;
