import ReviewListPage from './ReviewListPage';

export const ReviewListPageConfig = {
  routes: [
    {
      path: '/pages/review',
      exact: true,
      component: ReviewListPage,
    },
  ],
};
