import React, { useEffect, useState } from 'react';
import OftadehLayout from '../../components/OftadehLayout/OftadehLayout';
import { Button, CircularProgress, Grid, makeStyles, Typography } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import { getHospitalList, getReviewList } from '../../constants/PromiseHttp';
import { Rating } from '@material-ui/lab';
import swal from '@sweetalert/with-react';
import axiosInstance from '../../constants/AxiosInstance';
import { API_URL, getDefName } from '../../constants';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  my3: {
    margin: '1.3rem 0',
  },
  mb0: {
    marginBottom: 0,
  },
  mRight: {
    marginRight: '.85rem',
  },
  p1: {
    padding: '.85rem',
  },
}));

const ReviewListPage = (props) => {
  const { history } = props;
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hospitalList, setHospitalList] = useState([]);

  const cols = [
    {
      name: 'id',
      label: 'id',
      options: {
        filter: false,
        sort: true,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: 'id',
      label: 'No.',
      options: {
        filter: false,
        sort: true,
        display: true,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return tableMeta.rowIndex + 1;
        },
      },
    },
    {
      name: 'title',
      label: 'Title',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'hospitalId',
      label: 'Hospital',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const hospital = hospitalList && hospitalList.find((hos) => hos.id === value);
          return hospital ? hospital['name_ko'] : '';
        },
      },
    },
    {
      name: 'productId',
      label: 'Product',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value;
        },
      },
    },
    {
      name: 'userName',
      label: 'Writer',
      options: {
        filter: false,
        sort: true,
        display: true,
        viewColumns: false,
      },
    },
    {
      name: 'rating',
      label: 'Rating',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <Rating name="simple-controlled" value={value} readOnly />;
        },
      },
    },
    {
      name: 'hidden',
      label: 'Show/Hide',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? 'Hide' : 'Show';
        },
      },
    },
    {
      name: 'createdAt',
      label: 'Registration Date',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return moment(Number(value)).format('YYYY-MM-DD HH:mm');
        },
      },
    },
    {
      name: 'viewCnt',
      label: 'View Count',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value || 0;
        },
      },
    },
  ];

  const rowClick = (data) => {
    //console.log(data);
    history.push(`/pages/review/detail?id=${data[0]}`);
  };

  const rowDelete = (row) => {
    let ids = [];
    row.data.map((r) => {
      ids.push(data[r.dataIndex].id);
      return r;
    });
    let delParam = {
      ids: ids.toString(),
    };
    swal({
      title: 'Are you sure you want to delete?',
      text: '',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        axiosInstance.post(`${API_URL}/review/deleteIds`, delParam, { headers: { Pragma: 'no-cache' } }).then((response) => {
          swal('It is deleted.', {
            icon: 'success',
          });
          getData();
        });
      } else {
        getData();
      }
    });
  };

  const options = {
    print: false,
    filter: false,
    filterType: 'checkbox',
    selectableRows: 'multiple',
    viewColumns: false,
    download: false,
    onRowClick: rowClick,
    onRowsDelete: rowDelete,
  };

  const getData = () => {
    setLoading(true);
    getReviewList().then((result) => {
      setLoading(false);
      setData(result);
    });
  };

  useEffect(() => {
    getData();
    getHospitalList().then((result) => {
      setHospitalList(result);
    });
  }, []);

  return (
    <OftadehLayout>
      <Grid container className={classes.my3} alignItems="center">
        <Grid item className={classes.mRight} style={{ fontWeight: 'bold' }}>
          <Typography variant="h5" component="h1" style={{ fontWeight: 'bold' }}>
            Review List
          </Typography>
        </Grid>
        <Grid item>
          <Button onClick={() => history.push('/pages/review/edit?type=new')} variant="outlined" color="primary" size="small">
            Registration
          </Button>
        </Grid>
      </Grid>
      {/*<OftadehBreadcrumbs path={history} />*/}
      <MUIDataTable title={''} data={data} columns={cols} options={options} />
      {loading && (
        <div style={{ position: 'fixed', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', zIndex: 999 }}>
          <CircularProgress color={'secondary'} />
        </div>
      )}
    </OftadehLayout>
  );
};

export default ReviewListPage;
