import HospitalEditPage from './HospitalEditPage';

export const HospitalEditPageConfig = {
  routes: [
    {
      path: '/pages/hospital/edit',
      exact: true,
      component: HospitalEditPage,
    },
  ],
};
