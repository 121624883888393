import { makeStyles, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { getLanguageList } from '../../constants/PromiseHttp';
import { Autocomplete } from '@material-ui/lab';
import { DEF_LANGUAGE, getDefName } from '../../constants';

const useStyles = makeStyles((theme) => ({
  mInput: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
}));

const LanguageAutocomplete = (props) => {
  const [languages, setLanguages] = useState([]);

  const classes = useStyles();

  useEffect(() => {
    getLanguageList().then((result) => {
      setLanguages(result);
    });
  }, []);

  const getSelectedLanguage = () => {
    const item = languages.find((lang) => {
      if (props.target && props.target.languageId ? lang.id === props.target.languageId : lang.id === DEF_LANGUAGE) return lang;
    });
    //console.log('getSelectedLangugage', item);
    return item || null;
  };

  return (
    <Autocomplete
      key="cbox-language"
      value={getSelectedLanguage()}
      onChange={(event, newValue) => {
        props.handleChangeData(newValue.id, 'languageId');
      }}
      size={'small'}
      disableClearable
      options={languages}
      getOptionLabel={(option) => option[getDefName()] || ''}
      getOptionSelected={(option, value) => option.id === value.id}
      style={{ width: '100%' }}
      className={classes.mInput}
      renderInput={(params) => <TextField {...params} variant="outlined" inputProps={{ ...params.inputProps, readOnly: true }} />}
    />
  );
};

export default LanguageAutocomplete;
