import React, { useEffect, useRef, useState } from 'react';
import queryString from 'query-string';
import OftadehLayout from '../../components/OftadehLayout/OftadehLayout';
import { Button, FormControlLabel, Grid, makeStyles, Radio, RadioGroup, TextField, Typography } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import swal from '@sweetalert/with-react';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import SaveIcon from '@material-ui/icons/Save';
import ListIcon from '@material-ui/icons/List';
import DeleteIcon from '@material-ui/icons/Delete';
import FormControl from '@material-ui/core/FormControl';
import axiosInstance from '../../constants/AxiosInstance';
import { API_URL, DEF_LANGUAGE, DEF_NOTICE_CATE, getDefName, NOTICE_CATEGORY } from '../../constants';
import { getNoticeInfo } from '../../constants/PromiseHttp';
import FileUpload from '../../components/FileUpload/FileUpload';
import LanguageAutocomplete from '../customComp/LanguageAutocomplete';
import Jodit from '../../components/Jodit/Jodit';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  my3: {
    margin: '1.3rem 0',
  },
  mb0: {
    marginBottom: 0,
  },
  mRight: {
    marginRight: '.85rem',
  },
  p1: {
    padding: '.85rem',
  },
}));

const NoticeEditPage = (props) => {
  const query = queryString.parse(props.location.search);
  const type = query.type ? query.type : 'new';

  const editor = useRef(null);
  const [notice, setNotice] = useState({});

  const { history } = props;
  const classes = useStyles();

  const [uploadFile, setUploadFile] = useState([]);
  const childRef = useRef(null);
  const imgKey = 'files';

  const [uploadThumb, setUploadThumb] = useState([]);
  const thumbRef = useRef(null);
  const thumbKey = 'thumb';

  const handleChangeData = (value, field) => {
    //console.log('handleChangeData,', value);
    notice[field] = value;
    setNotice({ ...notice });
  };

  useEffect(() => {
    if (type === 'edit' && query.id) {
      getData();
    } else {
      setNotice([]);
    }
  }, []);

  const getData = () => {
    getNoticeInfo(query.id).then((result) => {
      setNotice(result);
      //console.log(result);
      if (childRef.current) {
        childRef.current.getSignedUrl(result[imgKey]);
      }
      if (thumbRef.current) {
        thumbRef.current.getSignedUrl(result[thumbKey]);
      }
      if (editor.current) {
        editor.current.setValue(result['contents']);
      }
    });
  };

  const addNotice = async (e) => {
    e.preventDefault();
    const contents = await editor.current.getValue();
    handleChangeData(contents, 'contents');

    if (!notice.title) {
      swal('Missing required title.');
      return;
    }
    if (!notice.contents) {
      swal('Missing required contents of notice.');
      return;
    }

    let param = {
      title: notice.title,
      contents: notice.contents,
      category: notice.category || DEF_NOTICE_CATE,
      languageId: notice.languageId || DEF_LANGUAGE,
    };

    if (type === 'new') {
      param['writerId'] = sessionStorage.getItem('bt_user');
      axiosInstance.post(`${API_URL}/notice/`, param).then(async (response) => {
        if (response.status === 200) {
          if (childRef.current && response.data.value.id) {
            await Promise.all([
              childRef.current.fileUpload(uploadFile, response.data.value.id, 'notice', `${API_URL}/notice/`),
              thumbRef.current.fileUpload(uploadThumb, response.data.value.id, 'notice', `${API_URL}/notice/`),
            ]).then(() => {
              swal('Registered.', {
                icon: 'success',
              });
              goList();
            });
          } else {
            swal('Registered.', {
              icon: 'success',
            });
            goList();
          }
        }
      });
    } else {
      param['id'] = query.id;

      axiosInstance.put(`${API_URL}/notice/`, param).then(async (response) => {
        if (response.status === 200) {
          if (childRef.current) {
            await Promise.all([
              childRef.current.fileUpload(uploadFile, query.id, 'notice', `${API_URL}/notice/`),
              thumbRef.current.fileUpload(uploadThumb, query.id, 'notice', `${API_URL}/notice/`),
            ]).then(() => {
              swal('It is changed.', {
                icon: 'success',
              });
              goDetail();
            });
          } else {
            swal('It is changed.', {
              icon: 'success',
            });
            goDetail();
          }
        }
      });
    }
  };

  const deleteNotice = () => {
    swal({
      title: 'Are you sure you want to delete?',
      text: '',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        axiosInstance.delete(`${API_URL}/notice/${query.id}`, { headers: { Pragma: 'no-cache' } }).then((response) => {
          if (response.status === 200) {
            swal('It is deleted.', {
              icon: 'success',
            });
            goList();
          }
        });
      } else {
      }
    });
  };

  const goList = () => {
    history.push('/pages/notice/');
  };

  const goDetail = () => {
    history.push(`/pages/notice/detail?type=edit&id=${query.id}`);
  };

  const handleFile = (file, key) => {
    if (key === imgKey) {
      setUploadFile([...file]);
    }
    if (key === thumbKey) {
      setUploadThumb([...file]);
    }
  };
  const handleUpload = () => {
    console.log('Upload Success');
  };
  const handleRemove = (file, key) => {
    // console.log('Remove Success');
    if (key === imgKey) {
      setUploadFile([...file]);
    }
    if (key === thumbKey) {
      setUploadThumb([...file]);
    }
  };

  return (
    <OftadehLayout>
      <Grid container className={classes.my3} alignItems="center">
        <Grid item className={classes.mRight} style={{ fontWeight: 'bold' }}>
          <Typography variant="h5" component="h1" style={{ fontWeight: 'bold' }}>
            Notice {type === 'new' ? 'Registration' : 'Edit'}
          </Typography>
        </Grid>
      </Grid>

      <Card className={classes.root}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <FormControl component="fieldset">
                <Typography component="legend">
                  Category <span style={{ color: 'red' }}>*</span>
                </Typography>
                <RadioGroup
                  aria-label="category"
                  name="category1"
                  //value={notice.category}
                  onChange={(event) => {
                    handleChangeData(event.target.value, 'category');
                  }}
                  row
                >
                  <FormControlLabel
                    value={DEF_NOTICE_CATE}
                    checked={notice.category === undefined || notice.category === NOTICE_CATEGORY[0].id}
                    control={<Radio />}
                    label={NOTICE_CATEGORY[0][getDefName()] || ''}
                  />
                  <FormControlLabel
                    value={NOTICE_CATEGORY[1].id}
                    checked={notice.category === NOTICE_CATEGORY[1].id}
                    control={<Radio />}
                    label={NOTICE_CATEGORY[1][getDefName()] || ''}
                  />
                  <FormControlLabel
                    value={NOTICE_CATEGORY[2].id}
                    checked={notice.category === NOTICE_CATEGORY[2].id}
                    control={<Radio />}
                    label={NOTICE_CATEGORY[2][getDefName()] || ''}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <Typography component="legend">
                Language <span style={{ color: 'red' }}>*</span>
              </Typography>
              <LanguageAutocomplete target={notice} handleChangeData={handleChangeData} />
            </Grid>
            <Grid item xs={12}>
              <Typography component="legend">
                Title <span style={{ color: 'red' }}>*</span>
              </Typography>
              <TextField
                className={classes.my3}
                placeholder="Please enter a title."
                margin="normal"
                style={{ width: '100%' }}
                InputLabelProps={{
                  shrink: true,
                }}
                value={notice.title || ''}
                variant={'outlined'}
                size={'small'}
                onChange={(event) => {
                  handleChangeData(event.target.value, 'title');
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography component="legend">
                Contents <span style={{ color: 'red' }}>*</span>
              </Typography>
              <Jodit ref={editor} id={'editor'} uploadApi={`${API_URL}/upload`} val={notice.contents} />
            </Grid>

            <Grid item xs={12} mb={12} style={{ marginTop: '20px' }}>
              <Typography component="legend">
                Thumbnail <span style={{ color: 'red' }}>*</span>
              </Typography>
              <FileUpload
                type={'file'}
                ref={thumbRef}
                accept={'image/*'}
                buttonText={'IMAGE'}
                onFileHandler={handleFile}
                onRemoveAfter={handleRemove}
                onUploadAfter={handleUpload}
                fileId={query.id}
                folder={'notice'}
                apiUrl={`${API_URL}/notice/`}
                uploadKey={thumbKey}
                maxCount={1}
              />
            </Grid>
            <Grid item xs={12} mb={12} style={{ marginTop: '20px' }}>
              <Typography component="legend">Attachments (max 4)</Typography>
              <FileUpload
                type={'file'}
                ref={childRef}
                buttonText={'File'}
                onFileHandler={handleFile}
                onRemoveAfter={handleRemove}
                onUploadAfter={handleUpload}
                fileId={query.id}
                folder={'notice'}
                apiUrl={`${API_URL}/notice/`}
                uploadKey={imgKey}
                maxCount={4}
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions style={{ marginLeft: '16px', marginRight: '16px' }}>
          <Grid container justifyContent={'space-between'} xs={12} md={12} spacing={3}>
            <Grid item>
              <Button
                variant="contained"
                style={{ backgroundColor: green[700], color: '#ffffff', marginRight: '10px' }}
                size="medium"
                onClick={goList}
                startIcon={<ListIcon />}
              >
                List
              </Button>
              {type !== 'new' ? (
                <Button variant="contained" color="secondary" size="medium" onClick={deleteNotice} startIcon={<DeleteIcon />}>
                  Delete
                </Button>
              ) : null}
            </Grid>
            <Grid item>
              <Button variant="contained" color="primary" size="medium" onClick={addNotice} startIcon={<SaveIcon />}>
                {type === 'new' ? 'Registration' : 'Edit'}
              </Button>
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    </OftadehLayout>
  );
};

export default NoticeEditPage;
