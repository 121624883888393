import React from 'react';
import { Redirect } from 'react-router-dom';

import { DashboardPageConfig } from '../pages/dashboard/DashboardPageConfig';
import { ForgotPasswordPageConfig } from '../pages/auth/forgot-password/ForgotPasswordPageConfig';
import { ChangePasswordPageConfig } from '../pages/auth/change-password/ChangePasswordPageConfig';
import { LoginPageConfig } from '../pages/auth/login/LoginPageConfig';
import { RegisterPageConfig } from '../pages/auth/register/RegisterPageConfig';
import { Error404PageConfig } from '../pages/errors/404/Error404PageConfig';
import { Error500PageConfig } from '../pages/errors/500/Error500PageConfig';
import { NoticeEditPageConfig } from '../pages/notice/NoticeEditPageConfig';
import { NoticeListPageConfig } from '../pages/notice/NoticeListPageConfig';
import { NoticeDetailPageConfig } from '../pages/notice/NoticeDetailPageConfig';
import { ConsultListPageConfig } from '../pages/consult/ConsultListPageConfig';
import { ConsultEditPageConfig } from '../pages/consult/ConsultEditPageConfig';
import { ConsultDetailPageConfig } from '../pages/consult/ConsultDetailPageConfig';
import { VideoConsultListPageConfig } from '../pages/consult/video/VideoConsultListPageConfig';
import { VideoConsultDetailPageConfig } from '../pages/consult/video/VideoConsultDetailPageConfig';
import { VideoConsultEditPageConfig } from '../pages/consult/video/VideoConsultEditPageConfig';
import { ConsultCaseListPageConfig } from '../pages/consult/case/ConsultCaseListPageConfig';
import { ConsultCaseDetailPageConfig } from '../pages/consult/case/ConsultCaseDetailPageConfig';
import { ConsultCaseEditPageConfig } from '../pages/consult/case/ConsultCaseEditPageConfig';
import { ReviewListPageConfig } from '../pages/review/ReviewListPageConfig';
import { ReviewDetailPageConfig } from '../pages/review/ReviewDetailPageConfig';
import { ReviewEditPageConfig } from '../pages/review/ReviewEditPageConfig';
import { HospitalListPageConfig } from '../pages/hospital/HospitalListPageConfig';
import { HospitalEditPageConfig } from '../pages/hospital/HospitalEditPageConfig';
import { HospitalDetailPageConfig } from '../pages/hospital/HospitalDetailPageConfig';
import { RecDoctorListPageConfig } from '../pages/recDoctor/RecDoctorListPageConfig';
import { RecDoctorDetailPageConfig } from '../pages/recDoctor/RecDoctorDetailPageConfig';
import { RecDoctorEditPageConfig } from '../pages/recDoctor/RecDoctorEditPageConfig';
import { ProductListPageConfig } from '../pages/product/ProductListPageConfig';
import { ProductDetailPageConfig } from '../pages/product/ProductDetailPageConfig';
import { ProductEditPageConfig } from '../pages/product/ProductEditPageConfig';
import { NewsListPageConfig } from '../pages/news/NewsListPageConfig';
import { NewsEditPageConfig } from '../pages/news/NewsEditPageConfig';
import { NewsDetailPageConfig } from '../pages/news/NewsDetailPageConfig';
import { MemberListPageConfig } from '../pages/member/MemberListPageConfig';
import { MemberDetailPageConfig } from '../pages/member/MemberDetailPageConfig';
import { ManagerListPageConfig } from '../pages/manager/ManagerListPageConfig';
import { ManagerDetailPageConfig } from '../pages/manager/ManagerDetailPageConfig';
import { BookingListPageConfig } from '../pages/booking/BookingListPageConfig';
import { BookingDetailPageConfig } from '../pages/booking/BookingDetailPageConfig';
import { BookingEditPageConfig } from '../pages/booking/BookingEditPageConfig';
import { SettingDefaultInfoPageConfig } from '../pages/setting/defaultInfo/SettingDefaultInfoPageConfig';
import { SettingBannerListPageConfig } from '../pages/setting/banner/SettingBannerListPageConfig';
import { SettingBannerDetailPageConfig } from '../pages/setting/banner/SettingBannerDetailPageConfig';
import { SettingDiseaseListPageConfig } from '../pages/setting/disease/SettingDiseaseListPageConfig';
import { SettingDiseaseDetailPageConfig } from '../pages/setting/disease/SettingDiseaseDetailPageConfig';
import { SettingCodesPageConfig } from '../pages/setting/codes/SettingCodesPageConfig';
import { SettingFaqListPageConfig } from '../pages/setting/faq/SettingFaqListPageConfig';
import { SettingFaqDetailPageConfig } from '../pages/setting/faq/SettingFaqDetailPageConfig';
import { InquireListPageConfig } from '../pages/inquire/InquireListPageConfig';
import { InquireDetailPageConfig } from '../pages/inquire/InquireDetailPageConfig';
import { QuestionListPageConfig } from '../pages/question/QuestionListPageConfig';
import { QuestionDetailPageConfig } from '../pages/question/QuestionDetailPageConfig';
import { SearchUserPageConfig } from '../pages/customComp/SearchUserPageConfig';
const routeConfigs = [
  ...DashboardPageConfig.routes,
  ...ForgotPasswordPageConfig.routes,
  ...ChangePasswordPageConfig.routes,
  ...LoginPageConfig.routes,
  ...RegisterPageConfig.routes,
  ...Error404PageConfig.routes,
  ...Error500PageConfig.routes,
  ...NoticeEditPageConfig.routes,
  ...NoticeDetailPageConfig.routes,
  ...NoticeListPageConfig.routes,
  ...ConsultDetailPageConfig.routes,
  ...ConsultEditPageConfig.routes,
  ...ConsultListPageConfig.routes,
  ...VideoConsultDetailPageConfig.routes,
  ...VideoConsultEditPageConfig.routes,
  ...VideoConsultListPageConfig.routes,
  ...ConsultCaseDetailPageConfig.routes,
  ...ConsultCaseEditPageConfig.routes,
  ...ConsultCaseListPageConfig.routes,
  ...ReviewEditPageConfig.routes,
  ...ReviewDetailPageConfig.routes,
  ...ReviewListPageConfig.routes,
  ...HospitalDetailPageConfig.routes,
  ...HospitalEditPageConfig.routes,
  ...HospitalListPageConfig.routes,
  ...RecDoctorDetailPageConfig.routes,
  ...RecDoctorEditPageConfig.routes,
  ...RecDoctorListPageConfig.routes,
  ...ProductEditPageConfig.routes,
  ...ProductDetailPageConfig.routes,
  ...ProductListPageConfig.routes,
  ...NewsEditPageConfig.routes,
  ...NewsDetailPageConfig.routes,
  ...NewsListPageConfig.routes,
  ...MemberDetailPageConfig.routes,
  ...MemberListPageConfig.routes,
  ...ManagerDetailPageConfig.routes,
  ...ManagerListPageConfig.routes,
  ...BookingListPageConfig.routes,
  ...BookingDetailPageConfig.routes,
  ...BookingEditPageConfig.routes,
  ...SettingDefaultInfoPageConfig.routes,
  ...SettingBannerDetailPageConfig.routes,
  ...SettingBannerListPageConfig.routes,
  ...SettingCodesPageConfig.routes,
  ...SettingDiseaseDetailPageConfig.routes,
  ...SettingDiseaseListPageConfig.routes,
  ...SettingFaqDetailPageConfig.routes,
  ...SettingFaqListPageConfig.routes,
  ...InquireListPageConfig.routes,
  ...InquireDetailPageConfig.routes,
  ...QuestionDetailPageConfig.routes,
  ...QuestionListPageConfig.routes,
  ...SearchUserPageConfig.routes,
];

const routes = [
  ...routeConfigs,
  {
    component: () => <Redirect to="/pages/errors/error-404" />,
  },
];

export default routes;
