import { makeStyles, TextField } from '@material-ui/core';
import React from 'react';
import { Autocomplete } from '@material-ui/lab';
import { countries } from 'country-data';
import { DEF_COUNTRY } from '../../constants';

const useStyles = makeStyles((theme) => ({
  mInput: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
}));

const CountryAutocomplete = (props) => {
  const classes = useStyles();

  const getSelectedCountry = () => {
    if (props.target) {
      const cid = props.target.countryId;
      if (cid) {
        const item = countries[`${cid}`];
        return item || countries[DEF_COUNTRY];
      }
    }
    return countries['KOR'];
  };

  const sortedCountryList = () => {
    return countries.all.sort((a, b) => a.name.localeCompare(b.name));
  };

  return (
    <Autocomplete
      key="cbox-region"
      value={getSelectedCountry()}
      onChange={(event, newValue) => {
        props.handleChangeData(newValue.alpha3, 'countryId');
      }}
      size={'small'}
      disableClearable
      options={sortedCountryList()}
      getOptionLabel={(option) => option.name || ''}
      style={{ width: '100%' }}
      className={classes.mInput}
      renderInput={(params) => <TextField {...params} variant="outlined" inputProps={{ ...params.inputProps, readOnly: false }} />}
    />
  );
};

export default CountryAutocomplete;
