import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Grid, makeStyles, ListItem, Typography, List, TextField } from '@material-ui/core';
import FileUpload from '../../components/FileUpload/FileUpload';
import { API_URL } from '../../constants';

const useStyles = makeStyles((theme) => ({
  my3: {
    margin: '3rem 0',
  },
}));

const TabAttatchView = forwardRef((props, ref) => {
  const classes = useStyles();

  const [att, setAtt] = useState({});
  const [uploadImage, setUploadImage] = useState([]);
  const [uploadFile, setUploadFile] = useState([]);
  const [uploadThumb1, setUploadThumb1] = useState([]);
  const [uploadThumb2, setUploadThumb2] = useState([]);
  const [uploadThumb3, setUploadThumb3] = useState([]);
  const imagesRef = useRef(null);
  const filesRef = useRef(null);
  const thumbRef1 = useRef(null);
  const thumbRef2 = useRef(null);
  const thumbRef3 = useRef(null);
  const imagesKey = 'images';
  const filesKey = 'files';
  const thumbKeys = ['thumbs1', 'thumbs2', 'thumbs3'];

  useImperativeHandle(ref, () => ({
    async doUpload(id) {
      await Promise.all([
        imagesRef.current.fileUpload(uploadImage, id, 'hospital', `${API_URL}/hospital/`),
        filesRef.current.fileUpload(uploadFile, id, 'hospital', `${API_URL}/hospital/`),
        thumbRef1.current.fileUpload(uploadThumb1, id, 'hospital', `${API_URL}/hospital/`),
        thumbRef2.current.fileUpload(uploadThumb2, id, 'hospital', `${API_URL}/hospital/`),
        thumbRef3.current.fileUpload(uploadThumb3, id, 'hospital', `${API_URL}/hospital/`),
      ]);
      return;
    },
  }));

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    if (imagesRef.current) {
      //console.log(props.hospital);
      imagesRef.current.getSignedUrl(props.hospital[imagesKey]);
    }
    if (filesRef.current) {
      filesRef.current.getSignedUrl(props.hospital[filesKey]);
    }
    if (thumbRef1.current) {
      thumbRef1.current.getSignedUrl(props.hospital[thumbKeys[0]]);
    }
    if (thumbRef2.current) {
      thumbRef2.current.getSignedUrl(props.hospital[thumbKeys[1]]);
    }
    if (thumbRef3.current) {
      thumbRef3.current.getSignedUrl(props.hospital[thumbKeys[2]]);
    }
    if (props.hospital.att) {
      setAtt(props.hospital.att);
      setUploadImage(props.hospital.att[imagesKey]);
      setUploadFile(props.hospital.att[filesKey]);
      setUploadThumb1(props.hospital.att[thumbKeys[0]]);
      setUploadThumb2(props.hospital.att[thumbKeys[1]]);
      setUploadThumb3(props.hospital.att[thumbKeys[2]]);
    }
  };

  const handleFile = (file, key) => {
    console.log(file, key);
    if (key === imagesKey) {
      setUploadImage([...file]);
      handleChangeAttData(uploadImage, key);
    }
    if (key === filesKey) {
      setUploadFile([...file]);
      handleChangeAttData(uploadFile, key);
    }
    if (key === thumbKeys[0]) {
      setUploadThumb1([...file]);
      handleChangeAttData(uploadThumb1, key);
    }
    if (key === thumbKeys[1]) {
      setUploadThumb2([...file]);
      handleChangeAttData(uploadThumb2, key);
    }
    if (key === thumbKeys[2]) {
      setUploadThumb3([...file]);
      handleChangeAttData(uploadThumb3, key);
    }
  };
  const handleUpload = () => {
    console.log('Upload Success');
  };
  const handleRemove = (file, key) => {
    console.log('Remove Success');
    if (key === thumbKeys[0]) {
      setUploadThumb1([...file]);
    }
    if (key === thumbKeys[1]) {
      setUploadThumb2([...file]);
    }
    if (key === thumbKeys[2]) {
      setUploadThumb3([...file]);
    }
  };
  const handleThumbnail = (value, key) => {
    //console.log('handleThumbnail : ', value, key);
    if (key === thumbKeys[0]) {
      props.handleChangeData(value, 'thumbUrl1');
    }
    if (key === thumbKeys[1]) {
      props.handleChangeData(value, 'thumbUrl2');
    }
    if (key === thumbKeys[2]) {
      props.handleChangeData(value, 'thumbUrl3');
    }
  };

  const handleChangeAttData = (value, key) => {
    att[key] = value;
    setAtt({ ...att });
    props.handleChangeData(att, 'att');
  };

  return (
    <Grid container spacing={1} style={{ marginTop: '30px' }}>
      <Grid item xs={12} mb={12} className={classes.my3}>
        <Typography>Hospital Main Images (Max 4)</Typography>
        <FileUpload
          type={'file'}
          ref={imagesRef}
          accept={'image/*'}
          buttonText={'Image'}
          onFileHandler={handleFile}
          onRemoveAfter={handleRemove}
          onUploadAfter={handleUpload}
          fileId={props.hospital.id}
          folder={'hospital'}
          apiUrl={`${API_URL}/hospital/`}
          uploadKey={imagesKey}
          maxCount={4}
        />
      </Grid>
      <Grid item xs={12} mb={12} className={classes.my3}>
        <Typography>Hospital Brochure/Promotion PDF file (Max 3)</Typography>
        <FileUpload
          type={'file'}
          ref={filesRef}
          accept={'application/pdf'}
          buttonText={'File'}
          onFileHandler={handleFile}
          onRemoveAfter={handleRemove}
          onUploadAfter={handleUpload}
          fileId={props.hospital.id}
          folder={'hospital'}
          apiUrl={`${API_URL}/hospital/`}
          uploadKey={filesKey}
          가
          maxCount={3}
        />
      </Grid>
      <Grid item xs={12} mb={12} className={classes.my3}>
        <Typography>Promotion Video(YOUTUBE) URL (Max 3)</Typography>
        <List dense>
          <ListItem>
            <FileUpload
              type={'thumbnail'}
              textInput={props.hospital.thumbUrl1 || ''}
              ref={thumbRef1}
              buttonText={'Thumbnail1'}
              onFileHandler={handleFile}
              onRemoveAfter={handleRemove}
              onUploadAfter={handleUpload}
              onInputTextChange={handleThumbnail}
              fileId={props.hospital.id}
              folder={'hospital'}
              apiUrl={`${API_URL}/hospital/`}
              uploadKey={thumbKeys[0]}
              maxCount={1}
            />
          </ListItem>
          <ListItem>
            <FileUpload
              type={'thumbnail'}
              textInput={props.hospital.thumbUrl2 || ''}
              ref={thumbRef2}
              buttonText={'Thumbnail2'}
              onFileHandler={handleFile}
              onRemoveAfter={handleRemove}
              onUploadAfter={handleUpload}
              onInputTextChange={handleThumbnail}
              fileId={props.hospital.id}
              folder={'hospital'}
              apiUrl={`${API_URL}/hospital/`}
              uploadKey={thumbKeys[1]}
              maxCount={1}
            />
          </ListItem>
          <ListItem>
            <FileUpload
              type={'thumbnail'}
              textInput={props.hospital.thumbUrl3 || ''}
              ref={thumbRef3}
              buttonText={'Thumbnail3'}
              onFileHandler={handleFile}
              onRemoveAfter={handleRemove}
              onUploadAfter={handleUpload}
              onInputTextChange={handleThumbnail}
              fileId={props.hospital.id}
              folder={'hospital'}
              apiUrl={`${API_URL}/hospital/`}
              uploadKey={thumbKeys[2]}
              maxCount={1}
            />
          </ListItem>
        </List>
      </Grid>
    </Grid>
  );
});

export default TabAttatchView;
