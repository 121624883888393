import ConsultEditPage from './ConsultEditPage';

export const ConsultEditPageConfig = {
  routes: [
    {
      path: '/pages/consult/edit',
      exact: true,
      component: ConsultEditPage,
    },
  ],
};
