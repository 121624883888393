import VideoConsultListPage from './VideoConsultListPage';

export const VideoConsultListPageConfig = {
  routes: [
    {
      path: '/pages/consult/video',
      exact: true,
      component: VideoConsultListPage,
    },
  ],
};
