import React, { useEffect, useState } from 'react';
import OftadehLayout from '../../../components/OftadehLayout/OftadehLayout';
import {
  Button,
  ButtonGroup,
  CircularProgress,
  Container,
  Grid,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Paper,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import { getFaqCategoryList, getFaqList, getLanguageList } from '../../../constants/PromiseHttp';
import swal from '@sweetalert/with-react';
import axiosInstance from '../../../constants/AxiosInstance';
import { API_URL, DEF_LANGUAGE, getDefName } from '../../../constants';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import queryString from 'query-string';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 'auto',
  },
  my3: {
    margin: '1.3rem 0',
  },
  mb0: {
    marginBottom: 0,
  },
  mLeft: {
    marginLeft: '.85rem',
  },
  mRight: {
    marginRight: '.85rem',
  },
  mInput: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
}));

const SettingFaqListPage = (props) => {
  const query = queryString.parse(props.location.search);
  const cateId = query.cate ? query.cate : '';

  const { history } = props;
  const classes = useStyles();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [languages, setLanguages] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [selectedLang, setSelectedLang] = useState(DEF_LANGUAGE);
  const [selectedCategoryId, setSelectedCategoryId] = useState('faqcategory_1635700492724');

  const deleteFaq = (fid) => {
    swal({
      title: 'Are you sure you want to delete?',
      text: '',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        axiosInstance.delete(`${API_URL}/faq/${fid}`, { headers: { Pragma: 'no-cache' } }).then((response) => {
          if (response.status === 200) {
            swal('It is deleted.', {
              icon: 'success',
            });
            getData();
          }
        });
      } else {
      }
    });
  };

  const options = {
    filter: false,
    sort: false,
    filterType: 'dropdown',
    responsive: 'scroll',
    print: false,
    download: false,
    searchable: false,
    selectableRows: 'none',
    expandableRows: true,
    // rowsExpanded: [0],
    viewColumns: false,
    showSearch: false,
    search: true,
    expandableRowsOnClick: true,
    renderExpandableRow: (rowData, rowMeta) => {
      //console.log(data[rowMeta.dataIndex]);

      return (
        <TableRow>
          <TableCell colSpan={2}>{data[rowMeta.dataIndex].contents}</TableCell>
        </TableRow>
      );
    },
  };

  const cols = [
    {
      name: 'id',
      label: 'id',
      options: {
        display: false,
      },
    },
    {
      name: 'title',
      label: '   ',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          let fid = tableMeta.rowData[0];
          return (
            <Grid container alignItems="left" spacing={1}>
              <Grid item xs={9}>
                <Typography variant="subtitle2">{value}</Typography>
              </Grid>
              <Grid item xs={1.5}>
                <Button
                  onClick={() => history.push(`/pages/setting/faq/detail?type=edit&id=${fid}`)}
                  variant="contained"
                  color="primary"
                  size="small"
                  startIcon={<SaveIcon />}
                >
                  Edit
                </Button>
              </Grid>
              <Grid item xs={1.5}>
                <Button variant="contained" color="secondary" onClick={() => deleteFaq(fid)} startIcon={<DeleteIcon />} size="small">
                  Delete
                </Button>
              </Grid>
            </Grid>
          );
        },
      },
    },
  ];

  const getData = () => {
    setLoading(true);
    getFaqCategoryList().then((result) => {
      setCategoryList(result);
      setLoading(false);
      if (result && result.length > 0) {
        const category = cateId.length > 0 ? cateId : result[0].id;
        setSelectedCategoryId(category);
        getFaqData(category, selectedLang);
      }
    });
  };

  useEffect(() => {
    getLanguageList().then((result) => {
      setLanguages(result);
    });
    getData();
  }, []);

  const handleFilter = (_filter) => {
    setSelectedLang(_filter);
    getFaqData(selectedCategoryId, _filter);
  };

  const getFaqData = (category, lang) => {
    setLoading(true);
    console.log('getFaqData : ', category);
    console.log('getFaqData : ', lang);
    setData([]);
    getFaqList(category, lang).then((result) => {
      setLoading(false);
      setData(result);
    });
  };
  const handleListItemClick = (event, id) => {
    setSelectedCategoryId(id);
    getFaqData(id, selectedLang);
  };

  const LeftList = () => {
    //console.log('LeftList >>>>> diseaseList.length', diseaseList.length);
    return (
      <Paper>
        <List component="nav" aria-label="faq category list">
          {/*<ListItem>*/}
          {/*  <ListItemText primary="FAQ Category" />*/}
          {/*</ListItem>*/}
          {/*<Divider />*/}
          {categoryList
            ? categoryList.map((cate) => {
                return (
                  <ListItem
                    key={cate.id}
                    button
                    selected={selectedCategoryId ? cate.id === selectedCategoryId : false}
                    onClick={(event) => handleListItemClick(event, cate.id)}
                  >
                    <ListItemText id={cate.id} primary={cate[getDefName()]} />
                  </ListItem>
                );
              })
            : ''}
        </List>
      </Paper>
    );
  };

  return (
    <OftadehLayout>
      <Grid container className={classes.my3} alignItems="center">
        <Grid item className={classes.mRight} style={{ fontWeight: 'bold' }}>
          <Typography variant="h5" component="h1" style={{ fontWeight: 'bold' }}>
            FAQ
          </Typography>
        </Grid>
        <Grid item>
          <Button onClick={() => history.push('/pages/setting/faq/detail?type=new')} variant="outlined" color="primary" size="small">
            Registration
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={3}>
          <LeftList />
        </Grid>
        <Grid item xs={9}>
          <ButtonGroup color="primary" disableElevation style={{ marginBottom: '20px' }}>
            {languages.map((lang) => (
              <Button size="small" variant={selectedLang === lang.id ? 'contained' : 'outlined'} onClick={() => handleFilter(lang.id)}>
                {lang[getDefName()]}
              </Button>
            ))}
          </ButtonGroup>
          <MUIDataTable title={''} data={data} columns={cols} options={options} />
        </Grid>
      </Grid>
      {loading && (
        <div style={{ position: 'fixed', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', zIndex: 999 }}>
          <CircularProgress color={'secondary'} />
        </div>
      )}
    </OftadehLayout>
  );
};

export default SettingFaqListPage;
