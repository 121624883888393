import DashboardPage from './DashboardPage';
import NoticeListPage from '../notice/NoticeListPage';

export const DashboardPageConfig = {
  routes: [
    {
      path: '/',
      exact: true,
      component: sessionStorage.getItem('bt_role') === 'SYS_MGR' ? NoticeListPage : DashboardPage,
    },
  ],
};
