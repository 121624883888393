import React, { useEffect, useRef, useState } from 'react';
import queryString from 'query-string';
import OftadehLayout from '../../components/OftadehLayout/OftadehLayout';
import { Button, Dialog, Divider, Grid, IconButton, makeStyles, Typography } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import swal from '@sweetalert/with-react';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import SaveIcon from '@material-ui/icons/Save';
import ListIcon from '@material-ui/icons/List';
import DeleteIcon from '@material-ui/icons/Delete';
import { getAnswerNestedList, getConsultInfo, getHospitalList, getSubDiseaseAll } from '../../constants/PromiseHttp';
import axiosInstance from '../../constants/AxiosInstance';
import { API_URL, getDefName } from '../../constants';
import AnswerComponent, { AnswerCard } from '../customComp/AnswerComponent';
import FileUpload from '../../components/FileUpload/FileUpload';
import { AddCircle, RemoveCircle } from '@material-ui/icons';
import ImgListView from '../customComp/ImgListView';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    color: '#3f51b5',
  },
  my3: {
    margin: '1.3rem 0',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  mRight: {
    marginRight: '.85rem',
  },
  mInput: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
  textArea: {
    width: '98%',
    minHeight: 300,
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    borderColor: 'lightgray',
  },
}));

const ConsultDetailPage = (props) => {
  const query = queryString.parse(props.location.search);

  const [consult, setConsult] = useState();
  const [subDiseases, setSubDiseases] = useState();
  const [hospitalList, setHospitalList] = useState([]);

  const [answers, setAnswers] = useState();
  const [editAnswerId, setEditAnswerId] = useState('');
  const [parentAnswerId, setParentAnswerId] = useState('');
  const [answerDlgOpen, setAnswerDlgOpen] = useState(false);

  const [uploadFile, setUploadFile] = useState([]);
  const childRef = useRef(null);
  const imgKey = 'estimate';

  const { history } = props;
  const classes = useStyles();

  useEffect(() => {
    getData();
    getSubDiseaseAll().then((result) => {
      setSubDiseases(result);
    });
    getHospitalList().then((result) => {
      setHospitalList(result);
    });
  }, []);

  const getData = () => {
    if (query.id) {
      getConsultInfo(query.id).then((result) => {
        setConsult(result);
        setParentAnswerId('');
        setEditAnswerId('');
        getAnswerNestedList(result.id).then((res) => {
          console.log('nested list:', res);
          setAnswers(res);
          if ((!res || res.length === 0) && result.status === 'complete') {
            setTimeout(updateStatus('request'), 1000);
          }
        });
        if (childRef.current) {
          childRef.current.getSignedUrl(result[imgKey]);
        }
      });
    }
  };

  const deleteConsult = () => {
    swal({
      title: 'Are you sure you want to delete?',
      text: '',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        axiosInstance.delete(`${API_URL}/consult/${query.id}`, { headers: { Pragma: 'no-cache' } }).then((response) => {
          if (response.status === 200) {
            goList();
            swal('It is deleted.', {
              icon: 'success',
            });
          }
        });
      } else {
      }
    });
  };

  const deleteAnswer = (answerId) => {
    axiosInstance.delete(`${API_URL}/answer/${answerId}`, { headers: { Pragma: 'no-cache' } }).then((response) => {
      if (response.status === 200) {
        swal('Answer has been deleted.', {
          icon: 'success',
        });
        handleAnswerDlgClose(true);
      }
    });
  };

  const openAddAnswer = (pid) => {
    console.log('DetailPage::openAddAnswer :', pid);
    setParentAnswerId(pid);
    setAnswerDlgOpen(true);
  };

  const openEditAnswer = (aid) => {
    console.log('DetailPage::openEditAnswer :', aid);
    setEditAnswerId(aid);
    setAnswerDlgOpen(true);
  };

  const handleAnswerDlgOpen = () => {
    setParentAnswerId('');
    setEditAnswerId('');
    setAnswerDlgOpen(true);
  };

  const handleAnswerDlgClose = (reload) => {
    if (reload) {
      getData();
      updateStatus('complete');
    }
    setParentAnswerId('');
    setEditAnswerId('');
    setAnswerDlgOpen(false);
  };

  const updateStatus = (status) => {
    if (consult && (!consult.status || consult.status === 'request')) {
      const params = {
        id: consult.id,
        status: status,
      };
      axiosInstance.put(`${API_URL}/consult`, params, { headers: { Pragma: 'no-cache' } }).then((response) => {
        console.log('updateStatus response : ', response.statusText);
      });
    }
  };

  const doUpload = () => {
    if (childRef.current && uploadFile.length > 0) {
      childRef.current.fileUpload(uploadFile, query.id, 'consult', `${API_URL}/consult/`).then(() => {
        setUploadFile([]);
        swal('Registered.', {
          icon: 'success',
        });
      });
    }
  };
  const handleFile = (file, key) => {
    console.log(file, key);
    setUploadFile([...file]);
  };
  const handleUpload = () => {
    console.log('Upload Success');
    setTimeout(getData, 1000);
  };
  const handleRemove = () => {
    console.log('Remove Success');
    getData();
  };

  const goList = () => {
    history.push('/pages/consult/');
  };

  const goAppend = () => {
    history.push(`/pages/consult/edit?type=new&id=${query.id}`);
  };
  const goEdit = () => {
    history.push(`/pages/consult/edit?type=edit&id=${query.id}`);
  };

  const getDiseaseNames = (ids) => {
    let names = '';
    if (!ids || !subDiseases) {
      return names;
    }

    for (let i = 0; i < ids.length; i++) {
      let sdi = subDiseases.find((_sdi) => _sdi.id === ids[i]);
      if (i > 0) names += ', ';
      names += sdi[getDefName()];
    }
    return names;
  };

  const getHospitalName = (hid) => {
    const hospital = hospitalList && hospitalList.find((hos) => hos.id === hid);
    console.log(hospitalList);
    console.log(hospital);
    console.log(consult);
    return hospital ? hospital['name_ko'] : consult && consult.selectAgency ? '-' : 'No Select';
  };

  const canLeave = () => {
    console.log('canLeave', uploadFile);
    if (uploadFile && uploadFile.length > 0) {
      swal('Please upload a file.', {
        icon: 'warning',
      });
      return false;
    }
    return true;
  };

  return (
    <OftadehLayout>
      <Grid container className={classes.my3} alignItems="center">
        <Grid item className={classes.mRight} style={{ fontWeight: 'bold' }}>
          <Typography variant="h5" component="h1" style={{ fontWeight: 'bold' }}>
            Consulting Detail
          </Typography>
        </Grid>
        {/*<Grid item>*/}
        {/*  {consult && !consult.parentId && (*/}
        {/*    <Button*/}
        {/*      variant="outlined"*/}
        {/*      color="primary"*/}
        {/*      size="medium"*/}
        {/*      style={{ marginRight: '10px' }}*/}
        {/*      onClick={(e) => {*/}
        {/*        canLeave() && goAppend();*/}
        {/*      }}*/}
        {/*      startIcon={<AddCircle />}*/}
        {/*    >*/}
        {/*      Append Consult*/}
        {/*    </Button>*/}
        {/*  )}*/}
        {/*</Grid>*/}
      </Grid>
      <div>
        <Card className={classes.root}>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} mb={12}>
                <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                  Basic Info
                </Typography>
              </Grid>
              <Grid item xs={4} mb={6}>
                <div className={classes.title}>Language</div>
                <Typography variant="body1">{(consult && consult.languageId) || ''}</Typography>
              </Grid>
              <Grid item xs={4} mb={6}>
                <div className={classes.title}>Country</div>
                <Typography variant="body1">{(consult && consult.countryId) || ''}</Typography>
              </Grid>
              <Grid item xs={4} mb={6}>
                <div className={classes.title}>Show/Hide</div>
                <Typography variant="body1">{consult && consult.hidden ? 'Hide' : 'Show'}</Typography>
              </Grid>
            </Grid>
            <Divider style={{ marginTop: '10px' }} />
            <Grid container spacing={3} style={{ marginTop: '10px' }}>
              <Grid item xs={12} mb={12}>
                <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                  Patient Info
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <div className={classes.title}>Name</div>
                <Typography variant="body1">{(consult && consult.userName) || ''}</Typography>
              </Grid>
              {consult && consult.patientsName && (
                <Grid item xs={4}>
                  <div className={classes.title}>Patient Name</div>
                  <Typography variant="body1">{(consult && (consult.patientsName || consult.userName)) || ''}</Typography>
                </Grid>
              )}
              <Grid item xs={4}>
                <div className={classes.title}>Email</div>
                <Typography variant="body1">{(consult && consult.email) || ''}</Typography>
              </Grid>

              <Grid item xs={4}>
                <form noValidate>
                  <div className={classes.title}>Birth date</div>
                  <Typography variant="body1">{(consult && consult.birth) || ''}</Typography>
                </form>
              </Grid>
              <Grid item xs={4}>
                <div className={classes.title}>Phone number</div>
                <Typography variant="body1">{(consult && consult.phoneNumber) || ''}</Typography>
              </Grid>
              <Grid item xs={4}>
                <div className={classes.title}>Gender</div>
                <Typography variant="body1">{(consult && consult.gender) || ''}</Typography>
              </Grid>
              <Grid item xs={4} mb={6}>
                <div className={classes.title}>Preferred institution</div>
                <Typography variant="body1">{(consult && getHospitalName(consult.hospitalId)) || ''}</Typography>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.title}>Tags</div>
                <Typography variant="body1">{(consult && consult.tags) || ''}</Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Card className={classes.my3}>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} mb={12}>
                <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                  Question
                </Typography>
              </Grid>
              <Divider variant={'fullWidth'} />
              <Grid item xs={12}>
                <div className={classes.title}>Title</div>
                <Typography variant="body1">{(consult && consult.title) || ''}</Typography>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.title}>Contents</div>
                <Typography variant="body1">{(consult && consult.contents) || ''}</Typography>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.title}>Diagnosis</div>
                <Typography variant="body1">{(consult && consult.diagnosis) || ''}</Typography>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.title}>Medical History</div>
                <Typography variant="body1">{(consult && consult.medicalHistory) || ''}</Typography>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.title}>Symptoms</div>
                <Typography variant="body1">{(consult && consult.symtoms) || ''}</Typography>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.title}>Taking medication</div>
                <Typography variant="body1">{(consult && consult.medicine) || ''}</Typography>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.title}>Disease</div>
                <Typography variant="body1">{getDiseaseNames(consult && consult.subDiseaseIds)}</Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Card>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} mb={12}>
                <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                  Attachments
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <div className={classes.title}>Diagnosis, Opinion, Test result sheet, etc. </div>
                {consult && <ImgListView images={consult && consult.statement} />}
              </Grid>
              <Grid item xs={4}>
                <div className={classes.title}>Photo Material (JPG, PNG, PDF, GIF) </div>
                {consult && <ImgListView images={consult && consult.photo} />}
              </Grid>
              <Grid item xs={4}>
                <div className={classes.title}>DICOM File</div>
                {consult && <ImgListView images={consult && consult.dicom} />}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Card className={classes.my3}>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} mb={12}>
                <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                  Estimation
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <FileUpload
                  type={'file'}
                  ref={childRef}
                  accept={'application/pdf, image/*'}
                  buttonText={'upload'}
                  onFileHandler={handleFile}
                  onRemoveAfter={handleRemove}
                  onUploadAfter={handleUpload}
                  doUpload={doUpload}
                  fileId={query.id}
                  folder={'consult'}
                  apiUrl={`${API_URL}/consult/`}
                  uploadKey={imgKey}
                  maxCount={3}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Grid container spacing={2} className={classes.my3} alignItems="center">
          <Grid item>
            <Typography variant="h5" component="h1" style={{ fontWeight: 'bold' }}>
              Answer
            </Typography>
          </Grid>
          <Grid item>
            <Button variant="outlined" color="primary" onClick={handleAnswerDlgOpen}>
              Register answer
            </Button>

            <Dialog fullWidth open={answerDlgOpen} onClose={handleAnswerDlgClose} aria-labelledby="customized-dialog-title">
              <AnswerComponent
                targetId={query.id}
                targetInfo={
                  consult && {
                    email: consult.email ? consult.email : consult.userId,
                    languageId: consult.languageId,
                    status: consult.status,
                  }
                }
                parentId={parentAnswerId}
                answerId={editAnswerId}
                handleClose={handleAnswerDlgClose}
              />
            </Dialog>
          </Grid>
        </Grid>
        <Card>
          <CardContent>
            {consult &&
              answers &&
              answers.map((answer, idx) => (
                <AnswerCard
                  key={`answercard-${answer.id}`}
                  answer={answer}
                  idx={idx}
                  depth={0}
                  handleChild={openAddAnswer}
                  handleEdit={openEditAnswer}
                  handleDelete={deleteAnswer}
                />
              ))}
          </CardContent>
        </Card>
      </div>
      <div className={classes.my3}>
        <Grid container xs={12} md={12} spacing={3}>
          <Grid item>
            <Button
              variant="contained"
              style={{ backgroundColor: green[700], color: '#ffffff', marginRight: '10px' }}
              size="medium"
              onClick={(e) => {
                canLeave() && goList();
              }}
              startIcon={<ListIcon />}
            >
              List
            </Button>
            <Button variant="contained" color="secondary" size="medium" onClick={deleteConsult} startIcon={<DeleteIcon />}>
              Delete
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              size="medium"
              onClick={(e) => {
                canLeave() && goEdit();
              }}
              startIcon={<SaveIcon />}
            >
              Edit
            </Button>
          </Grid>
        </Grid>
      </div>
    </OftadehLayout>
  );
};

export default ConsultDetailPage;
