import React, { useEffect, useState } from 'react';

import queryString from 'query-string';
import OftadehLayout from '../../components/OftadehLayout/OftadehLayout';
import { Button, Grid, makeStyles, Typography } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import swal from '@sweetalert/with-react';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import SaveIcon from '@material-ui/icons/Save';
import ListIcon from '@material-ui/icons/List';
import DeleteIcon from '@material-ui/icons/Delete';
import axiosInstance from '../../constants/AxiosInstance';
import { API_URL, DAY_PART_TIME, getDefName } from '../../constants';
import { getDiseaseList, getDoctorInfo, getHospitalList, getLanguageList, getSubDiseaseAll } from '../../constants/PromiseHttp';
import ModalImage from 'react-modal-image';
import { Add } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    color: '#3f51b5',
  },
  my3: {
    margin: '1.3rem 0',
  },
  mRight: {
    marginRight: '.85rem',
  },
  mInput: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
  textArea: {
    width: '100%',
    padding: theme.spacing(2),
    minHeight: 300,
    borderColor: 'lightgray',
    margin: theme.spacing(1),
    marginTop: 10,
  },
  searchBtn: {
    marginTop: '15px',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

const RecDoctorDetailPage = (props) => {
  const query = queryString.parse(props.location.search);
  const type = query.type ? query.type : 'new';
  const IS_SYS_ADMIN = sessionStorage.getItem('bt_role') === 'SYS_ADM';

  const [doctor, setDoctor] = useState({});
  const [languages, setLanguages] = useState([]);
  const [hospitalList, setHospitalList] = useState([]);
  const [subDiseases, setSubDiseases] = useState();
  const [diseases, setDiseases] = useState();

  const { history } = props;
  const classes = useStyles();

  const [isWriter, setIsWriter] = useState(false);

  useEffect(() => {
    getData();
    getLanguageList().then((result) => {
      setLanguages(result);
    });
    getDiseaseList().then((result) => {
      setDiseases(result);
    });
    getSubDiseaseAll().then((result) => {
      setSubDiseases(result);
    });
    getHospitalList().then((result) => {
      setHospitalList(result);
    });
  }, []);

  const getData = () => {
    if (type === 'edit' && query.id) {
      getDoctorInfo(query.id).then((result) => {
        setDoctor(result);
        if (result.writerId) {
          setIsWriter(result.writerId === sessionStorage.getItem('bt_user'));
        }
      });
    }
  };

  const deleteDoctor = () => {
    swal({
      title: 'Are you sure you want to delete?',
      text: '',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        axiosInstance.delete(`${API_URL}/doctor/${query.id}`, { headers: { Pragma: 'no-cache' } }).then((response) => {
          if (response.status === 200) {
            goList();
            swal('It is deleted.', {
              icon: 'success',
            });
          }
        });
      } else {
      }
    });
  };

  const goList = () => {
    history.push('/pages/recDoctor/');
  };

  const goEdit = () => {
    history.push(`/pages/recDoctor/edit?type=edit&id=${query.id}`);
  };

  const clone = () => {
    history.push(`/pages/recDoctor/edit?type=clone&id=${query.id}`);
  };

  const getDiseaseName = (id) => {
    let names = '';
    if (!id || !diseases) {
      return names;
    }
    let di = diseases.find((_di) => _di.id === id);
    names = di[getDefName()];
    return names;
  };

  const getSubDiseaseNames = (ids) => {
    let names = '';
    if (!ids || !subDiseases) {
      return names;
    }

    for (let i = 0; i < ids.length; i++) {
      let sdi = subDiseases.find((_sdi) => _sdi.id === ids[i]);
      if (i > 0) names += ', ';
      names += sdi[getDefName()];
    }
    return names;
  };

  const getSchedule = (schedule) => {
    let text = '';
    if (!schedule) {
      return text;
    }

    if (schedule['mon'] && schedule['mon'] != DAY_PART_TIME[3].id) {
      text += '[mon : ';
      text += schedule['mon'];
      text += ']\n';
    }
    if (schedule['tue'] && schedule['tue'] != DAY_PART_TIME[3].id) {
      text += '[tue : ';
      text += schedule['tue'];
      text += ']\n';
    }
    if (schedule['wed'] && schedule['wed'] != DAY_PART_TIME[3].id) {
      text += '[wed : ';
      text += schedule['wed'];
      text += ']\n';
    }
    if (schedule['thu'] && schedule['thu'] != DAY_PART_TIME[3].id) {
      text += '[thu : ';
      text += schedule['thu'];
      text += ']\n';
    }
    if (schedule['fri'] && schedule['fri'] != DAY_PART_TIME[3].id) {
      text += '[fri : ';
      text += schedule['fri'];
      text += ']\n';
    }

    return newLineText(text);
  };

  const getHospitalName = (hid) => {
    const hospital = hospitalList && hospitalList.find((hos) => hos.id === hid);
    return hospital ? hospital['name_ko'] : '-';
  };
  const newLineText = (text) => {
    //console.log(text);
    if (!text || !text.includes('\n')) return text;
    return text.split('\n').map((line, i) => {
      return (
        <span key={`line_${i}`}>
          {line}
          <br />
        </span>
      );
    });
  };
  return (
    <OftadehLayout>
      <Grid container className={classes.my3} alignItems="center">
        <Grid item className={classes.mRight} style={{ fontWeight: 'bold' }}>
          <Typography variant="h5" component="h1" style={{ fontWeight: 'bold' }}>
            Doctor Detail
          </Typography>
        </Grid>
        <Grid item>
          <Button variant="outlined" color="primary" size="medium" onClick={clone} startIcon={<Add />}>
            clone
          </Button>
        </Grid>
      </Grid>

      <div>
        <Card className={classes.root}>
          <CardContent>
            <Grid container spacing={3}>
              <Grid container spacing={3} justifyContent={'left'} alignItems={'left'} className={classes.my3}>
                <Grid item xs={6} md={4}>
                  <ModalImage
                    small={doctor.image && doctor.image.url}
                    large={doctor.image && doctor.image.url}
                    alt={doctor.image && doctor.image.name}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Grid container spacing={3}>
                    <Grid item xs={6} mb={6}>
                      <div className={classes.title}>Language</div>
                      <Typography variant="body1">{(doctor && doctor.languageId) || ''}</Typography>
                    </Grid>
                    <Grid item xs={6} mb={6}>
                      <div className={classes.title}>Affiliated hospital</div>
                      <Typography variant="body1">{(doctor && getHospitalName(doctor.hospitalId)) || ''}</Typography>
                    </Grid>
                    <Grid item xs={6} mb={6}>
                      <div className={classes.title}>Doctor Name</div>
                      <Typography variant="body1">{(doctor && doctor.name_ko) || ''}</Typography>
                    </Grid>
                    <Grid item xs={6} mb={6}>
                      <div className={classes.title}>Doctor Name (Another Language)</div>
                      <Typography variant="body1">{(doctor && doctor.name_en) || ''}</Typography>
                    </Grid>
                    <Grid item xs={12} mb={6}>
                      <div className={classes.title}>Introduce</div>
                      <Typography variant="body1">{(doctor && doctor.desc) || ''}</Typography>
                    </Grid>
                    <Grid item xs={6} mb={6}>
                      <div className={classes.title}>Treatment subject</div>
                      <Typography variant="body1">{(doctor && getDiseaseName(doctor.diseaseId)) || ''}</Typography>
                    </Grid>
                    <Grid item xs={6} mb={6}>
                      <div className={classes.title}>Specialty</div>
                      <Typography variant="body1">{(doctor && doctor.subject) || ''}</Typography>
                    </Grid>
                    <Grid item xs={6} mb={6}>
                      <div className={classes.title}>Tags</div>
                      <Typography variant="body1">{(doctor && doctor.tags) || ''}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} mb={6}>
                <div className={classes.title}>Related disease</div>
                <Typography variant="body1">{(doctor && getSubDiseaseNames(doctor.subDiseaseIds)) || ''}</Typography>
              </Grid>
              <Grid item xs={12} mb={12}>
                <Typography className={classes.title}>Treatment schedule</Typography>
                <Typography variant="body1">{(doctor && getSchedule(doctor.schedule)) || ''}</Typography>
              </Grid>
              <Grid item xs={12} mb={6}>
                <div className={classes.title}>Education</div>
                <Typography variant="body1">{newLineText(doctor && doctor.education) || ''}</Typography>
              </Grid>
              <Grid item xs={12} mb={6}>
                <div className={classes.title}>Career</div>
                <Typography variant="body1">{newLineText(doctor && doctor.career) || ''}</Typography>
              </Grid>
              <Grid item xs={12} mb={6}>
                <div className={classes.title}>Activity</div>
                <Typography variant="body1">{newLineText(doctor && doctor.activity) || ''}</Typography>
              </Grid>
              <Grid item xs={12} mb={6}>
                <div className={classes.title}>Monograph</div>
                <Typography variant="body1">{newLineText(doctor && doctor.paper) || ''}</Typography>
              </Grid>
              <Grid item xs={4} mb={6}>
                <div className={classes.title}>Show/Hide</div>
                <Typography variant="body1">{doctor && doctor.hidden ? 'Hide' : 'Show'}</Typography>
              </Grid>
            </Grid>
          </CardContent>
          <CardActions style={{ marginLeft: '16px', marginRight: '16px' }}>
            <Grid container justifyContent={'space-between'} xs={12} md={12} spacing={3}>
              <Grid item>
                <Button
                  variant="contained"
                  style={{ backgroundColor: green[700], color: '#ffffff', marginRight: '10px' }}
                  size="medium"
                  onClick={goList}
                  startIcon={<ListIcon />}
                >
                  List
                </Button>
                {/*{(IS_SYS_ADMIN || isWriter) && (*/}
                <Button variant="contained" color="secondary" size="medium" onClick={deleteDoctor} startIcon={<DeleteIcon />}>
                  Delete
                </Button>
                {/*)}*/}
              </Grid>
              {/*{(IS_SYS_ADMIN || isWriter) && (*/}
              <Grid item>
                <Button variant="contained" color="primary" size="medium" onClick={goEdit} startIcon={<SaveIcon />}>
                  Edit
                </Button>
              </Grid>
              {/*)}*/}
            </Grid>
          </CardActions>
        </Card>
      </div>
    </OftadehLayout>
  );
};

export default RecDoctorDetailPage;
