import SearchUserPage from './SearchUserPage';

export const SearchUserPageConfig = {
    routes: [
        {
            path: '/pages/search',
            exact: true,
            component: SearchUserPage,
        },
    ],
};
