import React, { useEffect, useRef, useState } from 'react';
import queryString from 'query-string';
import OftadehLayout from '../../../components/OftadehLayout/OftadehLayout';
import { Button, Divider, Grid, List, ListItem, makeStyles, TextField, Typography } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import swal from '@sweetalert/with-react';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import SaveIcon from '@material-ui/icons/Save';
import ListIcon from '@material-ui/icons/List';
import DeleteIcon from '@material-ui/icons/Delete';
import { getBannerInfo } from '../../../constants/PromiseHttp';
import { API_URL, DEF_LANGUAGE } from '../../../constants';
import axiosInstance from '../../../constants/AxiosInstance';
import LanguageAutocomplete from '../../customComp/LanguageAutocomplete';
import AddBannerItemView from './AddBannerItemView';
import IconButton from '@material-ui/core/IconButton';
import moment from 'moment';
import { fileUpload } from '../../../components/FileUpload/DropZone';
import awsconfig from '../../../aws-exports';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  my3: {
    margin: '1.3rem 0',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  divider: {
    marginTop: '10px',
    marginBottom: '10px',
  },
  mRight: {
    marginRight: '.85rem',
  },
  mInput: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
    display: 'flex',
    flexWrap: 'wrap',
  },
}));

const SettingBannerDetailPage = (props) => {
  const query = queryString.parse(props.location.search);
  const type = query.type ? query.type : 'new';
  const bannerItemViewRef = useRef(null);

  const [banner, setBanner] = useState({ languageId: DEF_LANGUAGE });

  const { history } = props;
  const classes = useStyles();

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    if (type === 'edit' && query.id) {
      getBannerInfo(query.id).then((result) => {
        setBanner(result);
      });
    }
  };

  const addBanner = () => {
    //console.log(banner);

    if (!banner.title) {
      swal('Missing required title.');
      return;
    }
    if (!banner.languageId) {
      swal('Missing required language, Select please.');
      return;
    }
    if (!banner.startDate) {
      swal('Missing required start date.');
      return;
    }
    if (!banner.endDate) {
      swal('Missing required end date.');
      return;
    }

    if (!banner.items || banner.items.length < 1) {
      swal('Missing required banner info, please, Add banner infomation.');
      return;
    }

    let sDate = new Date(banner.startDate).getTime();
    let eDate = new Date(banner.endDate).getTime();
    if (sDate > eDate) {
      swal('The end date cannot be earlier than the start date.');
      return;
    }

    const attFiles = [];
    let items = [];

    banner.items &&
      banner.items.map((_item) => {
        if (_item.file && _item.file.obj.name) {
          attFiles.push({ id: banner.id, key: `${_item.file.id}`, file: _item.file.obj });
        }
        _item.file.path = {};
        _item.file.obj = {};
        items = [...items, _item];
      });
    let param = {
      languageId: banner.languageId || DEF_LANGUAGE,
      title: banner.title,
      startDate: sDate ? sDate.toString() : banner.startDate,
      endDate: eDate ? eDate.toString() : banner.endDate,
      sortOrder: banner.sortOrder || 0,
      updateDate: new Date().getTime().toString(),
      items: items,
    };

    if (type === 'new') {
      param['id'] = banner.id;

      axiosInstance.post(`${API_URL}/banner/`, param, { headers: { Pragma: 'no-cache' } }).then(async (response) => {
        if (response.status === 200) {
          if (attFiles.length !== 0) {
            console.log(attFiles);
            await Promise.all([
              await fileUpload(attFiles, response.data.value.id, 'banner', `${API_URL}/banner/`).then((r) => {
                if (response.status === 200) {
                  console.log('Banner image upload success : ', response.data.value.id);
                } else {
                  console.log('Banner image upload failed : ', response.data.value.id);
                }
              }),
            ]).then(() => {
              swal('Registered.', {
                icon: 'success',
              });
              goList();
            });
          } else {
            swal('Registered.', {
              icon: 'success',
            });
            goList();
          }
        }
      });
    } else {
      param['id'] = query.id;

      axiosInstance.put(`${API_URL}/banner/`, param, { headers: { Pragma: 'no-cache' } }).then(async (response) => {
        if (response.status === 200) {
          if (attFiles.length !== 0) {
            await Promise.all([
              await fileUpload(attFiles, query.id, 'banner', `${API_URL}/banner/`).then((r) => {
                if (response.status === 200) {
                  console.log('Banner image upload success : ', query.id);
                } else {
                  console.log('Banner image upload failed : ', query.id);
                }
              }),
            ]).then(() => {
              swal('It is changed.', {
                icon: 'success',
              });
              goList();
            });
          } else {
            swal('It is changed.', {
              icon: 'success',
            });
            goList();
          }
        }
      });
    }
  };

  const deleteBanner = () => {
    swal({
      title: 'Are you sure you want to delete?',
      text: '',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        axiosInstance.delete(`${API_URL}/banner/${query.id}`, { headers: { Pragma: 'no-cache' } }).then((response) => {
          if (response.status === 200) {
            goList();
            swal('It is deleted.', {
              icon: 'success',
            });
          }
        });
      } else {
      }
    });
  };

  const addItem = (obj, isEdit) => {
    //console.log('addItem > ', isEdit, obj);

    const item = {
      textAlignment: obj.textAlignment || 'left',
      mainText: obj.mainText || '',
      topText: obj.topText || '',
      bottomText: obj.bottomText || '',
      buttonText: obj.buttonText || '',
      buttonLink: obj.buttonLink || '',
      buttonTarget: obj.buttonTarget || 'popup',
      file: obj.file,
    };

    if (!isEdit) {
      item['id'] = 'item_' + new Date().getTime().toString();
      if (banner.items && banner.items.length > 0) {
        banner.items.push(item);
      } else {
        banner['items'] = [item];
      }
    } else {
      item['id'] = obj.id ? obj.id : 'item_' + new Date().getTime().toString();
      let itemIdx = banner.items.findIndex((_item) => _item.id === obj.id);
      banner.items[itemIdx] = item;
    }
    setBanner({ ...banner });
  };

  const removeItem = (event, itemId, idx) => {
    if (banner.items[idx].file) {
      removeStorageFile(banner.items[idx].file).then((r) => {
        banner.items.splice(idx, 1);
        setBanner({ ...banner });
      });
    } else {
      banner.items.splice(idx, 1);
      setBanner({ ...banner });
    }

    if (bannerItemViewRef.current) {
      bannerItemViewRef.current.reset();
    }

    event.stopPropagation();
  };

  const removeStorageFile = async (file) => {
    if (file) {
      const param = {
        bucket: awsconfig.aws_user_files_s3_bucket,
        key: file.key,
      };
      const url = encodeURI(`${API_URL}/banner/files/` + param.bucket + '?key=' + param.key + '&id=' + file.id + '&uploadKey=' + banner.id);
      axiosInstance.delete(url).then((res) => {
        return;
      });
    }
  };

  const goList = () => {
    history.push('/pages/setting/banner/');
  };

  const handleChangeData = (value, field) => {
    banner[field] = value;
    setBanner({ ...banner });
    //console.log(banner);
  };

  const handleItemChangeData = (value, itemIdx, field) => {
    banner.items[itemIdx][field] = value;
    setBanner({ ...banner });
  };

  const handleListItemClick = (itemId) => {
    banner.items.map((_item) => {
      if (_item.id === itemId) {
        if (bannerItemViewRef.current) {
          bannerItemViewRef.current.editItem(_item);
        }
      }
    });
  };

  const getLocalDate = (date) => {
    if (date && date.includes('T')) {
      return date;
    }
    const dt = date ? new Date(Number(date)) : '';
    const utcT = moment(dt).format('YYYY-MM-DDThh:mm');
    return utcT;
  };

  const getImageSource = (item) => {
    return item.file ? (item.file.url ? item.file.url : item.file.path) : '';
  };

  return (
    <OftadehLayout>
      <Grid container className={classes.my3} alignItems="center">
        <Grid item className={classes.mRight} style={{ fontWeight: 'bold' }}>
          <Typography variant="h5" component="h1" style={{ fontWeight: 'bold' }}>
            Banner {type === 'new' ? 'Registration' : 'Edit'}
          </Typography>
        </Grid>
      </Grid>

      <div className={classes.root}>
        <Card>
          <CardContent>
            <Grid container spacing={3} className={classes.container}>
              <Grid item xs={12} mb={12}>
                <Typography variant="h6">기본 정보</Typography>
              </Grid>
            </Grid>
            <Divider className={classes.divider} />
            <Grid container spacing={3} className={classes.container}>
              <Grid item xs={6}>
                <Typography style={{ marginTop: 10 }}>
                  Language <span style={{ color: 'red' }}>*</span>
                </Typography>
                <LanguageAutocomplete
                  target={banner}
                  handleChangeData={(param) => {
                    handleChangeData(param, 'languageId');
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography style={{ marginTop: 10 }}>
                  Title <span style={{ color: 'red' }}>*</span>
                </Typography>
                <TextField
                  key={`TextField-title`}
                  className={classes.mInput}
                  placeholder=""
                  InputLabelProps={{
                    shrink: true,
                  }}
                  size="small"
                  variant="outlined"
                  value={banner.title || ''}
                  onChange={(event) => {
                    handleChangeData(event.target.value, 'title');
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography>
                  시작일 <span style={{ color: 'red' }}>*</span>
                </Typography>
                <form className={classes.container} noValidate>
                  <TextField
                    id="date-start"
                    label=""
                    type="datetime-local"
                    className={classes.mInput}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={getLocalDate(banner.startDate)}
                    size="small"
                    variant={'outlined'}
                    onChange={(e) => handleChangeData(e.target.value, 'startDate')}
                  />
                </form>
              </Grid>
              <Grid item xs={6}>
                <Typography>
                  종료일 <span style={{ color: 'red' }}>*</span>
                </Typography>
                <form className={classes.container} noValidate>
                  <TextField
                    id="date-end"
                    label=""
                    type="datetime-local"
                    className={classes.mInput}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={getLocalDate(banner.endDate)}
                    size="small"
                    variant={'outlined'}
                    onChange={(e) => handleChangeData(e.target.value, 'endDate')}
                  />
                </form>
              </Grid>
            </Grid>
            <Divider className={classes.divider} />
            <Grid container spacing={3} className={classes.container}>
              <Grid item xs={12}>
                <List dense>
                  {banner.items &&
                    banner.items.map((item, idx) => {
                      return (
                        <ListItem key={'file-' + idx} onClick={(e) => handleListItemClick(item.id)}>
                          <Grid container spacing={2} alignItems={'center'} style={{ marginTop: 5 }}>
                            <Grid item xs={1}>
                              {idx + 1}
                            </Grid>
                            <Grid item xs={10}>
                              <img
                                src={getImageSource(item)}
                                height={150}
                                alt={'file-' + idx}
                                onError={(e) => {
                                  e.target.src = 'https://via.placeholder.com/60.png?text=noimage';
                                }}
                              />
                            </Grid>
                            {/*<Grid item>*/}
                            {/*  순서 :*/}
                            {/*  <TextField*/}
                            {/*    type={'number'}*/}
                            {/*    key={'TextField-sortorder-' + idx}*/}
                            {/*    className={classes.mInput}*/}
                            {/*    placeholder=""*/}
                            {/*    InputLabelProps={{*/}
                            {/*      shrink: true,*/}
                            {/*    }}*/}
                            {/*    size="small"*/}
                            {/*    variant="outlined"*/}
                            {/*    value={banner.items[idx].sortOrder || ''}*/}
                            {/*    onChange={(event) => {*/}
                            {/*      handleItemChangeData(event.target.value, idx, 'sortOrder');*/}
                            {/*    }}*/}
                            {/*  />*/}
                            {/*</Grid>*/}
                            <Grid item xs={1}>
                              <IconButton aria-label="delete" key={'btn-' + idx}>
                                <DeleteIcon fontSize="small" onClick={(e) => removeItem(e, item.id, idx)} />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </ListItem>
                      );
                    })}
                </List>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <Card className={classes.my3}>
          <CardContent>
            <AddBannerItemView ref={bannerItemViewRef} addItem={addItem} removeStorageFile={removeStorageFile} />
          </CardContent>
        </Card>
      </div>
      <Grid container justifyContent={'space-between'} xs={12} md={12} spacing={3}>
        <Grid item>
          <Button
            variant="contained"
            style={{ backgroundColor: green[700], color: '#ffffff', marginRight: '10px' }}
            size="medium"
            onClick={goList}
            startIcon={<ListIcon />}
          >
            List
          </Button>
          {type !== 'new' ? (
            <Button variant="contained" color="secondary" size="medium" onClick={deleteBanner} startIcon={<DeleteIcon />}>
              Delete
            </Button>
          ) : null}
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" size="medium" onClick={addBanner} startIcon={<SaveIcon />}>
            {type === 'new' ? 'Registration' : 'Save'}
          </Button>
        </Grid>
      </Grid>
    </OftadehLayout>
  );
};

export default SettingBannerDetailPage;
