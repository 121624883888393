import React, { useEffect, useRef, useState } from 'react';
import { Button, List, ListItem, ListItemText, makeStyles, TextField, Typography } from '@material-ui/core';
import { searchByUserName } from '../../constants/PromiseHttp';
import Grid from 'antd/es/card/Grid';
import * as PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '.85rem',
    borderRadius: 5,
    background: '#fff',
  },
  mBottom: {
    marginBottom: '.5rem',
  },
  button: {
    marginTop: '.85rem',
  },
}));

function ListItemButton(props) {
  return null;
}

ListItemButton.propTypes = {
  dense: PropTypes.bool,
  onClick: PropTypes.any,
  role: PropTypes.any,
  children: PropTypes.node,
};
const SearchUserPage = (props) => {
  const classes = useStyles();

  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});
  const valueRef = useRef('');

  const handleListItemClick = (event, user) => {
    setSelectedUser(user);
  };

  const searchUser = (name) => {
    if (!name) {
      return;
    }

    searchByUserName(name, props.bManager || false).then((result) => {
      //console.log(result);
      setUsers(result);
    });
  };

  useEffect(() => {
    // getUserList().then((result) => {
    //   //console.log(result);
    //   setUsers(result);
    // });
  }, []);

  return (
    <div className={classes.root}>
      <Typography variant="h5" component="h1" style={{ fontWeight: 'bold' }}>
        Search User
      </Typography>
      <Typography className={classes.mBottom} variant="body1">
        Enter user name
      </Typography>
      <form noValidate autoComplete="off" spacing={2}>
        <TextField
          size="small"
          label="Name"
          variant="outlined"
          margin="dense"
          fullWidth
          inputRef={valueRef}
          onKeyPress={(e) => {
            if (e.key === 'Enter') e.preventDefault();
          }}
        />
        <Button variant="contained" color="primary" fullWidth className={classes.button} onClick={() => searchUser(valueRef.current.value)}>
          SEARCH
        </Button>
      </form>
      {users ? (
        <div>
          <List dense className={classes.root}>
            {users.map((user, idx) => {
              return (
                <ListItem key={user.id} button selected={selectedUser.id === user.id} onClick={(event) => handleListItemClick(event, user)}>
                  <ListItemText id={user.id} primary={`${user.name}(${user.id})`} />
                </ListItem>
              );
            })}
          </List>
        </div>
      ) : (
        ''
      )}

      <div className={classes.mBottom}>
        <Grid container xs={12} md={12} spacing={2}>
          <Grid item>
            <Button variant="contained" color="secondary" fullWidth className={classes.button} onClick={() => props.handleClose()}>
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              className={classes.button}
              onClick={() => props.callbackFunc(selectedUser)}
            >
              Ok
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default SearchUserPage;
