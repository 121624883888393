import React, { useState } from 'react';
import { Button, makeStyles, TextField, Typography } from '@material-ui/core';
import Amplify, { Auth } from 'aws-amplify';
import awsconfig from '../../../aws-exports';
import swal from '@sweetalert/with-react';

Amplify.configure(awsconfig);

const useStyles = makeStyles((theme) => ({
  root: {
    background: '#0d131d',
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  mBottom: {
    marginBottom: '.5rem',
  },
  button: {
    marginTop: '.85rem',
  },
  loginCard: {
    width: '350px',
    borderRadius: 5,
    background: '#fff',
    padding: '.85rem',
  },
}));

const RegisterPage = (props) => {
  const classes = useStyles();
  const { history } = props;

  const [username, setUserName] = useState();
  const [id, setId] = useState();
  const [password, setPassword] = useState();
  const [email, setEmail] = useState();

  const join = () => {
    console.log(`username = ${username}`);
    console.log(`id = ${id}`);
    console.log(`password = ${password}`);
    console.log(`email = ${email}`);

    Auth.signUp({
      username: id,
      password: password,
      attributes: {
        email: email,
        name: username,
        'custom:role': 'SYS_ADM',
      },
    }).then((user) => {
      swal('Registered.', {
        icon: 'success',
      });
      history && history.push('/pages/auth/login');
    });
  };

  return (
    <div className={classes.root}>
      <div className={classes.loginCard}>
        <Typography variant="h5" component="h1">
          Register
        </Typography>
        <Typography className={classes.mBottom} variant="body1">
          Create your account
        </Typography>
        <form noValidate autoComplete="off">
          <TextField label="Name" variant="outlined" margin="dense" fullWidth onChange={(e) => setUserName(e.target.value)} />
          <TextField label="Email" variant="outlined" margin="dense" fullWidth onChange={(e) => setEmail(e.target.value)} />
          <TextField label="ID" variant="outlined" margin="dense" fullWidth onChange={(e) => setId(e.target.value)} />
          <TextField
            label="Password"
            type="password"
            variant="outlined"
            margin="dense"
            fullWidth
            onChange={(e) => setPassword(e.target.value)}
          />
          {/*<TextField*/}
          {/*  label="Re-Password"*/}
          {/*  type="password"*/}
          {/*  variant="outlined"*/}
          {/*  margin="dense"*/}
          {/*  fullWidth*/}
          {/*  onChange={(e) => setUserName(e.target.value)}*/}
          {/*/>*/}

          <div className={classes.mBottom}>
            <Button variant="contained" color="primary" fullWidth className={classes.button} onClick={join}>
              Register
            </Button>
            <Button
              variant="outlined"
              color="primary"
              fullWidth
              className={classes.button}
              onClick={() => history && history.push('/pages/auth/login')}
            >
              Sign in
            </Button>
          </div>
        </form>
        <Typography variant="caption">&copy; koreamedis.com</Typography>
      </div>
    </div>
  );
};

export default RegisterPage;
