import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import OftadehLayout from '../../components/OftadehLayout/OftadehLayout';
import { Button, Divider, FormControlLabel, Grid, makeStyles, Radio, RadioGroup, Switch, TextField, Typography } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import swal from '@sweetalert/with-react';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import SaveIcon from '@material-ui/icons/Save';
import ListIcon from '@material-ui/icons/List';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  API_URL,
  BOOKING_CATEGORY,
  BOOKING_STATUS,
  DEF_BOOKING_STATUS,
  DEF_COUNTRY,
  DEF_LANGUAGE,
  getDefName,
  getTypeName,
} from '../../constants';
import FormControl from '@material-ui/core/FormControl';
import { getBookingInfo, getConsultInfo, getHospitalList, getProductInfo } from '../../constants/PromiseHttp';
import axiosInstance from '../../constants/AxiosInstance';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    color: '#3f51b5',
  },
  my3: {
    margin: '1.3rem 0',
  },
  mRight: {
    marginRight: '.85rem',
  },
  mInput: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
}));

const BookingDetailPage = (props) => {
  const query = queryString.parse(props.location.search);
  const type = query.type ? query.type : 'new';
  const cate = query.cate ? query.cate : 'hospital';

  const [booking, setBooking] = useState({});
  const [hospitals, setHospitals] = useState([]);
  const [product, setProduct] = useState();
  const [consult, setConsult] = useState();

  const { history } = props;
  const classes = useStyles();

  useEffect(() => {
    getData();
    getHospitalList().then((result) => {
      setHospitals(result);
    });
  }, []);

  const getData = () => {
    if (type === 'edit' && query.id) {
      getBookingInfo(query.id).then((result) => {
        setBooking(result);
        getConsultInfo(result.consultId).then((r) => {
          setConsult(r);
        });
        getProductInfo(result.productId).then((r) => {
          setProduct(r);
        });
      });
    }
  };

  const getHospitalName = () => {
    const _hos = hospitals.find((hos) => hos.id === booking.hospitalId);
    return _hos ? _hos['name_ko'] : '';
  };
  const getProductName = () => {
    return product ? product['name_ko'] : '';
  };
  const getFormattedDate = (value) => {
    if (!value) return '';
    const dt = new Date(Number(value));
    return dt.getFullYear() + '-' + (dt.getMonth() + 1) + '-' + dt.getDate() + ' ' + dt.getHours() + ':' + dt.getMinutes();
  };
  const getLocalDate = (date) => {
    if (date && date.includes('T')) {
      return date;
    }
    const dt = date ? new Date(Number(date)) : '';
    const utcT = moment(dt).format('YYYY-MM-DDThh:mm');
    return utcT;
  };
  const getStatusDesc = (status) => {
    if (status === BOOKING_STATUS[2].id) {
      return cate === 'product' ? '(Deposit confirmation)' : '';
    }
    if (status === BOOKING_STATUS[4].id) {
      return '(Service completed)';
    }
    return '';
  };

  const addBooking = () => {
    if (booking.status === 'confirm' && !booking.confirmedAt) {
      swal('Missing required confirmed date.');
      return;
    }
    if (booking.confirmedAt && booking.status === 'request') {
      swal('Please change the reservation status.');
      return;
    }

    let cdate = new Date(booking.confirmedAt).getTime();
    let nowDate = new Date().getTime();
    const param = {
      categoryId: booking.categoryId,
      userId: booking.userId,
      userName: booking.userName,
      languageId: booking.languageId || DEF_LANGUAGE,
      phoneNumber: booking.phoneNumber,
      email: booking.email,
      reserveDate: booking.reserveDate,
      confirmedAt: cdate ? cdate.toString() : booking.confirmedAt,
      canceledAt: booking.status === 'cancel' ? nowDate.toString() : '',
      closedAt: booking.status === 'close' ? nowDate.toString() : '',
      contents: booking.contents,
      hospitalId: booking.hospitalId,
      productId: booking.productId,
      consultId: booking.consultId,
      price: booking.price,
      status: booking.status || DEF_BOOKING_STATUS,
    };

    if (type === 'new') {
      // //등록페이지가 없는 메뉴이므로 임의로 테스트데이터 입력.
      // param['userId'] = 'test@test.com'; //test.kj
      // param['userName'] = '강경진'; //test.kj
      // // param['languageId'] = 'ko'; //test.kj
      // param['hospitalId'] = 'hospital_1629453935367'; //test.kj
      // param['productId'] = 'product_1630874508813'; //test.kj
      // param['reserveDate'] = new Date().getTime().toString(); //test.kj

      axiosInstance.post(`${API_URL}/booking/`, param, { headers: { Pragma: 'no-cache' } }).then((response) => {
        if (response.status === 200) {
          swal('Registered.', {
            icon: 'success',
          });

          goList();
        }
      });
    } else {
      param['id'] = query.id;

      axiosInstance.put(`${API_URL}/booking/`, param, { headers: { Pragma: 'no-cache' } }).then((response) => {
        if (response.status === 200) {
          swal('It is changed.', {
            icon: 'success',
          });
          goList();
        }
      });
    }
  };

  const deleteBooking = () => {
    swal({
      title: 'Are you sure you want to delete?',
      text: '',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        axiosInstance.delete(`${API_URL}/booking/${query.id}`, { headers: { Pragma: 'no-cache' } }).then((response) => {
          if (response.status === 200) {
            let msg = 'It is deleted.';
            swal(msg, {
              icon: 'success',
            });
            goList();
          }
        });
      } else {
      }
    });
  };

  const goList = () => {
    history.push(`/pages/booking/${cate}`);
  };

  const goEdit = () => {
    history.push(`/pages/booking/edit?type=edit&id=${query.id}&cate=${cate}`);
  };

  const handleChangeData = (value, field) => {
    booking[field] = value;
    setBooking({ ...booking });
  };

  return (
    <OftadehLayout>
      <Grid container className={classes.my3} alignItems="center">
        <Grid item className={classes.mRight} style={{ fontWeight: 'bold' }}>
          <Typography variant="h5" component="h1" style={{ fontWeight: 'bold' }}>
            {getTypeName(BOOKING_CATEGORY, cate) + ' Detail'}
          </Typography>
        </Grid>
      </Grid>
      <div>
        <Card className={classes.root}>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <div className={classes.title}>Language</div>
                <Typography variant="body1">{booking.languageId || DEF_LANGUAGE}</Typography>
              </Grid>
              <Grid item xs={4}>
                <div className={classes.title}>Country</div>
                <Typography variant="body1">{booking.countryId || DEF_COUNTRY}</Typography>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <div className={classes.title}>Name</div>
                <Typography variant="body1">{booking.userName || ''}</Typography>
              </Grid>
              <Grid item xs={4}>
                <div className={classes.title}>Email</div>
                <Typography variant="body1">{booking.userId ? booking.userId : `${booking.userName}(NOT MEMEBER)`}</Typography>
              </Grid>
              <Grid item xs={4}>
                <div className={classes.title}>Phone number</div>
                <Typography variant="body1">{booking.phoneNumber || ''}</Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Card className={classes.my3}>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} mb={12}>
                <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                  Reservation
                </Typography>
              </Grid>
              <Divider variant={'fullWidth'} />
              <Grid item xs={12}>
                <div className={classes.title}>Contents</div>
                <Typography variant="body1">{booking.contents || ''}</Typography>
              </Grid>
              <Grid container spacing={3} style={{ marginLeft: 3 }}>
                {booking.categoryId !== BOOKING_CATEGORY[2].id ? (
                  <Grid item xs={4}>
                    <div className={classes.title}>Hospital</div>
                    <Typography variant="body1">{`${getHospitalName()}`}</Typography>
                  </Grid>
                ) : (
                  ''
                )}
                {booking.categoryId === BOOKING_CATEGORY[1].id ? (
                  <Grid item xs={4}>
                    <div className={classes.title}>Product</div>
                    <Typography variant="body1">{`${getProductName()}`}</Typography>
                  </Grid>
                ) : (
                  ''
                )}
                {booking.categoryId === BOOKING_CATEGORY[0].id ? (
                  <Grid item xs={4}>
                    <div className={classes.title}>Consult ({consult && consult.id})</div>
                    <Typography variant="body1">{(consult && consult.title) || ''}</Typography>
                  </Grid>
                ) : (
                  ''
                )}
                {booking.categoryId === BOOKING_CATEGORY[0].id ? (
                  <Grid item xs={4}>
                    <div className={classes.title}>Consult Type</div>
                    <Typography variant="body1">{booking.consultType}</Typography>
                  </Grid>
                ) : (
                  ''
                )}
              </Grid>
              <Grid container spacing={3} style={{ marginLeft: 3 }}>
                {booking.categoryId === BOOKING_CATEGORY[1].id ? (
                  <Grid item xs={6}>
                    <div className={classes.title}>Deposit(KRW)</div>
                    <Typography variant="body1">{booking.price ? booking.price.deposit : '0'}</Typography>
                  </Grid>
                ) : (
                  ''
                )}
                {booking.categoryId === BOOKING_CATEGORY[2].id ? (
                  <>
                    <Grid item xs={3}>
                      <div className={classes.title}>Use Translator</div>
                      <Typography variant="body1">{booking.useTranslator ? 'USE' : 'NOT USED'}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <div className={classes.title}>AirFlight No.</div>
                      <Typography variant="body1">{booking.airflight || ''}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <div className={classes.title}>User Message</div>
                      <Typography variant="body1">{booking.pickupMemo ? booking.pickupMemo : ''}</Typography>
                    </Grid>
                  </>
                ) : (
                  ''
                )}
              </Grid>
              <Grid item xs={12}>
                <FormControl component="fieldset">
                  <div className={classes.title}>Reservation Status</div>
                  <RadioGroup
                    aria-label="status"
                    name="status"
                    onChange={(event) => {
                      handleChangeData(event.target.value, 'status');
                    }}
                    row
                  >
                    <FormControlLabel
                      value={DEF_BOOKING_STATUS}
                      checked={booking.status === undefined || booking.status === BOOKING_STATUS[0].id}
                      control={<Radio />}
                      label={`${BOOKING_STATUS[0][getDefName()]}`}
                    />
                    <FormControlLabel
                      value={BOOKING_STATUS[1].id}
                      checked={booking.status === BOOKING_STATUS[1].id}
                      control={<Radio />}
                      label={`${BOOKING_STATUS[1][getDefName()]}`}
                    />
                    {cate === 'product' && (
                      <FormControlLabel
                        value={BOOKING_STATUS[2].id}
                        checked={booking.status === BOOKING_STATUS[2].id}
                        control={<Radio />}
                        label={`${BOOKING_STATUS[2][getDefName()]}${getStatusDesc(BOOKING_STATUS[2].id)}`}
                      />
                    )}
                    <FormControlLabel
                      value={BOOKING_STATUS[3].id}
                      checked={booking.status === BOOKING_STATUS[3].id}
                      control={<Radio />}
                      label={`${BOOKING_STATUS[3][getDefName()]}`}
                    />
                    <FormControlLabel
                      value={BOOKING_STATUS[4].id}
                      checked={booking.status === BOOKING_STATUS[4].id}
                      control={<Radio />}
                      label={`${BOOKING_STATUS[4][getDefName()]}${getStatusDesc(BOOKING_STATUS[4].id)}`}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {/*<Grid item xs={6} mb={6}>*/}
              {/*  <FormControlLabel*/}
              {/*    control={*/}
              {/*      <Switch*/}
              {/*        checked={!booking.visit}*/}
              {/*        onChange={(e, newValue) => {*/}
              {/*          handleChangeData(!newValue, 'visit');*/}
              {/*        }}*/}
              {/*      />*/}
              {/*    }*/}
              {/*    label="Visit Hospital"*/}
              {/*  />*/}
              {/*</Grid>*/}
              <Grid item xs={4}>
                <div className={classes.title}>Reservation date</div>
                <Typography variant="body1">{`${getFormattedDate(booking.reserveDate)}`}</Typography>
              </Grid>
              <Grid item xs={4}>
                <div className={classes.title}>Confirmed date</div>
                <form className={classes.container} noValidate>
                  <TextField
                    id="date"
                    label=""
                    type="datetime-local"
                    className={classes.mInput}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={getLocalDate(booking.confirmedAt) || 'None'}
                    size="small"
                    variant={'outlined'}
                    disabled={booking.status !== BOOKING_STATUS[1].id}
                    onChange={(e) => handleChangeData(e.target.value, 'confirmedAt')}
                  />
                </form>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>
      <div className={classes.my3}>
        <Grid container justifyContent={'space-between'} xs={12} md={12} spacing={3}>
          <Grid item>
            <Button
              variant="contained"
              style={{ backgroundColor: green[700], color: '#ffffff', marginRight: '10px' }}
              size="medium"
              onClick={goList}
              startIcon={<ListIcon />}
            >
              List
            </Button>
            <Button variant="contained" color="secondary" size="medium" onClick={deleteBooking} startIcon={<DeleteIcon />}>
              Delete
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" size="medium" onClick={goEdit} style={{ marginRight: '10px' }}>
              Go Edit
            </Button>
            <Button variant="contained" color="primary" size="medium" onClick={addBooking} startIcon={<SaveIcon />}>
              Save
            </Button>
          </Grid>
        </Grid>
      </div>
    </OftadehLayout>
  );
};

export default BookingDetailPage;
