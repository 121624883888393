import React, { useEffect, useState } from 'react';
import { addDays } from 'date-fns';
import { Bar, Line } from 'react-chartjs-2';
import CustomDatePicker from './datepicker';
import { queryReport } from './queryReport';
import { ChartTitle, colors } from './styles';
import Grid from 'antd/es/card/Grid';
import { CircularProgress, Divider, makeStyles, Typography } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import MUIDataTable from 'mui-datatables';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '.85rem',
    borderRadius: 5,
    background: '#fff',
  },
  mTop: {
    marginTop: '20px',
  },
}));

const BrowsersReport = (props) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const INITIAL_STATE = {
    labels: [],
    values: [],
    colors: [],
  };
  const [pageViewReportData, setPageViewReportData] = useState(INITIAL_STATE);
  const [usersReportData, setUsersReportData] = useState(INITIAL_STATE);
  const [startDate, setStartDate] = useState(addDays(new Date(), -10));
  const [endDate, setEndDate] = useState(new Date());
  const [totals, setTotals] = useState([]);
  const [tableData, setTableData] = useState([]);

  const displayResults = (response) => {
    console.log(response);
    const queryResult = response.result.reports[0].data.rows;
    const totalValues = response.result.reports[0].data.totals[0].values;
    setTotals(totalValues);
    let labels = [];
    let pageViewValues = [];
    let usersValues = [];

    queryResult.forEach((row, idx) => {
      labels.push(row.dimensions[0]);
      pageViewValues.push(row.metrics[0].values[0]);
      usersValues.push(row.metrics[0].values[1]);

      let td = {
        date: row.dimensions[0],
        pageview: row.metrics[0].values[0],
        users: row.metrics[0].values[1],
        colors: colors[idx],
      };
      tableData.push(td);
    });
    setPageViewReportData({
      ...pageViewReportData,
      labels: labels,
      values: pageViewValues,
    });
    setUsersReportData({
      ...usersReportData,
      labels: labels,
      values: usersValues,
    });

    setLoading(false);
  };

  const data = {
    labels: pageViewReportData.labels,
    datasets: [
      {
        //pageviews
        label: 'Page View',
        data: pageViewReportData.values,
        lineTension: 0,
        backgroundColor: 'rgba(15, 107, 255, 0.1)',
        borderWidth: 1,
        borderColor: '#0f6bff',
        fill: true,
      },
      {
        //users
        label: 'Visit',
        data: usersReportData.values,
        lineTension: 0,
        backgroundColor: 'rgba(242, 184, 113, 0.1)',
        borderWidth: 1,
        borderColor: '#f2b471',
        fill: true,
      },
    ],
  };
  const legend = {
    display: true,
    labels: {
      fontColor: 'black',
    },
    position: 'top', //label를 넣어주지 않으면 position이 먹히지 않음
  };

  const optionsChart = {
    responsive: true,
    maintainAspectRatio: false,
    //tooltips 사용시
    tooltips: {
      enabled: true,
      mode: 'nearest',
      position: 'average',
      intersect: false,
    },
    scales: {
      xAxes: [
        {
          beginAtZero: true,
        },
      ],
      yAxes: [
        {
          beginAtZero: true,
        },
      ],
    },
  };

  useEffect(() => {
    setLoading(true);
    const request = {
      viewID: props.viewID,
      startDate,
      endDate,
      metrics: ['ga:pageviews', 'ga:users'],
      dimensions: ['ga:date'], //ga:date //ga:browser //ga:country
    };
    setTimeout(
      () =>
        queryReport(request)
          .then((resp) => displayResults(resp))
          .catch((error) => console.error(error)),
      1500,
    );
  }, [startDate, endDate]);

  function to_date_format(date_str, gubun) {
    let yyyyMMdd = String(date_str);
    let sYear = yyyyMMdd.substring(0, 4);
    let sMonth = yyyyMMdd.substring(4, 6);
    let sDate = yyyyMMdd.substring(6, 8);

    return sYear + gubun + sMonth + gubun + sDate;
  }

  const cols = [
    {
      name: 'date',
      label: 'Date',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return to_date_format(value, '-');
        },
      },
    },
    {
      name: 'pageview',
      label: 'Page View Count',
      options: {
        filter: false,
        sort: true,
        display: true,
        viewColumns: false,
      },
    },
    {
      name: 'users',
      label: 'User Visit Count',
      options: {
        filter: false,
        sort: true,
        display: true,
        viewColumns: false,
      },
    },
  ];

  const options = {
    print: false,
    filter: false,
    sort: true,
    search: false,
    filterType: false,
    selectableRows: 'none',
    viewColumns: false,
    download: false,
  };

  const getRegxNumber = (num) => {
    return num ? num.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',') : '';
  };

  return (
    <div>
      <Card className={classes.root}>
        <CardContent>
          <Grid container spacing={3} className={classes.mTop}>
            <Grid item xs={6} mb={12}>
              <CustomDatePicker placeholder={'Start date'} date={startDate} handleDateChange={(date) => setStartDate(date)} />
            </Grid>
            <Grid item xs={6} mb={12}>
              <CustomDatePicker placeholder={'End date'} date={endDate} handleDateChange={(date) => setEndDate(date)} />
            </Grid>
          </Grid>
          {data && (
            <>
              <Grid container spacing={3} className={classes.mTop}>
                <Grid item>
                  <ChartTitle>
                    Total Page View Count : {getRegxNumber(totals[0]) || 0} / Total Visit Count : {getRegxNumber(totals[1]) || 0}
                  </ChartTitle>
                </Grid>
                <Divider variant={'fullWidth'} />
                <Grid item xs={12} mb={12}>
                  <Line data={data} legend={legend} options={optionsChart} height={300} />
                </Grid>
              </Grid>
              <Grid container spacing={3} className={classes.mTop}>
                <Grid item xs={12} mb={12}>
                  <Typography variant="h6">Daily count</Typography>
                </Grid>
                <Divider variant={'fullWidth'} />
                <Grid item xs={12} mb={12}>
                  <MUIDataTable title={''} data={tableData} columns={cols} options={options} />
                </Grid>
              </Grid>
            </>
          )}
        </CardContent>
      </Card>
      {loading && (
        <div style={{ position: 'fixed', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', zIndex: 999 }}>
          <CircularProgress color={'secondary'} />
        </div>
      )}
    </div>
  );
};

export default BrowsersReport;
