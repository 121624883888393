import SettingFaqListPage from './SettingFaqListPage';

export const SettingFaqListPageConfig = {
  routes: [
    {
      path: '/pages/setting/faq',
      exact: true,
      component: SettingFaqListPage,
    },
  ],
};
