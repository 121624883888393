import ConsultListPage from './ConsultListPage';

export const ConsultListPageConfig = {
  routes: [
    {
      path: '/pages/consult',
      exact: true,
      component: ConsultListPage,
    },
  ],
};
