import SettingFaqDetailPage from './SettingFaqDetailPage';

export const SettingFaqDetailPageConfig = {
  routes: [
    {
      path: '/pages/setting/faq/detail',
      exact: true,
      component: SettingFaqDetailPage,
    },
  ],
};
