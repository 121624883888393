import React, { useEffect, useRef, useState } from 'react';
import queryString from 'query-string';
import OftadehLayout from '../../components/OftadehLayout/OftadehLayout';
import { Button, Dialog, Divider, Grid, makeStyles, Typography } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import swal from '@sweetalert/with-react';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import ListIcon from '@material-ui/icons/List';
import DeleteIcon from '@material-ui/icons/Delete';
import axiosInstance from '../../constants/AxiosInstance';
import { API_URL } from '../../constants';
import { getAnswerNestedList, getHospitalList, getQuestionInfo } from '../../constants/PromiseHttp';
import ImgListView from '../customComp/ImgListView';
import AnswerComponent, { AnswerCard } from '../customComp/AnswerComponent';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    color: '#3f51b5',
  },
  my3: {
    margin: '1.3rem 0',
  },
  mb0: {
    marginBottom: 0,
  },
  mRight: {
    marginRight: '.85rem',
  },
  p1: {
    padding: '.85rem',
  },
}));

const QuestionDetailPage = (props) => {
  const query = queryString.parse(props.location.search);
  const type = query.type ? query.type : 'new';

  const [question, setQuestion] = useState({});
  const [hospitalList, setHospitalList] = useState([]);

  const [answers, setAnswers] = useState();
  const [editAnswerId, setEditAnswerId] = useState('');
  const [parentAnswerId, setParentAnswerId] = useState('');
  const [answerDlgOpen, setAnswerDlgOpen] = useState(false);

  const { history } = props;
  const classes = useStyles();

  useEffect(() => {
    if (type === 'edit' && query.id) {
      getData();
      getHospitalList().then((result) => {
        setHospitalList(result);
      });
    } else {
      setQuestion([]);
    }
  }, []);

  const getData = () => {
    getQuestionInfo(query.id).then((result) => {
      setQuestion(result);
      setParentAnswerId('');
      setEditAnswerId('');
      getAnswerNestedList(result.id).then((res) => {
        console.log('nested list:', res);
        setAnswers(res);
      });
    });
  };

  const getHospitalName = (hid) => {
    const hospital = hospitalList && hospitalList.find((hos) => hos.id === hid);
    return (hospital && hospital['name_ko']) || '';
  };

  const deleteQuestion = () => {
    swal({
      title: 'Are you sure you want to delete?',
      text: '',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        axiosInstance.delete(`${API_URL}/question/${query.id}`, { headers: { Pragma: 'no-cache' } }).then((response) => {
          if (response.status === 200) {
            swal('It is deleted.', {
              icon: 'success',
            });
            goList();
          }
        });
      } else {
      }
    });
  };

  const deleteAnswer = (answerId) => {
    axiosInstance.delete(`${API_URL}/answer/${answerId}`, { headers: { Pragma: 'no-cache' } }).then((response) => {
      if (response.status === 200) {
        swal('Answer has been deleted.', {
          icon: 'success',
        });
        handleAnswerDlgClose(true);
      }
    });
  };

  const openAddAnswer = (pid) => {
    console.log('DetailPage::openAddAnswer :', pid);
    setParentAnswerId(pid);
    setAnswerDlgOpen(true);
  };

  const openEditAnswer = (aid) => {
    console.log('DetailPage::openEditAnswer :', aid);
    setEditAnswerId(aid);
    setAnswerDlgOpen(true);
  };

  const handleAnswerDlgOpen = () => {
    setParentAnswerId('');
    setEditAnswerId('');
    setAnswerDlgOpen(true);
  };

  const handleAnswerDlgClose = (reload) => {
    if (reload) {
      getData();
    }
    setParentAnswerId('');
    setEditAnswerId('');
    setAnswerDlgOpen(false);
  };

  const goList = () => {
    history.push('/pages/question/');
  };

  return (
    <OftadehLayout>
      <Grid container className={classes.my3} alignItems="center">
        <Grid item className={classes.mRight} style={{ fontWeight: 'bold' }}>
          <Typography variant="h5" component="h1" style={{ fontWeight: 'bold' }}>
            Message Detail
          </Typography>
        </Grid>
      </Grid>
      <div>
        <Card className={classes.root}>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} mb={12}>
                <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                  Basic Info
                </Typography>
              </Grid>
              <Grid item xs={6} mb={6}>
                <div className={classes.title}>Language</div>
                <Typography variant="body1">{(question && question.languageId) || ''}</Typography>
              </Grid>
              <Grid item xs={6} mb={6}>
                <div className={classes.title}>Hospital</div>
                <Typography variant="body1">{(question && getHospitalName(question.hospitalId)) || ''}</Typography>
              </Grid>
              <Grid item xs={6} mb={6}>
                <div className={classes.title}>User Name</div>
                <Typography variant="body1">{(question && question.userName) || ''}</Typography>
              </Grid>
              <Grid item xs={6} mb={6}>
                <div className={classes.title}>User Id</div>
                <Typography variant="body1">{(question && question.userId) || ''}</Typography>
              </Grid>
            </Grid>
            <Divider style={{ marginTop: '10px' }} />
            <Grid container spacing={3} style={{ marginTop: '10px' }}>
              <Grid item xs={12} mb={12}>
                <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                  Question
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.title}>Contents</div>
                <Typography variant="body1">{(question && question.contents) || ''}</Typography>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.title}>Attachments </div>
                {question && <ImgListView images={question && question.files} />}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Grid container spacing={2} className={classes.my3} alignItems="center">
          <Grid item>
            <Typography variant="h5" component="h1" style={{ fontWeight: 'bold' }}>
              Answer
            </Typography>
          </Grid>
          <Grid item>
            <Button variant="outlined" color="primary" onClick={handleAnswerDlgOpen}>
              Register answer
            </Button>

            <Dialog fullWidth open={answerDlgOpen} onClose={handleAnswerDlgClose} aria-labelledby="customized-dialog-title">
              <AnswerComponent
                targetId={query.id}
                targetInfo={
                  question && {
                    email: question.userId,
                    languageId: question.languageId,
                  }
                }
                parentId={parentAnswerId}
                answerId={editAnswerId}
                handleClose={handleAnswerDlgClose}
              />
            </Dialog>
          </Grid>
        </Grid>
        <Card>
          <CardContent>
            {question &&
              answers &&
              answers.map((answer, idx) => (
                <AnswerCard
                  key={`answercard-${answer.id}`}
                  answer={answer}
                  idx={idx}
                  depth={0}
                  handleChild={openAddAnswer}
                  handleEdit={openEditAnswer}
                  handleDelete={deleteAnswer}
                />
              ))}
          </CardContent>
        </Card>
      </div>
      <div className={classes.my3}>
        <Grid container xs={12} md={12} spacing={3}>
          <Grid item>
            <Button
              variant="contained"
              style={{ backgroundColor: green[700], color: '#ffffff', marginRight: '10px' }}
              size="medium"
              onClick={goList}
              startIcon={<ListIcon />}
            >
              List
            </Button>
            {type !== 'new' ? (
              <Button variant="contained" color="secondary" size="medium" onClick={deleteQuestion} startIcon={<DeleteIcon />}>
                Delete
              </Button>
            ) : null}
          </Grid>
        </Grid>
      </div>
    </OftadehLayout>
  );
};

export default QuestionDetailPage;
