import { makeStyles, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { getProductList } from '../../constants/PromiseHttp';
import { Autocomplete } from '@material-ui/lab';
import { getDefName } from '../../constants';

const useStyles = makeStyles((theme) => ({
  mInput: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
}));

const ProductAutocomplete = (props) => {
  const [products, setProducts] = useState([]);

  const classes = useStyles();

  useEffect(() => {
    getProductList().then((result) => {
      setProducts(result);
    });
  }, []);

  const getSelectedProduct = () => {
    const item = products.find((pro) => {
      if (props.target ? pro.id === props.target.productId : false) return pro;
    });
    //console.log('getSelectedProduct', item);
    return item || null;
  };

  return (
    <Autocomplete
      key="cbox-product"
      value={getSelectedProduct()}
      onChange={(event, newValue) => {
        props.handleChangeData(newValue.id, 'productId');
      }}
      size={'small'}
      disableClearable
      options={products}
      getOptionLabel={(option) => option.name_ko || ''}
      getOptionSelected={(option, value) => option.id === value.id}
      style={{ width: '100%' }}
      className={classes.mInput}
      renderInput={(params) => <TextField {...params} variant="outlined" inputProps={{ ...params.inputProps, readOnly: true }} />}
    />
  );
};

export default ProductAutocomplete;
