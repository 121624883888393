import React, { useEffect } from 'react';
import OftadehLayout from '../../../components/OftadehLayout/OftadehLayout';
import { Grid, makeStyles, Paper } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
  paddingPaper: {
    padding: '10px 5px 5px 10px',
  },
  mt: {
    marginTop: 13,
  },
  titlePaper: {
    marginBottom: '16px',
  },
  visitorChart: {
    // height: "150px"
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '25ch',
  },
}));

const SettingDefaultInfoPage = (props) => {
  // const { history } = props;
  const classes = useStyles();
  // const [data, setData] = useState();
  // const getData = () => {};

  useEffect(() => {
    // getData();
  }, []);

  return (
    <OftadehLayout>
      <Grid container alignItems="center">
        <Grid item>
          <h1>Config Default Page</h1>
        </Grid>
      </Grid>
      <Grid>
        <Paper elevation={3} className={classes.paddingPaper}>
          <div className={classes.root}>
            <div>
              <label style={{ fontSize: 16, margin: 8 }}>* 사이트 도메인</label>
              <TextField
                disabled
                style={{ margin: 8 }}
                fullWidth
                id="domain-info"
                helperText="The site domain. It cannot be modified."
                defaultValue="http://koreamedis.com"
              />
              <br />
              <br />
              <br />
              <label style={{ fontSize: 16, margin: 8 }}>* Site Title</label>
              <TextField
                id="site-title"
                helperText="The site title displayed in the browser. It will be displayed in all menus on the site."
                style={{ margin: 8 }}
                placeholder="koreamedis"
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <br />
              <br />
              <br />
              <label style={{ fontSize: 16, margin: 8 }}>* Meta Keyword (Tag)</label>
              <TextField
                id="keyword"
                style={{ margin: 8 }}
                placeholder="Keyword"
                helperText="검색엔진에 노출되는 사이트 Keyword 입니다. 꼭 필요한 내용을 우선적으로 넣으시면 됩니다."
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <br />
              <br />
              <br />
              <label style={{ fontSize: 16, margin: 8 }}>* Meta Description</label>
              <TextField
                id="description"
                style={{ margin: 8 }}
                placeholder="Description"
                helperText="검색엔진에 노출되는 사이트 설명 내용입니다. 꼭 필요한 내용을 우선적으로 넣으시면 됩니다."
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
          </div>
        </Paper>
      </Grid>
    </OftadehLayout>
  );
};
export default SettingDefaultInfoPage;
