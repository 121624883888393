import React from 'react';
import { Grid } from '@material-ui/core';
import DiseaseTransferList from '../customComp/DiseaseTransferList';

const TabSpecialtyView = (props) => {
  const handleChangeData = (value) => {
    props.handleChangeData(value, 'subDiseaseIds');
  };
  return (
    <Grid container spacing={1} style={{ marginTop: '30px' }}>
      <DiseaseTransferList selectedDisease={props.hospital.subDiseaseIds} setSelectedDisease={handleChangeData} />
    </Grid>
  );
};
export default TabSpecialtyView;
