import MemberListPage from './MemberListPage';

export const MemberListPageConfig = {
  routes: [
    {
      path: '/pages/member',
      exact: true,
      component: MemberListPage,
    },
  ],
};
