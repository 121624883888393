import React, { useEffect, useRef, useState } from 'react';
import queryString from 'query-string';
import OftadehLayout from '../../../components/OftadehLayout/OftadehLayout';
import { AppBar, Button, Grid, makeStyles, TextField, Typography } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import swal from '@sweetalert/with-react';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import SaveIcon from '@material-ui/icons/Save';
import ListIcon from '@material-ui/icons/List';
import DeleteIcon from '@material-ui/icons/Delete';
import { API_URL, getDefName } from '../../../constants';
import axiosInstance from '../../../constants/AxiosInstance';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { getDiseaseInfo, getLanguageList, getSubDiseaseInfo } from '../../../constants/PromiseHttp';
import Jodit from '../../../components/Jodit/Jodit';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  my3: {
    margin: '1.3rem 0',
  },
  mb0: {
    marginBottom: 0,
  },
  mRight: {
    marginRight: '.85rem',
  },
  p1: {
    padding: '.85rem',
  },
  mInput: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
}));

const SettingDiseaseDetailPage = (props) => {
  const query = queryString.parse(props.location.search);
  const type = query.type ? query.type : 'new';

  const editorRefs = useRef([]);
  const [disease, setDisease] = useState({});
  const [currentData, setCurrentData] = useState({});
  const [languages, setLanguages] = useState([]);
  const [tabLang, setTabLang] = useState('ko');

  const { history } = props;
  const classes = useStyles();

  useEffect(() => {
    getDiseaseInfo(query.id).then((result) => {
      setDisease(result);
    });
    if (type !== 'new') {
      getSubDiseaseInfo(query.id, query.subId).then((result) => {
        setCurrentData(result);
      });
    }
    getLanguageList().then((result) => {
      setLanguages(result);
    });
  }, []);

  const addSubDisease = async () => {
    let idx = 0;
    for await (let _lang of languages) {
      let isPost = type === 'new' && idx === 0;
      await sendSubDisease(isPost, _lang.id).then((result) => {
        if (idx === languages.length - 1) {
          let msg = type === 'new' ? 'Registered.' : 'It is changed.';
          swal(msg, {
            icon: 'success',
          });
          goList();
        }
        idx++;
      });
    }
  };

  const sendSubDisease = (isPost, langId) => {
    return new Promise(async function (resolve) {
      const param = {
        parentId: disease.id,
      };
      param[`name_${langId}`] = currentData[`name_${langId}`];
      param[`desc_${langId}`] = currentData[`desc_${langId}`];

      if (!param[`name_${langId}`] && !param[`desc_${langId}`]) {
        resolve(null);
        return;
      }

      if (isPost) {
        axiosInstance.post(`${API_URL}/subDisease/`, param, { headers: { Pragma: 'no-cache' } }).then((response) => {
          currentData['id'] = response.data.value.id;
          setCurrentData({ ...currentData });
          console.log(response.data.value.id);
          resolve(response);
        });
      } else {
        param['id'] = currentData.id;
        console.log(param);
        axiosInstance.put(`${API_URL}/subDisease/`, param, { headers: { Pragma: 'no-cache' } }).then((response) => {
          resolve(response);
        });
      }
    });
  };

  const deleteSubDisease = () => {
    swal({
      title: 'Are you sure you want to delete?',
      text: '',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (currentData) {
        axiosInstance
          .delete(`${API_URL}/subDisease/${disease.id}/${currentData.id}`, { headers: { Pragma: 'no-cache' } })
          .then((response) => {
            if (response.status === 200) {
              let msg = 'It is deleted';
              swal(msg, {
                icon: 'success',
              });
              goList();
            }
          });
      }
    });
  };

  const goList = () => {
    history.push(`/pages/setting/disease?sd=${query.id}`);
  };

  const TabView = () => {
    return (
      <Grid container spacing={3}>
        <Grid container xs={12} md={12} className={classes.mInput}>
          <Grid item xs={12} style={{ marginTop: '10px' }}>
            <TextField
              id={`TextField-${tabLang}`}
              label="Disease name"
              className={classes.my3}
              placeholder="Enter title here"
              margin="normal"
              style={{ width: '70%', marginRight: '10px' }}
              InputLabelProps={{
                shrink: true,
              }}
              defaultValue={currentData[`name_${tabLang}`] ? currentData[`name_${tabLang}`] : ''}
              onChange={(event) => {
                handleChangeTitle(event, tabLang);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Jodit
              id={`desc_${tabLang}`}
              uploadApi={`${API_URL}/upload`}
              handleEditor={handleChangeDesc}
              val={currentData[`desc_${tabLang}`] ? currentData[`desc_${tabLang}`] : ''}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  };
  //TAB =====
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div role="tabpanel" id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
        {value === index && <TabView index={index} />}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const [tabValue, setTabValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
    setTabLang(languages[newValue].id);
  };

  //TAB =====

  const PageTitle = () => {
    if (type === 'new') {
      return disease[getDefName()] + ' > Detailed disease registration';
    } else {
      return disease[getDefName()] + ' > ' + currentData[getDefName()];
    }
  };

  const handleChangeTitle = (event, lang) => {
    currentData[`name_${lang}`] = event.target.value;
  };

  const handleChangeDesc = (event) => {
    const id = event.target.id.replace('editor-', '');
    currentData[id] = event.target.value;
  };

  return (
    <OftadehLayout>
      <Grid container className={classes.my3} alignItems="center">
        <Grid item className={classes.mRight} style={{ fontWeight: 'bold' }}>
          <Typography variant="h5" component="h1" style={{ fontWeight: 'bold' }}>
            <PageTitle />
          </Typography>
        </Grid>
      </Grid>

      <div className={classes.root}>
        <Card className={classes.root}>
          <CardContent>
            <AppBar position="static">
              <Tabs value={tabValue} onChange={handleChange} aria-label="tabs">
                {languages &&
                  languages.map((lg, idx) => {
                    return <Tab label={lg[getDefName()]} {...a11yProps(idx)} />;
                  })}
              </Tabs>
            </AppBar>
            {languages &&
              languages.map((lg, idx) => {
                return <TabPanel value={tabValue} index={idx} />;
              })}
          </CardContent>
          <CardActions style={{ marginLeft: '16px', marginRight: '16px' }}>
            <Grid container justifyContent={'space-between'} item xs={12} md={12} spacing={3}>
              <Grid item>
                <Button
                  variant="contained"
                  style={{ backgroundColor: green[700], color: '#ffffff', marginRight: '10px' }}
                  size="medium"
                  onClick={goList}
                  startIcon={<ListIcon />}
                >
                  List
                </Button>
                {type !== 'new' ? (
                  <Button variant="contained" color="secondary" size="medium" onClick={deleteSubDisease} startIcon={<DeleteIcon />}>
                    Delete
                  </Button>
                ) : null}
              </Grid>
              <Grid item>
                <Button variant="contained" color="primary" size="medium" onClick={addSubDisease} startIcon={<SaveIcon />}>
                  {type === 'new' ? 'Registration' : 'Edit'}
                </Button>
              </Grid>
            </Grid>
          </CardActions>
        </Card>
      </div>
    </OftadehLayout>
  );
};

export default SettingDiseaseDetailPage;
