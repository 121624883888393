import React, { useEffect, useState } from 'react';
import OftadehLayout from '../../components/OftadehLayout/OftadehLayout';
import { Button, CircularProgress, Dialog, Grid, makeStyles, Typography } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import axiosInstance from '../../constants/AxiosInstance';
import { getUserList } from '../../constants/PromiseHttp';
import swal from '@sweetalert/with-react';
import { API_URL, getTypeName, MEMBER_STATUS } from '../../constants';
import RegisterPage from '../auth/register/RegisterPage';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  my3: {
    margin: '1.3rem 0',
  },
  mb0: {
    marginBottom: 0,
  },
  mRight: {
    marginRight: '.85rem',
  },
  p1: {
    padding: '.85rem',
  },
}));

const MemberListPage = (props) => {
  const { history } = props;
  const classes = useStyles();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [registerOpen, setRegisterOpen] = useState(false);

  const rowClick = (data) => {
    //console.log(data);
    history.push(`/pages/member/detail?type=edit&id=${data[0]}`);
  };

  const rowDelete = (row) => {
    let ids = [];
    row.data.map((r) => {
      ids.push(data[r.dataIndex].id);
      return r;
    });
    let delParam = {
      ids: ids.toString(),
    };
    swal({
      title: 'Are you sure you want to delete?',
      text: '',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        setLoading(true);
        axiosInstance.post(`${API_URL}/user/deleteIds`, delParam, { headers: { Pragma: 'no-cache' } }).then((response) => {
          setLoading(false);
          swal('It is deleted.', {
            icon: 'success',
          });
          getData();
        });
      } else {
        getData();
      }
    });
  };

  const options = {
    print: false,
    filter: false,
    filterType: 'checkbox',
    selectableRows: 'multiple',
    viewColumns: false,
    download: false,
    onRowClick: rowClick,
    onRowsDelete: rowDelete,
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    setLoading(true);
    getUserList().then((result) => {
      setLoading(false);
      setData(result);
    });
  };

  const cols = [
    {
      name: 'id',
      label: 'id',
      options: {
        filter: false,
        sort: true,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: 'id',
      label: 'No.',
      options: {
        filter: false,
        sort: true,
        display: true,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return tableMeta.rowIndex + 1;
        },
      },
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'id',
      label: 'Email',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'createdAt',
      label: 'Registration Date',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return moment(Number(value)).format('YYYY-MM-DD HH:mm');
        },
      },
    },
    // {
    //   name: 'status',
    //   label: 'Status',
    //   options: {
    //     filter: false,
    //     sort: true,
    //     customBodyRender: (value, tableMeta, updateValue) => {
    //       return getTypeName(MEMBER_STATUS, value);
    //     },
    //   },
    // },
  ];

  const handleRegisterOpen = () => {
    setRegisterOpen(true);
  };
  const handleRegisterClose = () => {
    setRegisterOpen(false);
  };
  const callbackRegisterUser = (u) => {
    setRegisterOpen(false);
    getData();
  };

  return (
    <OftadehLayout>
      <Grid container className={classes.my3} alignItems="center">
        <Grid item className={classes.mRight} style={{ fontWeight: 'bold' }}>
          <Typography variant="h5" component="h1" style={{ fontWeight: 'bold' }}>
            Member List
          </Typography>
        </Grid>
        <Grid item>
          <Button
            onClick={() => history.push('/pages/member/detail?type=new')}
            //onClick={handleRegisterOpen}
            variant="outlined"
            color="primary"
            size="small"
          >
            Registration
          </Button>
          <Dialog open={registerOpen} onClose={handleRegisterClose} aria-labelledby="customized-dialog-title">
            <RegisterPage callbackFunc={callbackRegisterUser} handleClose={handleRegisterClose} />
          </Dialog>
        </Grid>
      </Grid>
      {/*<OftadehBreadcrumbs path={history} />*/}
      <MUIDataTable title={''} data={data} columns={cols} options={options} />
      {loading && (
        <div style={{ position: 'fixed', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', zIndex: 999 }}>
          <CircularProgress color={'secondary'} />
        </div>
      )}
    </OftadehLayout>
  );
};

export default MemberListPage;
