import { Button, Checkbox, Dialog, FormControlLabel, Grid, makeStyles, Switch, TextField, Typography } from '@material-ui/core';
import LanguageAutocomplete from '../customComp/LanguageAutocomplete';
import HospitalTypeAutocomplete from '../customComp/HospitalTypeAutocomplete';
import RegionAutocomplete from '../customComp/RegionAutocomplete';
import React, { useEffect, useState } from 'react';
import SearchUser from '../customComp/SearchUserPage';
import { getHospitalList, getLanguageList } from '../../constants/PromiseHttp';
import { Autocomplete } from '@material-ui/lab';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { getDefName, phoneNumberValidation } from '../../constants';

const CountryLanguage = require('country-language');

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  formControl: {
    margin: theme.spacing(3),
  },
  searchBtn: {
    //margin: theme.spacing(0),
    marginTop: '15px',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  mInput: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
}));
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export function TabInfoView(props) {
  const IS_SYS_ADMIN = sessionStorage.getItem('bt_role') === 'SYS_ADM';
  const [userSearchOpen, setUserSearchOpen] = useState(false);
  const [languages, setLanguages] = useState([]);
  const [availableLang, setAvailableLang] = useState([]);
  const [hospitals, setHospitals] = useState([]);
  const [relatedHospitals, setRelatedHospitals] = useState([]);

  const [info, setInfo] = useState({});

  const classes = useStyles();

  useEffect(() => {
    getLanguageList().then((result) => {
      setLanguages(result);
    });
    getHospitalList().then((result) => {
      setHospitals(result);
    });
  }, []);

  useEffect(() => {
    if (props.hospital) {
      setInfo(props.hospital.info || {});

      if (!IS_SYS_ADMIN && !props.hospital.adminId) {
        props.handleChangeData(sessionStorage.getItem('bt_email'), 'adminId');
        props.handleChangeData(sessionStorage.getItem('bt_name'), 'adminName');
      }

      if (availableLang.length === 0) {
        if (props.hospital && props.hospital.info && props.hospital.info.availableLanguageIds) {
          CountryLanguage.getLanguageCodes(1).map((option) => {
            if (props.hospital.info.availableLanguageIds.indexOf(option) > -1) {
              availableLang.push(option);
            }
          });
        }
        // console.log('useEffect : ', availableLang);
        setAvailableLang(availableLang);
      }

      if (relatedHospitals.length === 0) {
        if (props.hospital && props.hospital.info && props.hospital.info.relatedHospitalIds) {
          hospitals.map((hos) => {
            if (props.hospital.info.relatedHospitalIds.indexOf(hos.id) > -1) {
              relatedHospitals.push(hos);
            }
          });
        }
        //console.log('useEffect : ', relatedHospitals);
        setRelatedHospitals(relatedHospitals);
      }
    }
  }, [props.hospital]);

  const callbackSearchedUser = (u) => {
    if (props.hospital) {
      props.handleChangeData(u.id, 'adminId');
      props.handleChangeData(u.name, 'adminName');
    }
    setUserSearchOpen(false);
  };

  const handleUserSearchOpen = () => {
    setUserSearchOpen(true);
  };
  const handleUserSearchClose = () => {
    setUserSearchOpen(false);
  };
  const handleChangeInfoData = (value, field) => {
    if (field === 'lat' || field === 'lon') {
      let coord = info['coord'] ? info['coord'] : {};
      coord[field] = value;
      info['coord'] = coord;
    } else {
      info[field] = value;
    }
    setInfo({ ...info });
    props.handleChangeData(info, 'info');
  };
  const handleChangeLanguage = (value) => {
    setAvailableLang(value);

    const ids = [];
    value.map((_lang) => {
      ids.push(_lang);
    });
    handleChangeInfoData(ids, 'availableLanguageIds');
  };

  const handleChangeRelatedHospital = (value) => {
    setRelatedHospitals(value);

    const ids = [];
    value.map((_hos) => {
      ids.push(_hos.id);
    });
    handleChangeInfoData(ids, 'relatedHospitalIds');
  };

  const SearchUserBox = () => {
    return (
      <Grid item xs={4} mb={6}>
        <Typography component="legend">
          Manager <span style={{ color: 'red' }}>*</span>
        </Typography>
        <TextField
          size="small"
          value={props.hospital ? props.hospital.adminName : IS_SYS_ADMIN ? '' : sessionStorage.getItem('bt_name')}
          variant="outlined"
          margin="dense"
          InputProps={{ readOnly: true }}
          className={classes.mInput}
        />
        {IS_SYS_ADMIN && (
          <Button className={classes.searchBtn} variant="contained" color="primary" onClick={handleUserSearchOpen}>
            Manager Search
          </Button>
        )}
        <Dialog fullWidth open={userSearchOpen} onClose={handleUserSearchClose} aria-labelledby="customized-dialog-title">
          <SearchUser bManager={true} callbackFunc={callbackSearchedUser} handleClose={handleUserSearchClose} />
        </Dialog>
      </Grid>
    );
  };

  const PossibleLanguage = () => {
    return (
      <Autocomplete
        key="multiple-lang"
        multiple
        value={availableLang}
        onChange={(event, newValue) => {
          handleChangeLanguage(newValue);
          console.log('onChange : ', newValue);
        }}
        disableCloseOnSelect
        // disableClearable
        size={'small'}
        options={CountryLanguage.getLanguageCodes(1)}
        // getOptionLabel={(option) => CountryLanguage.getLanguage(option).name[0] + `(${option})` || ''}
        style={{ width: '100%' }}
        className={classes.mInput}
        renderOption={(option, { selected }) => (
          <React.Fragment>
            <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
            {option}
            {/* {CountryLanguage.getLanguage(option).name[0] + `(${option})`} */}
          </React.Fragment>
        )}
        renderInput={(params) => <TextField {...params} variant="outlined" inputProps={{ ...params.inputProps, readOnly: false }} />}
      />
    );
  };

  const RelatedHospital = () => {
    return (
      <Autocomplete
        multiple
        disableCloseOnSelect
        size={'small'}
        id="rel-hospitals"
        options={hospitals}
        getOptionLabel={(option) => `${option['name_ko']} (${option['name_en']}) (${option['languageId']})` || ''}
        value={relatedHospitals}
        onChange={(event, newValue) => {
          //props.setSelected(newValue);
          //props.handleChangeSelected(newValue);
          handleChangeRelatedHospital(newValue);
        }}
        renderOption={(option, { selected }) => (
          <React.Fragment>
            <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
            {`${option['name_ko']} (${option['name_en']}) (${option['languageId']})`}
          </React.Fragment>
        )}
        style={{ width: '100%' }}
        className={classes.mInput}
        renderInput={(params) => (
          <TextField {...params} variant="outlined" label="" placeholder="" inputProps={{ ...params.inputProps, readOnly: false }} />
        )}
      />
    );
  };

  return (
    <Grid container spacing={1} style={{ marginTop: '30px' }}>
      <Grid item xs={2} mb={6}>
        <Typography component="legend">
          Language <span style={{ color: 'red' }}>*</span>
        </Typography>
        <LanguageAutocomplete target={props.hospital} handleChangeData={props.handleChangeData} />
      </Grid>
      <SearchUserBox />
      <Grid item xs={6}>
        <Typography style={{}}>
          Invitational medical institution registration number <span style={{ color: 'red' }}>*</span>
        </Typography>
        <TextField
          size={'small'}
          key={`TextField-reg-num`}
          className={classes.mInput}
          placeholder=""
          margin="normal"
          style={{ width: '100%', marginRight: '10px' }}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          value={(info && info.regNumber) || ''}
          onChange={(event) => {
            handleChangeInfoData(event.target.value, 'regNumber');
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <Typography style={{}}>
          Hospital name <span style={{ color: 'red' }}>*</span>
        </Typography>
        <TextField
          size={'small'}
          key={`TextField-name-ko`}
          className={classes.mInput}
          placeholder=""
          margin="normal"
          style={{ width: '100%', marginRight: '10px' }}
          InputLabelProps={{
            shrink: true,
          }}
          // helperText={'Enter the in each language.'}
          variant="outlined"
          value={(props.hospital && props.hospital.name_ko) || ''}
          onChange={(event) => {
            props.handleChangeData(event.target.value, 'name_ko');
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <Typography style={{}}>
          Hospital Name (Another Language) <span style={{ color: 'red' }}>*</span>
        </Typography>
        <TextField
          size={'small'}
          key={`TextField-name-en`}
          className={classes.mInput}
          placeholder=""
          margin="normal"
          style={{ width: '100%', marginRight: '10px' }}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          // helperText={'If the language is English, input it in Korean'}
          value={(props.hospital && props.hospital.name_en) || ''}
          onChange={(event) => {
            props.handleChangeData(event.target.value, 'name_en');
          }}
        />
      </Grid>
      <Grid item xs={4} mb={6}>
        <FormControlLabel
          control={
            <Switch
              checked={(info && info.insurance) || false}
              onChange={(e, newValue) => {
                handleChangeInfoData(newValue, 'insurance');
              }}
            />
          }
          label="Medical malpractice liability insurance"
        />
      </Grid>
      <Grid item xs={4} mb={6}>
        <FormControlLabel
          control={
            <Switch
              checked={(info && info.supportRemote) || false}
              onChange={(e, newValue) => {
                handleChangeInfoData(newValue, 'supportRemote');
              }}
            />
          }
          label="Use of remote consultation"
        />
      </Grid>
      {info && info.supportRemote && (
        <Grid item xs={4} mb={6}>
          <Typography style={{}}>
            Video Consulting(KRW) <span style={{ color: 'red' }}>*</span>
          </Typography>
          <TextField
            size={'small'}
            key={`TextField-reg-num`}
            className={classes.mInput}
            placeholder={'₩'}
            margin="normal"
            style={{ width: '100%', marginRight: '10px' }}
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            value={(info && info.remotePrice) || '0'}
            onChange={(event) => {
              handleChangeInfoData(event.target.value, 'remotePrice');
            }}
          />
        </Grid>
      )}

      <Grid item xs={6} mb={6}>
        <Typography component="legend">
          Hospital Category <span style={{ color: 'red' }}>*</span>
        </Typography>
        <HospitalTypeAutocomplete target={props.hospital} handleChangeData={props.handleChangeData} />
      </Grid>
      <Grid item xs={6} mb={6}>
        <Typography component="legend">Region</Typography>
        <RegionAutocomplete target={info} handleChangeData={handleChangeInfoData} />
      </Grid>
      <Grid item xs={4}>
        <Typography style={{}}>Number of medical staff</Typography>
        <TextField
          size={'small'}
          key={`TextField-numOfDoctor`}
          className={classes.mInput}
          placeholder=""
          margin="normal"
          style={{ width: '100%', marginRight: '10px' }}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          value={(info && info.numOfDoctor) || ''}
          onChange={(event) => {
            handleChangeInfoData(event.target.value, 'numOfDoctor');
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <Typography style={{}}>Number of bed</Typography>
        <TextField
          size={'small'}
          key={`TextField-numOfBed`}
          className={classes.mInput}
          placeholder=""
          margin="normal"
          style={{ width: '100%', marginRight: '10px' }}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          value={(info && info.numOfBed) || ''}
          onChange={(event) => {
            handleChangeInfoData(event.target.value, 'numOfBed');
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <Typography style={{}}>Years of establishment</Typography>
        <TextField
          size={'small'}
          key={`TextField-establishedYear`}
          className={classes.mInput}
          placeholder=""
          margin="normal"
          style={{ width: '100%', marginRight: '10px' }}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          value={(info && info.establishedYear) || ''}
          onChange={(event) => {
            handleChangeInfoData(event.target.value, 'establishedYear');
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <Typography style={{}}>Address(Korean)</Typography>
        <TextField
          size={'small'}
          key={`TextField-address_ko`}
          className={classes.mInput}
          placeholder=""
          margin="normal"
          style={{ width: '100%', marginRight: '10px' }}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          value={(info && info.address_ko) || ''}
          onChange={(event) => {
            handleChangeInfoData(event.target.value, 'address_ko');
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <Typography style={{}}>Address(English)</Typography>
        <TextField
          size={'small'}
          key={`TextField-address_en`}
          className={classes.mInput}
          placeholder=""
          margin="normal"
          style={{ width: '100%', marginRight: '10px' }}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          value={(info && info.address_en) || ''}
          onChange={(event) => {
            handleChangeInfoData(event.target.value, 'address_en');
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <Typography style={{}}>Latitude</Typography>
        <TextField
          size={'small'}
          key={`TextField-coord-lat`}
          className={classes.mInput}
          placeholder=""
          margin="normal"
          style={{ width: '100%', marginRight: '10px' }}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          value={(info && info.coord && info.coord.lat) || ''}
          onChange={(event) => {
            handleChangeInfoData(event.target.value, 'lat');
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <Typography style={{}}>Longitude</Typography>
        <TextField
          size={'small'}
          key={`TextField-coord-lon`}
          className={classes.mInput}
          placeholder=""
          margin="normal"
          style={{ width: '100%', marginRight: '10px' }}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          value={(info && info.coord && info.coord.lon) || ''}
          onChange={(event) => {
            handleChangeInfoData(event.target.value, 'lon');
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <Typography style={{}}>Tel.</Typography>
        <TextField
          size={'small'}
          key={`TextField-tel`}
          className={classes.mInput}
          placeholder=""
          margin="normal"
          style={{ width: '100%', marginRight: '10px' }}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          value={(info && info.tel) || ''}
          onChange={(event) => {
            handleChangeInfoData(event.target.value, 'tel');
          }}
          //error={!phoneNumberValidation(info.tel)}
          helperText={phoneNumberValidation(info.tel) ? '' : 'Please enter your tel number correctly.'}
        />
      </Grid>
      <Grid item xs={6}>
        <Typography style={{}}>Fax.</Typography>
        <TextField
          size={'small'}
          key={`TextField-tel`}
          className={classes.mInput}
          placeholder=""
          margin="normal"
          style={{ width: '100%', marginRight: '10px' }}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          value={(info && info.fax) || ''}
          onChange={(event) => {
            handleChangeInfoData(event.target.value, 'fax');
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <Typography style={{}}>Web Site</Typography>
        <TextField
          size={'small'}
          key={`TextField-url`}
          className={classes.mInput}
          placeholder="http://"
          margin="normal"
          style={{ width: '100%', marginRight: '10px' }}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          value={(info && info.url) || ''}
          onChange={(event) => {
            handleChangeInfoData(event.target.value, 'url');
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <Typography style={{}}>Email</Typography>
        <TextField
          size={'small'}
          key={`TextField-url`}
          className={classes.mInput}
          placeholder=""
          margin="normal"
          style={{ width: '100%', marginRight: '10px' }}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          value={(info && info.email) || ''}
          onChange={(event) => {
            handleChangeInfoData(event.target.value, 'email');
          }}
        />
      </Grid>

      <Grid item xs={6}>
        <Typography style={{}}>Available Language</Typography>
        <PossibleLanguage />
      </Grid>
      <Grid item xs={6}>
        <Typography style={{}}>More Hospitals</Typography>
        <RelatedHospital />
      </Grid>

      <Grid item xs={10}>
        <Typography style={{}}>Tags</Typography>
        <TextField
          size={'small'}
          key={`TextField-tags`}
          className={classes.mInput}
          placeholder=",Please enter them separated by commas."
          margin="normal"
          style={{ width: '100%', marginRight: '10px' }}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          value={(info && info.tags) || ''}
          onChange={(event) => {
            handleChangeInfoData(event.target.value, 'tags');
          }}
        />
      </Grid>
      {IS_SYS_ADMIN && (
        <Grid item xs={2} mb={6}>
          <FormControlLabel
            control={
              <Switch
                checked={props.hospital ? !props.hospital.hidden : true}
                onChange={(e, newValue) => {
                  props.handleChangeData(!newValue, 'hidden');
                }}
              />
            }
            label="Show"
          />
        </Grid>
      )}
    </Grid>
  );
}

// export default TabInfoView;
