import React, { useState } from 'react';
import { makeStyles, Typography, Button, TextField, CircularProgress } from '@material-ui/core';
import { Auth } from 'aws-amplify';
import { API_URL, getUser } from '../../../constants';
import swal from '@sweetalert/with-react';
import { Grid } from '@material-ui/core';
import queryString from 'query-string';
import axiosInstance from '../../../constants/AxiosInstance';

const useStyles = makeStyles((theme) => ({
  root: {
    background: '#0d131d',
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  mBottom: {
    marginBottom: '.5rem',
  },
  button: {
    marginTop: '.85rem',
  },
  loginCard: {
    width: '275px',
    borderRadius: 5,
    background: '#fff',
    padding: '.85rem',
  },
}));

const ChangePasswordPage = (props) => {
  const query = queryString.parse(props.location.search);

  const classes = useStyles();
  const { history } = props;
  const [loading, setLoading] = useState(false);
  //const IS_SYS_ADMIN = sessionStorage.getItem('bt_role') === 'SYS_ADM';

  const [userId, setUserId] = useState(query.userId ? query.userId : sessionStorage.getItem('bt_user'));
  const [password, setPassword] = useState(null);
  const [newPwd, setNewPwd] = useState(null);
  const [reNewPwd, setReNewPwd] = useState(null);

  React.useEffect(() => {
    getUser().then((userData) => {
      console.log(userData);
    });
  }, []);

  const goLogin = () => {
    window.location.href = '/pages/auth/login';
  };

  const goBack = () => {
    history.push('/');
  };
  const handleChangePwd = (event) => {
    setLoading(true);
    Auth.signIn(userId, password)
      .then(async (user) => {
        console.log(user);
        Auth.changePassword(user, password, newPwd)
          .then(async (result) => {
            setLoading(false);
            swal('It is changed.', {
              icon: 'success',
            });
            goLogin();
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
            alert(err.message);
          });
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        swal('Original password is not matched.', {
          icon: 'warning',
        });
      });
  };

  const forceChangePwd = (event) => {
    setLoading(true);
    let param = {
      userId: userId,
      password: newPwd,
    };
    console.log(API_URL);
    axiosInstance.put(`${API_URL}/user/passwordUpdate`, param, { headers: { Pragma: 'no-cache' } }).then(async (response) => {
      if (response.status === 200) {
        swal('It is changed.', {
          icon: 'success',
        });
        goBack();
        setLoading(false);
      }
      setLoading(false);
    });
  };

  const handleInputChange = (value, field) => {
    if (field === 'userId') {
      setUserId(value);
    }

    if (field === 'password') {
      setPassword(value);
    }
    if (field === 'newPwd') {
      setNewPwd(value);
    }
    if (field === 'reNewPwd') {
      setReNewPwd(value);
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.loginCard}>
        <Typography variant="h5" component="h1">
          Change Password
        </Typography>
        <form noValidate autoComplete="off">
          <TextField
            value={userId}
            size="small"
            label="User Id"
            variant="outlined"
            margin="dense"
            fullWidth
            onChange={(e) => handleInputChange(e.target.value, 'userId')}
          />
          {!query.userId && (
            <TextField
              value={password}
              size="small"
              label="Original Password"
              variant="outlined"
              margin="dense"
              type={'password'}
              fullWidth
              onChange={(e) => handleInputChange(e.target.value, 'password')}
            />
          )}
          <TextField
            value={newPwd}
            size="small"
            label="New Password"
            variant="outlined"
            margin="dense"
            type={'password'}
            fullWidth
            onChange={(e) => handleInputChange(e.target.value, 'newPwd')}
          />
          <TextField
            value={reNewPwd}
            size="small"
            label="Confirm New Password"
            variant="outlined"
            margin="dense"
            type={'password'}
            fullWidth
            helperText={newPwd !== reNewPwd ? 'Please enter password correctly.' : ''}
            onChange={(e) => handleInputChange(e.target.value, 'reNewPwd')}
          />
          <div className={classes.mBottom}>
            <Grid container spacing={1} alignItems="center">
              <Grid item>
                <Button variant="contained" color="primary" fullWidth className={classes.button} onClick={() => history.push('/')}>
                  cancel
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  className={classes.button}
                  onClick={query.userId ? forceChangePwd : handleChangePwd}
                >
                  send
                </Button>
              </Grid>
            </Grid>
          </div>
        </form>
        <Typography variant="caption">&copy; koreamedis.com</Typography>
      </div>
      {loading && (
        <div style={{ position: 'fixed', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', zIndex: 999 }}>
          <CircularProgress color={'secondary'} />
        </div>
      )}
    </div>
  );
};

export default ChangePasswordPage;
