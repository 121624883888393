import ProductListPage from './ProductListPage';

export const ProductListPageConfig = {
  routes: [
    {
      path: '/pages/product',
      exact: true,
      component: ProductListPage,
    },
  ],
};
