import SettingBannerListPage from './SettingBannerListPage';

export const SettingBannerListPageConfig = {
  routes: [
    {
      path: '/pages/setting/banner',
      exact: true,
      component: SettingBannerListPage,
    },
  ],
};
