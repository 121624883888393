import React, { useEffect, useState } from 'react';

import queryString from 'query-string';
import OftadehLayout from '../../components/OftadehLayout/OftadehLayout';
import {
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  makeStyles,
  Radio,
  RadioGroup,
  Switch,
  TextareaAutosize,
  TextField,
  Typography,
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import swal from '@sweetalert/with-react';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import SaveIcon from '@material-ui/icons/Save';
import ListIcon from '@material-ui/icons/List';
import DeleteIcon from '@material-ui/icons/Delete';
import axiosInstance from '../../constants/AxiosInstance';
import { API_URL, ARR_DAY, DAY_PART_TIME, DEF_LANGUAGE, getDefName } from '../../constants';
import { getDoctorInfo, getHospitalInfo } from '../../constants/PromiseHttp';
import HospitalAutocomplete from '../customComp/HospitalAutocomplete';
import LanguageAutocomplete from '../customComp/LanguageAutocomplete';
import DiseaseAutocomplete from '../customComp/DiseaseAutocomplete';
import SubDiseaseAutocomplete from '../customComp/SubDiseaseAutocomplete';
import DropZone, { fileUpload } from '../../components/FileUpload/DropZone';
import awsconfig from '../../aws-exports';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  my3: {
    margin: '1.3rem 0',
  },
  mRight: {
    marginRight: '.85rem',
  },
  mInput: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
  textArea: {
    width: '100%',
    padding: theme.spacing(2),
    minHeight: 300,
    borderColor: 'lightgray',
    margin: theme.spacing(1),
    marginTop: 10,
  },
  searchBtn: {
    marginTop: '15px',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

const RecDoctorEditPage = (props) => {
  const query = queryString.parse(props.location.search);
  const type = query.type ? query.type : 'new';
  const [loading, setLoading] = useState(false);
  const IS_SYS_ADMIN = sessionStorage.getItem('bt_role') === 'SYS_ADM';

  const [doctor, setDoctor] = useState({});
  const [selectedSubDisease, setSelectedSubDisease] = useState([]);

  const { history } = props;
  const classes = useStyles();

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    if ((type === 'edit' || type === 'clone') && query.id) {
      getDoctorInfo(query.id).then((result) => {
        setDoctor(result);
        let data = result;
        if (type === 'clone') {
          data['image'] = '';
          setDoctor(data);
        }
      });
    }
  };
  const addDoctor = (e) => {
    e.preventDefault();

    if (!doctor.image) {
      swal('Missing required doctor image.');
      return;
    }
    if (!doctor.name_ko || !doctor.name_en) {
      swal('Missing required doctor name.');
      return;
    }
    if (!doctor.hospitalId) {
      swal('Missing required hospital.');
      return;
    }
    if (!doctor.desc) {
      swal('Missing required introduce.');
      return;
    }

    setLoading(true);
    let subDiseaseIds = [];
    if (selectedSubDisease.length > 0) {
      selectedSubDisease.map((di) => {
        subDiseaseIds.push(di.id);
      });
    }

    const attFiles = [{ id: doctor.id, key: `${doctor.image.id}`, file: doctor.image.obj }];
    doctor.image.path = {};
    doctor.image.obj = {};

    let param = {
      name_ko: doctor.name_ko,
      name_en: doctor.name_en,
      hospitalId: doctor.hospitalId,
      languageId: doctor.languageId || DEF_LANGUAGE,
      desc: doctor.desc,
      subject: doctor.subject,
      diseaseId: doctor.diseaseId,
      subDiseaseIds: subDiseaseIds,
      education: doctor.education,
      activity: doctor.activity,
      career: doctor.career,
      paper: doctor.paper,
      schedule: doctor.schedule,
      officeHour: doctor.officeHour,
      image: doctor.image,
      hidden: IS_SYS_ADMIN ? doctor.hidden || false : true,
      tags: doctor.tags,
    };

    if (type === 'new' || type === 'clone') {
      param['writerId'] = sessionStorage.getItem('bt_user');
      param['isSysAdmin'] = IS_SYS_ADMIN;
      axiosInstance.post(`${API_URL}/doctor/`, param, { headers: { Pragma: 'no-cache' } }).then(async (response) => {
        if (response.status === 200) {
          console.log('post success : ', response.data.value.id);
          await Promise.all([
            fileUpload(attFiles, response.data.value.id, 'doctor', `${API_URL}/doctor/`).then((r) => {
              if (response.status === 200) {
                console.log('image upload success : ', doctor.image.id);
                setLoading(false);
              } else {
                console.log('image upload failed : ', doctor.image.id);
                setLoading(false);
              }
            }),
          ]).then(() => {
            swal('Registered.', {
              icon: 'success',
            });
            setLoading(false);
            goList();
          });
        } else {
          setLoading(false);
        }
      });
    } else {
      param['id'] = query.id;

      axiosInstance.put(`${API_URL}/doctor/`, param, { headers: { Pragma: 'no-cache' } }).then(async (response) => {
        if (response.status === 200) {
          await Promise.all([
            fileUpload(attFiles, query.id, 'doctor', `${API_URL}/doctor/`).then((r) => {
              if (response.status === 200) {
                console.log('image upload success : ', doctor.image.id);
                setLoading(false);
              } else {
                console.log('image upload failed : ', doctor.image.id);
                setLoading(false);
              }
            }),
          ]).then(() => {
            swal('It is changed.', {
              icon: 'success',
            });
            setLoading(false);
            goDetail();
          });
        } else {
          setLoading(false);
        }
      });
    }
  };

  const deleteDoctor = () => {
    swal({
      title: 'Are you sure you want to delete?',
      text: '',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        axiosInstance.delete(`${API_URL}/doctor/${query.id}`, { headers: { Pragma: 'no-cache' } }).then((response) => {
          if (response.status === 200) {
            goList();
            swal('It is deleted.', {
              icon: 'success',
            });
          }
        });
      } else {
      }
    });
  };

  const goList = () => {
    history.push('/pages/recDoctor/');
  };

  const goDetail = () => {
    history.push(`/pages/recDoctor/detail?type=edit&id=${query.id}`);
  };

  const handleChangeData = (value, field) => {
    doctor[field] = value;
    setDoctor({ ...doctor });
  };

  const removeStorageFile = async (file) => {
    if (file) {
      const param = {
        bucket: awsconfig.aws_user_files_s3_bucket,
        key: file.key,
      };
      const url = encodeURI(`${API_URL}/doctor/files/` + param.bucket + '?key=' + param.key + '&id=' + file.id + '&uploadKey=' + doctor.id);
      axiosInstance.delete(url).then((res) => {
        return;
      });
    }
  };

  const handleAddImage = (file) => {
    doctor.image = file;
    setDoctor({ ...doctor });
  };

  const handleChangeSchedule = (value, dayId) => {
    const newSchedule = { ...doctor.schedule };
    newSchedule[`${dayId}`] = value;
    handleChangeData(newSchedule, 'schedule');
  };

  const ScheduleBox = () => {
    return (
      <Grid container className={classes.mInput}>
        {ARR_DAY.map((day) => {
          return (
            <Grid item xs={2} mb={2} key={`schedulepaper-grid-${day.id}`}>
              <SchedulePaper title={day[getDefName()]} id={day.id} key={`schedulepaper-${day.id}`} />
            </Grid>
          );
        })}
      </Grid>
    );
  };

  const SchedulePaper = (props) => {
    return (
      <FormControl component="fieldset" id={`formcontrol-${props.id}`}>
        <FormLabel component="legend">{props.title}</FormLabel>
        <RadioGroup
          aria-label={`radio-group-${props.id}`}
          name={`radio-group-${props.id}`}
          value={doctor.schedule && doctor.schedule[`${props.id}`] ? doctor.schedule[`${props.id}`] : DAY_PART_TIME[3].id}
          onChange={(e) => handleChangeSchedule(e.target.value, props.id)}
        >
          <FormControlLabel
            id={`FormControlLabel-${props.id}-am`}
            value={DAY_PART_TIME[0].id}
            control={<Radio />}
            label={DAY_PART_TIME[0][getDefName()]}
          />
          <FormControlLabel
            id={`FormControlLabel-${props.id}-pm`}
            value={DAY_PART_TIME[1].id}
            control={<Radio />}
            label={DAY_PART_TIME[1][getDefName()]}
          />
          <FormControlLabel
            id={`FormControlLabel-${props.id}-all`}
            value={DAY_PART_TIME[2].id}
            control={<Radio />}
            label={DAY_PART_TIME[2][getDefName()]}
          />
          <FormControlLabel
            id={`FormControlLabel-${props.id}-none`}
            value={DAY_PART_TIME[3].id}
            control={<Radio />}
            label={DAY_PART_TIME[3][getDefName()]}
          />
        </RadioGroup>
      </FormControl>
    );
  };

  console.log(doctor);

  return (
    <OftadehLayout>
      <Grid container className={classes.my3} alignItems="center">
        <Grid item className={classes.mRight} style={{ fontWeight: 'bold' }}>
          <Typography variant="h5" component="h1" style={{ fontWeight: 'bold' }}>
            Doctor {type === 'new' ? 'Registration' : 'Edit'}
          </Typography>
        </Grid>
      </Grid>

      <div>
        <Card className={classes.root}>
          <CardContent>
            <Grid container justifyContent={'space-between'} xs={12} spacing={3}>
              <Grid item xs={6}>
                <Typography component="legend">
                  Doctor's Photo <span style={{ color: 'red' }}>*</span>
                </Typography>
                <DropZone
                  file={doctor.image}
                  handleAddImage={handleAddImage}
                  removeStorageFile={removeStorageFile}
                  customText={'+ click or drag'}
                />
              </Grid>

              <Grid item xs={6}>
                <Grid item xs={12}>
                  <Typography component="legend">
                    Language <span style={{ color: 'red' }}>*</span>
                  </Typography>
                  <LanguageAutocomplete target={doctor} handleChangeData={handleChangeData} />
                </Grid>
                <Grid item xs={12}>
                  <Typography component="legend">
                    Affiliated hospital <span style={{ color: 'red' }}>*</span>
                  </Typography>
                  <HospitalAutocomplete target={doctor} handleChangeData={handleChangeData} />
                </Grid>
                <Grid item xs={12}>
                  <Typography style={{}}>
                    Doctor Name <span style={{ color: 'red' }}>*</span>
                  </Typography>
                  <TextField
                    key={`TextField-name-ko`}
                    className={classes.mInput}
                    placeholder=""
                    margin="normal"
                    style={{ width: '100%', marginRight: '10px' }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    size="small"
                    variant="outlined"
                    value={doctor.name_ko || ''}
                    onChange={(event) => {
                      handleChangeData(event.target.value, 'name_ko');
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography style={{}}>
                    Doctor Name (Another Language) <span style={{ color: 'red' }}>*</span>
                  </Typography>
                  <TextField
                    key={`TextField-name-en`}
                    className={classes.mInput}
                    placeholder=""
                    margin="normal"
                    style={{ width: '100%', marginRight: '10px' }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    size="small"
                    variant="outlined"
                    value={doctor.name_en || ''}
                    onChange={(event) => {
                      handleChangeData(event.target.value, 'name_en');
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography style={{}}>
                    Introduce <span style={{ color: 'red' }}>*</span>
                  </Typography>
                  <TextField
                    key={`TextField-desc`}
                    className={classes.mInput}
                    placeholder=""
                    margin="normal"
                    style={{ width: '100%', marginRight: '10px' }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    size="small"
                    variant="outlined"
                    value={doctor.desc || ''}
                    onChange={(event) => {
                      handleChangeData(event.target.value, 'desc');
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography component="legend">Treatment subject</Typography>
                  <DiseaseAutocomplete target={doctor} handleChangeData={handleChangeData} />
                </Grid>
                <Grid item xs={12}>
                  <Typography component="legend">Tags</Typography>
                  <TextField
                    key={`TextField-name-en`}
                    className={classes.mInput}
                    placeholder=""
                    margin="normal"
                    style={{ width: '100%', marginRight: '10px' }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    size="small"
                    variant="outlined"
                    value={doctor.tags || ''}
                    onChange={(event) => {
                      handleChangeData(event.target.value, 'tags');
                    }}
                  />
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Typography style={{}}>Specialty</Typography>
                <TextField
                  key={`TextField-subject`}
                  className={classes.mInput}
                  placeholder=""
                  margin="normal"
                  style={{ width: '100%', marginRight: '10px' }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  size="small"
                  variant="outlined"
                  value={doctor.subject || ''}
                  onChange={(event) => {
                    handleChangeData(event.target.value, 'subject');
                  }}
                />
              </Grid>
              <Grid item xs={12} mb={12}>
                <Typography component="legend">Related disease selection</Typography>
                <SubDiseaseAutocomplete target={doctor} selected={selectedSubDisease} setSelected={setSelectedSubDisease} />
              </Grid>
              <Grid item xs={12} mb={12}>
                <Typography component="legend">Treatment schedule</Typography>
                <ScheduleBox />
              </Grid>
              <Grid item xs={12} mb={12}>
                <Typography>Education</Typography>
                <TextareaAutosize
                  className={classes.textArea}
                  aria-label="contents"
                  placeholder=""
                  value={doctor.education || ''}
                  onChange={(e) => handleChangeData(e.target.value, 'education')}
                />
              </Grid>
              <Grid item xs={12} mb={12}>
                <Typography>Career</Typography>
                <TextareaAutosize
                  className={classes.textArea}
                  aria-label="contents"
                  placeholder=""
                  value={doctor.career || ''}
                  onChange={(e) => handleChangeData(e.target.value, 'career')}
                />
              </Grid>
              <Grid item xs={12} mb={12}>
                <Typography>Activity</Typography>
                <TextareaAutosize
                  className={classes.textArea}
                  aria-label="contents"
                  placeholder=""
                  value={doctor.activity || ''}
                  onChange={(e) => handleChangeData(e.target.value, 'activity')}
                />
              </Grid>
              <Grid item xs={12} mb={12}>
                <Typography>Monograph</Typography>
                <TextareaAutosize
                  className={classes.textArea}
                  aria-label="contents"
                  placeholder=""
                  value={doctor.paper || ''}
                  onChange={(e) => handleChangeData(e.target.value, 'paper')}
                />
              </Grid>
              {IS_SYS_ADMIN && (
                <Grid item xs={2} mb={6}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={!doctor.hidden}
                        onChange={(e, newValue) => {
                          handleChangeData(!newValue, 'hidden');
                        }}
                      />
                    }
                    label="Show"
                  />
                </Grid>
              )}
            </Grid>
          </CardContent>
          <CardActions style={{ marginLeft: '16px', marginRight: '16px' }}>
            <Grid container justifyContent={'space-between'} xs={12} md={12} spacing={3}>
              <Grid item>
                <Button
                  variant="contained"
                  style={{ backgroundColor: green[700], color: '#ffffff', marginRight: '10px' }}
                  size="medium"
                  onClick={goList}
                  startIcon={<ListIcon />}
                >
                  List
                </Button>
                {type === 'edit' ? (
                  <Button variant="contained" color="secondary" size="medium" onClick={deleteDoctor} startIcon={<DeleteIcon />}>
                    Delete
                  </Button>
                ) : null}
              </Grid>
              <Grid item>
                <Button variant="contained" color="primary" size="medium" onClick={addDoctor} startIcon={<SaveIcon />}>
                  {type === 'edit' ? 'Save' : 'Registration'}
                </Button>
              </Grid>
            </Grid>
          </CardActions>
        </Card>
      </div>
      {loading && (
        <div style={{ position: 'fixed', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', zIndex: 999 }}>
          <CircularProgress color={'secondary'} />
        </div>
      )}
    </OftadehLayout>
  );
};

export default RecDoctorEditPage;
