import React, { useState } from 'react';
import { makeStyles, Typography, Button, TextField } from '@material-ui/core';
import { Auth } from 'aws-amplify';

const useStyles = makeStyles((theme) => ({
  root: {
    background: '#0d131d',
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  mBottom: {
    marginBottom: '.5rem',
  },
  button: {
    marginTop: '.85rem',
  },
  loginCard: {
    width: '275px',
    borderRadius: 5,
    background: '#fff',
    padding: '.85rem',
  },
}));

const ForgotPasswordPage = (props) => {
  const classes = useStyles();
  const { history } = props;

  const [email, setEmail] = useState(null);
  const [verifyCode, setVerifyCode] = useState(null);
  const [newPwd, setNewPwd] = useState(null);
  const [verifyCodeOpen, setVerifyCodeOpen] = useState(false);

  const goHome = () => {
    history.push('/');
  };

  const handleForgotPwd = () => {
    if (!email) {
      alert('Please fill out email fields');
      return;
    }
    email &&
      // Send confirmation code to user's email
      Auth.forgotPassword(email)
        .then((data) => {
          alert(`Please, check the email. We'll send a verification code`);
          setVerifyCodeOpen(true);
        })
        .catch((err) => alert(err.message));
  };

  const handleChange = (value, field) => {
    if (field === 'email') {
      setEmail(value);
    }

    if (field === 'verifyCode') {
      setVerifyCode(value);
    }
    if (field === 'newPwd') {
      setNewPwd(value);
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.loginCard}>
        <Typography variant="h5" component="h1">
          Forgot Password
        </Typography>

        <form noValidate autoComplete="off">
          <p>Enter your email</p>
          <TextField
            disabled={verifyCodeOpen}
            value={email}
            size="small"
            label="Email"
            variant="outlined"
            margin="dense"
            fullWidth
            onChange={(e) => handleChange(e.target.value, 'email')}
          />
          {verifyCodeOpen && (
            <>
              <p>Verification Code</p>
              <TextField
                value={verifyCode}
                variant="outlined"
                required
                fullWidth
                id="verifyCode"
                name="verifyCode"
                autoComplete="verifyCode"
                autoFocus
                onChange={(e) => handleChange(e.target.value, 'verifyCode')}
              />
              <p>New Password</p>
              <TextField
                value={newPwd}
                variant="outlined"
                required
                fullWidth
                id="newPwd"
                name="newPwd"
                autoComplete="newPwd"
                type={'password'}
                onChange={(e) => handleChange(e.target.value, 'newPwd')}
              />
            </>
          )}
          <div className={classes.mBottom}>
            <Button variant="contained" color="primary" fullWidth className={classes.button} onClick={handleForgotPwd}>
              send
            </Button>
          </div>
        </form>
        <Typography variant="caption">&copy; koreamedis.com</Typography>
      </div>
    </div>
  );
};

export default ForgotPasswordPage;
