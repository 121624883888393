import React, { useState } from 'react';
import { Button, CircularProgress, makeStyles, TextField, Typography } from '@material-ui/core';
import Amplify, { Auth, Hub } from 'aws-amplify';
import awsconfig from '../../../aws-exports';
import { getUser } from '../../../constants';

Amplify.configure(awsconfig);

const useStyles = makeStyles((theme) => ({
  root: {
    background: '#0d131d',
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  mBottom: {
    marginBottom: '.5rem',
  },
  button: {
    marginTop: '.85rem',
  },
  loginCard: {
    width: '275px',
    borderRadius: 5,
    background: '#fff',
    padding: '.85rem',
  },
}));

const LoginPage = (props) => {
  const classes = useStyles();
  const { history } = props;
  const [id, setId] = useState(null);
  const [password, setPassword] = useState(null);
  const [loading, setLoading] = useState(false);
  const [kakao, setKakao] = React.useState(null);

  const handleInputChange = (type, e) => {
    if (type === 'id') setId(e.target.value);
    else setPassword(e.target.value);
  };

  React.useEffect(() => {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
        case 'cognitoHostedUI':
          break;
        case 'signOut':
          break;
        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
          console.log('Sign in failure', data);
          break;
      }
    });
    getUser().then((userData) => {
      console.log(userData);
    });
  }, []);

  const login = () => {
    setLoading(true);
    Auth.signIn(id, password)
      .then(async (user) => {
        console.log(user);
        const idToken = user.signInUserSession.idToken;
        sessionStorage.setItem('bt_user', user.username);
        sessionStorage.setItem('bt_jwt', idToken.getJwtToken());
        sessionStorage.setItem('bt_name', user.attributes.name);
        sessionStorage.setItem('bt_email', user.attributes.email);
        sessionStorage.setItem('bt_role', user.attributes['custom:role']);
        window.localStorage.setItem('diseaseList', '');
        window.localStorage.setItem('hospitalList', '');
        window.localStorage.setItem('hospitalTypeList', '');
        window.localStorage.setItem('regionList', '');
        window.localStorage.setItem('languageList', '');
        history.push('/');
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        alert(err.message);
      });
  };

  const getAWSCredentials = (response, snsType, userInfo) => {
    console.log(response);
    console.log(userInfo);
    const { accessToken, expiresIn } = response;
    const date = new Date();
    const expires_at = expiresIn * 1000 + date.getTime();
    if (!accessToken) {
      return;
    }

    console.log(snsType);

    Auth.signIn(userInfo.userId, userInfo.userId.replace(snsType + '_', '')).then((authRes) => {
      console.log(authRes);
    });
  };

  const goForgotPwd = () => {
    window.location.href = '/pages/auth/forgot-password';
  };

  return (
    <div className={classes.root}>
      <div className={classes.loginCard}>
        <Typography variant="h5" component="h1">
          Login
        </Typography>

        <Typography className={classes.mBottom} variant="body1">
          Sign In to your account
        </Typography>
        <form noValidate autoComplete="off">
          <TextField size="small" label="Id" variant="outlined" margin="dense" onChange={(e) => handleInputChange('id', e)} fullWidth />
          <TextField
            size="small"
            label="Password"
            type="password"
            variant="outlined"
            margin="dense"
            onChange={(e) => handleInputChange('password', e)}
            fullWidth
          />
          <div className={classes.mBottom}>
            <Button variant="contained" color="primary" fullWidth className={classes.button} onClick={() => login()}>
              Login
            </Button>
          </div>
          <div className={classes.mBottom}>
            <Button variant="contained" color="secondary" fullWidth className={classes.button} onClick={() => goForgotPwd()}>
              Forgot Password
            </Button>
          </div>
        </form>
        <Typography variant="caption">&copy; koreamedis.com</Typography>
      </div>
      {loading && (
        <div style={{ position: 'fixed', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', zIndex: 999 }}>
          <CircularProgress color={'secondary'} />
        </div>
      )}
    </div>
  );
};

export default LoginPage;
