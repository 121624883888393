import NoticeListPage from './NoticeListPage';

export const NoticeListPageConfig = {
  routes: [
    {
      path: '/pages/notice',
      exact: true,
      component: NoticeListPage,
    },
  ],
};
