import React, { useEffect, useRef, useState } from 'react';
import queryString from 'query-string';
import OftadehLayout from '../../components/OftadehLayout/OftadehLayout';
import {
  Button,
  Dialog,
  Divider,
  FormControlLabel,
  Grid,
  makeStyles,
  Switch,
  TextareaAutosize,
  TextField,
  Typography,
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import swal from '@sweetalert/with-react';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import SaveIcon from '@material-ui/icons/Save';
import ListIcon from '@material-ui/icons/List';
import DeleteIcon from '@material-ui/icons/Delete';
import axiosInstance from '../../constants/AxiosInstance';
import { API_URL, DEF_COUNTRY, DEF_LANGUAGE } from '../../constants';
import { Rating } from '@material-ui/lab';
import SearchUser from '../customComp/SearchUserPage';
import { getReviewInfo } from '../../constants/PromiseHttp';
import FileUpload from '../../components/FileUpload/FileUpload';
import LanguageAutocomplete from '../customComp/LanguageAutocomplete';
import HospitalAutocomplete from '../customComp/HospitalAutocomplete';
import ProductAutocomplete from '../customComp/ProductAutocomplete';
import DiseaseAutocomplete from '../customComp/DiseaseAutocomplete';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  my3: {
    margin: '1.3rem 0',
  },
  mRight: {
    marginRight: '.85rem',
  },
  chipContainer: {
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
    display: 'flex',
  },
  searchBtn: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
  textArea: {
    width: '98%',
    margin: theme.spacing(2),
    padding: theme.spacing(2),
    minHeight: 300,
    borderColor: 'lightgray',
  },
  mInput: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
}));

const ReviewEditPage = (props) => {
  const query = queryString.parse(props.location.search);
  const type = query.type ? query.type : 'new';

  const [review, setReview] = useState({ hidden: false });

  // const [selectedDisease, setSelectedDisease] = useState([]);
  const [uploadFile, setUploadFile] = useState([]);

  const [userSearchOpen, setUserSearchOpen] = useState(false);

  const { history } = props;
  const classes = useStyles();

  const childRef = useRef(null);
  const imgKey = 'photo';

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    if (type === 'edit' && query.id) {
      getReviewInfo(query.id).then((result) => {
        setReview(result);
        if (childRef.current) {
          childRef.current.getSignedUrl(result[imgKey]);
        }
      });
    }
  };

  const addReview = () => {
    // if (!review.userId && !review.userName) {
    //   swal('Missing required name.');
    //   return;
    // }
    if (!review.hospitalId) {
      swal('Missing required hospital.');
      return;
    }
    if (!review.title) {
      swal('Missing required title.');
      return;
    }
    if (!review.contents) {
      swal('Missing required contents of review.');
      return;
    }

    // let diseaseIds = [];
    // if (selectedDisease.length > 0) {
    //   selectedDisease.map((di) => {
    //     diseaseIds.push(di.id);
    //   });
    // }

    const param = {
      title: review.title,
      contents: review.contents,
      userId: review.userId,
      userName: review.userName,
      userProfile: review.userProfile,
      userCountryId: review.userCountryId || DEF_COUNTRY,
      languageId: review.languageId || DEF_LANGUAGE,
      hospitalId: review.hospitalId,
      productId: review.productId,
      diseaseId: review.diseaseId,
      hidden: review.hidden || false,
      rating: review.rating || 0,
    };

    if (type === 'new') {
      param['writerId'] = sessionStorage.getItem('bt_user');
      axiosInstance.post(`${API_URL}/review/`, param).then((response) => {
        if (response.status === 200) {
          if (childRef.current) {
            childRef.current.fileUpload(uploadFile, response.data.value.id, 'review', `${API_URL}/review/`).then(() => {
              swal('Registered.', {
                icon: 'success',
              });
              goList();
            });
          } else {
            swal('Registered.', {
              icon: 'success',
            });
            goList();
          }
        }
      });
    } else {
      param['id'] = query.id;

      axiosInstance.put(`${API_URL}/review/`, param).then((response) => {
        if (response.status === 200) {
          if (childRef.current) {
            childRef.current.fileUpload(uploadFile, query.id, 'review', `${API_URL}/review/`).then(() => {
              swal('It is changed.', {
                icon: 'success',
              });
              goDetail();
            });
          } else {
            swal('It is changed.', {
              icon: 'success',
            });
            goDetail();
          }
        }
      });
    }
  };

  const deleteReview = () => {
    swal({
      title: 'Are you sure you want to delete?',
      text: '',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        axiosInstance.delete(`${API_URL}/review/${query.id}`, { headers: { Pragma: 'no-cache' } }).then((response) => {
          if (response.status === 200) {
            swal('It is deleted.', {
              icon: 'success',
            });
            goList();
          }
        });
      } else {
      }
    });
  };

  const goList = () => {
    history.push('/pages/review/');
  };

  const goDetail = () => {
    history.push(`/pages/review/detail?type=edit&id=${query.id}`);
  };

  const callbackSearchedUser = (u) => {
    if (review) {
      review.userId = u.id;
      review.userName = u.name;
      review.userCountryId = u.countryId;
      review.userProfile = u.photo && u.photo[0].url;
      setReview({ ...review });
    }
    setUserSearchOpen(false);
  };

  const handleUserSearchOpen = () => {
    setUserSearchOpen(true);
  };
  const handleUserSearchClose = () => {
    setUserSearchOpen(false);
  };

  const handleChangeData = (value, field) => {
    review[field] = value;
    setReview({ ...review });
  };

  const SearchUserBox = () => {
    return (
      <Grid item xs={6} mb={6}>
        <Typography component="legend">Member</Typography>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <TextField
              size="small"
              value={review.userName}
              variant="outlined"
              margin="dense"
              InputProps={{ readOnly: true }}
              className={classes.mInput}
              fullWidth
            />
          </Grid>
          <Grid item xs={4} style={{ marginTop: '16px' }}>
            <Button variant="contained" color="primary" onClick={handleUserSearchOpen}>
              Member Search
            </Button>
          </Grid>
          <Dialog open={userSearchOpen} onClose={handleUserSearchClose} aria-labelledby="customized-dialog-title">
            <SearchUser callbackFunc={callbackSearchedUser} handleClose={handleUserSearchClose} />
          </Dialog>
        </Grid>
      </Grid>
    );
  };

  const handleFile = (file, key) => {
    console.log(file, key);
    setUploadFile([...file]);
  };
  const handleUpload = () => {
    console.log('Upload Success');
  };
  const handleRemove = () => {
    console.log('Remove Success');
    getData();
  };

  return (
    <OftadehLayout>
      <Grid container className={classes.my3} alignItems="center">
        <Grid item className={classes.mRight} style={{ fontWeight: 'bold' }}>
          <Typography variant="h5" component="h1" style={{ fontWeight: 'bold' }}>
            Review {type === 'new' ? 'Registration' : 'Edit'}
          </Typography>
        </Grid>
      </Grid>
      <Card className={classes.root}>
        <CardContent className={classes.my3}>
          <Grid container spacing={3}>
            <Grid item xs={6} mb={6}>
              <Typography component="legend">
                Language <span style={{ color: 'red' }}>*</span>
              </Typography>
              <LanguageAutocomplete target={review} handleChangeData={handleChangeData} />
            </Grid>
            <SearchUserBox />
            <Grid item xs={6}>
              <Typography component="legend">Medical institution </Typography>
              <HospitalAutocomplete target={review} handleChangeData={handleChangeData} />
            </Grid>
            <Grid item xs={6}>
              <Typography component="legend">Product</Typography>
              <ProductAutocomplete target={review} handleChangeData={handleChangeData} />
            </Grid>
            <Grid item xs={6}>
              <Typography component="legend"> Diesease </Typography>
              <DiseaseAutocomplete target={review} handleChangeData={handleChangeData} />
            </Grid>
            <Grid item xs={6}>
              <Typography component="legend">Rating</Typography>
              <Rating
                name="simple-controlled"
                value={review ? Number(review.rating) : 0}
                onChange={(event, newValue) => {
                  handleChangeData(newValue, 'rating');
                }}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card className={classes.my3}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12} mb={12}>
              <Typography style={{ marginTop: 10 }}>
                Title <span style={{ color: 'red' }}>*</span>
              </Typography>
              <TextField
                placeholder="Please enter a title."
                margin="normal"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
                variant="outlined"
                className={classes.mInput}
                value={review.title || ''}
                onChange={(e) => handleChangeData(e.target.value, 'title')}
              />
            </Grid>
            <Grid item xs={12} mb={12}>
              <Typography>
                Contents <span style={{ color: 'red' }}>*</span>
              </Typography>
              <TextareaAutosize
                className={classes.textArea}
                aria-label="contents"
                placeholder=""
                value={review.contents || ''}
                onChange={(e) => handleChangeData(e.target.value, 'contents')}
              />
            </Grid>
            <Grid item xs={12} mb={12}>
              <Typography component="legend">Attachments</Typography>
              <FileUpload
                type={'file'}
                ref={childRef}
                buttonText={'File'}
                onFileHandler={handleFile}
                onRemoveAfter={handleRemove}
                onUploadAfter={handleUpload}
                fileId={query.id}
                folder={'review'}
                apiUrl={`${API_URL}/review/`}
                uploadKey={imgKey}
                maxCount={5}
              />
            </Grid>
            <Grid item xs={4} mb={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={!review.hidden}
                    onChange={(e, newValue) => {
                      handleChangeData(!newValue, 'hidden');
                    }}
                  />
                }
                label="Show"
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Grid container justifyContent={'space-between'} xs={12} md={12} spacing={3}>
            <Grid item>
              <Button
                variant="contained"
                style={{ backgroundColor: green[700], color: '#fdfdfd', marginRight: '10px' }}
                size="medium"
                onClick={goList}
                startIcon={<ListIcon />}
              >
                List
              </Button>
              {type !== 'new' ? (
                <Button variant="contained" color="secondary" size="medium" onClick={deleteReview} startIcon={<DeleteIcon />}>
                  Delete
                </Button>
              ) : null}
            </Grid>
            <Grid item>
              <Button variant="contained" color="primary" size="medium" onClick={addReview} startIcon={<SaveIcon />}>
                {type === 'new' ? 'Registration' : 'Save'}
              </Button>
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    </OftadehLayout>
  );
};

export default ReviewEditPage;
