import React, { Fragment, useEffect, useState } from 'react';
import { AddCircle, RemoveCircle } from '@material-ui/icons';
import { IconButton, makeStyles, Paper, TableCell, TextField } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 'auto',
  },
  mInput: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
    display: 'flex',
    flexWrap: 'wrap',
  },
}));
const PriceDynamicForm = (props) => {
  const classes = useStyles();

  const [inputFields, setInputFields] = useState([{ title: '', orgPrice: '', finalPrice: '' }]);

  useEffect(() => {
    if (props.target && props.target.price) {
      setInputFields(props.target.price);
    }
  }, [props.target.price]);

  const handleAddFields = () => {
    const values = [...inputFields];
    values.push({ title: '', orgPrice: '', finalPrice: '' });
    setInputFields(values);
  };

  const handleRemoveFields = (index) => {
    const values = [...inputFields];
    values.splice(index, 1);
    setInputFields(values);
    props.handleChangeData(inputFields, 'price');
  };

  const handleInputChange = (index, event) => {
    const values = [...inputFields];
    if (event.target.name === 'title') {
      values[index].title = event.target.value;
    } else if (event.target.name === 'originalPrice') {
      values[index].orgPrice = event.target.value;
    } else {
      values[index].finalPrice = event.target.value;
    }

    setInputFields(values);
    props.handleChangeData(inputFields, 'price');
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow style={{ height: '10px' }}>
            <TableCell style={{ margin: 0, padding: 0, width: '30%' }} align={'center'}>
              Title
            </TableCell>
            <TableCell style={{ margin: 0, padding: 0, width: '30%' }} align={'center'}>
              Original Price(KRW)
            </TableCell>
            <TableCell style={{ margin: 0, padding: 0, width: '30%' }} align={'center'}>
              Final Price(KRW)
            </TableCell>
            <TableCell style={{ margin: 0, padding: 0, width: '10%' }}>
              <IconButton variant="outlined" color="primary" aria-label="add" onClick={() => handleAddFields()}>
                <AddCircle />
              </IconButton>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {inputFields.map((inputField, index) => (
            <Fragment key={`${inputField}~${index}`}>
              <TableRow style={{ height: '10px' }}>
                <TableCell style={{ margin: 0, padding: 3, width: '30%' }}>
                  <TextField
                    type="text"
                    className={classes.mInput}
                    id="title"
                    name="title"
                    value={inputField.title}
                    size={'small'}
                    variant={'outlined'}
                    style={{ margin: 0, padding: 0 }}
                    onChange={(event) => handleInputChange(index, event)}
                  />
                </TableCell>

                <TableCell style={{ margin: 0, padding: 3, width: '30%' }}>
                  <TextField
                    type="number"
                    className={classes.mInput}
                    placeholder={'₩'}
                    id="originalPrice"
                    name="originalPrice"
                    value={inputField.orgPrice}
                    size={'small'}
                    variant={'outlined'}
                    style={{ margin: 0, padding: 0 }}
                    onChange={(event) => handleInputChange(index, event)}
                  />
                </TableCell>
                <TableCell style={{ margin: 0, padding: 3, width: '30%' }}>
                  <TextField
                    type="number"
                    className={classes.mInput}
                    id="finalPrice"
                    name="finalPrice"
                    placeholder={'₩'}
                    value={inputField.finalPrice}
                    size={'small'}
                    variant={'outlined'}
                    style={{ margin: 0, padding: 0 }}
                    onChange={(event) => handleInputChange(index, event)}
                  />
                </TableCell>
                <TableCell style={{ margin: 0, padding: 0, width: '10%' }}>
                  <IconButton variant="outlined" color="secondary" aria-label="remove" onClick={() => handleRemoveFields(index)}>
                    <RemoveCircle />
                  </IconButton>
                </TableCell>
              </TableRow>
            </Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PriceDynamicForm;
