import dotenv from 'dotenv';
import CryptoJS from 'crypto-js';
import React, { useState } from 'react';
import { Auth } from 'aws-amplify';

dotenv.config();
// export const API_URL = 'http://localhost:3000';
export const API_URL = 'https://api.koreamedis.co.kr';
export const BASE_IMG_URL = 'https://s3.ap-northeast-2.amazonaws.com/file.koreamedis.com/';
export const EXCHANGE_EXTERNAL_URL = 'https://open.er-api.com/v6/latest';

export const KAKAKO_KEY = '96d0a8ff8acfd4e067e1eba97ba1e71a';
export const FACEBOOK_KEY = '351960399970939';
export const GOOGLE_KEY = '1053686177924-5s13l6n0dqsb40idalb5uk3h5kdp9qc6.apps.googleusercontent.com';
export const NAVER_KEY = '3Ty7pgGgkub34VU9WnkL';
export const NAVER_SECRET_KEY = 'aWyvwumxGD';

export const SETTING_CODES = [
  { idx: 0, name_ko: '병원유형', name_en: 'Hospital Type', tableName: 'hospitalType' },
  { idx: 1, name_ko: '지역', name_en: 'Region', tableName: 'region' },
  { idx: 2, name_ko: '상품유형', name_en: 'Product Category', tableName: 'productType' },
  { idx: 3, name_ko: '언어', name_en: 'Languages', tableName: 'languages' },
  { idx: 4, name_ko: 'FAQ', name_en: 'FAQ Category', tableName: 'faqCategory' },
];

export const NOTICE_CATEGORY = [
  { id: 'notice', name_ko: '전체공지', name_en: 'Notice' },
  { id: 'partner', name_ko: '파트너', name_en: 'Partner' },
  { id: 'hospital', name_ko: '병원', name_en: 'Hospital' },
];

export const RELATIONSHIP = [
  { id: 'me', name_ko: '본인', name_en: 'Me' },
  { id: 'spouse', name_ko: '배우자', name_en: 'Spouse' },
  { id: 'parent', name_ko: '부모', name_en: 'parent' },
  { id: 'children', name_ko: '자식', name_en: 'children' },
  { id: 'etc', name_ko: '기타', name_en: 'etc' },
];

export const ARR_DAY = [
  { id: 'mon', name_ko: '월요일', name_en: 'MON.' },
  { id: 'tue', name_ko: '화요일', name_en: 'TUE.' },
  { id: 'wed', name_ko: '수요일', name_en: 'WED.' },
  { id: 'thu', name_ko: '목요일', name_en: 'THU.' },
  { id: 'fri', name_ko: '금요일', name_en: 'FRI.' },
];

export const DAY_PART_TIME = [
  { id: 'am', name_ko: '오전', name_en: 'Morning' },
  { id: 'pm', name_ko: '오후', name_en: 'Afternoon' },
  { id: 'all', name_ko: '전일', name_en: 'All-day' },
  { id: 'none', name_ko: '선택안함', name_en: 'None' },
];

export const MEMBER_TYPE = [
  { id: 'member', name_ko: '일반회원', name_en: 'Member' },
  { id: 'partner', name_ko: '파트너', name_en: 'Partner' },
  { id: 'hospital', name_ko: '병원 담당자', name_en: 'Hospital' },
  { id: 'manager', name_ko: '담당자', name_en: 'Manger' },
];

export const MEMBER_STATUS = [
  { id: 'approval', name_ko: '승인', name_en: 'Approval' },
  { id: 'waiting', name_ko: '승인대기', name_en: 'Waiting' },
  // { id: 'badmember', name_ko: '불량회원', name_en: 'BadMember' },
];

export const CONSULT_STATUS = [
  { id: 'request', name_ko: '답변대기', name_en: 'Request' },
  { id: 'complete', name_ko: '답변완료', name_en: 'Complete' },
  { id: 'reserved', name_ko: '예약완료', name_en: 'Reserved' },
];

export const BOOKING_CATEGORY = [
  { id: 'hospital', name_ko: '병원예약', name_en: 'Hospital Reservation' },
  { id: 'product', name_ko: '상품예약', name_en: 'Product Reservation' },
  { id: 'pickup', name_ko: '픽업예약', name_en: 'Pickup Reservation' },
];

export const BOOKING_STATUS = [
  { id: 'request', name_ko: '예약요청', name_en: 'Request' },
  { id: 'confirm', name_ko: '예약일 확정', name_en: 'Confirmed' },
  { id: 'complete', name_ko: '예약(입금확인)완료', name_en: 'Complete' },
  { id: 'cancel', name_ko: '예약 취소', name_en: 'Cancel' },
  { id: 'close', name_ko: '서비스 종료', name_en: 'Close' },
];

export const GENDER_TYPE = [
  { id: 'male', name_ko: '남성', name_en: 'Male' },
  { id: 'female', name_ko: '여성', name_en: 'Female' },
  { id: 'etc', name_ko: '기타', name_en: 'etc' },
];

export const AGENCY_STATE = [
  { id: '0', name_ko: '미선택', name_en: 'Select' },
  { id: '1', name_ko: '선택', name_en: 'No Select' },
  { id: '2', name_ko: 'Agency 있음', name_en: 'Have Agency' },
];

export const DEF_NOTICE_CATE = NOTICE_CATEGORY[0].id;
export const DEF_NEWS_CATE = 'news';
export const DEF_BOOKING_CATE = BOOKING_CATEGORY[0].id;
export const DEF_BOOKING_STATUS = BOOKING_STATUS[0].id;
export const DEF_CONSULT_STATUS = CONSULT_STATUS[0].id;
export const DEF_GENDER = GENDER_TYPE[0].id;
export const DEF_AGENCY = AGENCY_STATE[1].id;
export const DEF_COUNTRY = 'KOR'; //alpha3
export const DEF_LANGUAGE = 'en'; //기본값 en
export const DEF_DATE = new Date().toString();
export const DEF_USER_STATUS = MEMBER_STATUS[0].id;

export const getTypeName = (types, id) => {
  const type = types.find((cate) => cate.id === id);
  return type ? type[getDefName()] : '';
};

export const getSystemLanguage = () => {
  return localStorage.getItem('systemLanguage') || DEF_LANGUAGE;
};

export const getDefName = () => {
  return `name_${DEF_LANGUAGE}`;
};

export const getSubName = () => {
  return DEF_LANGUAGE == 'en' ? `name_ko` : 'name_en';
};

export const getUser = () => {
  return Auth.currentAuthenticatedUser()
    .then((userData) => userData)
    .catch(() => {
      console.log('Not signed in');
    });
};

export const encrypt = (str) => {
  const hash = CryptoJS.MD5(str);
  const key = CryptoJS.enc.Utf8.parse(hash); // hex로 변환
  return CryptoJS.enc.Base64.stringify(key);
};

export const decrypt = (str) => {
  const decrypt = CryptoJS.enc.Base64.parse(str);
  return decrypt.toString(CryptoJS.enc.Utf8);
};

export const encryptAES = (str) => {
  return CryptoJS.AES.encrypt(str, process.env.REACT_APP_ENC_KEY);
};

export const decryptAES = (str) => {
  const dc = CryptoJS.AES.decrypt(str, process.env.REACT_APP_ENC_KEY);
  return dc.toString(CryptoJS.enc.Utf8);
};

export const groupBy = (array, key) => {
  return array.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export const tokenRefresh = async () => {
  try {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const currentSession = await Auth.currentSession();

    cognitoUser.refreshSession(currentSession.refreshToken, (err, session) => {
      if (session) {
        const { idToken } = session;
        sessionStorage.setItem('bt_jwt', idToken.jwtToken);
        return Promise.resolve(idToken.jwtToken);
      } else {
        console.log(err.message);
      }
    });
  } catch (e) {
    console.log('Unable to refresh Token', e);
  }
};

export const PHONE_REG = new RegExp(/^([0|\+[0-9]{1,5})?([0-9]{10})$/);
export const PHONE_REG2 = new RegExp(/^\d{3}-\d{3,4}-\d{4}$/);
export const PHONE_REG3 = new RegExp(/^\d{2,3}-\d{3,4}-\d{4}$/);
export const phoneNumberValidation = (number) => {
  if (PHONE_REG.test(number) || PHONE_REG2.test(number) || PHONE_REG3.test(number)) {
    return true;
  } else {
    return false;
  }
};

export const EMAIL_REG = /^([-_\.0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
export const emailValidation = (email) => {
  if (EMAIL_REG.test(email)) {
    return true;
  } else {
    return false;
  }
};

// ISO 3166-1 alpha-2
// ⚠️ No support for IE 11
export function countryToFlag(isoCode) {
  return typeof String.fromCodePoint !== 'undefined'
    ? isoCode.toUpperCase().replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
    : isoCode;
}
