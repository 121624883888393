import React from 'react';
import AWS from 'aws-sdk';
import awsconfig from '../../aws-exports';
const S3_BUCKET = 'file.koreamedis.com';
const REGION = 'ap-northeast-2';

AWS.config.update({
  accessKeyId: awsconfig.aws_access_key_id,
  secretAccessKey: awsconfig.aws_secret_access_key,
});

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});

const UploadToS3 = (file, folder, id, callback, keyName) => {
  return new Promise((resolve, reject) => {
    const orgFileName = file.name;
    const ext = file.name.substr(file.name.lastIndexOf('.') + 1);
    const _key = keyName ? folder + '/' + id + '_' + keyName + '.' + ext : folder + '/' + id + '_' + file.name;
    const params = {
      ACL: 'public-read',
      Body: file,
      Bucket: S3_BUCKET,
      Key: _key,
    };

    myBucket
      .putObject(params)
      .on('httpUploadProgress', (evt) => {
        // setProgress(Math.round((evt.loaded / evt.total) * 100))
        console.log(Math.round((evt.loaded / evt.total) * 100));
        if (callback) callback(Math.round((evt.loaded / evt.total) * 100));
      })
      .send((err, data) => {
        if (err) console.log(err);
        else {
          resolve({ key: _key, name: orgFileName, path: `https://s3.${REGION}.amazonaws.com/${S3_BUCKET}/${_key}` });
        }
      });
  });

  // };

  //
  // return <div>
  //   <div>Native SDK File Upload Progress is {progress}%</div>
  //   <input type="file" onChange={handleFileInput}/>
  //   <button onClick={() => uploadFile(selectedFile)}> Upload to S3</button>
  // </div>
};

export default UploadToS3;
