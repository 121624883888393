import ConsultCaseDetailPage from './ConsultCaseDetailPage';

export const ConsultCaseDetailPageConfig = {
  routes: [
    {
      path: '/pages/consult/case/detail',
      exact: true,
      component: ConsultCaseDetailPage,
    },
  ],
};
