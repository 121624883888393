import BookingDetailPage from './BookingDetailPage';

export const BookingDetailPageConfig = {
  routes: [
    {
      path: '/pages/booking/detail',
      exact: true,
      component: BookingDetailPage,
    },
  ],
};
