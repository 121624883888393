import React, { useEffect, useRef, useState } from 'react';
import queryString from 'query-string';
import OftadehLayout from '../../components/OftadehLayout/OftadehLayout';
import { Button, Grid, makeStyles, Typography } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import swal from '@sweetalert/with-react';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import SaveIcon from '@material-ui/icons/Save';
import ListIcon from '@material-ui/icons/List';
import DeleteIcon from '@material-ui/icons/Delete';
import { getDiseaseList, getHospitalList, getProductInfo, getProductTypeList } from '../../constants/PromiseHttp';
import { API_URL, getDefName, IS_SYS_ADMIN } from '../../constants';
import axiosInstance from '../../constants/AxiosInstance';
import ImgListView from '../customComp/ImgListView';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    color: '#3f51b5',
  },
  my3: {
    margin: '1.3rem 0',
  },
  mRight: {
    marginRight: '.85rem',
  },
  mInput: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
    display: 'flex',
    flexWrap: 'wrap',
  },
}));

const ProductDetailPage = (props) => {
  const query = queryString.parse(props.location.search);
  const type = query.type ? query.type : 'new';
  const IS_SYS_ADMIN = sessionStorage.getItem('bt_role') === 'SYS_ADM';

  const [product, setProduct] = useState({});
  const [types, setTypes] = useState([]);
  const [hospitalList, setHospitalList] = useState([]);
  const [diseases, setDiseases] = useState();

  const [isWriter, setIsWriter] = useState(false);

  const { history } = props;
  const classes = useStyles();

  useEffect(() => {
    getProductTypeList().then((result) => {
      setTypes(result);
    });
    getDiseaseList().then((result) => {
      setDiseases(result);
    });
    getHospitalList().then((result) => {
      setHospitalList(result);
    });
    getData();
  }, []);

  const getData = () => {
    if (type === 'edit' && query.id) {
      getProductInfo(query.id).then((result) => {
        setProduct(result);
        if (result.writerId) {
          setIsWriter(result.writerId === sessionStorage.getItem('bt_user'));
        }
      });
    }
  };

  const deleteProduct = () => {
    swal({
      title: 'Are you sure you want to delete?',
      text: '',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        axiosInstance.delete(`${API_URL}/product/${query.id}`, { headers: { Pragma: 'no-cache' } }).then((response) => {
          if (response.status === 200) {
            let msg = 'It is deleted.';
            swal(msg, {
              icon: 'success',
            });
            goList();
          }
        });
      } else {
      }
    });
  };

  const goList = () => {
    history.push('/pages/product/');
  };

  const goEdit = () => {
    history.push(`/pages/product/edit?type=edit&id=${query.id}`);
  };

  const getDiseaseName = (id) => {
    let names = '';
    if (!id || !diseases) {
      return names;
    }
    let di = diseases.find((_di) => _di.id === id);
    names = di[getDefName()];
    return names;
  };

  const getHospitalName = (hid) => {
    const hospital = hospitalList && hospitalList.find((hos) => hos.id === hid);
    return hospital ? hospital['name_ko'] : '-';
  };

  const getCategoryName = (id) => {
    const obj = types.find((obj) => obj.id === id);
    return obj ? obj[getDefName()] : '';
  };

  const getSchedule = (schedule) => {
    let text = '';
    if (!schedule) {
      return text;
    }

    schedule.map((s, idx) => {
      text += `${s.day || ''} : ${s.contents || ''} ${idx !== schedule.length - 1 && '\n'}`;
    });

    return text.split('\n').map((line) => {
      return (
        <span>
          {line}
          <br />
        </span>
      );
    });
    //return text;
  };

  const getPrice = (price) => {
    let text = '';
    if (!price) {
      return text;
    }

    price.map((p, idx) => {
      text += (p.title || '') + ' : ' + (p.orgPrice || '') + ' -> ' + (p.finalPrice || '');
      if (idx !== price.length - 1) {
        text += ' / ';
      }
    });

    return text;
  };

  return (
    <OftadehLayout>
      <Grid container className={classes.my3} alignItems="center">
        <Grid item className={classes.mRight} style={{ fontWeight: 'bold' }}>
          <Typography variant="h5" component="h1" style={{ fontWeight: 'bold' }}>
            Product Detail
          </Typography>
        </Grid>
      </Grid>

      <div className={classes.root}>
        <Card className={classes.root}>
          <CardContent className={classes.root}>
            <Grid container spacing={3}>
              <Grid item xs={4} mb={6}>
                <div className={classes.title}>Language</div>
                <Typography variant="body1">{(product && product.languageId) || ''}</Typography>
              </Grid>
              <Grid item xs={6} mb={6}>
                <div className={classes.title}>Product Number</div>
                <Typography variant="body1">{(product && product.code) || ''}</Typography>
              </Grid>
              <Grid item xs={4} mb={6}>
                <div className={classes.title}>Category</div>
                <Typography variant="body1">{(product && getCategoryName(product.typeId)) || ''}</Typography>
              </Grid>
              <Grid item xs={6} mb={6}>
                <div className={classes.title}>Product Name</div>
                <Typography variant="body1">{(product && product.name_ko) || ''}</Typography>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.title}>Product Images</div>
                {product.images && <ImgListView images={product.images} />}
              </Grid>
              <Grid item xs={6} mb={6}>
                <div className={classes.title}>Related Hospital</div>
                <Typography variant="body1">{(product && getHospitalName(product.hospitalId)) || ''}</Typography>
              </Grid>
              <Grid item xs={6} mb={6}>
                <div className={classes.title}>Related Disease</div>
                <Typography variant="body1">{(product && getDiseaseName(product.diseaseId)) || ''}</Typography>
              </Grid>
              <Grid item xs={6} mb={6}>
                <div className={classes.title}>Price</div>
                <Typography variant="body1">{(product && getPrice(product.price)) || ''}</Typography>
              </Grid>
              <Grid item xs={6} mb={6}>
                <div className={classes.title}>Deposit(KRW)</div>
                <Typography variant="body1">{(product && product.deposit) || ''}</Typography>
              </Grid>
              <Grid item xs={6} mb={6}>
                <div className={classes.title}>Schedule</div>
                <Typography variant="body1">{(product && getSchedule(product.schedule)) || ''}</Typography>
              </Grid>
              <Grid item xs={6} mb={6}>
                <div className={classes.title}>Time required</div>
                <Typography variant="body1">{(product && product.requiredTime) || ''}</Typography>
              </Grid>
              <Grid item xs={12} mb={6}>
                <div className={classes.title}>Introduce</div>
                <div dangerouslySetInnerHTML={{ __html: product && product.desc }} />
              </Grid>
              <Grid item xs={12} mb={6}>
                <div className={classes.title}>Payment information</div>
                <div dangerouslySetInnerHTML={{ __html: product && product.payDesc }} />
              </Grid>
              <Grid item xs={12} mb={6}>
                <div className={classes.title}>Caution</div>
                <div dangerouslySetInnerHTML={{ __html: product && product.cautionDesc }} />
              </Grid>
              <Grid item xs={6} mb={6}>
                <div className={classes.title}>Tags</div>
                <Typography variant="body1">{(product && product.tags) || ''}</Typography>
              </Grid>
              <Grid item xs={6} mb={6}>
                <div className={classes.title}>Show/Hide</div>
                <Typography variant="body1">{product && product.hidden ? 'Hide' : 'Show'}</Typography>
              </Grid>
            </Grid>
          </CardContent>
          <CardActions style={{ marginLeft: '16px', marginRight: '16px' }}>
            <Grid container justifyContent={'space-between'} xs={12} md={12} spacing={3}>
              <Grid item>
                <Button
                  variant="contained"
                  style={{ backgroundColor: green[700], color: '#ffffff', marginRight: '10px' }}
                  size="medium"
                  onClick={goList}
                  startIcon={<ListIcon />}
                >
                  List
                </Button>
                {/*{(IS_SYS_ADMIN || isWriter) && (*/}
                <Button variant="contained" color="secondary" size="medium" onClick={deleteProduct} startIcon={<DeleteIcon />}>
                  Delete
                </Button>
                {/*)}*/}
              </Grid>
              {/*{(IS_SYS_ADMIN || isWriter) && (*/}
              <Grid item>
                <Button variant="contained" color="primary" size="medium" onClick={goEdit} startIcon={<SaveIcon />}>
                  Edit
                </Button>
              </Grid>
              {/*)}*/}
            </Grid>
          </CardActions>
        </Card>
      </div>
    </OftadehLayout>
  );
};

export default ProductDetailPage;
