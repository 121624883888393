import MemberDetailPage from './MemberDetailPage';

export const MemberDetailPageConfig = {
  routes: [
    {
      path: '/pages/member/detail',
      exact: true,
      component: MemberDetailPage,
    },
  ],
};
