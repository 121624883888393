import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Card,
  CardHeader,
  Checkbox,
  CircularProgress,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { getDiseaseList, getSubDiseaseAll } from '../../constants/PromiseHttp';
import { getDefName, getSubName } from '../../constants';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
  },
  paper: {
    width: 300,
    height: 300,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
  listSection: {
    backgroundColor: 'white',
  },
  ul: {
    backgroundColor: 'inherit',
    padding: 0,
  },
  mInput: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
    textAlign: 'center',
  },
  list: {
    width: '100%',
    height: 500,
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
  },
}));

const DiseaseTransferList = (props) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const [checked, setChecked] = React.useState([]);
  const [left, setLeft] = React.useState([]);
  const [right, setRight] = React.useState([]);
  const [leftChecked, setLeftChecked] = React.useState([]);
  const [rightChecked, setRightChecked] = React.useState([]);

  const [rightArr, setRightArr] = React.useState([]);
  const [parent, setParent] = React.useState([]);
  const [allButtons, setAllButtons] = React.useState([]);

  useEffect(() => {
    const checked = [];
    setLoading(true);
    getDiseaseList().then((result) => {
      const parentResult = result;
      const newRightArr = [];
      getSubDiseaseAll().then((subResult) => {
        subResult.map((_sdi) => {
          const sdi = _sdi;
          parentResult.map((_di) => {
            if (sdi.parentId === _di.id) {
              if (props.selectedDisease && props.selectedDisease.includes(sdi.id)) {
                const _sub = _di['sub'] ? [..._di['sub'], sdi] : [sdi];
                if (!checked.find((c) => c.id === _di.id)) {
                  checked.push({
                    id: _di.id,
                    name_ko: _di.name_ko,
                    sub: _sub.filter((s) => props.selectedDisease.includes(s.id)),
                  });
                } else {
                  const exist = checked.find((c) => c.id === _di.id);
                  exist['sub'] = exist['sub'] ? [...exist['sub'], sdi] : [sdi];
                }
              }

              _di['sub'] = _di['sub'] ? [..._di['sub'], sdi] : [sdi];
            }
          });

          if (props.selectedDisease && props.selectedDisease.includes(sdi.id)) {
            console.log('checked :', sdi.id);
            newRightArr.push(sdi.id);
          }
        });

        setRightArr(newRightArr);
        setRight(checked);
        setParent(parentResult);
        setLeft(parentResult);
        setLoading(false);
      });
    });
  }, []);

  const handleToggle = (value, direction) => () => {
    const currentIndex = checked.findIndex((ch) => ch.id === value.id);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);

    if (direction === 'left') {
      setLeftChecked(newChecked);
    } else {
      setRightChecked(newChecked);
    }
  };

  const handleCheckedRight = () => {
    const arr = [];
    const tempRight = [];
    leftChecked.map((lc, idx) => {
      const findParent = parent.find((pp) => pp.id === lc.parentId);
      const cloneObj = (obj) => JSON.parse(JSON.stringify(obj));
      const newParent = cloneObj(findParent);
      const exist = right.find((r) => r.id === newParent.id);
      const exist2 = tempRight.find((r) => r.id === newParent.id);
      if (exist) {
        if (!exist.sub.includes(lc)) {
          exist.sub.push(lc);
        }
      } else if (exist2) {
        if (!exist2.sub.includes(lc)) {
          exist2.sub.push(lc);
        }
      } else {
        newParent.sub = [lc];
        tempRight.push(newParent);
      }

      arr.push(lc.id);
      if (idx === leftChecked.length - 1) {
        setRightArr([...rightArr, ...arr]);
        props.setSelectedDisease([...rightArr, ...arr]);
        setRight([...right, ...tempRight]);
      }
    });

    setChecked([]);
    setLeftChecked([]);
    setRightChecked([]);

    for (let i = 0; i < allButtons.length; i++) {
      allButtons[i].innerText = 'SELECT ALL';
    }
  };

  const handleCheckedLeft = () => {
    rightChecked.map((r, idx) => {
      const rp = right.find((rg) => rg.id === r.parentId);
      rp.sub = rp.sub.filter((item) => item.id !== r.id);
      const removeIdx = rightArr.findIndex((item) => item === r.id);
      rightArr.splice(removeIdx, 1);
    });

    setChecked([]);
    setLeftChecked([]);
    setRightChecked([]);
    for (let i = 0; i < allButtons.length; i++) {
      allButtons[i].innerText = 'SelectAll';
    }
    props.setSelectedDisease(rightArr);
  };

  const handleSelectAll = (val, direction) => {
    const button = document.getElementById(direction + '-all-' + val.id);
    allButtons.push(button);
    setAllButtons([...allButtons]);
    if (val.sub) {
      if (button.innerText === 'SELECT ALL') {
        setChecked([...checked, ...val.sub]);
        if (direction === 'left') {
          setLeftChecked([...checked, ...val.sub]);
        } else {
          setRightChecked([...checked, ...val.sub]);
        }
      } else {
        setChecked(checked.filter((ch) => ch.parentId !== val.id));
        if (direction === 'left') {
          setLeftChecked(checked.filter((ch) => ch.parentId !== val.id));
        } else {
          setRightChecked(checked.filter((ch) => ch.parentId !== val.id));
        }
      }
    }

    if (button.innerText === 'SELECT ALL') {
      button.innerText = 'UNSELECT ALL';
    } else {
      button.innerText = 'SELECT ALL';
    }
  };

  const customList = (items, direction) => {
    return (
      //<Paper className={classes.paper}>
      <Card>
        <CardHeader
          className={classes.cardHeader}
          titleTypographyProps={{ variant: 'subtitle2' }}
          title={direction === 'left' ? 'UnSelect' : 'Select'}
        />
        <Divider />
        <List className={classes.list} dense subheader={<li />}>
          {items &&
            items.map((value, idx) => {
              let count = 0;
              if (value.sub) count = value.sub.length;
              const headerDp = direction === 'left' ? true : count > 0;
              return (
                <li key={`section-${idx}`} className={classes.listSection}>
                  <ul className={classes.ul}>
                    {headerDp && (
                      <ListSubheader>
                        {`${value[getDefName()]}(${value[getSubName()]})`}
                        <Button
                          variant={'outlined'}
                          size={'small'}
                          color={'primary'}
                          style={{ marginLeft: 10 }}
                          id={direction + '-all-' + value.id}
                          onClick={(e) => handleSelectAll(value, direction)}
                        >
                          SELECT ALL
                        </Button>
                      </ListSubheader>
                    )}
                    {value.sub &&
                      value.sub.map((sub, idx) => {
                        const dp = !(direction === 'left' && rightArr.includes(sub.id));
                        const checkedArr = direction === 'left' ? leftChecked : rightChecked;
                        if (dp) {
                          return (
                            <ListItem key={'sub-' + idx} role="listitem" button onClick={handleToggle(sub, direction)}>
                              <ListItemIcon>
                                <Checkbox checked={checkedArr.findIndex((ch) => ch.id === sub.id) !== -1} tabIndex={-1} disableRipple />
                              </ListItemIcon>
                              <ListItemText id={sub.id} primary={`${sub[getDefName()]}(${sub[getSubName()]})`} />
                            </ListItem>
                          );
                        }
                      })}
                  </ul>
                  {/*)}*/}
                </li>
              );
            })}
          <ListItem />
        </List>
      </Card>
    );
  };

  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center" className={classes.mInput}>
      <Grid item xs={5}>
        {customList(left, 'left')}
      </Grid>
      <Grid item xs={2}>
        <Grid container direction="column" alignItems="center">
          {/*<Button*/}
          {/*  variant="outlined"*/}
          {/*  size="small"*/}
          {/*  className={classes.button}*/}
          {/*  onClick={handleAllRight}*/}
          {/*  // disabled={left.length === 0}*/}
          {/*  aria-label="move all right"*/}
          {/*>*/}
          {/*  ≫*/}
          {/*</Button>*/}
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
          {/*<Button*/}
          {/*  variant="outlined"*/}
          {/*  size="small"*/}
          {/*  className={classes.button}*/}
          {/*  onClick={handleAllLeft}*/}
          {/*  disabled={right.length === 0}*/}
          {/*  aria-label="move all left"*/}
          {/*>*/}
          {/*  ≪*/}
          {/*</Button>*/}
        </Grid>
      </Grid>
      <Grid item xs={5}>
        {customList(right, 'right')}
      </Grid>
      {loading && (
        <div style={{ position: 'fixed', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', zIndex: 999 }}>
          <CircularProgress color={'secondary'} />
        </div>
      )}
    </Grid>
  );
};

export default DiseaseTransferList;
