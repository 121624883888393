import React, { forwardRef, useEffect, useImperativeHandle } from 'react';
import { Dialog, DialogTitle } from '@material-ui/core';
let editorJodit = {};
const Jodit = forwardRef((props, ref) => {
  const config = {
    style: {
      height: '350px',
    },
  };

  useImperativeHandle(ref, () => ({
    async setValue(val) {
      if (val) {
        editorJodit[props.id].value = val;
      }
    },
    async getValue() {
      return editorJodit[props.id].value;
    },
  }));

  const showPreview = (editor) => {
    return window.open('', 'PopupWin', 'width=500,height=600').document.write(`${editor.value}`);
  };

  useEffect(() => {
    editorJodit[props.id] = new window.Jodit('#editor-' + props.id, {
      controls: {
        preview: {
          exec: function (editor) {
            showPreview(editor);
          },
        },
      },
      style: config.style,
      removeButtons: ['fullsize', 'file'],
      uploader: {
        url: props.uploadApi,
        filesVariableName: function (t) {
          return 'files';
        },
        isSuccess: function (resp) {
          return !resp.error;
        },
        getMsg: function (resp) {
          return resp.msg;
        },
        process: function (resp) {
          return {
            files: resp['files'] || [],
            path: resp.path,
            baseurl: resp.baseurl,
            error: resp.error,
            msg: resp.msg,
          };
        },
        defaultHandlerSuccess: function (data, resp) {
          console.log('defaultHandlerSuccess', data);
          const field = 'files';
          if (data[field] && data[field].length) {
            for (let i = 0; i < data[field].length; i += 1) {
              this.selection.insertImage(data[field][i].location);
            }
          }
        },
        error: function (e) {
          console.log('error', e);
        },
        config: config,
      },
    });

    document.getElementById('editor-' + props.id).addEventListener('change', props.handleEditor);
    if (props.val) editorJodit[props.id].value = props.val;
  }, []);

  return <textarea id={'editor-' + props.id} name={'editor-' + props.id} />;
});
export default Jodit;
