import React, { useEffect, useState } from 'react';
import OftadehLayout from '../../../components/OftadehLayout/OftadehLayout';
import { Button, CircularProgress, Divider, Grid, makeStyles, Typography } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import { getBannerList, getLanguageList } from '../../../constants/PromiseHttp';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { getDefName } from '../../../constants';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  my3: {
    margin: '1.3rem 0',
  },
  mRight: {
    marginRight: '.85rem',
  },
  mTop: {
    marginTop: '20px',
  },
}));

const SettingBannerListPage = (props) => {
  const { history } = props;
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [nowData, setNowData] = useState();
  const [waitingData, setWaitingData] = useState();
  const [completeData, setCompleteData] = useState();
  // const [types, setTypes] = useState([]);
  const [languages, setLanguages] = useState([]);

  const cols = [
    {
      name: 'id',
      label: 'id',
      options: {
        filter: false,
        sort: true,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: 'id',
      label: 'No.',
      options: {
        filter: false,
        sort: true,
        display: true,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return tableMeta.rowIndex + 1;
        },
      },
    },
    {
      name: 'languageId',
      label: 'Language',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const lang = languages.find((lang) => lang.id === value);
          return lang ? lang[getDefName()] : '';
        },
      },
    },
    {
      name: 'position',
      label: '위치',
      options: {
        filter: false,
        sort: true,
        display: false,
      },
    },
    {
      name: 'title',
      label: 'Title',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'items',
      label: 'Banner Count',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? value.length : 0;
        },
      },
    },
    {
      name: 'startDate',
      label: 'Start Date',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const dt = new Date(Number(value));
          return dt.getFullYear() + '-' + (dt.getMonth() + 1) + '-' + dt.getDate() + ' ' + dt.getHours() + ':' + dt.getMinutes();
        },
      },
    },
    {
      name: 'endDate',
      label: 'End Date',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const dt = new Date(Number(value));
          return dt.getFullYear() + '-' + (dt.getMonth() + 1) + '-' + dt.getDate() + ' ' + dt.getHours() + ':' + dt.getMinutes();
        },
      },
    },
    {
      name: 'createdAt',
      label: 'Registration Date',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return moment(Number(value)).format('YYYY-MM-DD HH:mm');
        },
      },
    },
  ];

  const rowClick = (data) => {
    console.log(data);
    history.push(`/pages/setting/banner/detail?type=edit&id=${data[0]}`);
  };

  const options = {
    print: false,
    filter: false,
    sort: false,
    search: false,
    filterType: 'checkbox',
    selectableRows: 'none',
    viewColumns: false,
    download: false,
    onRowClick: rowClick,
  };

  useEffect(() => {
    getLanguageList().then((result) => {
      setLanguages(result);
    });
    getData();
  }, []);

  const getData = () => {
    setLoading(true);
    getBannerList('now').then((result) => {
      setNowData(result);
    });
    getBannerList('before').then((result) => {
      setWaitingData(result);
    });
    getBannerList('after').then((result) => {
      setLoading(false);
      setCompleteData(result);
    });
  };

  return (
    <OftadehLayout>
      <Grid container className={classes.my3} alignItems="center">
        <Grid item className={classes.mRight} style={{ fontWeight: 'bold' }}>
          <Typography variant="h5" component="h1" style={{ fontWeight: 'bold' }}>
            Banner List
          </Typography>
        </Grid>
        <Grid item>
          <Button onClick={() => history.push('/pages/setting/banner/detail?type=new')} variant="outlined" color="primary" size="small">
            Registration
          </Button>
        </Grid>
      </Grid>
      {/*<OftadehBreadcrumbs path={history} />*/}
      <div>
        <Card className={classes.root}>
          <CardContent>
            <Grid container spacing={3} className={classes.mTop}>
              <Grid item xs={12} mb={12}>
                <Typography variant="h6">Ongoing Banner List</Typography>
              </Grid>
              <Grid item xs={12} mb={12}>
                <MUIDataTable title={''} data={nowData} columns={cols} options={options} />
              </Grid>
            </Grid>
            <Grid container spacing={3} className={classes.mTop}>
              <Grid item xs={12} mb={12}>
                <Typography variant="h6">Wating Banner List</Typography>
              </Grid>
              <Divider variant={'fullWidth'} />
              <Grid item xs={12} mb={12}>
                <MUIDataTable title={''} data={waitingData} columns={cols} options={options} />
              </Grid>
            </Grid>
            <Grid container spacing={3} className={classes.mTop}>
              <Grid item xs={12} mb={12}>
                <Typography variant="h6">Finished Banner List</Typography>
              </Grid>
              <Divider variant={'fullWidth'} />
              <Grid item xs={12} mb={12}>
                <MUIDataTable title={''} data={completeData} columns={cols} options={options} />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>
      {loading && (
        <div style={{ position: 'fixed', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', zIndex: 999 }}>
          <CircularProgress color={'secondary'} />
        </div>
      )}
    </OftadehLayout>
  );
};

export default SettingBannerListPage;
