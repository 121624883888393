import ManagerDetailPage from './ManagerDetailPage';

export const ManagerDetailPageConfig = {
  routes: [
    {
      path: '/pages/manager/detail',
      exact: true,
      component: ManagerDetailPage,
    },
  ],
};
