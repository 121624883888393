import QuestionDetailPage from './QuestionDetailPage';

export const QuestionDetailPageConfig = {
  routes: [
    {
      path: '/pages/question/detail',
      exact: true,
      component: QuestionDetailPage,
    },
  ],
};
