import React from 'react';
import { AppBar, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabBookingList from './TabBookingList';
import TabReviewList from './TabReviewList';

const MemberRelationList = (props) => {
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        style={{ width: '100%' }}
        role="tabpanel"
        hidden={value !== index}
        key={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && getTabView(index)}
      </div>
    );
  }

  const getTabView = (idx) => {
    switch (idx) {
      case 0:
        return <TabBookingList id={`tabview-${idx}`} userId={props.userId} history={props.history} />;
      case 1:
        return <TabReviewList id={`tabview-${idx}`} userId={props.userId} history={props.history} />;
      default:
        return <TabBookingList id={`tabview-${idx}`} userId={props.userId} history={props.history} />;
    }
  };
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  const [tabValue, setTabValue] = React.useState(0);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Grid container spacing={1} style={{ marginTop: '10px', minHeight: '100px' }}>
      <AppBar position="static">
        <Tabs value={tabValue} onChange={handleTabChange} aria-label="tabs">
          <Tab label="Reservation" {...a11yProps(0)} />
          <Tab label="Review" {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <TabPanel value={tabValue} index={0} />
      <TabPanel value={tabValue} index={1} />
    </Grid>
  );
};
export default MemberRelationList;
