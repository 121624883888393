import ReviewDetailPage from './ReviewDetailPage';

export const ReviewDetailPageConfig = {
  routes: [
    {
      path: '/pages/review/detail',
      exact: true,
      component: ReviewDetailPage,
    },
  ],
};
