import RecDoctorListPage from './RecDoctorListPage';

export const RecDoctorListPageConfig = {
  routes: [
    {
      path: '/pages/recDoctor',
      exact: true,
      component: RecDoctorListPage,
    },
  ],
};
