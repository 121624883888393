import React, { useEffect, useState } from 'react';
import OftadehLayout from '../../components/OftadehLayout/OftadehLayout';
import { Button, CircularProgress, Grid, makeStyles, Typography } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import { API_URL, BASE_IMG_URL, getDefName } from '../../constants';
import { getHospitalList, getLanguageList, getProductList, getProductManagerList, getProductTypeList } from '../../constants/PromiseHttp';
import swal from '@sweetalert/with-react';
import axiosInstance from '../../constants/AxiosInstance';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  my3: {
    margin: '1.3rem 0',
  },
  mb0: {
    marginBottom: 0,
  },
  mRight: {
    marginRight: '.85rem',
  },
  p1: {
    padding: '.85rem',
  },
}));

const ProductListPage = (props) => {
  const { history } = props;
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState();
  const [types, setTypes] = useState([]);
  const [hospitals, setHospitals] = useState([]);
  const [languages, setLanguages] = useState([]);

  const cols = [
    {
      name: 'id',
      label: 'id',
      options: {
        filter: false,
        sort: true,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: 'id',
      label: 'No.',
      options: {
        filter: false,
        sort: true,
        display: true,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return tableMeta.rowIndex + 1;
        },
      },
    },
    {
      name: 'languageId',
      label: 'Language',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const lang = languages.find((lang) => lang.id === value);
          return lang ? lang[getDefName()] : '';
        },
      },
    },
    {
      name: 'typeId',
      label: 'Category',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const type = types.find((_type) => _type.id === value);
          return type ? type[getDefName()] : '';
        },
      },
    },
    {
      name: 'hospitalId',
      label: 'Hospital',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const hos = hospitals.find((_hos) => _hos.id === value);
          return hos ? hos['name_ko'] : '';
        },
      },
    },
    {
      name: 'images',
      label: 'Photo',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          if (value) {
            const url = `${BASE_IMG_URL}` + value[0].key;

            return <img src={url} width={30} alt={value[0].name} />;
          } else {
            return '';
          }
        },
      },
    },
    {
      name: 'name_ko',
      label: 'Product Name',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'hidden',
      label: 'Show/Hide',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value ? 'Hide' : 'Show';
        },
      },
    },
    {
      name: 'createdAt',
      label: 'Registration Date',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return moment(Number(value)).format('YYYY-MM-DD HH:mm');
        },
      },
    },
    {
      name: 'viewCnt',
      label: 'View Count',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value || 0;
        },
      },
    },
  ];

  const rowClick = (data) => {
    //console.log(data);
    history.push(`/pages/product/detail?type=edit&id=${data[0]}`);
  };

  const rowDelete = (row) => {
    let ids = [];
    row.data.map((r) => {
      ids.push(data[r.dataIndex].id);
      return r;
    });
    let delParam = {
      ids: ids.toString(),
    };
    swal({
      title: 'Are you sure you want to delete?',
      text: '',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        axiosInstance.post(`${API_URL}/product/deleteIds`, delParam, { headers: { Pragma: 'no-cache' } }).then((response) => {
          swal('It is deleted.', {
            icon: 'success',
          });
          getData();
        });
      } else {
        getData();
      }
    });
  };

  const options = {
    print: false,
    filter: false,
    filterType: 'checkbox',
    selectableRows: 'multiple',
    viewColumns: false,
    download: false,
    onRowClick: rowClick,
    onRowsDelete: rowDelete,
  };

  useEffect(() => {
    getData();
    getProductTypeList().then((result) => {
      setTypes(result);
    });
    getHospitalList(true).then((result) => {
      setHospitals(result);
    });
    getLanguageList().then((result) => {
      setLanguages(result);
    });
  }, []);

  const getData = () => {
    setLoading(true);
    if (sessionStorage.getItem('bt_role') === 'SYS_MGR') {
      getProductManagerList(sessionStorage.getItem('bt_user')).then((result) => {
        setLoading(false);
        setData(result);
      });
    } else {
      getProductList().then((result) => {
        setLoading(false);
        setData(result);
      });
    }
  };

  return (
    <OftadehLayout>
      <Grid container className={classes.my3} alignItems="center">
        <Grid item className={classes.mRight} style={{ fontWeight: 'bold' }}>
          <Typography variant="h5" component="h1" style={{ fontWeight: 'bold' }}>
            Product List
          </Typography>
        </Grid>
        <Grid item>
          <Button onClick={() => history.push('/pages/product/edit?type=new')} variant="outlined" color="primary" size="small">
            Registration
          </Button>
        </Grid>
      </Grid>
      {/*<OftadehBreadcrumbs path={history} />*/}
      <MUIDataTable title={''} data={data} columns={cols} options={options} />
      {loading && (
        <div style={{ position: 'fixed', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', zIndex: 999 }}>
          <CircularProgress color={'secondary'} />
        </div>
      )}
    </OftadehLayout>
  );
};

export default ProductListPage;
