import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import OftadehLayout from '../../components/OftadehLayout/OftadehLayout';
import { Button, Grid, makeStyles, Typography } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import swal from '@sweetalert/with-react';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import SaveIcon from '@material-ui/icons/Save';
import ListIcon from '@material-ui/icons/List';
import DeleteIcon from '@material-ui/icons/Delete';
import { getHospitalList, getNewsCategoryList, getNewsInfo, getSubDiseaseAll } from '../../constants/PromiseHttp';
import { API_URL, getDefName } from '../../constants';
import axiosInstance from '../../constants/AxiosInstance';
import ImgListView from '../customComp/ImgListView';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    color: '#3f51b5',
  },
  my3: {
    margin: '1.3rem 0',
  },
}));

const NewsDetailPage = (props) => {
  const query = queryString.parse(props.location.search);
  const type = query.type ? query.type : 'new';

  const [news, setNews] = useState({});
  const [categoryList, setCategoryList] = useState([]);
  const [hospitalList, setHospitalList] = useState([]);
  const [subDiseases, setSubDiseases] = useState();

  const { history } = props;
  const classes = useStyles();

  useEffect(() => {
    getNewsCategoryList().then((result) => {
      // console.log(result);
      setCategoryList(result);
    });
    getSubDiseaseAll().then((result) => {
      setSubDiseases(result);
    });
    getHospitalList().then((result) => {
      setHospitalList(result);
    });
    getData();
  }, []);

  const getData = () => {
    if (type === 'edit' && query.id) {
      getNewsInfo(query.id).then((result) => {
        setNews(result);
      });
    }
  };

  const deleteNews = () => {
    swal({
      title: 'Are you sure you want to delete?',
      text: '',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        axiosInstance.delete(`${API_URL}/news/${query.id}`, { headers: { Pragma: 'no-cache' } }).then((response) => {
          if (response.status === 200) {
            swal('It is deleted.', {
              icon: 'success',
            });
            goList();
          }
        });
      } else {
      }
    });
  };

  const goList = () => {
    history.push('/pages/news/');
  };
  const goEdit = () => {
    history.push(`/pages/news/edit?type=edit&id=${query.id}`);
  };

  const getDiseaseNames = (ids) => {
    let names = '';
    if (!ids || !subDiseases) {
      return names;
    }

    for (let i = 0; i < ids.length; i++) {
      let sdi = subDiseases.find((_sdi) => _sdi.id === ids[i]);
      if (i > 0) names += ', ';
      names += sdi[getDefName()] || '';
    }
    return names;
  };
  const getHospitalName = (hid) => {
    const hospital = hospitalList && hospitalList.find((hos) => hos.id === hid);
    return hospital ? hospital['name_ko'] : '-';
  };
  const getCategorylName = (cid) => {
    const cate = categoryList.find((ct) => ct.id === cid);
    return cate ? cate[getDefName()] : '';
  };

  return (
    <OftadehLayout>
      <Grid container className={classes.my3} alignItems="center">
        <Grid item className={classes.mRight} style={{ fontWeight: 'bold' }}>
          <Typography variant="h5" component="h1" style={{ fontWeight: 'bold' }}>
            News & Column Detail
          </Typography>
        </Grid>
      </Grid>

      <Card className={classes.root}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12} mb={12}>
              <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                {getCategorylName(news.categoryId)}
              </Typography>
            </Grid>
            <Grid item xs={6} mb={6}>
              <Grid item xs={6} mb={6}>
                <div className={classes.title}>Language</div>
                <Typography variant="body1">{news.languageId}</Typography>
              </Grid>
            </Grid>
            <Grid item xs={6} mb={6}>
              <div className={classes.title}>Hospital</div>
              <Typography variant="body1">{getHospitalName(news.hospitalId)}</Typography>
            </Grid>
            <Grid item xs={12} mb={6}>
              <div className={classes.title}>Title</div>
              <Typography variant="body1">{news.title}</Typography>
            </Grid>
            <Grid item xs={12} mb={6}>
              <div className={classes.title}>Contents</div>
              <div dangerouslySetInnerHTML={{ __html: news.contents }} />
            </Grid>
            <Grid item xs={6} mb={6}>
              <div className={classes.title}>Tags</div>
              <Typography variant="body1">{news.tags}</Typography>
            </Grid>
            <Grid item xs={6} mb={6}>
              <div className={classes.title}>Show/Hide</div>
              <Typography variant="body1">{news && news.hidden ? 'Hide' : 'Show'}</Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card className={classes.my3}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12} mb={12}>
              <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                Thumbnail
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {news && <ImgListView images={news && news.images} />}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card className={classes.my3}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12} mb={12}>
              <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                Disease
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.title}>Disease</div>
              <Typography variant="body1">{getDiseaseNames(news && news.subDiseaseIds)}</Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Grid container xs={12} md={12} spacing={3}>
        <Grid item>
          <Button
            variant="contained"
            style={{ backgroundColor: green[700], color: '#ffffff', marginRight: '10px' }}
            size="medium"
            onClick={goList}
            startIcon={<ListIcon />}
          >
            List
          </Button>
          {type !== 'new' ? (
            <Button variant="contained" color="secondary" size="medium" onClick={deleteNews} startIcon={<DeleteIcon />}>
              Delete
            </Button>
          ) : null}
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" size="medium" onClick={goEdit} startIcon={<SaveIcon />}>
            Edit
          </Button>
        </Grid>
      </Grid>
    </OftadehLayout>
  );
};

export default NewsDetailPage;
