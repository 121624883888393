import NewsDetailPage from './NewsDetailPage';

export const NewsDetailPageConfig = {
  routes: [
    {
      path: '/pages/news/detail',
      exact: true,
      component: NewsDetailPage,
    },
  ],
};
