import React, { useEffect, useRef, useState } from 'react';
import queryString from 'query-string';
import OftadehLayout from '../../components/OftadehLayout/OftadehLayout';
import { Button, CircularProgress, FormControlLabel, Grid, makeStyles, Switch, TextField, Typography } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import swal from '@sweetalert/with-react';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import SaveIcon from '@material-ui/icons/Save';
import ListIcon from '@material-ui/icons/List';
import DeleteIcon from '@material-ui/icons/Delete';
import { getProductInfo, getProductTypeList } from '../../constants/PromiseHttp';
import HospitalAutocomplete from '../customComp/HospitalAutocomplete';
import LanguageAutocomplete from '../customComp/LanguageAutocomplete';
import { Autocomplete } from '@material-ui/lab';
import { API_URL, DEF_LANGUAGE, getDefName } from '../../constants';
import axiosInstance from '../../constants/AxiosInstance';
import FileUpload from '../../components/FileUpload/FileUpload';
import PriceDynamicForm from './PriceDynamicForm';
import ScheduleDynamicForm from './ScheduleDynamicForm';
import DiseaseAutocomplete from '../customComp/DiseaseAutocomplete';
import Jodit from '../../components/Jodit/Jodit';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  my3: {
    margin: '1.3rem 0',
  },
  mRight: {
    marginRight: '.85rem',
  },
  mInput: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
    display: 'flex',
    flexWrap: 'wrap',
  },
}));

const ProductEditPage = (props) => {
  const query = queryString.parse(props.location.search);
  const type = query.type ? query.type : 'new';
  const [loading, setLoading] = useState(false);
  const IS_SYS_ADMIN = sessionStorage.getItem('bt_role') === 'SYS_ADM';

  const editor1 = useRef(null);
  const editor2 = useRef(null);
  const editor3 = useRef(null);
  const [product, setProduct] = useState({});
  const [types, setTypes] = useState([]);

  const [uploadFile, setUploadFile] = useState([]);
  const childRef = useRef(null);
  const imgKey = 'images';

  const { history } = props;
  const classes = useStyles();

  useEffect(() => {
    getProductTypeList().then((result) => {
      setTypes(result);
    });
    getData();
  }, []);

  const getData = () => {
    if (type === 'edit' && query.id) {
      getProductInfo(query.id).then((result) => {
        setProduct(result);
        if (childRef.current) {
          childRef.current.getSignedUrl(result[imgKey]);
        }
        editor1.current && editor1.current.setValue(result['desc']);
        editor2.current && editor2.current.setValue(result['payDesc']);
        editor3.current && editor3.current.setValue(result['cautionDesc']);
      });
    }
  };

  const addProduct = async () => {
    console.log(editor1.current.getValue());
    editor1.current && (product['desc'] = await editor1.current.getValue());
    editor2.current && (product['payDesc'] = await editor2.current.getValue());
    editor3.current && (product['cautionDesc'] = await editor3.current.getValue());
    setProduct({ ...product });

    if (!product.typeId) {
      swal('Missing required category.');
      return;
    }
    if ((!uploadFile || uploadFile.length === 0) && (!product[imgKey] || product[imgKey].length === 0)) {
      swal('Missing required photo.');
      return;
    }
    if (!product.hospitalId) {
      swal('Missing hospital information.');
      return;
    }
    if (!product.diseaseId) {
      swal('Missing related disease.');
      return;
    }
    if (!product.code) {
      swal('Missing required product code.');
      return;
    }
    if (!product['name_ko']) {
      swal('Missing required product name.');
      return;
    }
    if (!product.price) {
      swal('Missing required product price.');
      return;
    }
    if (!product.deposit) {
      swal('Missing required product deposit.');
      return;
    }
    if (!product.desc) {
      swal('Missing required product description.');
      return;
    }
    setLoading(true);
    let param = {
      name_ko: product.name_ko,
      hospitalId: product.hospitalId,
      languageId: product.languageId || DEF_LANGUAGE,
      code: product.code,
      typeId: product.typeId,
      diseaseId: product.diseaseId,
      deposit: product.deposit,
      desc: product.desc,
      payDesc: product.payDesc,
      cautionDesc: product.cautionDesc,
      hidden: IS_SYS_ADMIN ? product.hidden || false : true,
      requiredTime: product.requiredTime,
      tags: product.tags,
      price: product.price,
      schedule: product.schedule,
    };

    if (type === 'new') {
      param['writerId'] = sessionStorage.getItem('bt_user');
      param['isSysAdmin'] = IS_SYS_ADMIN;
      axiosInstance.post(`${API_URL}/product/`, param, { headers: { Pragma: 'no-cache' } }).then((response) => {
        if (response.status === 200) {
          if (childRef.current) {
            childRef.current.fileUpload(uploadFile, response.data.value.id, 'product', `${API_URL}/product/`).then(() => {
              swal('Registered.', {
                icon: 'success',
              });
            });
          } else {
            swal('Registered.', {
              icon: 'success',
            });
          }
          setLoading(false);
          goList();
        } else {
          setLoading(false);
        }
      });
    } else {
      param['id'] = query.id;

      axiosInstance.put(`${API_URL}/product/`, param, { headers: { Pragma: 'no-cache' } }).then(async (response) => {
        if (response.status === 200) {
          if (childRef.current) {
            await Promise.all([childRef.current.fileUpload(uploadFile, query.id, 'product', `${API_URL}/product/`)]);

            swal('It is changed.', {
              icon: 'success',
            });
            goDetail();
            setLoading(false);
          } else {
            swal('It is changed.', {
              icon: 'success',
            });
            goDetail();
            setLoading(false);
          }
        } else {
          setLoading(false);
        }
      });
    }
  };

  const deleteProduct = () => {
    swal({
      title: 'Are you sure you want to delete?',
      text: '',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        axiosInstance.delete(`${API_URL}/product/${query.id}`, { headers: { Pragma: 'no-cache' } }).then((response) => {
          if (response.status === 200) {
            let msg = 'It is deleted.';
            swal(msg, {
              icon: 'success',
            });
            goList();
          }
        });
      } else {
      }
    });
  };

  const goList = () => {
    history.push('/pages/product/');
  };

  const goDetail = () => {
    history.push(`/pages/product/detail?type=edit&id=${query.id}`);
  };

  // const handleChangeDesc = (event) => {
  //   console.log('handleChangeDesc : ', event.target.value);
  //   const id = event.target.id.replace('editor-', '');
  //   product[id] = event.target.value;
  //   setProduct({ ...product });
  // };

  const handleChangeData = (value, field) => {
    product[field] = value;
    setProduct({ ...product });
  };

  const handleFile = (file, key) => {
    //console.log(file, key);
    if (key === imgKey) {
      setUploadFile([...file]);
    }
  };
  const handleUpload = () => {
    console.log('Upload Success');
  };
  const handleRemove = () => {
    console.log('Remove Success');
    getData();
  };

  const getSelectedType = () => {
    const item = types.find((_type) => {
      if (product.typeId ? _type.id === product.typeId : false) return _type;
    });
    return item || null;
  };

  return (
    <OftadehLayout>
      <Grid container className={classes.my3} alignItems="center">
        <Grid item className={classes.mRight} style={{ fontWeight: 'bold' }}>
          <Typography variant="h5" component="h1" style={{ fontWeight: 'bold' }}>
            Product {type === 'new' ? 'Registration' : 'Edit'}
          </Typography>
        </Grid>
      </Grid>

      <div className={classes.root}>
        <Card className={classes.root}>
          <CardContent className={classes.root}>
            <Grid container spacing={3}>
              <Grid item xs={6} mb={12}>
                <Typography component="legend">
                  Product Images <span style={{ color: 'red' }}>*</span>
                </Typography>
                <FileUpload
                  type={'gallery'}
                  ref={childRef}
                  buttonText={'PHOTO'}
                  onFileHandler={handleFile}
                  onRemoveAfter={handleRemove}
                  onUploadAfter={handleUpload}
                  fileId={query.id}
                  folder={'product'}
                  apiUrl={`${API_URL}/product/`}
                  uploadKey={imgKey}
                  maxCount={4}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography component="legend">
                  Product Number <span style={{ color: 'red' }}>*</span>
                </Typography>
                <TextField
                  label=""
                  className={classes.mInput}
                  placeholder=" "
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={(product && product.code) || ''}
                  size="small"
                  variant={'outlined'}
                  onChange={(e) => handleChangeData(e.target.value, 'code')}
                />
                <Typography component="legend">
                  Product Name <span style={{ color: 'red' }}>*</span>
                </Typography>
                <TextField
                  label=""
                  className={classes.mInput}
                  placeholder=" "
                  margin="normal"
                  size="small"
                  variant={'outlined'}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={(product && product.name_ko) || ''}
                  onChange={(e) => handleChangeData(e.target.value, 'name_ko')}
                />
              </Grid>
              <Grid item xs={6} mb={6}>
                <Typography component="legend">
                  Language <span style={{ color: 'red' }}>*</span>
                </Typography>
                <LanguageAutocomplete target={product} handleChangeData={handleChangeData} />
              </Grid>
              <Grid item xs={6} mb={6}>
                <Typography component="legend">
                  Category <span style={{ color: 'red' }}>*</span>
                </Typography>
                <Autocomplete
                  id="autocomplete-type"
                  value={getSelectedType()}
                  onChange={(event, newValue) => {
                    handleChangeData(newValue.id, 'typeId');
                  }}
                  size={'small'}
                  disableClearable
                  options={types}
                  getOptionLabel={(option) => option[getDefName()] || ''}
                  getOptionSelected={(option, value) => option.id === value.id}
                  style={{ width: '100%' }}
                  className={classes.mInput}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" inputProps={{ ...params.inputProps, readOnly: true }} />
                  )}
                />
              </Grid>

              <Grid item xs={6} mb={6}>
                <Typography component="legend">
                  Relation Disease <span style={{ color: 'red' }}>*</span>
                </Typography>
                <DiseaseAutocomplete target={product} handleChangeData={handleChangeData} />
              </Grid>
              <Grid item xs={6} mb={6}>
                <Typography component="legend">Hospital</Typography>
                <HospitalAutocomplete target={product} handleChangeData={handleChangeData} />
              </Grid>

              <Grid item xs={6}>
                <Typography component="legend">
                  Product Price <span style={{ color: 'red' }}>*</span>
                </Typography>
                <PriceDynamicForm target={product} handleChangeData={handleChangeData} />
              </Grid>
              <Grid item xs={6}>
                <Typography component="legend">Deposit(KRW)</Typography>
                <TextField
                  label=""
                  type={'number'}
                  className={classes.mInput}
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  placeholder={'₩'}
                  value={(product && product.deposit) || ''}
                  variant={'outlined'}
                  size={'small'}
                  onChange={(e) => handleChangeData(e.target.value, 'deposit')}
                />
              </Grid>

              <Grid item xs={6}>
                <Typography component="legend">Schedule</Typography>
                <ScheduleDynamicForm target={product} handleChangeData={handleChangeData} />
              </Grid>
              <Grid item xs={6}>
                <Typography component="legend">Time required</Typography>
                <TextField
                  label=""
                  className={classes.mInput}
                  placeholder=" "
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={(product && product.requiredTime) || ''}
                  variant={'outlined'}
                  size={'small'}
                  onChange={(e) => handleChangeData(e.target.value, 'requiredTime')}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography component="legend">
                  Introduce <span style={{ color: 'red' }}>*</span>
                </Typography>
                <Jodit ref={editor1} id={'editor1'} uploadApi={`${API_URL}/upload`} val={product.desc} />
              </Grid>
              <Grid item xs={12}>
                <Typography component="legend">Payment information</Typography>
                <Jodit ref={editor2} id={'editor2'} uploadApi={`${API_URL}/upload`} val={product.payDesc} />
              </Grid>
              <Grid item xs={12}>
                <Typography component="legend">Caution</Typography>
                <Jodit ref={editor3} id={'editor3'} uploadApi={`${API_URL}/upload`} val={product.cautionDesc} />
              </Grid>

              <Grid item xs={10}>
                <Typography component="legend">Tags</Typography>
                <TextField
                  label=""
                  className={classes.mInput}
                  placeholder="Enter Tags here"
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={(product && product.tags) || ''}
                  variant={'outlined'}
                  size={'small'}
                  onChange={(e) => handleChangeData(e.target.value, 'tags')}
                />
              </Grid>
              {IS_SYS_ADMIN && (
                <Grid item xs={2} mb={6}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={!product.hidden}
                        onChange={(e, newValue) => {
                          handleChangeData(!newValue, 'hidden');
                        }}
                      />
                    }
                    label="Show"
                  />
                </Grid>
              )}
            </Grid>
          </CardContent>
          <CardActions style={{ marginLeft: '16px', marginRight: '16px' }}>
            <Grid container justifyContent={'space-between'} xs={12} md={12} spacing={3}>
              <Grid item>
                <Button
                  variant="contained"
                  style={{ backgroundColor: green[700], color: '#ffffff', marginRight: '10px' }}
                  size="medium"
                  onClick={goList}
                  startIcon={<ListIcon />}
                >
                  List
                </Button>
                {type !== 'new' ? (
                  <Button variant="contained" color="secondary" size="medium" onClick={deleteProduct} startIcon={<DeleteIcon />}>
                    Delete
                  </Button>
                ) : null}
              </Grid>
              <Grid item>
                <Button variant="contained" color="primary" size="medium" onClick={addProduct} startIcon={<SaveIcon />}>
                  {type === 'new' ? 'Registration' : 'Save'}
                </Button>
              </Grid>
            </Grid>
          </CardActions>
        </Card>
      </div>
      {loading && (
        <div style={{ position: 'fixed', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', zIndex: 999 }}>
          <CircularProgress color={'secondary'} />
        </div>
      )}
    </OftadehLayout>
  );
};

export default ProductEditPage;
