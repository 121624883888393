import SettingDiseaseDetailPage from './SettingDiseaseDetailPage';

export const SettingDiseaseDetailPageConfig = {
  routes: [
    {
      path: '/pages/setting/disease/detail',
      exact: true,
      component: SettingDiseaseDetailPage,
    },
  ],
};
