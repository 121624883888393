import RecDoctorDetailPage from './RecDoctorDetailPage';

export const RecDoctorDetailPageConfig = {
  routes: [
    {
      path: '/pages/recDoctor/detail',
      exact: true,
      component: RecDoctorDetailPage,
    },
  ],
};
