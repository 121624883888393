import React, { useEffect, useRef, useState } from 'react';
import queryString from 'query-string';
import OftadehLayout from '../../../components/OftadehLayout/OftadehLayout';
import {
  Button,
  Dialog,
  Divider,
  FormControlLabel,
  Grid,
  makeStyles,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import swal from '@sweetalert/with-react';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import SaveIcon from '@material-ui/icons/Save';
import ListIcon from '@material-ui/icons/List';
import DeleteIcon from '@material-ui/icons/Delete';
import { getVideoConsultInfo } from '../../../constants/PromiseHttp';
import axiosInstance from '../../../constants/AxiosInstance';
import {
  API_URL,
  DEF_CONSULT_STATUS,
  DEF_COUNTRY,
  DEF_DATE,
  DEF_GENDER,
  DEF_LANGUAGE,
  GENDER_TYPE,
  getDefName,
  CONSULT_STATUS,
  RELATIONSHIP,
  EMAIL_REG,
  PHONE_REG,
  AGENCY_STATE,
  DEF_AGENCY,
  phoneNumberValidation,
} from '../../../constants';
import LanguageAutocomplete from '../../customComp/LanguageAutocomplete';
import FormControl from '@material-ui/core/FormControl';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import CountryAutocomplete from '../../customComp/CountryAutocomplete';
import DiseaseTransferList from '../../customComp/DiseaseTransferList';
import SearchUser from '../../customComp/SearchUserPage';
import HospitalAutocomplete from '../../customComp/HospitalAutocomplete';
import { Autocomplete } from '@material-ui/lab';
import FileUpload from '../../../components/FileUpload/FileUpload';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  my3: {
    margin: '1.3rem 0',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  mRight: {
    marginRight: '.85rem',
  },
  chipContainer: {
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
    display: 'flex',
  },
  searchBtn: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
  textArea: {
    width: '98%',
    margin: theme.spacing(2),
    padding: theme.spacing(2),
    minHeight: 300,
    borderColor: 'lightgray',
  },
  mInput: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
}));

const VideoConsultEditPage = (props) => {
  const query = queryString.parse(props.location.search);
  const type = query.type ? query.type : 'new';

  const [consult, setConsult] = useState({});
  const [userSearchOpen, setUserSearchOpen] = useState(false);

  const [uploadFile, setUploadFile] = useState([]);
  const childRef = useRef(null);
  const imgKey = 'statement';

  const [uploadPhoto, setUploadPhoto] = useState([]);
  const photoRef = useRef(null);
  const photoKey = 'photo';

  const [uploadDicom, setUploadDicom] = useState([]);
  const dicomRef = useRef(null);
  const dicomKey = 'dicom';

  const { history } = props;
  const classes = useStyles();

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    if (type === 'edit' && query.id) {
      getVideoConsultInfo(query.id).then((result) => {
        setConsult(result);
        if (childRef.current) {
          childRef.current.getSignedUrl(result[imgKey]);
        }
        if (photoRef.current) {
          photoRef.current.getSignedUrl(result[photoKey]);
        }
        if (dicomRef.current) {
          dicomRef.current.getSignedUrl(result[dicomKey]);
        }
      });
    }
  };

  const addConsult = () => {
    if (!consult.userId && !consult.patientsName) {
      swal('Missing required name.');
      return;
    }
    if (!consult.email) {
      swal('Missing required email.');
      return;
    }
    if (!consult.birth) {
      swal('Missing required birth.');
      return;
    }
    if (!consult.title) {
      swal('Missing required title of consult.');
      return;
    }
    if (!consult.contents) {
      swal('Missing required contents of consult.');
      return;
    }
    let rdate = new Date(consult.reserveDate).getTime();
    let param = {
      type: 'video',
      languageId: consult.languageId || DEF_LANGUAGE,
      userId: consult.userId,
      userName: consult.userName,
      patientsName: consult.patientsName || consult.userName,
      email: consult.email,
      gender: consult.gender || DEF_GENDER,
      phoneNumber: consult.phoneNumber,
      birth: consult.birth,
      countryId: consult.countryId || DEF_COUNTRY,
      relationship: consult.relationship,
      selectAgency: consult.selectAgency ? consult.selectAgency : DEF_AGENCY,
      hospitalId: consult.hospitalId,
      hospitalName: consult.hospitalName,
      title: consult.title,
      contents: consult.contents,
      answers: consult.answers,
      subDiseaseIds: consult.subDiseaseIds,
      tags: consult.tags,
      hidden: consult.hidden || false,
      status: consult.status || DEF_CONSULT_STATUS,
      chartId: consult.chartId,
      diagnosis: consult.diagnosis, //"Diagnosis",
      medicine: consult.medicine, //"복용중인 약물",
      symtoms: consult.symtoms, //"증상",
      medicalHistory: consult.medicalHistory, //"Medical History"
      reserveDate: rdate ? rdate.toString() : consult.reserveDate,
    };

    if (type === 'new') {
      axiosInstance.post(`${API_URL}/consult/`, param, { headers: { Pragma: 'no-cache' } }).then(async (response) => {
        if (response.status === 200) {
          await Promise.all([
            childRef.current &&
              childRef.current.fileUpload(uploadFile, response.data.value.id, 'consult', `${API_URL}/consult/`).then(() => {
                console.log(`${imgKey} upload success`);
              }),
            photoRef.current &&
              photoRef.current.fileUpload(uploadPhoto, response.data.value.id, 'consult', `${API_URL}/consult/`).then(() => {
                console.log(`${photoKey} upload success`);
              }),
            dicomRef.current &&
              dicomRef.current.fileUpload(uploadDicom, response.data.value.id, 'consult', `${API_URL}/consult/`).then(() => {
                console.log(`${dicomKey} upload success`);
              }),
          ]);
          swal('Registered.', {
            icon: 'success',
          });
          goList();
        }
      });
    } else {
      param['id'] = query.id;

      axiosInstance.put(`${API_URL}/consult/`, param, { headers: { Pragma: 'no-cache' } }).then(async (response) => {
        if (response.status === 200) {
          await Promise.all([
            childRef.current &&
              childRef.current.fileUpload(uploadFile, query.id, 'consult', `${API_URL}/consult/`).then(() => {
                console.log(`${imgKey} upload success`);
              }),
            photoRef.current &&
              photoRef.current.fileUpload(uploadPhoto, query.id, 'consult', `${API_URL}/consult/`).then(() => {
                console.log(`${photoKey} upload success`);
              }),
            dicomRef.current &&
              dicomRef.current.fileUpload(uploadDicom, query.id, 'consult', `${API_URL}/consult/`).then(() => {
                console.log(`${dicomKey} upload success`);
              }),
          ]);
          swal('It is changed.', {
            icon: 'success',
          });
          goList();
        }
      });
    }
  };

  const deleteConsult = () => {
    swal({
      title: 'Are you sure you want to delete?',
      text: '',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        axiosInstance.delete(`${API_URL}/consult/${query.id}`, { headers: { Pragma: 'no-cache' } }).then((response) => {
          if (response.status === 200) {
            goList();
            swal('It is deleted.', {
              icon: 'success',
            });
          }
        });
      } else {
      }
    });
  };

  const goList = () => {
    history.push('/pages/consult/video/');
  };

  const setSelectedDisease = (value) => {
    handleChangeData(value, 'subDiseaseIds');
  };
  const handleChangeData = (value, field) => {
    consult[field] = value;
    setConsult({ ...consult });
  };

  const callbackSearchedUser = (u) => {
    if (consult) {
      consult.userId = u.id;
      consult.userName = u.name;
      if (!consult.patientsName) {
        consult.patientsName = u.name;
      }
      if (!consult.email) {
        consult.email = u.email || u.id;
      }
      if (!consult.phoneNumber) {
        consult.phoneNumber = u.phoneNumber || '';
      }
      if (!consult.birth) {
        consult.birth = u.birth || '';
      }
      if (!consult.gender) {
        consult.gender = u.gender || '';
      }
      setConsult({ ...consult });
    }
    setUserSearchOpen(false);
  };

  const handleUserSearchOpen = () => {
    setUserSearchOpen(true);
  };
  const handleUserSearchClose = () => {
    setUserSearchOpen(false);
  };

  const SearchUserBox = () => {
    return (
      <Grid item xs={6} mb={6}>
        <Typography component="legend">
          Member <span style={{ color: 'red' }}>*</span>
        </Typography>
        <div style={{ marginTop: '8px', marginLeft: 20 }}>
          <TextField
            clssName={classes.mInput}
            size="small"
            value={consult.userName}
            variant="outlined"
            margin="dense"
            InputProps={{ readOnly: true }}
          />
          <Button className={classes.searchBtn} variant="contained" color="primary" onClick={handleUserSearchOpen}>
            Member Search
          </Button>
        </div>
        <Dialog open={userSearchOpen} onClose={handleUserSearchClose} aria-labelledby="customized-dialog-title">
          <SearchUser callbackFunc={callbackSearchedUser} handleClose={handleUserSearchClose} />
        </Dialog>
      </Grid>
    );
  };
  const getSelectedRelationship = () => {
    const item = RELATIONSHIP.find((rel) => {
      if (rel.id === consult.relationship) return rel;
    });
    //console.log('getSelectedRelationship', item);
    return item || RELATIONSHIP[0];
  };
  const getFormattedDate = (value) => {
    if (!value) return '';
    const dt = new Date(Number(value));
    return dt.getFullYear() + '-' + (dt.getMonth() + 1) + '-' + dt.getDate() + ' ' + dt.getHours() + ':' + dt.getMinutes();
  };
  const getLocalDate = (date) => {
    if (date && date.includes('T')) {
      return date;
    }
    const dt = date ? new Date(Number(date)) : '';
    const utcT = moment(dt).format('YYYY-MM-DDThh:mm');
    return utcT;
  };

  const handleFile = (file, key) => {
    console.log(file, key);

    if (key === imgKey) {
      setUploadFile([...file]);
    }
    if (key === photoKey) {
      setUploadPhoto([...file]);
    }
    if (key === dicomKey) {
      setUploadDicom([...file]);
    }
  };
  const handleUpload = () => {
    console.log('Upload Success');
  };
  const handleRemove = () => {
    console.log('Remove Success');
    getData();
  };

  return (
    <OftadehLayout>
      <Grid container className={classes.my3} alignItems="center">
        <Grid item className={classes.mRight} style={{ fontWeight: 'bold' }}>
          <Typography variant="h5" component="h1" style={{ fontWeight: 'bold' }}>
            Video Consulting {type === 'new' ? 'Registration' : 'Edit'}
          </Typography>
        </Grid>
      </Grid>
      <div>
        <Card className={classes.root}>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} mb={6}>
                <Typography style={{ marginTop: 10 }} variant={'h6'}>
                  Basic Info
                </Typography>
              </Grid>
              <Grid item xs={6} mb={6}>
                <Typography component="legend">
                  Language <span style={{ color: 'red' }}>*</span>
                </Typography>
                <LanguageAutocomplete target={consult} handleChangeData={handleChangeData} />
              </Grid>
              <Grid item xs={6} mb={6}>
                <Typography style={{}}>
                  Country <span style={{ color: 'red' }}>*</span>
                </Typography>
                <CountryAutocomplete target={consult} handleChangeData={handleChangeData} />
              </Grid>
              <Grid item xs={6} mb={6}>
                <Typography component="legend">
                  Relationship <span style={{ color: 'red' }}>*</span>
                </Typography>
                <Autocomplete
                  id="autocomplete-relation"
                  value={getSelectedRelationship()}
                  onChange={(event, newValue) => {
                    handleChangeData(newValue.id, 'relationship');
                  }}
                  size={'small'}
                  disableClearable
                  options={RELATIONSHIP}
                  getOptionLabel={(option) => option[getDefName()] || ''}
                  getOptionSelected={(option, value) => option.id === value.id}
                  style={{ width: '100%' }}
                  className={classes.mInput}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" inputProps={{ ...params.inputProps, readOnly: true }} />
                  )}
                />
              </Grid>
              <SearchUserBox />
            </Grid>
            <Divider />
            <Grid container spacing={3}>
              <Grid item xs={12} mb={6}>
                <Typography style={{ marginTop: 10 }} variant={'h6'}>
                  Patient Info
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography style={{}}>Name</Typography>
                <TextField
                  size={'small'}
                  key={`TextField-name`}
                  className={classes.mInput}
                  placeholder="Please enter if you are not a member."
                  margin="normal"
                  style={{ width: '100%', marginRight: '10px' }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  value={(consult && consult.patientsName) || ''}
                  onChange={(event) => {
                    handleChangeData(event.target.value, 'patientsName');
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <form noValidate>
                  <Typography style={{}}>
                    Birth date <span style={{ color: 'red' }}>*</span>
                  </Typography>
                  <TextField
                    id="date"
                    type="date"
                    value={(consult && consult.birth) || DEF_DATE}
                    variant="outlined"
                    size="small"
                    className={classes.mInput}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(event) => {
                      handleChangeData(event.target.value, 'birth');
                    }}
                  />
                </form>
              </Grid>
              <Grid item xs={3}>
                <Typography style={{}}>
                  Gender <span style={{ color: 'red' }}>*</span>
                </Typography>
                <FormControl component="gender-form" className={classes.mInput}>
                  <RadioGroup
                    aria-label="gender-radio-group"
                    name="gender-radio-group"
                    onChange={(event) => {
                      handleChangeData(event.target.value, 'gender');
                    }}
                    row
                  >
                    <FormControlLabel
                      value={GENDER_TYPE[0].id}
                      checked={consult.gender === undefined || consult.gender === GENDER_TYPE[0].id}
                      control={<Radio />}
                      label={GENDER_TYPE[0][getDefName()] || ''}
                    />
                    <FormControlLabel
                      value={GENDER_TYPE[1].id}
                      checked={consult.gender === GENDER_TYPE[1].id}
                      control={<Radio />}
                      label={GENDER_TYPE[1][getDefName()] || ''}
                    />
                    <FormControlLabel
                      value={GENDER_TYPE[2].id}
                      checked={consult.gender === GENDER_TYPE[2].id}
                      control={<Radio />}
                      label={GENDER_TYPE[2][getDefName()] || ''}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <Typography style={{}}>
                  Email <span style={{ color: 'red' }}>*</span>
                </Typography>
                <TextField
                  size={'small'}
                  type={'email'}
                  key={`TextField-email`}
                  className={classes.mInput}
                  placeholder=""
                  margin="normal"
                  style={{ width: '100%', marginRight: '10px' }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  value={(consult && consult.email) || ''}
                  onChange={(event) => {
                    handleChangeData(event.target.value, 'email');
                  }}
                  error={!EMAIL_REG.test(consult.email)}
                  helperText={EMAIL_REG.test(consult.email) ? '' : 'Please enter your email correctly.'}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography style={{}}>Phone number</Typography>
                <TextField
                  size={'small'}
                  type="text"
                  key={`TextField-phone`}
                  className={classes.mInput}
                  placeholder=""
                  margin="normal"
                  style={{ width: '100%', marginRight: '10px' }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  value={(consult && consult.phoneNumber) || ''}
                  onChange={(event) => {
                    handleChangeData(event.target.value, 'phoneNumber');
                  }}
                />
              </Grid>
            </Grid>
            <Divider />
            <Grid container spacing={3} style={{ marginTop: 20 }}>
              <Grid item xs={6}>
                <Typography style={{ marginTop: 10 }}>Status</Typography>
                <FormControl component="status-form" className={classes.mInput}>
                  <RadioGroup
                    aria-label="status-radio-group"
                    name="status-radio-group"
                    onChange={(event) => {
                      handleChangeData(event.target.value, 'status');
                    }}
                    row
                  >
                    <FormControlLabel
                      value={CONSULT_STATUS[0].id}
                      checked={consult.status === undefined || consult.status === CONSULT_STATUS[0].id}
                      control={<Radio />}
                      label={CONSULT_STATUS[0][getDefName()] || ''}
                    />
                    <FormControlLabel
                      value={CONSULT_STATUS[1].id}
                      checked={consult.status === CONSULT_STATUS[1].id}
                      control={<Radio />}
                      label={CONSULT_STATUS[1][getDefName()] || ''}
                    />
                    <FormControlLabel
                      value={CONSULT_STATUS[2].id}
                      checked={consult.status === CONSULT_STATUS[2].id}
                      control={<Radio />}
                      label={CONSULT_STATUS[2][getDefName()] || ''}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <Typography style={{ marginTop: 10 }}>Reservation date</Typography>
                <form className={classes.container} noValidate>
                  <TextField
                    id="date"
                    label=""
                    type="datetime-local"
                    className={classes.mInput}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={getLocalDate(consult.reserveDate) || 'None'}
                    size="small"
                    variant={'outlined'}
                    onChange={(e) => handleChangeData(e.target.value, 'reserveDate')}
                  />
                </form>
              </Grid>
              <Grid item xs={2} mb={6}>
                <Typography style={{}}>Hope Medical Institution</Typography>
                <FormControl component="gender-form" className={classes.mInput}>
                  <RadioGroup
                    aria-label="selectAgency-radio-group"
                    name="selectAgency-radio-group"
                    onChange={(event) => {
                      handleChangeData(event.target.value, 'selectAgency');
                    }}
                    row
                  >
                    <FormControlLabel
                      value={AGENCY_STATE[0].id}
                      checked={consult.selectAgency === AGENCY_STATE[0].id}
                      control={<Radio />}
                      label={AGENCY_STATE[0][getDefName()] || ''}
                    />
                    <FormControlLabel
                      value={AGENCY_STATE[1].id}
                      checked={consult.selectAgency === undefined || consult.selectAgency === AGENCY_STATE[1].id}
                      control={<Radio />}
                      label={AGENCY_STATE[1][getDefName()] || ''}
                    />
                    <FormControlLabel
                      value={AGENCY_STATE[2].id}
                      checked={consult.selectAgency === AGENCY_STATE[2].id}
                      control={<Radio />}
                      label={AGENCY_STATE[2][getDefName()] || ''}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={5} mb={6}>
                <Typography component="legend">Hope Medical Institution</Typography>
                <HospitalAutocomplete target={consult} handleChangeData={handleChangeData} disable={true} />
              </Grid>

              <Grid item xs={5}>
                <Typography style={{}}>Medical institution name</Typography>
                <TextField
                  size={'small'}
                  key={`TextField-want-hospital`}
                  className={classes.mInput}
                  placeholder="If the desired medical institution is not on the list, please enter it."
                  margin="normal"
                  style={{ width: '100%', marginRight: '10px' }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  value={(consult && consult.hospitalName) || ''}
                  onChange={(event) => {
                    handleChangeData(event.target.value, 'hospitalName');
                  }}
                />
              </Grid>

              <Grid item xs={10}>
                <Typography style={{}}>Tags</Typography>
                <TextField
                  size={'small'}
                  key={`TextField-tags`}
                  className={classes.mInput}
                  placeholder=",Please enter them separated by commas."
                  margin="normal"
                  style={{ width: '100%', marginRight: '10px' }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  value={(consult && consult.tags) || ''}
                  onChange={(event) => {
                    handleChangeData(event.target.value, 'tags');
                  }}
                />
              </Grid>
              <Grid item xs={2} mb={6}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={!consult.hidden}
                      onChange={(e, newValue) => {
                        handleChangeData(!newValue, 'hidden');
                      }}
                    />
                  }
                  label="Show"
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Card className={classes.my3}>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} mb={12}>
                <Typography style={{ marginTop: 10 }} variant="h6">
                  Question{' '}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography style={{}}>
                  Title <span style={{ color: 'red' }}>*</span>
                </Typography>
                <TextField
                  size={'small'}
                  type="text"
                  key={`TextField-title`}
                  className={classes.mInput}
                  placeholder=""
                  margin="normal"
                  style={{ width: '100%', marginRight: '10px' }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  value={(consult && consult.title) || ''}
                  onChange={(event) => {
                    handleChangeData(event.target.value, 'title');
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography style={{}}>
                  Contents <span style={{ color: 'red' }}>*</span>
                </Typography>
                <TextareaAutosize
                  key={'textarea-contents'}
                  id={'textarea-contents'}
                  className={classes.textArea}
                  aria-label="default"
                  placeholder=""
                  value={consult.contents || ''}
                  onChange={(e) => handleChangeData(e.target.value, 'contents')}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography style={{}}>Diagnosis</Typography>
                <TextareaAutosize
                  key={'textarea-diagnosis'}
                  id={'textarea-diagnosis'}
                  className={classes.textArea}
                  aria-label="default"
                  placeholder=""
                  value={consult.diagnosis || ''}
                  onChange={(e) => handleChangeData(e.target.value, 'diagnosis')}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography style={{}}>Medical History</Typography>
                <TextareaAutosize
                  key={'textarea-medicalHistory'}
                  id={'textarea-medicalHistory'}
                  className={classes.textArea}
                  aria-label="default"
                  placeholder=""
                  value={consult.medicalHistory || ''}
                  onChange={(e) => handleChangeData(e.target.value, 'medicalHistory')}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography style={{}}>Symptoms</Typography>
                <TextareaAutosize
                  key={'textarea-symtoms'}
                  id={'textarea-symtoms'}
                  className={classes.textArea}
                  aria-label="default"
                  placeholder=""
                  value={consult.symtoms || ''}
                  onChange={(e) => handleChangeData(e.target.value, 'symtoms')}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography style={{}}>Taking medication</Typography>
                <TextareaAutosize
                  key={'textarea-medicine'}
                  id={'textarea-medicine'}
                  className={classes.textArea}
                  aria-label="default"
                  placeholder=""
                  value={consult.medicine || ''}
                  onChange={(e) => handleChangeData(e.target.value, 'medicine')}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Card className={classes.my3}>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} mb={12}>
                <Typography style={{ marginTop: 10 }} variant="h6">
                  Attachments{' '}
                </Typography>
              </Grid>
              <Grid item xs={12} mb={12}>
                <Typography style={{ marginTop: 10 }} variant="subtitle2">
                  Diagnosis, Opinion, Test result sheet, etc.{' '}
                </Typography>
                <FileUpload
                  type={'file'}
                  ref={childRef}
                  buttonText={'File'}
                  onFileHandler={handleFile}
                  onRemoveAfter={handleRemove}
                  onUploadAfter={handleUpload}
                  fileId={query.id}
                  folder={'consult'}
                  apiUrl={`${API_URL}/consult/`}
                  uploadKey={imgKey}
                  maxCount={1}
                />
              </Grid>
              <Grid item xs={12} mb={12}>
                <Typography style={{ marginTop: 20 }} variant="subtitle2">
                  Photo Material (JPG, PNG, PDF, GIF){' '}
                </Typography>
                <FileUpload
                  type={'file'}
                  accept={'application/pdf, image/*'}
                  ref={photoRef}
                  buttonText={'File'}
                  onFileHandler={handleFile}
                  onRemoveAfter={handleRemove}
                  onUploadAfter={handleUpload}
                  fileId={query.id}
                  folder={'consult'}
                  apiUrl={`${API_URL}/consult/`}
                  uploadKey={photoKey}
                  maxCount={1}
                />
              </Grid>
              <Grid item xs={12} mb={12}>
                <Typography style={{ marginTop: 20 }} variant="subtitle2">
                  DICOM File
                </Typography>
                <FileUpload
                  type={'file'}
                  ref={dicomRef}
                  buttonText={'File'}
                  onFileHandler={handleFile}
                  onRemoveAfter={handleRemove}
                  onUploadAfter={handleUpload}
                  fileId={query.id}
                  folder={'consult'}
                  apiUrl={`${API_URL}/consult/`}
                  uploadKey={dicomKey}
                  maxCount={1}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Card className={classes.my3}>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography style={{ marginTop: 20 }} variant="h6">
                  Disease selection
                </Typography>
                {(type === 'new' || (consult && consult.id)) && (
                  <DiseaseTransferList selectedDisease={consult.subDiseaseIds} setSelectedDisease={setSelectedDisease} />
                )}
              </Grid>
            </Grid>
          </CardContent>
          <CardActions style={{ marginLeft: '16px', marginRight: '16px' }}>
            <Grid container justifyContent={'space-between'} xs={12} md={12} spacing={3}>
              <Grid item>
                <Button
                  variant="contained"
                  style={{ backgroundColor: green[700], color: '#ffffff', marginRight: '10px' }}
                  size="medium"
                  onClick={goList}
                  startIcon={<ListIcon />}
                >
                  List
                </Button>
                {type !== 'new' ? (
                  <Button variant="contained" color="secondary" size="medium" onClick={deleteConsult} startIcon={<DeleteIcon />}>
                    Delete
                  </Button>
                ) : null}
              </Grid>
              <Grid item>
                <Button variant="contained" color="primary" size="medium" onClick={addConsult} startIcon={<SaveIcon />}>
                  {type === 'new' ? 'Registration' : 'Save'}
                </Button>
              </Grid>
            </Grid>
          </CardActions>
        </Card>
      </div>
    </OftadehLayout>
  );
};

export default VideoConsultEditPage;
