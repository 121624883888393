import React, { useEffect, useRef, useState } from 'react';
import queryString from 'query-string';
import OftadehLayout from '../../components/OftadehLayout/OftadehLayout';
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  makeStyles,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import swal from '@sweetalert/with-react';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import SaveIcon from '@material-ui/icons/Save';
import ListIcon from '@material-ui/icons/List';
import DeleteIcon from '@material-ui/icons/Delete';
import { getHospitalList, getUserInfo } from '../../constants/PromiseHttp';
import axiosInstance from '../../constants/AxiosInstance';
import {
  API_URL,
  DEF_COUNTRY,
  DEF_LANGUAGE,
  DEF_USER_STATUS,
  EMAIL_REG,
  getDefName,
  MEMBER_TYPE,
  phoneNumberValidation,
} from '../../constants';
import FileUpload from '../../components/FileUpload/FileUpload';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import FormControl from '@material-ui/core/FormControl';
import LanguageAutocomplete from '../customComp/LanguageAutocomplete';
import CountryAutocomplete from '../customComp/CountryAutocomplete';
import { Autocomplete } from '@material-ui/lab';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  my3: {
    margin: '1.3rem 0',
  },
  mb0: {
    marginBottom: 0,
  },
  mRight: {
    marginRight: '.85rem',
  },
  mInput: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
  textArea: {
    width: '100%',
    padding: theme.spacing(2),
    minHeight: 100,
    borderColor: 'lightgray',
    margin: theme.spacing(1),
    marginTop: 10,
  },
}));

const ManagerDetailPage = (props) => {
  const query = queryString.parse(props.location.search);
  const type = query.type ? query.type : 'new';
  const [loading, setLoading] = useState(false);
  const [orgHospitalIds, setOrgHospitalIds] = useState([]);
  const [hospitals, setHospitals] = useState([]);

  const [user, setUser] = useState({});
  const [userRePwd, setUserRePwd] = useState('');

  const [uploadFile1, setUploadFile1] = useState([]);
  const childRef1 = useRef(null);
  const imgKey1 = 'businessNumberFile';

  const [uploadFile2, setUploadFile2] = useState([]);
  const childRef2 = useRef(null);
  const imgKey2 = 'foreignMedicalCertFile';

  const [uploadFile3, setUploadFile3] = useState([]);
  const childRef3 = useRef(null);
  const imgKey3 = 'businessAgreementFile';

  const { history } = props;
  const classes = useStyles();

  useEffect(() => {
    getHospitalList().then((result) => {
      setHospitals(result);
    });
    getData();
  }, []);

  const getData = () => {
    if (type === 'edit' && query.id) {
      getUserInfo(query.id).then((result) => {
        setUser(result);
        setOrgHospitalIds(result['hospitalIds']);

        if (childRef1.current) {
          childRef1.current.getSignedUrl(result[imgKey1]);
        }
        if (childRef2.current) {
          childRef2.current.getSignedUrl(result[imgKey2]);
        }
        if (childRef3.current) {
          childRef3.current.getSignedUrl(result[imgKey3]);
        }
      });
    }
  };

  const addUser = () => {
    if (!user.id) {
      swal('Missing required id.');
      return;
    }
    if (!user.name) {
      swal('Missing required name.');
      return;
    }
    if (type === 'new' && !user.password) {
      swal('Missing required password.');
      return;
    }
    if (type === 'new' && !userRePwd && user.password !== userRePwd) {
      swal('Incorrect password.');
      return;
    }

    let param = {
      typeId: user.typeId || MEMBER_TYPE[1].id,
      languageId: user.languageId || DEF_LANGUAGE,
      countryId: user.countryId || DEF_COUNTRY,
      name: user.name,
      password: user.password,
      phoneNumber: user.phoneNumber,
      rank: user.rank,
      email: user.email,
      status: user.status || DEF_USER_STATUS,
      memo: user.memo,
      introduce: user.introduce,
      businessNumber: user.businessNumber,
      foreignMedicalCert: user.foreignMedicalCert,
      hospitalIds: user.hospitalIds,
    };

    var promises = [];
    console.log('orgHospitala: ', orgHospitalIds);
    console.log('user.hospitalIds: ', user.hospitalIds);
    if (orgHospitalIds !== user.hospitalIds) {
      console.log('In (orgHospitalIds !== user.hospitalIds)');
      user.hospitalIds.map((hosId) => {
        let willRemoveIds = orgHospitalIds.filter((e) => e !== hosId);
        setOrgHospitalIds(willRemoveIds);

        let hosParam = {};
        hosParam['id'] = hosId;
        hosParam['adminId'] = user.id;
        hosParam['adminName'] = user.name;
        promises.push(axiosInstance.put(`${API_URL}/hospital/`, hosParam, { headers: { Pragma: 'no-cache' } }));
      });

      console.log('willRemoveHospitalIds: ', orgHospitalIds);
      orgHospitalIds.map((hosId) => {
        console.log('remove hospitals admin :', hosId);
        let hosParam = {};
        hosParam['id'] = hosId;
        hosParam['adminId'] = sessionStorage.getItem('bt_user');
        hosParam['adminName'] = sessionStorage.getItem('bt_name');
        promises.push(axiosInstance.put(`${API_URL}/hospital/`, hosParam, { headers: { Pragma: 'no-cache' } }));
      });
    }

    setLoading(true);
    if (type === 'new') {
      param['id'] = user.id;
      axiosInstance.post(`${API_URL}/user/`, param, { headers: { Pragma: 'no-cache' } }).then(async (response) => {
        console.log(response);
        if (response.status === 200) {
          childRef1.current && promises.push(childRef1.current.fileUpload(uploadFile1, response.data.value.id, 'user', `${API_URL}/user/`));
          childRef2.current && promises.push(childRef2.current.fileUpload(uploadFile1, response.data.value.id, 'user', `${API_URL}/user/`));
          childRef3.current && promises.push(childRef3.current.fileUpload(uploadFile1, response.data.value.id, 'user', `${API_URL}/user/`));
          await Promise.all(promises);

          swal('Registered.', {
            icon: 'success',
          });
          setLoading(false);
          goList();
        } else {
          setLoading(false);
          console.log(response.message);
          alert(response.message);
        }
      });
    } else {
      param['id'] = query.id;

      axiosInstance.put(`${API_URL}/user/`, param, { headers: { Pragma: 'no-cache' } }).then(async (response) => {
        if (response.status === 200) {
          childRef1.current && promises.push(childRef1.current.fileUpload(uploadFile1, query.id, 'user', `${API_URL}/user/`));
          childRef2.current && promises.push(childRef2.current.fileUpload(uploadFile1, query.id, 'user', `${API_URL}/user/`));
          childRef3.current && promises.push(childRef3.current.fileUpload(uploadFile1, query.id, 'user', `${API_URL}/user/`));
          await Promise.all(promises);

          swal('It is changed.', {
            icon: 'success',
          });
          goList();
          setLoading(false);
        }
        setLoading(false);
      });
    }
  };

  const deleteUser = () => {
    swal({
      title: 'Are you sure you want to delete?',
      text: '',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        axiosInstance.delete(`${API_URL}/user/manager/${query.id}`, { headers: { Pragma: 'no-cache' } }).then((response) => {
          if (response.status === 200) {
            goList();
            swal('It is deleted.', {
              icon: 'success',
            });
          } else {
            alert(response);
          }
        });
      }
    });
  };

  const goList = () => {
    history.push('/pages/manager/');
  };

  const handleChangeData = (value, field) => {
    user[field] = value;
    setUser({ ...user });
  };

  const handleFile = (file, key) => {
    console.log(file, key);
    if (key === imgKey1) {
      setUploadFile1([...file]);
    }
    if (key === imgKey2) {
      setUploadFile2([...file]);
    }
    if (key === imgKey3) {
      setUploadFile3([...file]);
    }
  };
  const handleUpload = () => {
    console.log('Upload Success');
  };
  const handleRemove = () => {
    console.log('Remove Success');
    getData();
  };

  const handleChangeManagedHospital = (value) => {
    const ids = [];
    value.map((_hos) => {
      ids.push(_hos.id);
    });
    handleChangeData(ids, 'hospitalIds');
  };

  const getSelectedHospitals = () => {
    let retHospitals = [];
    user.hospitalIds &&
      user.hospitalIds.map((hosId) => {
        const hos =
          hospitals &&
          hospitals.find((_hos) => {
            if (_hos.id === hosId) return _hos;
          });
        hos && retHospitals.push(hos);
      });
    return retHospitals;
  };

  const ManagedHospitalAutocomplete = () => {
    return (
      <Autocomplete
        multiple
        size={'small'}
        id="mng-hospitals"
        options={hospitals}
        disableCloseOnSelect
        getOptionLabel={(option) => `${option['name_ko']} (${option['name_en']}) (${option['languageId']})` || ''}
        value={getSelectedHospitals()}
        onChange={(e, value, reason) => {
          if (reason === 'clear') {
            handleChangeData([], 'hospitalIds');
          } else {
            handleChangeManagedHospital(value);
          }
        }}
        renderOption={(option, { selected }) => (
          <React.Fragment>
            <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
            {`${option['name_ko']} (${option['name_en']}) (${option['languageId']})`}
          </React.Fragment>
        )}
        style={{ width: '100%' }}
        className={classes.mInput}
        renderInput={(params) => (
          <TextField {...params} variant="outlined" label="" placeholder="" inputProps={{ ...params.inputProps, readOnly: false }} />
        )}
      />
    );
  };

  return (
    <OftadehLayout>
      <Grid container className={classes.my3} alignItems="center">
        <Grid item className={classes.mRight} style={{ fontWeight: 'bold' }}>
          <Typography variant="h5" component="h1" style={{ fontWeight: 'bold' }}>
            Manager {type === 'new' ? 'Registration' : 'Edit'}
          </Typography>
        </Grid>
      </Grid>

      <div className={classes.root}>
        <Card className={classes.root}>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} mb={12}>
                <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                  Basic Info
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography style={{ marginTop: 10 }}>Type</Typography>
                <FormControl component="gender-form" className={classes.mInput}>
                  <RadioGroup
                    aria-label="type-radio-group"
                    name="type-radio-group"
                    onChange={(event) => {
                      handleChangeData(event.target.value, 'typeId');
                    }}
                    row
                  >
                    <FormControlLabel
                      value={MEMBER_TYPE[1].id}
                      checked={user.typeId === undefined || user.typeId === MEMBER_TYPE[1].id}
                      control={<Radio />}
                      label={MEMBER_TYPE[1][getDefName()] || ''}
                    />
                    <FormControlLabel
                      value={MEMBER_TYPE[2].id}
                      checked={user.typeId === MEMBER_TYPE[2].id}
                      control={<Radio />}
                      label={MEMBER_TYPE[2][getDefName()] || ''}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <Grid container>
                  <Grid item xs={6}>
                    <Typography style={{ marginTop: 10 }}>Language</Typography>
                    <LanguageAutocomplete target={user} handleChangeData={handleChangeData} />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography style={{ marginTop: 10 }}>Country</Typography>
                    <CountryAutocomplete target={user} handleChangeData={handleChangeData} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Typography style={{ marginTop: 10 }}>Manager ID</Typography>
                <TextField
                  readOnly={type === 'edit' && query.id}
                  size={'small'}
                  key={`TextField-user-id`}
                  className={classes.mInput}
                  placeholder="example@example.com"
                  margin="normal"
                  style={{ width: '100%', marginRight: '10px' }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  value={user.id || ''}
                  onChange={(event) => {
                    type === 'new' && handleChangeData(event.target.value, 'id');
                  }}
                  error={!EMAIL_REG.test(user.id)}
                  helperText={EMAIL_REG.test(user.id) ? '' : 'Please enter manager id(email) correctly.'}
                />
              </Grid>
              {type === 'new' && (
                <Grid container xs={6}>
                  <Grid item xs={6}>
                    <Typography style={{ marginTop: 10 }}>Password</Typography>
                    <TextField
                      size={'small'}
                      key={`TextField-pwd`}
                      className={classes.mInput}
                      placeholder=""
                      margin="normal"
                      type="password"
                      style={{ width: '100%', marginRight: '10px' }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      value={user.password || ''}
                      onChange={(event) => {
                        handleChangeData(event.target.value, 'password');
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography style={{ marginTop: 10 }}>Re-Password</Typography>
                    <TextField
                      error={user.password !== userRePwd}
                      size={'small'}
                      key={`TextField-patient-number`}
                      className={classes.mInput}
                      placeholder=""
                      margin="normal"
                      type="password"
                      style={{ width: '100%', marginRight: '10px' }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      value={userRePwd || ''}
                      onChange={(e) => setUserRePwd(e.target.value)}
                      helperText={user.password !== userRePwd ? 'Incorrect password.' : ''}
                    />
                  </Grid>
                </Grid>
              )}
              <Grid item xs={6}>
                <Typography style={{ marginTop: 10 }}>Manager name</Typography>
                <TextField
                  size={'small'}
                  key={`TextField-name-ko`}
                  className={classes.mInput}
                  placeholder=""
                  margin="normal"
                  style={{ width: '100%', marginRight: '10px' }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  value={user.name || ''}
                  onChange={(event) => {
                    handleChangeData(event.target.value, 'name');
                  }}
                />
              </Grid>
              {/*<Grid item xs={6}>*/}
              {/*  <Typography style={{ marginTop: 10 }}>Manager rank</Typography>*/}
              {/*  <TextField*/}
              {/*    size={'small'}*/}
              {/*    key={`TextField-manager-rank`}*/}
              {/*    className={classes.mInput}*/}
              {/*    placeholder=""*/}
              {/*    margin="normal"*/}
              {/*    style={{ width: '100%', marginRight: '10px' }}*/}
              {/*    InputLabelProps={{*/}
              {/*      shrink: true,*/}
              {/*    }}*/}
              {/*    variant="outlined"*/}
              {/*    value={user.rank || ''}*/}
              {/*    onChange={(event) => {*/}
              {/*      handleChangeData(event.target.value, 'rank');*/}
              {/*    }}*/}
              {/*  />*/}
              {/*</Grid>*/}
              {type === 'edit' && (
                <>
                  <Grid item xs={6}>
                    <Typography component="legend">Managed Hospital</Typography>
                    {/*<HospitalAutocomplete target={user} handleChangeData={handleChangeData} />*/}
                    <ManagedHospitalAutocomplete />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography style={{ marginTop: 10 }}>Manager Phone Number</Typography>
                    <TextField
                      size={'small'}
                      key={`TextField-phonenumber`}
                      className={classes.mInput}
                      placeholder=""
                      margin="normal"
                      style={{ width: '100%', marginRight: '10px' }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      value={user.phoneNumber || ''}
                      onChange={(event) => {
                        handleChangeData(event.target.value, 'phoneNumber');
                      }}
                      // error={!phoneNumberValidation(user.phoneNumber)}
                      // helperText={phoneNumberValidation(user.phoneNumber) ? '' : 'Please enter your phone number correctly.'}
                    />
                  </Grid>
                  {/*<Grid item xs={6}>*/}
                  {/*  <Typography style={{ marginTop: 10 }}>Status</Typography>*/}
                  {/*  <FormControl component="status-form" className={classes.mInput}>*/}
                  {/*    <RadioGroup*/}
                  {/*      aria-label="status-radio-group"*/}
                  {/*      name="status-radio-group"*/}
                  {/*      onChange={(event) => {*/}
                  {/*        handleChangeData(event.target.value, 'status');*/}
                  {/*      }}*/}
                  {/*      row*/}
                  {/*    >*/}
                  {/*      <FormControlLabel*/}
                  {/*        value={MEMBER_STATUS[0].id}*/}
                  {/*        checked={user.status === undefined || user.status === MEMBER_STATUS[0].id}*/}
                  {/*        control={<Radio />}*/}
                  {/*        label={MEMBER_STATUS[0][getDefName()] || ''}*/}
                  {/*      />*/}
                  {/*      <FormControlLabel*/}
                  {/*        value={MEMBER_STATUS[1].id}*/}
                  {/*        checked={user.status === MEMBER_STATUS[1].id}*/}
                  {/*        control={<Radio />}*/}
                  {/*        label={MEMBER_STATUS[1][getDefName()] || ''}*/}
                  {/*      />*/}
                  {/*    </RadioGroup>*/}
                  {/*  </FormControl>*/}
                  {/*</Grid>*/}
                </>
              )}
            </Grid>
          </CardContent>
        </Card>
        {type === 'edit' && (
          <>
            <Card className={classes.my3}>
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12} mb={12}>
                    <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                      Company Info
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography style={{ marginTop: 10 }}>Memo</Typography>
                    <TextareaAutosize
                      key={'textarea-memo'}
                      id={'textarea-memo'}
                      className={classes.textArea}
                      aria-label="default"
                      placeholder=""
                      value={user.memo || ''}
                      onChange={(e) => handleChangeData(e.target.value, 'memo')}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography style={{ marginTop: 10 }}>Company introduction</Typography>
                    <TextareaAutosize
                      key={'textarea-introduce'}
                      id={'textarea-introduce'}
                      className={classes.textArea}
                      aria-label="default"
                      placeholder=""
                      value={user.introduce || ''}
                      onChange={(e) => handleChangeData(e.target.value, 'introduce')}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography style={{ marginTop: 10 }}>Company Registration Number</Typography>
                    <TextField
                      size={'small'}
                      key={`TextField-name-ko`}
                      className={classes.mInput}
                      placeholder=""
                      margin="normal"
                      style={{ width: '100%', marginRight: '10px' }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      value={user.businessNumber || ''}
                      onChange={(event) => {
                        handleChangeData(event.target.value, 'businessNumber');
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography style={{ marginTop: 10 }}>
                      Foreign patient attraction medical institution registration certificate
                    </Typography>
                    <TextField
                      size={'small'}
                      key={`TextField-manager-rank`}
                      className={classes.mInput}
                      placeholder=""
                      margin="normal"
                      style={{ width: '100%', marginRight: '10px' }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      value={user.foreignMedicalCert || ''}
                      onChange={(event) => {
                        handleChangeData(event.target.value, 'foreignMedicalCert');
                      }}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <Card className={classes.my3}>
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12} mb={12}>
                    <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                      Attachments
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography style={{ marginTop: 10 }}>Business license file</Typography>
                    <FileUpload
                      type={'file'}
                      ref={childRef1}
                      buttonText={'FILE'}
                      onFileHandler={handleFile}
                      onRemoveAfter={handleRemove}
                      onUploadAfter={handleUpload}
                      fileId={query.id}
                      folder={'user'}
                      apiUrl={`${API_URL}/user/`}
                      uploadKey={imgKey1}
                      maxCount={1}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography style={{ marginTop: 10 }}>
                      Foreign patient attraction medical institution registration certificate file
                    </Typography>
                    <FileUpload
                      type={'file'}
                      ref={childRef2}
                      buttonText={'FILE'}
                      onFileHandler={handleFile}
                      onRemoveAfter={handleRemove}
                      onUploadAfter={handleUpload}
                      fileId={query.id}
                      folder={'user'}
                      apiUrl={`${API_URL}/user/`}
                      uploadKey={imgKey2}
                      maxCount={1}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography style={{ marginTop: 10 }}>Business agreement</Typography>
                    <FileUpload
                      type={'file'}
                      ref={childRef3}
                      buttonText={'FILE'}
                      onFileHandler={handleFile}
                      onRemoveAfter={handleRemove}
                      onUploadAfter={handleUpload}
                      fileId={query.id}
                      folder={'user'}
                      apiUrl={`${API_URL}/user/`}
                      uploadKey={imgKey3}
                      maxCount={1}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </>
        )}
        {/*<Card className={classes.my3}>*/}
        {/*  <CardActions style={{ marginLeft: '16px', marginRight: '16px' }}>*/}
        {/*    */}
        {/*  </CardActions>*/}
        {/*</Card>*/}

        <Grid container className={classes.my3} justifyContent={'space-between'} xs={12} md={12} spacing={3}>
          <Grid item>
            <Button
              variant="contained"
              style={{ backgroundColor: green[700], color: '#ffffff', marginRight: '10px' }}
              size="medium"
              onClick={goList}
              startIcon={<ListIcon />}
            >
              List
            </Button>
            {type !== 'new' ? (
              <Button variant="contained" color="secondary" size="medium" onClick={deleteUser} startIcon={<DeleteIcon />}>
                Delete
              </Button>
            ) : null}
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" size="medium" onClick={addUser} startIcon={<SaveIcon />}>
              {type === 'new' ? 'Registration' : 'Edit'}
            </Button>
          </Grid>
        </Grid>
      </div>
      {loading && (
        <div style={{ position: 'fixed', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', zIndex: 999 }}>
          <CircularProgress color={'secondary'} />
        </div>
      )}
    </OftadehLayout>
  );
};

export default ManagerDetailPage;
