import ChangePasswordPage from './ChangePasswordPage';

export const ChangePasswordPageConfig = {
  routes: [
    {
      path: '/pages/auth/change-password',
      exact: true,
      component: ChangePasswordPage,
    },
  ],
};
