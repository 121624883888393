/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: 'ap-northeast-2',
  aws_cognito_identity_pool_id: 'ap-northeast-2:ae2f6d6d-e1eb-4a5f-ae8a-63c61c4e48e4',
  aws_cognito_region: 'ap-northeast-2',
  aws_user_pools_id: 'ap-northeast-2_RsmKqH0Al',
  aws_user_pools_web_client_id: '1j82d5ikis2p069033g619glr',
  oauth: {},
  aws_user_files_s3_bucket: 'file.koreamedis.com',
  aws_user_files_s3_bucket_region: 'ap-northeast-2',
  aws_access_key_id: 'AKIAUWFE652H4SORZ55E',
  aws_secret_access_key: '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',
};

export default awsmobile;
