const navigationConfig = [
  {
    id: 'Main',
    title: '',
    type: 'group',
    children: [
      {
        id: 'notice',
        title: 'Notice',
        type: 'item',
        icon: 'notification',
        url: '/pages/notice',
        exact: true,
      },
      {
        id: 'hospital',
        title: 'Hospital',
        type: 'item',
        icon: 'database',
        url: '/pages/hospital',
        exact: true,
      },
      {
        id: 'recDoctor',
        title: 'Doctor',
        type: 'item',
        icon: 'idcard',
        url: '/pages/recDoctor',
        exact: true,
      },
      {
        id: 'product',
        title: 'Product',
        type: 'item',
        icon: 'shopping',
        url: '/pages/product',
        exact: true,
      },
      {
        id: 'news',
        title: 'News & Column',
        type: 'item',
        icon: 'file_text',
        url: '/pages/news',
        exact: true,
      },
    ],
  },
];

export default navigationConfig;
