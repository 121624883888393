import { makeStyles, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { getDiseaseList } from '../../constants/PromiseHttp';
import { Autocomplete } from '@material-ui/lab';
import { getDefName, getSubName } from '../../constants';

const useStyles = makeStyles((theme) => ({
  mInput: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
}));

const DiseaseAutocomplete = (props) => {
  const [diseases, setDiseases] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    getDiseaseList().then((result) => {
      setDiseases(result);
    });
  }, []);

  const getSelectedDisease = () => {
    const item = diseases.find((di) => {
      if (di.id === props.target.diseaseId) return di;
    });
    //console.log('getSelectedDisease', item);
    return item || null;
  };

  return (
    <Autocomplete
      key="cbox-treatment-disaseId"
      value={getSelectedDisease()}
      onChange={(event, newValue) => {
        props.handleChangeData(newValue.id, 'diseaseId');
      }}
      size={'small'}
      disableClearable
      options={diseases}
      getOptionLabel={(option) => `${option[getDefName()]}(${option[getSubName()]})` || ''}
      getOptionSelected={(option, value) => option.id === value.id}
      style={{ width: '100%' }}
      className={classes.mInput}
      renderInput={(params) => <TextField {...params} variant="outlined" inputProps={{ ...params.inputProps, readOnly: true }} />}
    />
  );
};

export default DiseaseAutocomplete;
