import React, { Fragment, useEffect, useState } from 'react';
import { AddCircle, RemoveCircle } from '@material-ui/icons';
import { IconButton, makeStyles, Paper, TableCell, TextField } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 'auto',
  },
  mInput: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
}));
const ScheduleDynamicForm = (props) => {
  const classes = useStyles();

  const [inputFields, setInputFields] = useState([{ day: '', contents: '' }]);

  useEffect(() => {
    if (props.target && props.target.schedule) {
      setInputFields(props.target.schedule);
    }
  }, [props.target.schedule]);

  const handleAddFields = () => {
    const values = [...inputFields];
    values.push({ day: '', contents: '' });
    setInputFields(values);
  };

  const handleRemoveFields = (index) => {
    const values = [...inputFields];
    values.splice(index, 1);
    setInputFields(values);
    props.handleChangeData(inputFields, 'schedule');
  };

  const handleInputChange = (index, event) => {
    const values = [...inputFields];
    if (event.target.name === 'day') {
      values[index].day = event.target.value;
    } else {
      values[index].contents = event.target.value;
    }

    setInputFields(values);
    props.handleChangeData(inputFields, 'schedule');
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('inputFields', inputFields);
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow style={{ height: '10px', width: '20%' }}>
            <TableCell style={{ margin: 0, padding: 0 }} align={'center'}>
              Day
            </TableCell>
            <TableCell style={{ margin: 0, padding: 0, width: '70%' }} align={'center'}>
              Contents
            </TableCell>
            <TableCell style={{ margin: 0, padding: 0, width: '10%' }}>
              <IconButton variant="outlined" color="primary" aria-label="add" onClick={() => handleAddFields()}>
                <AddCircle />
              </IconButton>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {inputFields.map((inputField, index) => (
            <Fragment key={`${inputField}~${index}`}>
              <TableRow style={{ height: '10px' }}>
                <TableCell style={{ margin: 0, padding: 3, width: '20%' }}>
                  <TextField
                    type="text"
                    className={classes.mInput}
                    id="day"
                    name="day"
                    value={inputField.day}
                    size={'small'}
                    variant={'outlined'}
                    style={{ margin: 0, padding: 0 }}
                    onChange={(event) => handleInputChange(index, event)}
                  />
                </TableCell>
                <TableCell style={{ margin: 0, padding: 3, width: '70%' }}>
                  <TextField
                    type="text"
                    className={classes.mInput}
                    id="contents"
                    name="contents"
                    value={inputField.contents}
                    size={'small'}
                    variant={'outlined'}
                    style={{ margin: 0, padding: 0 }}
                    fullWidth
                    onChange={(event) => handleInputChange(index, event)}
                  />
                </TableCell>
                <TableCell style={{ margin: 0, padding: 0, width: '10%' }}>
                  <IconButton variant="outlined" color="secondary" aria-label="remove" onClick={() => handleRemoveFields(index)}>
                    <RemoveCircle />
                  </IconButton>
                </TableCell>
              </TableRow>
            </Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ScheduleDynamicForm;
