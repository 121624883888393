import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import OftadehLayout from '../../components/OftadehLayout/OftadehLayout';
import { Button, Dialog, Grid, makeStyles, Typography } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import swal from '@sweetalert/with-react';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import SaveIcon from '@material-ui/icons/Save';
import ListIcon from '@material-ui/icons/List';
import DeleteIcon from '@material-ui/icons/Delete';
import axiosInstance from '../../constants/AxiosInstance';
import { API_URL, getDefName } from '../../constants';
import { Rating } from '@material-ui/lab';
import { getAnswerNestedList, getDiseaseList, getHospitalList, getReviewInfo } from '../../constants/PromiseHttp';
import AnswerComponent, { AnswerCard } from '../customComp/AnswerComponent';
import ImgListView from '../customComp/ImgListView';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    color: '#3f51b5',
  },
  my3: {
    margin: '1.3rem 0',
  },
  mRight: {
    marginRight: '.85rem',
  },
  chipContainer: {
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
    display: 'flex',
  },

  mInput: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
}));

const ReviewDetailPage = (props) => {
  const query = queryString.parse(props.location.search);

  const [review, setReview] = useState({ hidden: false });
  const [diseases, setDiseases] = useState();
  const [hospitalList, setHospitalList] = useState([]);

  const { history } = props;
  const classes = useStyles();

  const [answerDlgOpen, setAnswerDlgOpen] = useState(false);
  const [editAnswerId, setEditAnswerId] = useState('');
  const [parentAnswerId, setParentAnswerId] = useState();
  const [answers, setAnswers] = useState();

  useEffect(() => {
    getData();
    getDiseaseList().then((result) => {
      setDiseases(result);
    });
    getHospitalList().then((result) => {
      setHospitalList(result);
    });
  }, []);

  const getData = () => {
    if (query.id) {
      getReviewInfo(query.id).then((result) => {
        setReview(result);
        setParentAnswerId('');
        setEditAnswerId('');
        getAnswerNestedList(result.id).then((res) => {
          console.log('nested list:', res);
          setAnswers(res);
        });
      });
    }
  };

  const deleteReview = () => {
    swal({
      title: 'Are you sure you want to delete?',
      text: '',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        axiosInstance.delete(`${API_URL}/review/${query.id}`, { headers: { Pragma: 'no-cache' } }).then((response) => {
          if (response.status === 200) {
            swal('It is deleted.', {
              icon: 'success',
            });
            goList();
          }
        });
      } else {
      }
    });
  };

  const deleteAnswer = (answerId) => {
    axiosInstance.delete(`${API_URL}/answer/${answerId}`, { headers: { Pragma: 'no-cache' } }).then((response) => {
      if (response.status === 200) {
        swal('Answer has been deleted.', {
          icon: 'success',
        });
        handleAnswerDlgClose(true);
      }
    });
  };

  const openAddAnswer = (pid) => {
    console.log('DetailPage::openAddAnswer :', pid);
    setParentAnswerId(pid);
    setAnswerDlgOpen(true);
  };

  const openEditAnswer = (aid) => {
    console.log('DetailPage::openEditAnswer :', aid);
    setEditAnswerId(aid);
    setAnswerDlgOpen(true);
  };

  const handleAnswerDlgOpen = () => {
    setParentAnswerId('');
    setEditAnswerId('');
    setAnswerDlgOpen(true);
  };

  const handleAnswerDlgClose = (reload) => {
    if (reload) {
      getData();
    }
    setParentAnswerId('');
    setEditAnswerId('');
    setAnswerDlgOpen(false);
  };

  const goList = () => {
    history.push('/pages/review/');
  };

  const goEdit = () => {
    history.push(`/pages/review/edit?type=edit&id=${query.id}`);
  };

  const SearchUserBox = () => {
    return (
      <Grid item xs={6} mb={6}>
        <div className={classes.title}>Member</div>
        <Typography variant="body1">
          {review.userName}({review.userId})
        </Typography>
      </Grid>
    );
  };

  const RatingBox = () => {
    return (
      <Grid item xs={6} mb={6}>
        <div className={classes.title}>Rating</div>
        <Rating name="simple-controlled" value={review ? review.rating : 0} readOnly />
      </Grid>
    );
  };

  const getDiseaseNames = (id) => {
    let names = '';
    if (!id || !diseases) {
      return names;
    }
    let di = diseases.find((_di) => _di.id === id);
    names = di[getDefName()];
    return names;
  };

  const getHospitalName = (hid) => {
    const hospital = hospitalList && hospitalList.find((hos) => hos.id === hid);
    return hospital ? hospital['name_ko'] : '-';
  };

  return (
    <OftadehLayout>
      <Grid container className={classes.my3} alignItems="center">
        <Grid item className={classes.mRight} style={{ fontWeight: 'bold' }}>
          <Typography variant="h5" component="h1" style={{ fontWeight: 'bold' }}>
            Review
          </Typography>
        </Grid>
      </Grid>
      <div>
        <Card className={classes.root}>
          <CardContent className={classes.my3}>
            <Grid container spacing={3}>
              <Grid item xs={6} mb={6}>
                <div className={classes.title}>Language</div>
                <Typography variant="body1">{review.languageId}</Typography>
              </Grid>
              <SearchUserBox />
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={6} mb={6}>
                <div className={classes.title}>Medical institution </div>
                <Typography variant="body1">{getHospitalName(review && review.hospitalId)}</Typography>
              </Grid>
              <RatingBox />
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} mb={12}>
                <div className={classes.title}>Title</div>
                <Typography variant="body1">{review.title}</Typography>
              </Grid>
              <Grid item xs={12} mb={12}>
                <div className={classes.title}>Contents</div>
                <Typography variant="body1">{review.contents}</Typography>
              </Grid>
              <Grid item xs={12} mb={12}>
                <div className={classes.title}>Disease</div>
                <Typography variant="body1">{getDiseaseNames(review && review.diseaseId)}</Typography>
              </Grid>
              <Grid item xs={12} mb={12}>
                <div className={classes.title}>Show/Hide</div>
                <Typography variant="body1">{review.hidden ? 'Hide' : 'Show'}</Typography>
              </Grid>
              <Grid item xs={12} mb={12}>
                <div className={classes.title}>Attachments</div>
                {review && <ImgListView images={review && review.photo} />}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Grid container className={classes.my3} alignItems="center">
          <Grid item xs={12}>
            <Typography variant="h5" component="h1" style={{ fontWeight: 'bold' }}>
              Answer
            </Typography>
            <Button variant="outlined" color="primary" onClick={handleAnswerDlgOpen}>
              Register answer
            </Button>
            <Dialog fullWidth open={answerDlgOpen} onClose={handleAnswerDlgClose} aria-labelledby="customized-dialog-title">
              <AnswerComponent targetId={query.id} parentId={parentAnswerId} answerId={editAnswerId} handleClose={handleAnswerDlgClose} />
            </Dialog>
          </Grid>
        </Grid>
        <Card>
          <CardContent>
            {review &&
              answers &&
              answers.map((answer, idx) => (
                <AnswerCard
                  key={`answercard-${answer.id}`}
                  answer={answer}
                  idx={idx}
                  depth={0}
                  handleChild={openAddAnswer}
                  handleEdit={openEditAnswer}
                  handleDelete={deleteAnswer}
                />
              ))}
          </CardContent>
        </Card>
      </div>
      <div className={classes.my3}>
        <Grid container xs={12} md={12} spacing={3}>
          <Grid item>
            <Button
              variant="contained"
              style={{ backgroundColor: green[700], color: '#fdfdfd', marginRight: '10px' }}
              size="medium"
              onClick={goList}
              startIcon={<ListIcon />}
            >
              List
            </Button>
            <Button variant="contained" color="secondary" size="medium" onClick={deleteReview} startIcon={<DeleteIcon />}>
              Delete
            </Button>
          </Grid>
          {/*<Grid item>*/}
          {/*  <Button variant="contained" color="primary" size="medium" onClick={goEdit} startIcon={<SaveIcon />}>*/}
          {/*    Edit*/}
          {/*  </Button>*/}
          {/*</Grid>*/}
        </Grid>
      </div>
    </OftadehLayout>
  );
};

export default ReviewDetailPage;
