import React, { useEffect, useRef, useState } from 'react';
import queryString from 'query-string';
import OftadehLayout from '../../components/OftadehLayout/OftadehLayout';
import { Button, Divider, FormControlLabel, Grid, makeStyles, Radio, RadioGroup, TextField, Typography } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import swal from '@sweetalert/with-react';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import SaveIcon from '@material-ui/icons/Save';
import ListIcon from '@material-ui/icons/List';
import DeleteIcon from '@material-ui/icons/Delete';
import { getUserInfo } from '../../constants/PromiseHttp';
import axiosInstance from '../../constants/AxiosInstance';
import {
  API_URL,
  DEF_COUNTRY,
  DEF_DATE,
  DEF_GENDER,
  DEF_LANGUAGE,
  DEF_USER_STATUS,
  GENDER_TYPE,
  MEMBER_STATUS,
  getDefName,
  PHONE_REG,
  phoneNumberValidation,
  EMAIL_REG,
} from '../../constants';
import FileUpload, { fileUploadOnly } from '../../components/FileUpload/FileUpload';
import LanguageAutocomplete from '../customComp/LanguageAutocomplete';
import CountryAutocomplete from '../customComp/CountryAutocomplete';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import FormControl from '@material-ui/core/FormControl';
import MemberRelationList from './MemberRelationList';
import DropZone from '../../components/FileUpload/DropZone';
import awsconfig from '../../aws-exports';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  my3: {
    margin: '1.3rem 0',
  },
  mb0: {
    marginBottom: 0,
  },
  mRight: {
    marginRight: '.85rem',
  },
  mInput: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
  textArea: {
    width: '100%',
    padding: theme.spacing(2),
    minHeight: 300,
    borderColor: 'lightgray',
    margin: theme.spacing(1),
    marginTop: 10,
  },
}));

const MemberDetailPage = (props) => {
  const query = queryString.parse(props.location.search);
  const type = query.type ? query.type : 'new';

  const [user, setUser] = useState({});
  const [userRePwd, setUserRePwd] = useState('');

  const [uploadPhoto, setUploadPhoto] = useState([]);
  const photoRef = useRef(null);
  const photoKey = 'photo';

  const [uploadFile, setUploadFile] = useState([]);
  const childRef = useRef(null);
  const imgKey = 'passport';

  const { history } = props;
  const classes = useStyles();

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    if (type === 'edit' && query.id) {
      getUserInfo(query.id).then((result) => {
        setUser(result);
        if (photoRef.current) {
          photoRef.current.getSignedUrl(result[photoKey]);
        }
        if (childRef.current) {
          childRef.current.getSignedUrl(result[imgKey]);
        }
      });
    }
  };

  const addUser = () => {
    if (!user.id) {
      swal('Missing required id.');
      return;
    }
    if (!user.name) {
      swal('Missing required name.');
      return;
    }
    if (type === 'new' && !user.password) {
      swal('Missing required password.');
      return;
    }
    if (type === 'new' && !userRePwd && user.password !== userRePwd) {
      swal('Incorrect password.');
      return;
    }

    let param = {
      typeId: 'member',
      languageId: user.languageId || DEF_LANGUAGE,
      name: user.name,
      countryId: user.countryId || DEF_COUNTRY,
      password: user.password,
    };

    if (type === 'new') {
      param['id'] = user.id;

      console.log(param);

      axiosInstance.post(`${API_URL}/user/`, param, { headers: { Pragma: 'no-cache' } }).then(async (response) => {
        if (response.status === 200) {
          swal('Registered.', {
            icon: 'success',
          });
          goList();
        } else {
          alert(response);
        }
      });
    } else {
      param['id'] = query.id;
      param['phoneNumber'] = user.phoneNumber;
      param['birth'] = user.birth;
      param['status'] = user.status || DEF_USER_STATUS;
      param['gender'] = user.gender || DEF_GENDER;
      param['address'] = user.address;
      param['memo'] = user.memo;

      axiosInstance.put(`${API_URL}/user/`, param, { headers: { Pragma: 'no-cache' } }).then(async (response) => {
        if (response.status === 200) {
          await Promise.all([
            user.photo &&
              user.photo.length > 0 &&
              fileUploadOnly(user.photo[0].obj, query.id, 'user', `${API_URL}/user/`, 'photo').then((r) => {
                console.log(r ? `${r} upload success` : 'upload failed');
              }),
            uploadFile &&
              uploadFile.length > 0 &&
              fileUploadOnly(uploadFile[0], query.id, 'user', `${API_URL}/user/`, imgKey).then((r) => {
                console.log(r ? `${r} upload success` : 'upload failed');
              }),
          ]).then(() => {
            swal('It is changed.', {
              icon: 'success',
            });
            goList();
          });
        } else {
          alert(response);
        }
      });
    }
  };

  const deleteUser = () => {
    swal({
      title: 'Are you sure you want to delete?',
      text: '',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        axiosInstance.delete(`${API_URL}/user/${query.id}`, { headers: { Pragma: 'no-cache' } }).then((response) => {
          if (response.status === 200) {
            swal('It is deleted.', {
              icon: 'success',
            });
            goList();
          } else {
            alert(response);
          }
        });
      }
    });
  };

  const goList = () => {
    history.push('/pages/member/');
  };

  const handleChangeData = (value, field) => {
    //console.log('handleChangeData', value, field);
    user[field] = value;
    setUser({ ...user });
  };

  const removeStorageFile = async (file) => {
    if (file) {
      const param = {
        bucket: awsconfig.aws_user_files_s3_bucket,
        key: file.key,
      };
      const url = encodeURI(`${API_URL}/user/files/` + param.bucket + '?key=' + param.key + '&id=' + file.id + '&uploadKey=' + user.id);
      axiosInstance.delete(url).then((res) => {
        return;
      });
    }
  };

  const handleAddImage = (file) => {
    console.log(file);
    user.photo = [file];
    setUser({ ...user });
  };

  const handleFile = (file, key) => {
    console.log(file, key);
    if (key === imgKey) {
      setUploadFile([...file]);
    }
    if (key === photoKey) {
      setUploadPhoto([...file]);
    }
  };
  const handleUpload = () => {
    console.log('Upload Success');
  };
  const handleRemove = () => {
    console.log('Remove Success');
    getData();
  };

  return (
    <OftadehLayout>
      <Grid container className={classes.my3} alignItems="center">
        <Grid item className={classes.mRight} style={{ fontWeight: 'bold' }}>
          <Typography variant="h5" component="h1" style={{ fontWeight: 'bold' }}>
            Member {type === 'new' ? 'Registration' : 'Edit'}
          </Typography>
        </Grid>
      </Grid>

      <div className={classes.root}>
        <Card className={classes.root}>
          <CardContent>
            <Grid container xs={12} spacing={3}>
              {type === 'edit' && (
                <Grid item xs={4}>
                  <Typography component="legend">
                    Photo <span style={{ color: 'red' }}>*</span>
                  </Typography>
                  <DropZone
                    file={user.photo && user.photo[0]}
                    handleAddImage={handleAddImage}
                    removeStorageFile={removeStorageFile}
                    customText={'+ click or drag'}
                  />
                </Grid>
              )}
              <Grid item xs={8}>
                {/*<Grid container xs={12} md={12} spacing={3}>*/}
                <Grid container>
                  <Grid item xs={6}>
                    <Typography style={{ marginTop: 10 }}>Language</Typography>
                    <LanguageAutocomplete target={user} handleChangeData={handleChangeData} />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography style={{ marginTop: 10 }}>Country</Typography>
                    <CountryAutocomplete target={user} handleChangeData={handleChangeData} />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={6}>
                    <Typography style={{ marginTop: 10 }}>User ID</Typography>
                    <TextField
                      readOnly={type === 'edit' && query.id}
                      size={'small'}
                      key={`TextField-user-id`}
                      className={classes.mInput}
                      placeholder="email@email.com"
                      margin="normal"
                      style={{ width: '100%', marginRight: '10px' }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      value={user.id || ''}
                      onChange={(event) => {
                        type === 'new' && handleChangeData(event.target.value, 'id');
                      }}
                      error={!EMAIL_REG.test(user.id)}
                      helperText={EMAIL_REG.test(user.id) ? '' : 'Please enter member id(email) correctly.'}
                    />
                  </Grid>
                  {type === 'edit' && query.id && (
                    <Grid item xs={6}>
                      <Typography style={{ marginTop: 10 }}>Patient Number</Typography>
                      <TextField
                        readOnly
                        size={'small'}
                        key={`TextField-patient-number`}
                        className={classes.mInput}
                        placeholder=""
                        margin="normal"
                        style={{ width: '100%', marginRight: '10px' }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="outlined"
                        value={user.patientNumber || ''}
                      />
                    </Grid>
                  )}
                  {type === 'new' && (
                    <Grid container xs={6}>
                      <Grid item xs={6}>
                        <Typography style={{ marginTop: 10 }}>Password</Typography>
                        <TextField
                          size={'small'}
                          key={`TextField-pwd`}
                          className={classes.mInput}
                          placeholder=""
                          margin="normal"
                          type="password"
                          style={{ width: '100%', marginRight: '10px' }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                          value={user.password || ''}
                          onChange={(event) => {
                            handleChangeData(event.target.value, 'password');
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Typography style={{ marginTop: 10 }}>Re-Password</Typography>
                        <TextField
                          error={user.password !== userRePwd}
                          size={'small'}
                          key={`TextField-patient-number`}
                          className={classes.mInput}
                          placeholder=""
                          margin="normal"
                          type="password"
                          style={{ width: '100%', marginRight: '10px' }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                          value={userRePwd || ''}
                          onChange={(e) => setUserRePwd(e.target.value)}
                          helperText={user.password !== userRePwd ? 'Incorrect password.' : ''}
                        />
                      </Grid>
                    </Grid>
                  )}
                </Grid>
                <Grid container>
                  <Grid item xs={4}>
                    <Typography style={{ marginTop: 10 }}>Name</Typography>
                    <TextField
                      size={'small'}
                      key={`TextField-name-ko`}
                      className={classes.mInput}
                      placeholder=""
                      margin="normal"
                      style={{ width: '100%', marginRight: '10px' }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      value={user.name || ''}
                      onChange={(event) => {
                        handleChangeData(event.target.value, 'name');
                      }}
                    />
                  </Grid>
                  {type === 'edit' && (
                    <Grid item xs={4}>
                      <form noValidate>
                        <Typography style={{ marginTop: 10 }}>Birth date</Typography>
                        <TextField
                          id="date"
                          type="date"
                          value={user.birth || DEF_DATE}
                          variant="outlined"
                          size="small"
                          className={classes.mInput}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(event) => {
                            handleChangeData(event.target.value, 'birth');
                          }}
                        />
                      </form>
                    </Grid>
                  )}
                  {type === 'edit' && (
                    <Grid item xs={4}>
                      <Typography style={{ marginTop: 10 }}>Gender</Typography>
                      <FormControl component="gender-form" className={classes.mInput}>
                        <RadioGroup
                          aria-label="gender-radio-group"
                          name="gender-radio-group"
                          onChange={(event) => {
                            handleChangeData(event.target.value, 'gender');
                          }}
                          row
                        >
                          <FormControlLabel
                            value={GENDER_TYPE[0].id}
                            checked={user.gender === undefined || user.gender === GENDER_TYPE[0].id}
                            control={<Radio />}
                            label={GENDER_TYPE[0][getDefName()] || ''}
                          />
                          <FormControlLabel
                            value={GENDER_TYPE[1].id}
                            checked={user.gender === GENDER_TYPE[1].id}
                            control={<Radio />}
                            label={GENDER_TYPE[1][getDefName()] || ''}
                          />
                          <FormControlLabel
                            value={GENDER_TYPE[2].id}
                            checked={user.gender === GENDER_TYPE[2].id}
                            control={<Radio />}
                            label={GENDER_TYPE[2][getDefName()] || ''}
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
            {type === 'edit' && (
              <>
                <Grid container xs={12} md={12} spacing={3}>
                  <Grid item xs={6}>
                    <Typography style={{ marginTop: 10 }}>Phone number</Typography>
                    <TextField
                      size={'small'}
                      key={`TextField-phonenumber`}
                      className={classes.mInput}
                      placeholder=""
                      margin="normal"
                      style={{ width: '100%', marginRight: '10px' }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      value={user.phoneNumber || ''}
                      onChange={(event) => {
                        handleChangeData(event.target.value, 'phoneNumber');
                      }}
                      error={!phoneNumberValidation(user.phoneNumber)}
                      helperText={phoneNumberValidation(user.phoneNumber) ? '' : 'Please enter your phone number correctly.'}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography style={{ marginTop: 10 }}>Address</Typography>
                    <TextField
                      size={'small'}
                      key={`TextField-address`}
                      className={classes.mInput}
                      placeholder=""
                      margin="normal"
                      style={{ width: '100%', marginRight: '10px' }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      value={user.address || ''}
                      onChange={(event) => {
                        handleChangeData(event.target.value, 'address');
                      }}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <Typography style={{ marginTop: 10 }}>Passport</Typography>
                    <FileUpload
                      type={'file'}
                      ref={childRef}
                      buttonText={'FILE'}
                      onFileHandler={handleFile}
                      onRemoveAfter={handleRemove}
                      onUploadAfter={handleUpload}
                      fileId={query.id}
                      folder={'user'}
                      apiUrl={`${API_URL}/user/`}
                      uploadKey={imgKey}
                      maxCount={1}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography style={{ marginTop: 10 }}>Memo</Typography>
                    <TextareaAutosize
                      key={'textarea-memo'}
                      id={'textarea-memo'}
                      className={classes.textArea}
                      aria-label="default"
                      placeholder=""
                      value={user.memo || ''}
                      onChange={(e) => handleChangeData(e.target.value, 'memo')}
                    />
                  </Grid>
                  {/*<Grid item xs={12}>*/}
                  {/*  <Typography style={{ marginTop: 10 }}>Status</Typography>*/}
                  {/*  <FormControl component="status-form" className={classes.mInput}>*/}
                  {/*    <RadioGroup*/}
                  {/*      aria-label="status-radio-group"*/}
                  {/*      name="status-radio-group"*/}
                  {/*      onChange={(event) => {*/}
                  {/*        handleChangeData(event.target.value, 'status');*/}
                  {/*      }}*/}
                  {/*      row*/}
                  {/*    >*/}
                  {/*      <FormControlLabel*/}
                  {/*        value={MEMBER_STATUS[0].id}*/}
                  {/*        checked={user.status === undefined || user.status === MEMBER_STATUS[0].id}*/}
                  {/*        control={<Radio />}*/}
                  {/*        label={MEMBER_STATUS[0][getDefName()] || ''}*/}
                  {/*      />*/}
                  {/*      <FormControlLabel*/}
                  {/*        value={MEMBER_STATUS[1].id}*/}
                  {/*        checked={user.status === MEMBER_STATUS[1].id}*/}
                  {/*        control={<Radio />}*/}
                  {/*        label={MEMBER_STATUS[1][getDefName()] || ''}*/}
                  {/*      />*/}
                  {/*      <FormControlLabel*/}
                  {/*        value={MEMBER_STATUS[2].id}*/}
                  {/*        checked={user.status === MEMBER_STATUS[2].id}*/}
                  {/*        control={<Radio />}*/}
                  {/*        label={MEMBER_STATUS[2][getDefName()] || ''}*/}
                  {/*      />*/}
                  {/*    </RadioGroup>*/}
                  {/*  </FormControl>*/}
                  {/*</Grid>*/}
                </Grid>
              </>
            )}
          </CardContent>
          <Divider />
          {type === 'edit' && query.id && (
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <MemberRelationList userId={user.id} history={history} />
                </Grid>
              </Grid>
            </CardContent>
          )}
        </Card>
        {/*<Card>*/}
        {/*  <CardActions style={{ marginLeft: '16px', marginRight: '16px' }}>*/}
        {/*    */}
        {/*  </CardActions>*/}
        {/*</Card>*/}
        <Grid container className={classes.my3} justifyContent={'space-between'} xs={12} md={12} spacing={3}>
          <Grid item>
            <Button
              variant="contained"
              style={{ backgroundColor: green[700], color: '#ffffff', marginRight: '10px' }}
              size="medium"
              onClick={goList}
              startIcon={<ListIcon />}
            >
              List
            </Button>
            {type !== 'new' ? (
              <Button variant="contained" color="secondary" size="medium" onClick={deleteUser} startIcon={<DeleteIcon />}>
                Delete
              </Button>
            ) : null}
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" size="medium" onClick={addUser} startIcon={<SaveIcon />}>
              {type === 'new' ? 'Registration' : 'Edit'}
            </Button>
          </Grid>
        </Grid>
      </div>
    </OftadehLayout>
  );
};

export default MemberDetailPage;
