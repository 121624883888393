import BookingListPage from './BookingListPage';

export const BookingListPageConfig = {
  routes: [
    {
      path: '/pages/booking/hospital',
      exact: true,
      component: BookingListPage,
    },
    {
      path: '/pages/booking/product',
      exact: true,
      component: BookingListPage,
    },
    {
      path: '/pages/booking/pickup',
      exact: true,
      component: BookingListPage,
    },
  ],
};
