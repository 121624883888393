import RecDoctorEditPage from './RecDoctorEditPage';

export const RecDoctorEditPageConfig = {
  routes: [
    {
      path: '/pages/recDoctor/edit',
      exact: true,
      component: RecDoctorEditPage,
    },
  ],
};
