import React, { useEffect, useState } from 'react';
import OftadehLayout from '../../components/OftadehLayout/OftadehLayout';
import { Badge, Button, CircularProgress, Grid, Typography } from '@material-ui/core';
import { gapi } from 'gapi-script';
import BrowsersReport from './browsersReport';
import { getConsultList, getInquireList, getQuestionList, getVideoConsultList } from '../../constants/PromiseHttp';
import { right } from 'core-js/internals/array-reduce';
import { Notifications } from '@material-ui/icons';
// import { useTranslation } from 'react-i18next';

// export const adminName = 'koreamedis';
export const trackingCode = 'UA-134244190-1';
export const viewId = '189676060';
export const clientId = '397566369903-ngtlijo5ktr0ufbrc99gv31sne591n1j.apps.googleusercontent.com'; //'397566369903-4lpn9vherrht8t7ddke2mlchr1vchumd.apps.googleusercontent.com';
export const apiKey = 'AIzaSyAauzLN8AB4FaD1Kvs8Ksc86Abt8BmNc-M'; //'AIzaSyAf4xBwB8kRcIBxMY2H1drgXYPJa0d9uVM';
export const SCOPES = ['https://www.googleapis.com/auth/analytics.readonly'];

// // export const adminName = 'atozsoft';
// export const trackingCode = 'UA-209354693-1';
// export const viewId = '166224305';
// export const clientId = '966573148764-qrsqt00mforetr5kr989e46bsckad98t.apps.googleusercontent.com';
// export const apiKey = 'AIzaSyB0lCnMLVtxo1PzjlypCJjGP-YX5Qa80-s';
// export const SCOPES = ['https://www.googleapis.com/auth/analytics.readonly'];

const DashboardPage = (props) => {
  // const { t, i18n } = useTranslation();
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [loading, setLoading] = useState(false);
  const { history } = props;

  useEffect(() => {
    checkNewMessage().then(() => {
      initAuth();
    });
    // initClient();
  }, []);

  function initAuth() {
    setLoading(true);
    gapi.load('auth2', function () {
      let gOAuth = gapi.auth2.init({
        client_id: clientId,
        scope: 'profile',
      });

      gOAuth.then(
        function () {
          setLoading(false);
          console.log('init success :', gOAuth.currentUser.get().getBasicProfile().getEmail());
          setIsSignedIn(gOAuth.isSignedIn.get());
        },
        function (err) {
          setLoading(false);
          console.error('init fail:', err);
        },
      );
    });
  }

  function authorize(event) {
    // Handles the authorization flow.
    // `immediate` should be false when invoked from the button click.
    const useImmdiate = event ? false : true;
    const authData = {
      client_id: clientId,
      scope: SCOPES,
      immediate: useImmdiate,
    };

    gapi.auth.authorize(authData, function (response) {
      //var authButton = document.getElementById('auth-button');
      if (response.error) {
        setIsSignedIn(false);
      } else {
        setIsSignedIn(true);
      }
    });
  }

  let newConsult = 0;
  let newVideoConsult = 0;
  let newInquire = 0;

  const checkNewMessage = () => {
    return Promise.all([
      getConsultList().then((r) => {
        r.map((obj) => {
          if (!obj.adminViewCnt || obj.adminViewCnt < 1) {
            newConsult += 1;
          }
        });
        localStorage.setItem('new_consult', newConsult);
      }),
      getVideoConsultList().then((r) => {
        r.map((obj, idx) => {
          if (!obj.adminViewCnt || obj.adminViewCnt < 1) {
            newVideoConsult += 1;
          }
        });
        localStorage.setItem('new_videoConsult', newVideoConsult);
      }),
      getInquireList().then((r) => {
        r.map((obj) => {
          if (!obj.adminViewCnt || obj.adminViewCnt < 1) {
            newInquire += 1;
          }
        });
        localStorage.setItem('new_inquire', newInquire);
      }),
      getQuestionList().then((r) => {
        r.map((obj) => {
          if (!obj.adminViewCnt || obj.adminViewCnt < 1) {
            newInquire += 1;
          }
        });
        localStorage.setItem('new_question', newInquire);
      }),
    ]);
  };

  const goConsult = () => {
    history.push('/pages/consult');
  };
  const goVideoConsult = () => {
    history.push('/pages/consult/video');
  };
  const goInquire = () => {
    history.push('/pages/inquire');
  };
  const goQuestion = () => {
    history.push('/pages/question');
  };

  return (
    <OftadehLayout>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <h1>Dashboard</h1>
        </Grid>
        <Grid item>
          {localStorage.getItem('new_consult') && parseInt(localStorage.getItem('new_consult')) > 0 && (
            <Badge badgeContent={localStorage.getItem('new_consult')} color="secondary">
              <Button variant={'outlined'} color={'primary'} size={'small'} onClick={goConsult} startIcon={<Notifications />}>
                CONSULT
              </Button>
            </Badge>
          )}
        </Grid>
        <Grid item>
          {localStorage.getItem('new_videoConsult') && parseInt(localStorage.getItem('new_videoConsult')) > 0 && (
            <Badge badgeContent={localStorage.getItem('new_videoConsult')} color="secondary">
              <Button variant={'outlined'} color={'primary'} size={'small'} onClick={goVideoConsult} startIcon={<Notifications />}>
                Video CONSULT
              </Button>
            </Badge>
          )}
        </Grid>
        <Grid item>
          {localStorage.getItem('new_inquire') && parseInt(localStorage.getItem('new_inquire')) > 0 && (
            <Badge badgeContent={localStorage.getItem('new_inquire')} color="secondary">
              <Button variant={'outlined'} color={'primary'} size={'small'} onClick={goInquire} startIcon={<Notifications />}>
                inquire
              </Button>
            </Badge>
          )}
        </Grid>
        <Grid item>
          {localStorage.getItem('new_question') && parseInt(localStorage.getItem('new_question')) > 0 && (
            <Badge badgeContent={localStorage.getItem('new_question')} color="secondary">
              <Button variant={'outlined'} color={'primary'} size={'small'} onClick={goQuestion} startIcon={<Notifications />}>
                hospital message
              </Button>
            </Badge>
          )}
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              window.open('https://analytics.google.com/');
            }}
          >
            Google Analytics
          </Button>
        </Grid>
        {!isSignedIn && (
          <Grid item>
            <Button variant={'outlined'} color={'primary'} onClick={authorize}>
              google authorize
            </Button>
          </Grid>
        )}
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {isSignedIn && <BrowsersReport viewID={viewId} />}
        </Grid>
      </Grid>
      {loading && (
        <div style={{ position: 'fixed', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', zIndex: 999 }}>
          <CircularProgress color={'secondary'} />
        </div>
      )}
    </OftadehLayout>
  );
};

export default DashboardPage;
