import SettingBannerDetailPage from './SettingBannerDetailPage';

export const SettingBannerDetailPageConfig = {
  routes: [
    {
      path: '/pages/setting/banner/detail',
      exact: true,
      component: SettingBannerDetailPage,
    },
  ],
};
