import React, { useEffect, useState } from 'react';
import './App.css';
import { ThemeProvider } from '@material-ui/core/styles';
import getTheme from './config/themeConfig';
import ThemeContext from './context/ThemeContext';
import OftadehRoutes from './config/OftadehRoutes';

import Amplify, { Auth, Hub } from 'aws-amplify';
import aws_exports from './aws-exports';
import { withRouter } from 'react-router-dom';

Amplify.configure(aws_exports);

const App = (props) => {
  const curThemeName = localStorage.getItem('appTheme') || 'light';

  const [themeType, setThemeType] = useState(curThemeName);
  const [user, setUser] = useState(null);

  useEffect(() => {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
        case 'cognitoHostedUI':
          getUser().then((userData) => setUser(userData));
          break;
        case 'signOut':
          setUser(null);
          break;
        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
          console.log('Sign in failure', data);
          break;
      }
    });

    getUser().then((userData) => setUser(userData));
  }, []);

  function getUser() {
    return Auth.currentAuthenticatedUser()
      .then((userData) => userData)
      .catch(() => {
        console.log('Not signed in', props);
        if (
          props.location.pathname !== '/pages/auth/login' &&
          props.location.pathname !== '/pages/auth/register' &&
          props.location.pathname !== '/pages/auth/forgot-password'
        ) {
          window.location.href = '/pages/auth/login';
        }
      });
  }

  const setThemeName = (themeName) => {
    localStorage.setItem('appTheme', themeName);
    setThemeType(themeName);
  };

  const theme = getTheme({
    paletteType: themeType,
  });
  return (
    <ThemeContext.Provider value={{ setThemeName, curThemeName }}>
      <ThemeProvider theme={theme}>
        <div className="App">
          <OftadehRoutes />
        </div>
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};

export default withRouter(App);
