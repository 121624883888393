import ProductDetailPage from './ProductDetailPage';

export const ProductDetailPageConfig = {
  routes: [
    {
      path: '/pages/product/detail',
      exact: true,
      component: ProductDetailPage,
    },
  ],
};
