import SettingCodesPage from './SettingCodesPage';

export const SettingCodesPageConfig = {
  routes: [
    {
      path: '/pages/setting/codes',
      exact: true,
      component: SettingCodesPage,
    },
  ],
};
