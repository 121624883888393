import InquireListPage from './InquireListPage';

export const InquireListPageConfig = {
  routes: [
    {
      path: '/pages/inquire',
      exact: true,
      component: InquireListPage,
    },
  ],
};
