import SettingDefaultInfoPage from './SettingDefaultInfoPage';

export const SettingDefaultInfoPageConfig = {
  routes: [
    {
      path: '/pages/setting/defaultInfo',
      exact: true,
      component: SettingDefaultInfoPage,
    },
  ],
};
