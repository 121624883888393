import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import OftadehLayout from '../../../components/OftadehLayout/OftadehLayout';
import { Button, Dialog, Divider, Grid, makeStyles, Typography } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import swal from '@sweetalert/with-react';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import SaveIcon from '@material-ui/icons/Save';
import ListIcon from '@material-ui/icons/List';
import DeleteIcon from '@material-ui/icons/Delete';
import { getAnswerNestedList, getConsultCaseInfo, getHospitalList, getSubDiseaseAll } from '../../../constants/PromiseHttp';
import axiosInstance from '../../../constants/AxiosInstance';
import { API_URL, getDefName } from '../../../constants';
import AnswerComponent, { AnswerCard } from '../../customComp/AnswerComponent';
import ImgListView from '../../customComp/ImgListView';
import { Add } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    color: '#3f51b5',
  },
  my3: {
    margin: '1.3rem 0',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  mRight: {
    marginRight: '.85rem',
  },
  mInput: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
  textArea: {
    width: '98%',
    minHeight: 300,
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    borderColor: 'lightgray',
  },
}));

const ConsultCaseDetailPage = (props) => {
  const query = queryString.parse(props.location.search);

  const [consultCase, setConsultCase] = useState();
  const [hospitalList, setHospitalList] = useState([]);
  const [subDiseases, setSubDiseases] = useState();
  const [answerDlgOpen, setAnswerDlgOpen] = useState(false);
  const [editAnswerId, setEditAnswerId] = useState('');
  const [parentAnswerId, setParentAnswerId] = useState();
  const [answers, setAnswers] = useState();

  const { history } = props;
  const classes = useStyles();

  useEffect(() => {
    getData();
    getSubDiseaseAll().then((result) => {
      setSubDiseases(result);
    });
    getHospitalList().then((result) => {
      setHospitalList(result);
    });
  }, []);

  const getData = () => {
    if (query.id) {
      getConsultCaseInfo(query.id).then((result) => {
        setConsultCase(result);
        setParentAnswerId('');
        setEditAnswerId('');
        getAnswerNestedList(result.id).then((res) => {
          console.log('nested list:', res);
          setAnswers(res);
        });
      });
    }
  };

  const deleteConsultCase = () => {
    swal({
      title: 'Are you sure you want to delete?',
      text: '',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        axiosInstance.delete(`${API_URL}/consultCase/${query.id}`, { headers: { Pragma: 'no-cache' } }).then((response) => {
          if (response.status === 200) {
            goList();
            swal('It is deleted.', {
              icon: 'success',
            });
          }
        });
      } else {
      }
    });
  };

  const deleteAnswer = (answerId) => {
    axiosInstance.delete(`${API_URL}/answer/${answerId}`, { headers: { Pragma: 'no-cache' } }).then((response) => {
      if (response.status === 200) {
        swal('Answer has been deleted.', {
          icon: 'success',
        });
        handleAnswerDlgClose(true);
      }
    });
  };

  const openAddAnswer = (pid) => {
    console.log('DetailPage::openAddAnswer :', pid);
    setParentAnswerId(pid);
    setAnswerDlgOpen(true);
  };

  const openEditAnswer = (aid) => {
    console.log('DetailPage::openEditAnswer :', aid);
    setEditAnswerId(aid);
    setAnswerDlgOpen(true);
  };

  const handleAnswerDlgOpen = () => {
    setParentAnswerId('');
    setEditAnswerId('');
    setAnswerDlgOpen(true);
  };

  const handleAnswerDlgClose = (reload) => {
    if (reload) {
      getData();
    }
    setParentAnswerId('');
    setEditAnswerId('');
    setAnswerDlgOpen(false);
  };

  const goList = () => {
    history.push('/pages/consult/case/');
  };

  const goEdit = () => {
    history.push(`/pages/consult/case/edit?type=edit&id=${query.id}`);
  };

  const clone = () => {
    history.push(`/pages/consult/case/edit?type=clone&id=${query.id}`);
  };

  const getDiseaseNames = (ids) => {
    let names = '';
    if (!ids || !subDiseases) {
      return names;
    }

    for (let i = 0; i < ids.length; i++) {
      let sdi = subDiseases.find((_sdi) => _sdi.id === ids[i]);
      if (i > 0) names += ', ';
      names += sdi[getDefName()];
    }
    return names;
  };

  const getHospitalName = (hid) => {
    const hospital = hospitalList && hospitalList.find((hos) => hos.id === hid);
    return hospital ? hospital['name_ko'] : '-';
  };

  return (
    <OftadehLayout>
      <Grid container className={classes.my3} alignItems="center">
        <Grid item className={classes.mRight} style={{ fontWeight: 'bold' }}>
          <Typography variant="h5" component="h1" style={{ fontWeight: 'bold' }}>
            Consultation Case Detail
          </Typography>
        </Grid>
        <Grid item>
          <Button variant="outlined" color="primary" size="medium" onClick={clone} startIcon={<Add />}>
            clone
          </Button>
        </Grid>
      </Grid>
      <div>
        <Card className={classes.root}>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} mb={12}>
                <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                  Basic Info
                </Typography>
              </Grid>
              <Grid item xs={4} mb={6}>
                <div className={classes.title}>Language</div>
                <Typography variant="body1">{(consultCase && consultCase.languageId) || ''}</Typography>
              </Grid>
              <Grid item xs={4} mb={6}>
                <div className={classes.title}>Country</div>
                <Typography variant="body1">{(consultCase && consultCase.countryId) || ''}</Typography>
              </Grid>
              <Grid item xs={4} mb={6}>
                <div className={classes.title}>Show/Hide</div>
                <Typography variant="body1">{consultCase && consultCase.hidden ? 'Hide' : 'Show'}</Typography>
              </Grid>
            </Grid>
            <Divider style={{ marginTop: '10px' }} />
            <Grid container spacing={3} style={{ marginTop: '10px' }}>
              <Grid item xs={12} mb={12}>
                <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                  Patient Info
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <div className={classes.title}>Name</div>
                <Typography variant="body1">{(consultCase && consultCase.userName) || ''}</Typography>
              </Grid>
              <Grid item xs={4}>
                <div className={classes.title}>Email</div>
                <Typography variant="body1">{(consultCase && consultCase.email) || ''}</Typography>
              </Grid>
              <Grid item xs={4}>
                <form noValidate>
                  <div className={classes.title}>Birth date</div>
                  <Typography variant="body1">{(consultCase && consultCase.birth) || ''}</Typography>
                </form>
              </Grid>
              <Grid item xs={4}>
                <div className={classes.title}>Phone number</div>
                <Typography variant="body1">{(consultCase && consultCase.phoneNumber) || ''}</Typography>
              </Grid>
              <Grid item xs={4}>
                <div className={classes.title}>Gender</div>
                <Typography variant="body1">{(consultCase && consultCase.gender) || ''}</Typography>
              </Grid>
              <Grid item xs={4} mb={6}>
                <div className={classes.title}>Preferred institutionl</div>
                <Typography variant="body1">{(consultCase && getHospitalName(consultCase.hospitalId)) || ''}</Typography>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.title}>Tags</div>
                <Typography variant="body1">{(consultCase && consultCase.tags) || ''}</Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Card className={classes.my3}>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} mb={12}>
                <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                  Question
                </Typography>
              </Grid>
              <Divider variant={'fullWidth'} />

              <Grid item xs={12}>
                <div className={classes.title}>Title</div>
                <Typography variant="body1">{(consultCase && consultCase.title) || ''}</Typography>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.title}>Contents</div>
                <Typography variant="body1">{(consultCase && consultCase.contents) || ''}</Typography>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.title}>Diagnosis</div>
                <Typography variant="body1">{(consultCase && consultCase.diagnosis) || ''}</Typography>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.title}>Medical History</div>
                <Typography variant="body1">{(consultCase && consultCase.medicalHistory) || ''}</Typography>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.title}>Symptoms</div>
                <Typography variant="body1">{(consultCase && consultCase.symtoms) || ''}</Typography>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.title}>Taking medication</div>
                <Typography variant="body1">{(consultCase && consultCase.medicine) || ''}</Typography>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.title}>Disease</div>
                {consultCase && consultCase.subDiseaseIds && (
                  <Typography variant="body1">{getDiseaseNames(consultCase.subDiseaseIds)}</Typography>
                )}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Card>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} mb={12}>
                <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                  Attachments
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <div className={classes.title}>Diagnosis, Opinion, Test result sheet, etc. </div>
                {consultCase && <ImgListView images={consultCase && consultCase.statement} />}
              </Grid>
              <Grid item xs={4}>
                <div className={classes.title}>Photo Material (JPG, PNG, PDF, GIF) </div>
                {consultCase && <ImgListView images={consultCase && consultCase.photo} />}
              </Grid>
              <Grid item xs={4}>
                <div className={classes.title}>DICOM File</div>
                {consultCase && <ImgListView images={consultCase && consultCase.dicom} />}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Grid container spacing={2} className={classes.my3} alignItems="center">
          <Grid item>
            <Typography variant="h5" component="h1" style={{ fontWeight: 'bold' }}>
              Answer
            </Typography>
          </Grid>
          <Grid item>
            <Button variant="outlined" color="primary" onClick={handleAnswerDlgOpen}>
              Register answer
            </Button>
            <Dialog fullWidth open={answerDlgOpen} onClose={handleAnswerDlgClose} aria-labelledby="customized-dialog-title">
              <AnswerComponent
                targetId={query.id}
                targetInfo={
                  consultCase && {
                    email: consultCase.email ? consultCase.email : consultCase.userId,
                    languageId: consultCase.languageId,
                    status: consultCase.status,
                  }
                }
                parentId={parentAnswerId}
                answerId={editAnswerId}
                handleClose={handleAnswerDlgClose}
              />
            </Dialog>
          </Grid>
        </Grid>
        <Card>
          <CardContent>
            {consultCase &&
              answers &&
              answers.map((answer, idx) => (
                <AnswerCard
                  key={`answercard-${answer.id}`}
                  answer={answer}
                  idx={idx}
                  depth={0}
                  handleChild={openAddAnswer}
                  handleEdit={openEditAnswer}
                  handleDelete={deleteAnswer}
                />
              ))}
          </CardContent>
        </Card>
      </div>
      <div className={classes.my3}>
        <Grid container xs={12} md={12} spacing={3}>
          <Grid item>
            <Button
              variant="contained"
              style={{ backgroundColor: green[700], color: '#ffffff', marginRight: '10px' }}
              size="medium"
              onClick={goList}
              startIcon={<ListIcon />}
            >
              List
            </Button>
            <Button variant="contained" color="secondary" size="medium" onClick={deleteConsultCase} startIcon={<DeleteIcon />}>
              Delete
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" size="medium" onClick={goEdit} startIcon={<SaveIcon />}>
              Edit
            </Button>
          </Grid>
        </Grid>
      </div>
    </OftadehLayout>
  );
};

export default ConsultCaseDetailPage;
