import React, { useEffect, useState } from 'react';
import OftadehLayout from '../../components/OftadehLayout/OftadehLayout';
import { Button, CircularProgress, Dialog, Grid, makeStyles, Typography } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import axiosInstance from '../../constants/AxiosInstance';
import swal from '@sweetalert/with-react';
import { API_URL, getTypeName, MEMBER_STATUS, MEMBER_TYPE } from '../../constants';
import RegisterPage from '../auth/register/RegisterPage';
import { getHospitalList, getUserList } from '../../constants/PromiseHttp';
import { Auth } from 'aws-amplify';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  my3: {
    margin: '1.3rem 0',
  },
  mb0: {
    marginBottom: 0,
  },
  mRight: {
    marginRight: '.85rem',
  },
  p1: {
    padding: '.85rem',
  },
}));

const ManagerListPage = (props) => {
  const { history } = props;
  const classes = useStyles();
  const [data, setData] = useState();
  const [hospitals, setHospitals] = useState([]);
  const [loading, setLoading] = useState(false);
  const [registerOpen, setRegisterOpen] = useState(false);

  //const rowClick = (data) => {
  const cellClick = (param, value) => {
    if (value.colIndex !== 6) {
      history.push(`/pages/manager/detail?type=edit&id=${data[value.rowIndex].id}`);
    }
  };

  const rowDelete = (row) => {
    let ids = [];
    row.data.map((r) => {
      ids.push(data[r.dataIndex].id);
      return r;
    });
    let delParam = {
      ids: ids.toString(),
    };
    swal({
      title: 'Are you sure you want to delete?',
      text: '',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        axiosInstance.post(`${API_URL}/user/manager/deleteIds`, delParam, { headers: { Pragma: 'no-cache' } }).then((response) => {
          swal('It is deleted.', {
            icon: 'success',
          });
          getData();
        });
      } else {
        getData();
      }
    });
  };

  const options = {
    print: false,
    filter: false,
    filterType: 'checkbox',
    selectableRows: 'multiple',
    viewColumns: false,
    download: false,
    //onRowClick: rowClick,
    onCellClick: cellClick,
    onRowsDelete: rowDelete,
  };

  useEffect(() => {
    getData();
    getHospitalList(true).then((result) => {
      setHospitals(result);
    });
  }, []);

  const getData = () => {
    setLoading(true);
    getUserList('manager').then((result) => {
      setLoading(false);
      setData(result);
    });
  };

  const cols = [
    {
      name: 'id',
      label: 'id',
      options: {
        filter: false,
        sort: true,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: 'id',
      label: 'No.',
      options: {
        filter: false,
        sort: true,
        display: true,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return tableMeta.rowIndex + 1;
        },
      },
    },
    {
      name: 'typeId',
      label: 'Type',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return getTypeName(MEMBER_TYPE, value);
        },
      },
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'id',
      label: 'Email',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'hospitalIds',
      label: 'Managed Hospitals',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          let names = [];
          value &&
            value.map((hid) => {
              const _hos = hospitals.find((hos) => hos.id === hid);
              _hos && names.push(_hos['name_ko']);
            });
          return names.toString();
        },
      },
    },
    {
      name: 'createdAt',
      label: 'Registration Date',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return moment(Number(value)).format('YYYY-MM-DD HH:mm');
        },
      },
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          console.log(tableMeta);
          const txt = getTypeName(MEMBER_STATUS, value ? value : 'waiting');
          return (
            <Grid container spacing={1}>
              <Grid item>{txt}</Grid>
              {value !== 'approval' ? (
                <Grid item>
                  <Button
                    variant={'outlined'}
                    size={'small'}
                    color={'primary'}
                    onClick={(e) => approvalManager(e, data[tableMeta.rowIndex])}
                  >
                    {getTypeName(MEMBER_STATUS, 'approval')}
                  </Button>
                </Grid>
              ) : (
                <Grid item>
                  <Button
                    variant={'outlined'}
                    size={'small'}
                    color={'primary'}
                    onClick={(e) => handleChangePwd(e, data[tableMeta.rowIndex])}
                  >
                    CHANGE PWD
                  </Button>
                </Grid>
              )}
              {/*<Grid item>*/}
              {/*  <Button variant={'outlined'} size={'small'} color={'secondary'}>*/}
              {/*    {getTypeName(MEMBER_STATUS, 'badmember')}*/}
              {/*  </Button>*/}
              {/*</Grid>*/}
            </Grid>
          );
        },
      },
    },
  ];

  const handleChangePwd = (e, val) => {
    e.stopPropagation();
    history.push(`/pages/auth/change-password?userId=${val.id}`);
  };

  const approvalManager = (e, val) => {
    e.stopPropagation();
    try {
      Auth.signUp({
        username: val.id,
        password: val.password,
        attributes: {
          email: val.email,
          name: val.name,
          'custom:role': 'SYS_MGR',
        },
      }).then((user) => {
        console.log(user);
        const param = {
          id: val.id,
          status: 'approval',
        };
        axiosInstance.put(`${API_URL}/user/`, param, { headers: { Pragma: 'no-cache' } }).then(async (response) => {
          if (response.status === 200) {
            swal('Registered.', {
              icon: 'success',
            });
            getData();
          }
        });
      });
    } catch (e) {
      alert(e);
    }
  };

  const handleRegisterOpen = () => {
    setRegisterOpen(true);
  };
  const handleRegisterClose = () => {
    setRegisterOpen(false);
  };
  const callbackRegisterUser = (u) => {
    setRegisterOpen(false);
    getData();
  };

  return (
    <OftadehLayout>
      <Grid container className={classes.my3} alignItems="center">
        <Grid item className={classes.mRight} style={{ fontWeight: 'bold' }}>
          <Typography variant="h5" component="h1" style={{ fontWeight: 'bold' }}>
            Hospital/Partner Manager List
          </Typography>
        </Grid>
        <Grid item>
          <Button
            onClick={() => history.push('/pages/manager/detail?type=new')}
            //onClick={handleRegisterOpen}
            variant="outlined"
            color="primary"
            size="small"
          >
            Registration
          </Button>
          <Dialog open={registerOpen} onClose={handleRegisterClose} aria-labelledby="customized-dialog-title">
            <RegisterPage callbackFunc={callbackRegisterUser} handleClose={handleRegisterClose} />
          </Dialog>
        </Grid>
      </Grid>
      {/*<OftadehBreadcrumbs path={history} />*/}
      <MUIDataTable title={''} data={data} columns={cols} options={options} />
      {loading && (
        <div style={{ position: 'fixed', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', zIndex: 999 }}>
          <CircularProgress color={'secondary'} />
        </div>
      )}
    </OftadehLayout>
  );
};

export default ManagerListPage;
