import HospitalDetailPage from './HospitalDetailPage';

export const HospitalDetailPageConfig = {
  routes: [
    {
      path: '/pages/hospital/detail',
      exact: true,
      component: HospitalDetailPage,
    },
  ],
};
