import ReviewEditPage from './ReviewEditPage';

export const ReviewEditPageConfig = {
  routes: [
    {
      path: '/pages/review/edit',
      exact: true,
      component: ReviewEditPage,
    },
  ],
};
