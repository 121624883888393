import { Button, Divider, FormControlLabel, Grid, makeStyles, Radio, RadioGroup, TextField, Typography } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import DropZone from '../../../components/FileUpload/DropZone';
import swal from '@sweetalert/with-react';
import { AddCircle, Refresh } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  my3: {
    margin: '1.3rem 0',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  divider: {
    marginTop: '10px',
    marginBottom: '10px',
  },
  mRight: {
    marginRight: '.85rem',
  },
  mInput: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
    display: 'flex',
    flexWrap: 'wrap',
  },
}));

const AddBannerItemView = forwardRef((props, ref) => {
  const classes = useStyles();
  const [item, setItem] = useState({});
  const [isEditMode, setIsEditMode] = useState(false);

  useImperativeHandle(ref, () => ({
    async editItem(item) {
      await Promise.all([setStoredItem(item)]);
    },
    async reset() {
      await Promise.all([reset()]);
    },
  }));

  const setStoredItem = (item) => {
    //console.log('setStoredItem', item);
    setIsEditMode(true);
    setItem(item);
  };

  const reset = () => {
    //console.log('setStoredItem');
    setIsEditMode(false);
    setItem({});
  };

  const addItem = () => {
    //console.log('addItem in AddBannerItemView >>', item);
    if (!item.file || !item.file.id) {
      swal('Missing required image.');
      return;
    }

    props.addItem(item, isEditMode);
    reset();
  };

  const removeStorageFile = async (file) => {
    if (file && file.key) {
      props.removeStorageFile(file);
    }
  };

  const handleAddImage = (file) => {
    item.file = file;
    setItem({ ...item });
  };

  const handleItemChangeData = (value, field) => {
    item[field] = value;
    // console.log(field, value);
    // console.log(item);
    setItem({ ...item });
  };

  return (
    <>
      <Grid container justifyContent={'space-between'} spacing={3}>
        <Grid item>
          <Typography variant="h6">Banner Info</Typography>
        </Grid>
        <Grid item className={classes.mRight}>
          <Grid container spacing={1}>
            <Grid item>
              <Button variant="contained" color="primary" size="medium" onClick={reset} startIcon={<Refresh />}>
                Refresh
              </Button>
            </Grid>
            <Grid item>
              <Button
                style={{ marginRight: 10 }}
                variant="contained"
                color="primary"
                size="medium"
                onClick={addItem}
                startIcon={isEditMode ? '' : <AddCircle />}
              >
                {isEditMode ? 'Edit' : 'Add'}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
      <Grid container spacing={1} className={classes.container}>
        <Grid item xs={6}>
          <Typography style={{ marginTop: 10, marginBottom: 10 }}>
            Banner Image (Size 1871*565) <span style={{ color: 'red' }}>*</span>{' '}
          </Typography>
          <DropZone file={item.file} handleAddImage={handleAddImage} removeStorageFile={removeStorageFile} />
        </Grid>

        <Grid item xs={6}>
          <Grid item xs={12}>
            <Typography style={{ marginTop: 10 }}>Text Alignment</Typography>
            <FormControl component="alignment-form" className={classes.mInput}>
              <RadioGroup
                row
                aria-label="alignment-radio-group"
                name="alignment-radio-group"
                onChange={(event) => {
                  handleItemChangeData(event.target.value, 'textAlignment');
                }}
              >
                <FormControlLabel
                  value="left"
                  checked={!item || item.textAlignment === undefined || item.textAlignment === 'left'}
                  control={<Radio />}
                  label="Left"
                />
                <FormControlLabel value="center" checked={item && item.textAlignment === 'center'} control={<Radio />} label="Center" />
                <FormControlLabel value="right" checked={item && item.textAlignment === 'right'} control={<Radio />} label="Right" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Typography style={{ marginTop: 10 }}>Main Text </Typography>
            <TextField
              size={'small'}
              key={`TextField-text-main`}
              className={classes.mInput}
              placeholder=""
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              value={item.mainText || ''}
              onChange={(event) => {
                //console.log(event.target.value);
                handleItemChangeData(event.target.value, 'mainText');
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography style={{ marginTop: 10 }}>Top Text </Typography>
            <TextField
              size={'small'}
              key={`TextField-text-top`}
              className={classes.mInput}
              placeholder=""
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              value={item.topText || ''}
              onChange={(event) => {
                handleItemChangeData(event.target.value, 'topText');
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography style={{ marginTop: 10 }}>Bottom Text </Typography>
            <TextField
              size={'small'}
              key={`TextField-text-bottom`}
              className={classes.mInput}
              placeholder=""
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              value={item.bottomText || ''}
              onChange={(event) => {
                handleItemChangeData(event.target.value, 'bottomText');
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography style={{ marginTop: 10 }}>Button Text </Typography>
            <TextField
              size={'small'}
              key={`TextField-text-button`}
              className={classes.mInput}
              placeholder=""
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              value={item.buttonText || ''}
              onChange={(event) => {
                handleItemChangeData(event.target.value, 'buttonText');
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography style={{ marginTop: 10 }}>Button Link </Typography>
            <TextField
              size={'small'}
              key={`TextField-link-button`}
              className={classes.mInput}
              placeholder="http://"
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              value={item.buttonLink || ''}
              onChange={(event) => {
                handleItemChangeData(event.target.value, 'buttonLink');
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography style={{ marginTop: 10 }}>Button Target </Typography>
            <FormControl component="alignment-form" className={classes.mInput}>
              <RadioGroup
                row
                aria-label="target-radio-group"
                name="target-radio-group"
                onChange={(event) => {
                  handleItemChangeData(event.target.value, 'buttonTarget');
                }}
              >
                <FormControlLabel
                  value="popup"
                  checked={!item || item.buttonTarget === undefined || item.buttonTarget === 'popup'}
                  control={<Radio />}
                  label="Pop-up"
                />
                <FormControlLabel value="page" checked={item && item.buttonTarget === 'page'} control={<Radio />} label="Move Page" />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>

        <Divider />
      </Grid>
    </>
  );
});

export default AddBannerItemView;
