import HospitalListPage from './HospitalListPage';

export const HospitalListPageConfig = {
  routes: [
    {
      path: '/pages/hospital',
      exact: true,
      component: HospitalListPage,
    },
  ],
};
