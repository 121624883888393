import React, { useEffect, useRef, useState } from 'react';
import OftadehLayout from '../../../components/OftadehLayout/OftadehLayout';
import clsx from 'clsx';
import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Paper,
  TableCell,
  TextField,
  Typography,
} from '@material-ui/core';
import { API_URL, getDefName } from '../../../constants';
import axiosInstance from '../../../constants/AxiosInstance';
import swal from '@sweetalert/with-react';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import { getDiseaseInfo, getDiseaseList, getLanguageList, getSubDiseaseList } from '../../../constants/PromiseHttp';
import { fileUploadOnly } from '../../../components/FileUpload/FileUpload';
import queryString from 'query-string';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 'auto',
  },
  my3: {
    margin: '1.3rem 0',
  },
  mb0: {
    marginBottom: 0,
  },
  mLeft: {
    marginLeft: '.85rem',
  },
  mRight: {
    marginRight: '.85rem',
  },
  mBottom: {
    marginBottom: '.5rem',
  },
  p1: {
    padding: '.85rem',
  },
  fixedHeight: {
    height: 350,
  },
  minHeight: {
    minHeight: 240,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  table: {
    width: '100%',
  },
}));

function RightMultipleTf(props) {
  return (
    <Grid container spacing={2} className={props.classes.p1}>
      {props.selectedDisease &&
        props.languages &&
        props.languages.map((lang, idx) => {
          let key = `name_${lang.id}`;
          return (
            <Grid item xs={4} md={4} key={'value' + key}>
              <Typography style={{ fontSize: 12 }} id={'title-' + key}>
                {lang[getDefName()]}
              </Typography>
              <TextField
                type="text"
                id={'name-' + key}
                name={'name_' + key}
                key={'name_' + key}
                placeholder={lang[key]}
                inputProps={{ readOnly: lang.id === 'ko' ? true : false }}
                defaultValue={props.selectedDisease[key]}
                variant="outlined"
                size="small"
                onChange={(e) => props.handleChangeTfNames(e.target.value, lang.id)}
              />
            </Grid>
          );
        })}
    </Grid>
  );
}

const SettingDiseaseListPage = (props) => {
  const query = queryString.parse(props.location.search);
  const sdId = query.sd ? query.sd : '';

  const { history } = props;
  const classes = useStyles();
  const minHeightPaper = clsx(classes.paper, classes.minHeight);

  const [addDiseaseOpen, setAddDiseaseOpen] = useState(false);
  const [diseaseList, setDiseaseList] = useState([]);
  const [selectedDisease, setSelectedDisease] = useState(undefined);
  const [subDisease, setSubDisease] = useState(undefined);
  const [languages, setLanguages] = useState([]);

  const [pcImage, setPcImage] = useState([]);
  const pcKey = 'pcImage';
  const usedMobileImage = false; //Not used at front
  const [mobileImage, setMobileImage] = useState([]); //Not used at front
  const mobileKey = 'mobileImage'; //Not used at front

  useEffect(() => {
    getData();
    getLanguageList().then((result) => {
      setLanguages(result);
    });
  }, []);

  useEffect(() => {
    if (!subDisease && selectedDisease) {
      getSubDiseaseList(selectedDisease.id).then((result) => {
        setSubDisease(result);
      });
    }
  }, [diseaseList, selectedDisease]);

  const getData = () => {
    getDiseaseList().then((result) => {
      setDiseaseList(result);
      if (result && result.length > 0) {
        handleListItemClick(sdId.length > 0 ? sdId : result[0].id);
      }
    });
  };

  const handleListItemClick = (id) => {
    getDiseaseInfo(id).then((result) => {
      setSelectedDisease(result);
      setPcImage([]);
      usedMobileImage && setMobileImage([]);
    });
    getSubDiseaseList(id).then((result) => {
      setSubDisease(result);
    });
  };
  const handleSubListItemClick = (di) => {
    history.push(`/pages/setting/disease/detail?type=edit&id=${selectedDisease.id}&subId=${di.id}`);
  };
  const handleChangeTfNames = (value, lang) => {
    selectedDisease[`name_${lang}`] = value;
  };

  const handleAddDiseaseOpen = () => {
    setAddDiseaseOpen(true);
  };
  const handleAddDiseaseClose = (event, name, idx) => {
    if (name && name.length > 0) {
      console.log(name, idx);
      addDisease(name, idx);
    }

    setAddDiseaseOpen(false);
  };

  const handleEditDisease = () => {
    editDisease(selectedDisease);
  };
  const handleDeleteDisease = () => {
    deleteDisease(selectedDisease);
  };
  const handleAddSubDisease = () => {
    addSubDisease(selectedDisease);
  };

  const browseFile = (objId) => {
    document.getElementById(objId).click();
  };

  const handleChangeImage = (objId, event) => {
    let file = event && event.target.files[0];
    if (file) {
      //console.log('handleChangeImage file : ', file);
      file['path'] = window.URL.createObjectURL(file);
      if (objId === pcKey) {
        setPcImage([file]);
      } else {
        usedMobileImage && setMobileImage([file]);
      }
    } else {
      console.log('handleChangeImage empty file');
    }
  };

  const handleChangeSortOrder = (event) => {
    selectedDisease['sortOrder'] = event.target.value;
    setSelectedDisease({ ...selectedDisease });
  };

  const getImageSrc = (key) => {
    let file;
    if (key === pcKey) {
      file = pcImage && pcImage[0];
    } else {
      file = usedMobileImage && mobileImage && mobileImage[0];
    }

    if (file) {
      console.log('getImageSrc :', file.path);
      return file.path;
    } else {
      let arr = selectedDisease && selectedDisease[`${key}`];
      if (arr && arr.length > 0) {
        return arr[0].url;
      }
    }
    return '';
  };

  const AddDiseaseDialog = (prop) => {
    const [name, setName] = useState('');
    const updateInput = (event) => {
      setName(event.target.value);
      console.log('updateInput: ', event.target.value);
    };
    return (
      <Dialog open={addDiseaseOpen} onClose={handleAddDiseaseClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Add Disease</DialogTitle>
        <DialogContent>
          <TextField autoFocus margin="dense" id="name" onChange={updateInput} placeholder="Korean Disease Title" fullWidth />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddDiseaseClose} color="primary">
            Cancel
          </Button>
          <Button onClick={(event) => handleAddDiseaseClose(event, name, prop.prop)} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  const AddDiseaseButton = () => (
    <IconButton onClick={handleAddDiseaseOpen}>
      <Icon>add_circle</Icon>
    </IconButton>
  );
  const SortOrderInput = () => {
    if (selectedDisease) {
      return (
        <TextField
          label="Sort"
          id="sortOrder-tf"
          defaultValue="Small"
          size="small"
          type={'number'}
          style={{ width: 100 }}
          variant={'outlined'}
          value={selectedDisease.sortOrder}
          onChange={(e) => handleChangeSortOrder(e)}
        />
      );
    } else {
      return '';
    }
  };
  const EditDiseaseButton = () => {
    if (selectedDisease) {
      return (
        <IconButton onClick={handleEditDisease}>
          <Icon>save</Icon>
        </IconButton>
      );
    } else {
      return '';
    }
  };
  const DeleteDiseaseButton = () => {
    if (selectedDisease) {
      return (
        <IconButton onClick={handleDeleteDisease}>
          <Icon>delete</Icon>
        </IconButton>
      );
    } else {
      return '';
    }
  };
  const AddSubDiseaseButton = () => {
    if (selectedDisease) {
      return (
        <IconButton onClick={handleAddSubDisease}>
          <Icon>add_circle</Icon>
        </IconButton>
      );
    } else {
      return '';
    }
  };

  const addDisease = (name, idx) => {
    if (name) {
      if (name.length === 0) {
        return;
      }
      const param = {
        name_ko: name,
        sortOrder: idx + 1,
      };

      axiosInstance.post(`${API_URL}/disease/`, param, { headers: { Pragma: 'no-cache' } }).then((response) => {
        if (response.status === 200) {
          swal('Registered.', {
            icon: 'success',
          });
          window.localStorage.setItem('diseaseList', '');
          setSelectedDisease(response.data.value);
          setPcImage([]);
          usedMobileImage && setMobileImage([]);
          getData();
        }
      });
    }
  };

  const addSubDisease = (di) => {
    history.push(`/pages/setting/disease/detail?type=new&id=${di.id}`);
  };

  const editDisease = (di) => {
    if (di) {
      const param = {
        id: di.id,
        sortOrder: di.sortOrder,
      };

      languages.map((lan) => {
        param[`name_${lan.id}`] = di[`name_${lan.id}`] || '';
      });
      console.log(param);
      axiosInstance.put(`${API_URL}/disease/`, param, { headers: { Pragma: 'no-cache' } }).then(async (response) => {
        if (response.status === 200) {
          await Promise.all([
            fileUploadOnly(pcImage[0], param.id, 'disease', `${API_URL}/disease/`, 'pcImage').then((r) => {
              console.log(r ? `${r} upload success` : 'upload failed');
            }),
            usedMobileImage &&
              fileUploadOnly(mobileImage[0], param.id, 'disease', `${API_URL}/disease/`, 'mobileImage').then((r) => {
                console.log(r ? `${r} upload success` : 'upload failed');
              }),
          ]).then(() => {
            window.localStorage.setItem('diseaseList', '');
            window.location.reload();
            swal('It is changed.', {
              icon: 'success',
            });
          });
        } else {
          console.log(response);
        }
      });
    }
  };

  const deleteDisease = (di) => {
    if (di) {
      swal({
        title: 'Are you sure you want to delete?',
        text: '',
        icon: 'warning',
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          axiosInstance.delete(`${API_URL}/disease/${di.id}`, { headers: { Pragma: 'no-cache' } }).then((response) => {
            if (response.status === 200) {
              swal('It is deleted.', {
                icon: 'success',
              });
              window.localStorage.setItem('diseaseList', '');
              setSelectedDisease({});
              setPcImage([]);
              usedMobileImage && setMobileImage([]);
              getData();
            }
          });
        } else {
        }
      });
    }
  };

  const LeftList = () => {
    //console.log('LeftList >>>>> diseaseList.length', diseaseList.length);
    return (
      <Paper>
        <List component="nav" aria-label="Disease List">
          <ListItem className={classes.p3}>
            <ListItemText primary="Disease Category" />
            <AddDiseaseButton />
            <AddDiseaseDialog prop={diseaseList.length} />
          </ListItem>
          <Divider />
          {diseaseList
            ? diseaseList.map((di) => {
                return (
                  <ListItem
                    key={di.id}
                    button
                    selected={selectedDisease ? di.id === selectedDisease.id : false}
                    onClick={(event) => handleListItemClick(di.id)}
                  >
                    <ListItemText id={di.id} primary={di[getDefName()] || di['name_ko']} />
                  </ListItem>
                );
              })
            : ''}
        </List>
      </Paper>
    );
  };
  const RightInfo = () => {
    return (
      <Paper className={minHeightPaper} style={{ marginBottom: 20 }}>
        <List component="nav" aria-label="Disease Info">
          <ListItem className={classes.p3}>
            <ListItemText primary={selectedDisease ? selectedDisease[getDefName()] + ' Detail' : 'Detail'} />
            <SortOrderInput />
            <EditDiseaseButton />
            <DeleteDiseaseButton />
          </ListItem>
          <Divider />
          <RightMultipleTf
            classes={classes}
            languages={languages}
            selectedDisease={selectedDisease}
            handleChangeTfNames={handleChangeTfNames}
          />

          <ListItem>
            {selectedDisease && (
              <Grid container spacing={2} className={classes.p1}>
                <Grid item xs={2}>
                  <div style={{ display: 'flex', alignItems: 'center', width: '100%', height: '100%' }}>
                    <TextField
                      type="file"
                      variant="outlined"
                      value={pcImage.path}
                      id={pcKey}
                      onChange={(e) => handleChangeImage(pcKey, e)}
                      style={{ display: 'none' }}
                    />
                    <Button onClick={(e) => browseFile(pcKey)} variant={'outlined'}>
                      <Icon>attach_file</Icon> PC Image
                    </Button>
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'hidden' }}>
                    {getImageSrc(pcKey) && (
                      <img id={`img-${pcKey}`} src={getImageSrc(pcKey)} width={'100%'} height={'100px'} style={{ objectFit: 'cover' }} />
                    )}
                  </div>
                </Grid>
                {usedMobileImage && (
                  <>
                    <Grid item xs={2}>
                      <div style={{ display: 'flex', alignItems: 'center', width: '100%', height: '100%' }}>
                        <TextField
                          type="file"
                          variant="outlined"
                          value={mobileImage.path}
                          id={mobileKey}
                          onChange={(e) => handleChangeImage(mobileKey, e)}
                          style={{ display: 'none' }}
                        />
                        <Button onClick={(e) => browseFile(mobileKey)} variant={'outlined'}>
                          <Icon>attach_file</Icon> Mobile Image
                        </Button>
                      </div>
                    </Grid>
                    <Grid item xs={4}>
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'hidden' }}>
                        {getImageSrc(mobileKey) && (
                          <img
                            id={`img-${mobileKey}`}
                            src={getImageSrc(mobileKey)}
                            width={'100%'}
                            height={'100px'}
                            style={{ objectFit: 'none' }}
                          />
                        )}
                      </div>
                    </Grid>
                  </>
                )}
              </Grid>
            )}
          </ListItem>
        </List>
      </Paper>
    );
  };

  const RightSubList = () => {
    return (
      <Paper className={minHeightPaper}>
        <List component="nav" aria-label="Disease-Sub List">
          <ListItem className={classes.p3}>
            <ListItemText primary="Sub Category" />
            <AddSubDiseaseButton />
          </ListItem>
          <Divider />
          <ListItem>
            <RightSubTable />
          </ListItem>
        </List>
      </Paper>
    );
  };
  const RightSubTable = () => {
    if (languages) {
      return (
        <Table className={classes.table} stickyHeader aria-label="sub-disease-table">
          <TableHead>
            <TableRow>
              {languages.map((lang) => {
                let key = `name_${lang.id}`;
                return (
                  <TableCell key={'col_' + key} align={'center'}>
                    {lang[getDefName()]}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {selectedDisease &&
              subDisease &&
              subDisease.map((di) => {
                return (
                  <TableRow key={'row_' + di.id}>
                    {languages.map((lang) => {
                      let key = `name_${lang.id}`;
                      return (
                        <TableCell
                          key={'cell_' + key + '_' + di.id}
                          align={'center'}
                          onClick={(e) => {
                            handleSubListItemClick(di);
                          }}
                        >
                          {di[key]}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      );
    } else {
      return '';
    }
  };

  return (
    <OftadehLayout>
      <Grid container className={classes.my3} alignItems="center">
        <Grid item>
          <Typography variant="h5" component="h1" style={{ fontWeight: 'bold' }}>
            Disease Title
          </Typography>
        </Grid>
      </Grid>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <LeftList />
          </Grid>
          <Grid item xs={9}>
            <RightInfo />
            <RightSubList />
          </Grid>
        </Grid>
      </main>
    </OftadehLayout>
  );
};
export default SettingDiseaseListPage;
