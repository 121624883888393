import React, { useEffect, useState } from 'react';
import OftadehLayout from '../../../components/OftadehLayout/OftadehLayout';
import { Button, CircularProgress, Grid, makeStyles, Typography } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import { getConsultCaseList } from '../../../constants/PromiseHttp';
import swal from '@sweetalert/with-react';
import axiosInstance from '../../../constants/AxiosInstance';
import { API_URL } from '../../../constants';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  my3: {
    margin: '1.3rem 0',
  },
  mRight: {
    marginRight: '.85rem',
  },
}));

const ConsultCaseListPage = (props) => {
  const { history } = props;
  const classes = useStyles();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);

  const cols = [
    {
      name: 'id',
      label: 'id',
      options: {
        filter: false,
        sort: true,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: 'id',
      label: 'No.',
      options: {
        filter: false,
        sort: true,
        display: true,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return tableMeta.rowIndex + 1;
        },
      },
    },
    {
      name: 'title',
      label: 'Title',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'userName',
      label: 'Inquirer',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'languageId',
      label: 'Language',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'countryId',
      label: 'Country',
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'createdAt',
      label: 'Registration Date',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return moment(Number(value)).format('YYYY-MM-DD HH:mm');
        },
      },
    },
    {
      name: 'viewCnt',
      label: 'View Count',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value || 0;
        },
      },
    },
  ];

  const rowClick = (data) => {
    // console.log(data);
    history.push(`/pages/consult/case/detail?id=${data[0]}`);
  };

  const rowDelete = (row) => {
    let ids = [];
    row.data.map((r) => {
      ids.push(data[r.dataIndex].id);
      return r;
    });
    let delParam = {
      ids: ids.toString(),
    };
    swal({
      title: 'Are you sure you want to delete?',
      text: '',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        axiosInstance.post(`${API_URL}/consultCase/deleteIds`, delParam, { headers: { Pragma: 'no-cache' } }).then((response) => {
          swal('It is deleted.', {
            icon: 'success',
          });
          getData();
        });
      } else {
        getData();
      }
    });
  };

  const options = {
    print: false,
    filter: false,
    filterType: 'checkbox',
    selectableRows: 'multiple',
    viewColumns: false,
    download: false,
    onRowClick: rowClick,
    onRowsDelete: rowDelete,
  };

  const getData = () => {
    setLoading(true);
    getConsultCaseList().then((result) => {
      setLoading(false);
      setData(result);
    });
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <OftadehLayout>
      <Grid container className={classes.my3} alignItems="center">
        <Grid item className={classes.mRight} style={{ fontWeight: 'bold' }}>
          <Typography variant="h5" component="h1" style={{ fontWeight: 'bold' }}>
            Consultation Case list
          </Typography>
        </Grid>
        <Grid item>
          <Button onClick={() => history.push('/pages/consult/case/edit?type=new')} variant="outlined" color="primary" size="small">
            Registration
          </Button>
        </Grid>
      </Grid>
      {/*<OftadehBreadcrumbs path={history} />*/}
      <MUIDataTable title={''} data={data} columns={cols} options={options} />
      {loading && (
        <div style={{ position: 'fixed', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', zIndex: 999 }}>
          <CircularProgress color={'secondary'} />
        </div>
      )}
    </OftadehLayout>
  );
};

export default ConsultCaseListPage;
