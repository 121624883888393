import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import { alpha, makeStyles } from '@material-ui/core/styles';
import OftadehAvatarMenu from '../OftadehAvatarMenu/OftadehAvatarMenu';
import clsx from 'clsx';
import NavigationContext from '../../context/NavigationContext';
import ThemeContext from '../../context/ThemeContext';
import jwt from 'jsonwebtoken';
import { tokenRefresh } from '../../constants';
const moment = require('moment-timezone');

const useStyles = makeStyles((theme) => ({
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: (props) => `calc(100% - ${props.drawerWidth}px)`,
    marginLeft: (props) => props.drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    // marginRight: theme.spacing(2)
  },
  grow: {
    flexGrow: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.type === 'dark' ? alpha(theme.palette.common.white, 0.15) : alpha(theme.palette.action.disabled, 0.15),
    '&:hover': {
      backgroundColor: theme.palette.type === 'dark' ? alpha(theme.palette.common.white, 0.25) : alpha(theme.palette.action.disabled, 0.25),
    },
    // marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  appbarSection: {
    display: 'flex',
    // display: "none",
    alignItems: 'center',
    // [theme.breakpoints.up("sm")]: {
    //   display: "flex"
    // }
  },
  appbarToday: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
}));

const OftadehAppBar = (props) => {
  const classes = useStyles(props);
  const { open, handleDrawerToggle, handleRightPanelOpen } = React.useContext(NavigationContext);

  const { setThemeName, curThemeName } = React.useContext(ThemeContext);

  useEffect(() => {
    // check token expire
    const decoded = jwt.decode(sessionStorage.getItem('bt_jwt'));
    if (decoded) {
      console.log(
        'tokenExp',
        moment(decoded.exp * 1000)
          .tz('Asia/Seoul')
          .format('YYYY-MM-DD HH:mm:ss'),
      );
      const exp = moment(decoded.exp * 1000);
      const now = moment();
      const diff = exp.diff(now, 'minutes');
      console.log(`토근 만료까지 ${diff}분 남았습니다.`);
      if (diff < 10) tokenRefresh().then((r) => {});
    }
  }, []);

  return (
    <AppBar
      position="fixed"
      className={clsx(classes.appBar, {
        [classes.appBarShift]: open,
      })}
    >
      <Toolbar>
        <IconButton color="inherit" aria-label="open drawer" onClick={handleDrawerToggle} edge="start" className={classes.menuButton}>
          <MenuIcon />
        </IconButton>
        {/*<div className={classes.search}>*/}
        {/*  <div className={classes.searchIcon}>*/}
        {/*    <SearchIcon />*/}
        {/*  </div>*/}
        {/*  <InputBase*/}
        {/*    placeholder="Search…"*/}
        {/*    classes={{*/}
        {/*      root: classes.inputRoot,*/}
        {/*      input: classes.inputInput,*/}
        {/*    }}*/}
        {/*    inputProps={{ "aria-label": "search" }}*/}
        {/*  />*/}
        {/*</div>*/}
        <div></div>
        <div className={classes.grow} />
        <div className={classes.grow} />
        <div className={classes.appbarSection}>
          {/*<IconButton*/}
          {/*  aria-haspopup="true"*/}
          {/*  onClick={() =>*/}
          {/*    curThemeName === "dark"*/}
          {/*      ? setThemeName("light")*/}
          {/*      : setThemeName("dark")*/}
          {/*  }*/}
          {/*  color="inherit"*/}
          {/*>*/}
          {/*  {curThemeName === "dark" ? (*/}
          {/*    <Brightness7Icon />*/}
          {/*  ) : (*/}
          {/*    <Brightness4Icon />*/}
          {/*  )}*/}
          {/*</IconButton>*/}
          {/*<div className={classes.appbarToday}>*/}
          {/*  <IconButton*/}
          {/*    aria-haspopup="true"*/}
          {/*    onClick={(event) => handleRightPanelOpen(event, 0)}*/}
          {/*    color="inherit"*/}
          {/*  >*/}
          {/*    <TodayIcon />*/}
          {/*  </IconButton>*/}
          {/*  <IconButton*/}
          {/*    aria-haspopup="true"*/}
          {/*    onClick={(event) => handleRightPanelOpen(event, 2)}*/}
          {/*    aria-label="show 4 new messages"*/}
          {/*    color="inherit"*/}
          {/*  >*/}
          {/*    <Badge badgeContent={4} color="secondary">*/}
          {/*      <MailIcon />*/}
          {/*    </Badge>*/}
          {/*  </IconButton>*/}
          {/*</div>*/}
          {/*<IconButton*/}
          {/*  aria-haspopup="true"*/}
          {/*  onClick={(event) => handleRightPanelOpen(event, 1)}*/}
          {/*  aria-label="show 17 new notifications"*/}
          {/*  color="inherit"*/}
          {/*>*/}
          {/*  <Badge badgeContent={17} color="secondary">*/}
          {/*    <NotificationsIcon />*/}
          {/*  </Badge>*/}
          {/*</IconButton>*/}
          <OftadehAvatarMenu />
        </div>
      </Toolbar>
    </AppBar>
  );
};

OftadehAppBar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  container: PropTypes.any,
};

export default OftadehAppBar;
