import VideoConsultDetailPage from './VideoConsultDetailPage';

export const VideoConsultDetailPageConfig = {
  routes: [
    {
      path: '/pages/consult/video/detail',
      exact: true,
      component: VideoConsultDetailPage,
    },
  ],
};
