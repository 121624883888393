import BookingEditPage from './BookingEditPage';

export const BookingEditPageConfig = {
  routes: [
    {
      path: '/pages/booking/edit',
      exact: true,
      component: BookingEditPage,
    },
  ],
};
