import { makeStyles, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { getHospitalList, getHospitalListIn, getHospitalManagerList } from '../../constants/PromiseHttp';
import { Autocomplete } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  mInput: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
}));

const HospitalAutocomplete = (props) => {
  const [hospitals, setHospitals] = useState([]);

  const classes = useStyles();

  useEffect(() => {
    if (sessionStorage.getItem('bt_role') === 'SYS_MGR') {
      getHospitalManagerList(sessionStorage.getItem('bt_user')).then((result) => {
        setHospitals(result);
      });
    } else {
      getHospitalList().then((result) => {
        setHospitals(result);
      });
    }
  }, []);

  const getSelectedHospital = () => {
    const item =
      hospitals &&
      hospitals.find((hos) => {
        if (props.target ? hos.id === props.target.hospitalId : false) return hos;
      });
    //console.log('getSelectedHospital', item);
    return item || null;
  };

  return (
    <Autocomplete
      key="cbox-hospital"
      value={getSelectedHospital()}
      onChange={(event, newValue) => {
        props.handleChangeData(newValue ? newValue.id : '', 'hospitalId');
        props.withName && props.handleChangeData(newValue ? newValue.name_ko : '', 'hospitalName_ko');
        props.withName && props.handleChangeData(newValue ? newValue.name_en : '', 'hospitalName_en');
      }}
      size={'small'}
      options={hospitals}
      getOptionLabel={(option) => `${option['name_ko']}(${option.languageId})` || ''}
      getOptionSelected={(option, value) => option.id === value.id}
      style={{ width: '100%' }}
      className={classes.mInput}
      renderInput={(params) => <TextField {...params} variant="outlined" inputProps={{ ...params.inputProps, readOnly: false }} />}
    />
  );
};

export default HospitalAutocomplete;
